import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IProjectSubmissionLog, defaultValue } from 'app/shared/model/project-submission-log.model';

export const ACTION_TYPES = {
  FETCH_PROJECTSUBMISSIONLOG_LIST: 'projectSubmissionLog/FETCH_PROJECTSUBMISSIONLOG_LIST',
  FETCH_PROJECTSUBMISSIONLOG: 'projectSubmissionLog/FETCH_PROJECTSUBMISSIONLOG',
  CREATE_PROJECTSUBMISSIONLOG: 'projectSubmissionLog/CREATE_PROJECTSUBMISSIONLOG',
  UPDATE_PROJECTSUBMISSIONLOG: 'projectSubmissionLog/UPDATE_PROJECTSUBMISSIONLOG',
  DELETE_PROJECTSUBMISSIONLOG: 'projectSubmissionLog/DELETE_PROJECTSUBMISSIONLOG',
  RESET: 'projectSubmissionLog/RESET',
  FETCH_PROJECTSUBMISSIONLOG_DETAILS: 'projectSubmissionLog/FETCH_PROJECTSUBMISSIONLOG_DETAILS',
  RESET_PROJECT_SUBMISSION_DETAILS: 'projectSubmissionLog/RESET_PROJECT_SUBMISSION_DETAILS',
};

const initialState = {
  loading: true,
  errorMessage: null,
  entities: [] as ReadonlyArray<IProjectSubmissionLog>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  projectSubmissionDetails: null,
  loadingDetails: true,
};

export type ProjectSubmissionLogState = Readonly<typeof initialState>;

// Reducer

export default (state: ProjectSubmissionLogState = initialState, action): ProjectSubmissionLogState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PROJECTSUBMISSIONLOG_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PROJECTSUBMISSIONLOG):
    case REQUEST(ACTION_TYPES.FETCH_PROJECTSUBMISSIONLOG_DETAILS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_PROJECTSUBMISSIONLOG_DETAILS):
      return {
        ...state,
        errorMessage: null,
        loadingDetails: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PROJECTSUBMISSIONLOG):
    case REQUEST(ACTION_TYPES.UPDATE_PROJECTSUBMISSIONLOG):
    case REQUEST(ACTION_TYPES.DELETE_PROJECTSUBMISSIONLOG):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PROJECTSUBMISSIONLOG_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PROJECTSUBMISSIONLOG):
    case FAILURE(ACTION_TYPES.CREATE_PROJECTSUBMISSIONLOG):
    case FAILURE(ACTION_TYPES.UPDATE_PROJECTSUBMISSIONLOG):
    case FAILURE(ACTION_TYPES.DELETE_PROJECTSUBMISSIONLOG):
    case FAILURE(ACTION_TYPES.FETCH_PROJECTSUBMISSIONLOG_DETAILS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.FETCH_PROJECTSUBMISSIONLOG_DETAILS):
      return {
        ...state,
        errorMessage: action.payload,
        loadingDetails: false,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PROJECTSUBMISSIONLOG_LIST):
      return {
        ...state,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PROJECTSUBMISSIONLOG):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PROJECTSUBMISSIONLOG_DETAILS):
      return {
        ...state,
        projectSubmissionDetails: action.payload.data,
        loadingDetails: false,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PROJECTSUBMISSIONLOG):
    case SUCCESS(ACTION_TYPES.UPDATE_PROJECTSUBMISSIONLOG):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PROJECTSUBMISSIONLOG):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case SUCCESS(ACTION_TYPES.RESET_PROJECT_SUBMISSION_DETAILS):
      return {
        ...state,
        projectSubmissionDetails: null,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/project-submission-logs';

// Actions

// @ts-ignore
export const getEntities: ICrudGetAllAction<IProjectSubmissionLog> = (page, size, sort, filters, search) => {
  const params = prepareQueryParams(page, size, sort, filters, search);
  return {
    type: ACTION_TYPES.FETCH_PROJECTSUBMISSIONLOG_LIST,
    payload: axios.get<IProjectSubmissionLog>(`${apiUrl}${params ? `?${params}` : ''}`),
  };
};
const prepareQueryParams = (page, size, sort, filter, search) => {
  const params = [];
  params.push(encodeURIComponent('page') + '=' + encodeURIComponent(page ? page : 0));
  if (size) {
    params.push(encodeURIComponent('size') + '=' + encodeURIComponent(size));
  }
  if (sort) {
    params.push(encodeURIComponent('sort') + '=' + encodeURIComponent(sort));
  }
  if (filter) {
    if (filter.email && filter.email.length) {
      params.push(encodeURIComponent('email.contains') + '=' + encodeURIComponent(filter.email));
    }
    if (filter.guid && filter.guid.length) {
      params.push(encodeURIComponent('guid.equals') + '=' + encodeURIComponent(filter.guid));
    }
    if (filter.autoDeskId && filter.autoDeskId.length) {
      params.push(encodeURIComponent('autoDeskId.equals') + '=' + encodeURIComponent(filter.autoDeskId));
    }
    if (filter.jobId && filter.jobId.length) {
      params.push(encodeURIComponent('jobId.equals') + '=' + encodeURIComponent(filter.jobId));
    }
    if (filter.projectCode && filter.projectCode.length) {
      params.push(encodeURIComponent('projectCode.equals') + '=' + encodeURIComponent(filter.projectCode));
    }
    if (filter.projectName && filter.projectName.length) {
      params.push(encodeURIComponent('projectName.equals') + '=' + encodeURIComponent(filter.projectName));
    }
    if (filter.clientName && filter.clientName.length) {
      params.push(encodeURIComponent('clientName.equals') + '=' + encodeURIComponent(filter.clientName));
    }
    if (filter.addedDate) {
      params.push(encodeURIComponent('dateCreated.greaterOrEqualThan') + '=' + encodeURIComponent(filter.dateCreatedTo.format()));
    }
    if (filter.addedDate) {
      params.push(encodeURIComponent('dateCreated.lessOrEqualThan') + '=' + encodeURIComponent(filter.dateCreatedTo.format()));
    }
    if (search) {
      params.push(encodeURIComponent('search') + '=' + encodeURIComponent(search));
    }
    params.push(encodeURIComponent('cacheBuster') + '=' + encodeURIComponent(`${new Date().getTime()}`));
    return params.join('&');
  }
};
export const getEntity: ICrudGetAction<IProjectSubmissionLog> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PROJECTSUBMISSIONLOG,
    payload: axios.get<IProjectSubmissionLog>(requestUrl),
  };
};

export const getProjectSubmissionDetails = id => {
  const requestUrl = `${apiUrl}/details/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PROJECTSUBMISSIONLOG_DETAILS,
    payload: axios.get(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IProjectSubmissionLog> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_PROJECTSUBMISSIONLOG,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IProjectSubmissionLog> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PROJECTSUBMISSIONLOG,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IProjectSubmissionLog> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PROJECTSUBMISSIONLOG,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const resetProjectSubmissionDetails = () => ({
  type: ACTION_TYPES.RESET_PROJECT_SUBMISSION_DETAILS,
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
