import React from 'react';
import { Table, Button } from 'reactstrap';
import './projectMarketplace.scss';

interface IProductsTable {
  products: any;
  openDeliveryDetailsModal: Function;
  openDealersModal: Function;
}

const ProductsTable = (props: IProductsTable) => {
  return (
    <Table responsive borderless className="kreator-page-table-template">
      <thead>
        <tr className="kreator-page-table-template--header">
          <th></th>
          <th>Manufacturer</th>
          <th>Product</th>
          <th></th>
          <th>Dealer(s)</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.products.map(product => (
          <tr key={product}>
            <td>
              <img src="../../../content/images/cart-product-example.jpg" width="50" />
            </td>
            <td>Thermo-Kool</td>
            <td>Walk-In</td>
            <td>
              <Button onClick={props.openDealersModal} className="project-marketplace-table-item">
                Select Dealer(s)
              </Button>
            </td>
            <td>Singer Equipment</td>
            <td style={{ maxWidth: 220 }}>
              <Button onClick={props.openDeliveryDetailsModal} className="project-marketplace-table-item">
                Select Delivery & Installation Options
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ProductsTable;
