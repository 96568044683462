import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getProject, updateProject, createProject, reset } from './project.reducer';
import { IProject } from 'app/shared/model/project.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IProjectUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IProjectUpdateState {
  isNew: boolean;
  userId: string;
}

export class ProjectUpdate extends React.Component<IProjectUpdateProps, IProjectUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      userId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getProject(this.props.match.params.id);
    }

    this.props.getUsers();
  }

  saveEntity = (event, errors, values) => {
    values.dateCreated = convertDateTimeToServer(values.dateCreated);
    values.lastModified = convertDateTimeToServer(values.lastModified);

    if (errors.length === 0) {
      const { projectEntity } = this.props;
      const entity = {
        ...projectEntity,
        ...values,
      };

      if (this.state.isNew) {
        this.props.createProject(entity);
      } else {
        this.props.updateProject(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/project');
  };

  render() {
    const { projectEntity, users, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="familyBuilderApiApp.project.home.createOrEditLabel">Create or edit a Project</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : projectEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="project-id">ID</Label>
                    <AvInput id="project-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="projectNameLabel" for="project-projectName">
                    Project Name
                  </Label>
                  <AvField id="project-projectName" type="text" name="projectName" />
                </AvGroup>
                <AvGroup>
                  <Label id="isRoomDesignLabel" check>
                    <AvInput id="project-isRoomDesign" type="checkbox" className="form-control" name="isRoomDesign" />
                    Is Room Design
                  </Label>
                </AvGroup>
                <AvGroup>
                  <Label id="roomlePlanIdLabel" for="project-roomlePlanId">
                    Roomle Plan Id
                  </Label>
                  <AvField id="project-roomlePlanId" type="text" name="roomlePlanId" />
                </AvGroup>
                <AvGroup>
                  <Label id="jobIdLabel" for="project-jobId">
                    Job Id
                  </Label>
                  <AvField id="project-jobId" type="string" className="form-control" name="jobId" />
                </AvGroup>
                <AvGroup>
                  <Label id="revitProjectFileLabel" for="project-revitProjectFile">
                    Revit Project File
                  </Label>
                  <AvField id="project-revitProjectFile" type="text" name="revitProjectFile" />
                </AvGroup>
                <AvGroup>
                  <Label id="thumbnailImageLabel" for="project-thumbnailImage">
                    Thumbnail Image
                  </Label>
                  <AvField id="project-thumbnailImage" type="text" name="thumbnailImage" />
                </AvGroup>
                <AvGroup>
                  <Label id="clientNameLabel" for="project-clientName">
                    Client Name
                  </Label>
                  <AvField id="project-clientName" type="text" name="clientName" />
                </AvGroup>
                <AvGroup>
                  <Label id="dateCreatedLabel" for="project-dateCreated">
                    Date Created
                  </Label>
                  <AvInput
                    id="project-dateCreated"
                    type="datetime-local"
                    className="form-control"
                    name="dateCreated"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.projectEntity.dateCreated)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="lastModifiedLabel" for="project-lastModified">
                    Last Modified
                  </Label>
                  <AvInput
                    id="project-lastModified"
                    type="datetime-local"
                    className="form-control"
                    name="lastModified"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.projectEntity.lastModified)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="statusLabel" for="project-status">
                    Status
                  </Label>
                  <AvInput
                    id="project-status"
                    type="select"
                    className="form-control"
                    name="status"
                    value={(!isNew && projectEntity.status) || 'IN_PROGRESS'}
                  >
                    <option value="IN_PROGRESS">IN_PROGRESS</option>
                    <option value="SENT">SENT</option>
                    <option value="APPROVED">APPROVED</option>
                    <option value="REVISE_RESUBMIT">REVISE_RESUBMIT</option>
                    <option value="CLOSED">CLOSED</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="guidLabel" for="project-guid">
                    Guid
                  </Label>
                  <AvField id="project-guid" type="text" name="guid" />
                </AvGroup>
                <AvGroup>
                  <Label id="autoDeskIdLabel" for="project-autoDeskId">
                    Auto Desk Id
                  </Label>
                  <AvField id="project-autoDeskId" type="text" name="autoDeskId" />
                </AvGroup>
                <AvGroup>
                  <Label id="outputZipUrlLabel" for="project-outputZipUrl">
                    Output Zip Url
                  </Label>
                  <AvField id="project-outputZipUrl" type="text" name="outputZipUrl" />
                </AvGroup>
                <AvGroup>
                  <Label id="outputReportUrlLabel" for="project-outputReportUrl">
                    Output Report Url
                  </Label>
                  <AvField id="project-outputReportUrl" type="text" name="outputReportUrl" />
                </AvGroup>
                <AvGroup>
                  <Label id="forgeStatusLabel" for="project-forgeStatus">
                    Forge Status
                  </Label>
                  <AvInput
                    id="project-forgeStatus"
                    type="select"
                    className="form-control"
                    name="forgeStatus"
                    value={(!isNew && projectEntity.forgeStatus) || 'IN_PROGRESS'}
                  >
                    <option value="IN_PROGRESS">IN_PROGRESS</option>
                    <option value="COMPLETE">COMPLETE</option>
                    <option value="FAILURE">FAILURE</option>
                    <option value="GENERATING_RVT">GENERATING_RVT</option>
                    <option value="GENERATING_DWG">GENERATING_DWG</option>
                    <option value="GENERATING_ZIP">GENERATING_ZIP</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="projectCodeLabel" for="project-projectCode">
                    Project Code
                  </Label>
                  <AvField id="project-projectCode" type="text" name="projectCode" />
                </AvGroup>
                <AvGroup>
                  <Label id="projectStreetAddressLabel" for="project-projectStreetAddress">
                    Project Street Address
                  </Label>
                  <AvField id="project-projectStreetAddress" type="text" name="projectStreetAddress" />
                </AvGroup>
                <AvGroup>
                  <Label id="projectCityLabel" for="project-projectCity">
                    Project City
                  </Label>
                  <AvField id="project-projectCity" type="text" name="projectCity" />
                </AvGroup>
                <AvGroup>
                  <Label id="projectStateLabel" for="project-projectState">
                    Project State
                  </Label>
                  <AvField id="project-projectState" type="text" name="projectState" />
                </AvGroup>
                <AvGroup>
                  <Label id="projectZipCodeLabel" for="project-projectZipCode">
                    Project Zip Code
                  </Label>
                  <AvField id="project-projectZipCode" type="text" name="projectZipCode" />
                </AvGroup>
                <AvGroup>
                  <Label id="userCompanyNameLabel" for="project-userCompanyName">
                    User Company Name
                  </Label>
                  <AvField id="project-userCompanyName" type="text" name="userCompanyName" />
                </AvGroup>
                <AvGroup>
                  <Label id="userStreetAddressLabel" for="project-userStreetAddress">
                    User Street Address
                  </Label>
                  <AvField id="project-userStreetAddress" type="text" name="userStreetAddress" />
                </AvGroup>
                <AvGroup>
                  <Label id="userCityLabel" for="project-userCity">
                    User City
                  </Label>
                  <AvField id="project-userCity" type="text" name="userCity" />
                </AvGroup>
                <AvGroup>
                  <Label id="userStateLabel" for="project-userState">
                    User State
                  </Label>
                  <AvField id="project-userState" type="text" name="userState" />
                </AvGroup>
                <AvGroup>
                  <Label id="userZipCodeLabel" for="project-userZipCode">
                    User Zip Code
                  </Label>
                  <AvField id="project-userZipCode" type="text" name="userZipCode" />
                </AvGroup>
                <AvGroup>
                  <Label id="userCompanyUrlLabel" for="project-userCompanyUrl">
                    User Company Url
                  </Label>
                  <AvField id="project-userCompanyUrl" type="text" name="userCompanyUrl" />
                </AvGroup>
                <AvGroup>
                  <Label id="userPhoneLabel" for="project-userPhone">
                    User Phone
                  </Label>
                  <AvField id="project-userPhone" type="text" name="userPhone" />
                </AvGroup>
                <AvGroup>
                  <Label for="project-user">User</Label>
                  <AvInput id="project-user" type="select" className="form-control" name="userId">
                    <option value="" key="0" />
                    {users
                      ? users.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.login}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/project" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Save
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  users: storeState.userManagement.users,
  projectEntity: storeState.project.entity,
  loading: storeState.project.loading,
  updating: storeState.project.updating,
  updateSuccess: storeState.project.updateSuccess,
});

const mapDispatchToProps = {
  getUsers,
  getProject,
  updateProject,
  createProject,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProjectUpdate);
