// prettier-ignore
import folder, {
  FolderState
} from 'app/entities/folder/folder.reducer';
// prettier-ignore
import manufacturer, {
  ManufacturerState
} from 'app/entities/manufacturer/manufacturer.reducer';
// prettier-ignore
import product, {
  ProductState
} from 'app/entities/product/product.reducer';
// prettier-ignore
import roomleProduct, {
  RoomleProductState
} from 'app/entities/roomle-product/roomle-product.reducer';
// prettier-ignore
import userConfiguration, {
  UserConfigurationState
} from 'app/entities/user-configuration/user-configuration.reducer';
// prettier-ignore
import version, {
  VersionState
} from 'app/entities/version/version.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import fillUserInfo, { FillUserInfoState } from 'app/modules/account/fill-user-info/fill-user-info.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';
import { combineReducers } from 'redux';
import applicationProfile, { ApplicationProfileState } from './application-profile';
import authentication, { AuthenticationState } from './authentication';

// prettier-ignore
import dealer, {
  DealerState
} from 'app/entities/dealer/dealer.reducer';
// prettier-ignore
import leads, {
  LeadsState
} from 'app/entities/leads/leads.reducer';
// prettier-ignore
import enabledProductConfigurators, {
  EnabledProductConfiguratorsState
} from 'app/entities/enabled-product-configurators/enabled-product-configurators.reducer';
// prettier-ignore
import leadsProducts, {
  LeadsProductsState
} from 'app/entities/leads-products/leads-products.reducer';
// prettier-ignore
import enableRegion, {
  EnableRegionState
} from 'app/entities/enable-region/enable-region.reducer';
// prettier-ignore
import notification, {
  NotificationState
} from 'app/entities/notification/notification.reducer';
// prettier-ignore
import userNotificationToken, {
  UserNotificationTokenState
} from 'app/entities/user-notification-token/user-notification-token.reducer';
// prettier-ignore
import userSettings, {
  UserSettingsState
} from 'app/entities/user-settings/user-settings.reducer';
// prettier-ignore
import dealerData, {
  DealerDataState
} from 'app/entities/dealer-data/dealer-data.reducer';

import stripe, { StripeState } from 'app/entities/stripe/stripe.reducer';
// prettier-ignore
import project, {
  ProjectState
} from 'app/entities/project/project.reducer';
// prettier-ignore
import projectProduct, {
  ProjectProductState
} from 'app/entities/project-product/project-product.reducer';
// prettier-ignore
import projectSubmissionLog, {
  ProjectSubmissionLogState
} from 'app/entities/project-submission-log/project-submission-log.reducer';
// prettier-ignore
import country, {
  CountryState
} from 'app/entities/country/country.reducer';
// prettier-ignore
import emailSetting, {
  EmailSettingState
} from 'app/entities/email-setting/email-setting.reducer';
// prettier-ignore
import consultantData, {
  ConsultantDataState
} from 'app/entities/consultant-data/consultant-data.reducer';
// prettier-ignore
import consultant, {
  ConsultantState
} from 'app/entities/consultant/consultant.reducer';
// prettier-ignore
import consultantUser, {
  ConsultantUserState
} from 'app/entities/consultant-user/consultant-user.reducer';
// prettier-ignore
import manufacturerRef, {
  ManufacturerRefState
} from 'app/entities/manufacturer-ref/manufacturer-ref.reducer';
// prettier-ignore
import manufacturerRefData, {
  ManufacturerRefDataState
} from 'app/entities/manufacturer-ref-data/manufacturer-ref-data.reducer';
// prettier-ignore
import manufacturerData, {
  ManufacturerDataState
} from 'app/entities/manufacturer-data/manufacturer-data.reducer';
// prettier-ignore
import socketConnection, {
  SocketConnectionState
} from 'app/entities/socket-connection/socket-connection.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly product: ProductState;
  readonly roomleProduct: RoomleProductState;
  readonly manufacturer: ManufacturerState;
  readonly userConfiguration: UserConfigurationState;
  readonly folder: FolderState;
  readonly version: VersionState;
  readonly fillUserInfo: FillUserInfoState;
  readonly dealer: DealerState;
  readonly leads: LeadsState;
  readonly enabledProductConfigurators: EnabledProductConfiguratorsState;
  readonly leadsProducts: LeadsProductsState;
  readonly enableRegion: EnableRegionState;
  readonly notification: NotificationState;
  readonly userNotificationToken: UserNotificationTokenState;
  readonly userSettings: UserSettingsState;
  readonly dealerData: DealerDataState;
  readonly stripe: StripeState;
  readonly project: ProjectState;
  readonly projectProduct: ProjectProductState;
  readonly projectSubmissionLog: ProjectSubmissionLogState;
  readonly country: CountryState;
  readonly emailSetting: EmailSettingState;
  readonly consultantData: ConsultantDataState;
  readonly consultant: ConsultantState;
  readonly consultantUser: ConsultantUserState;
  readonly manufacturerRef: ManufacturerRefState;
  readonly manufacturerRefData: ManufacturerRefDataState;
  readonly manufacturerData: ManufacturerDataState;
  readonly socketConnection: SocketConnectionState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  product,
  roomleProduct,
  manufacturer,
  userConfiguration,
  folder,
  version,
  fillUserInfo,
  dealer,
  leads,
  enabledProductConfigurators,
  leadsProducts,
  enableRegion,
  notification,
  userNotificationToken,
  userSettings,
  dealerData,
  stripe,
  project,
  projectProduct,
  projectSubmissionLog,
  country,
  emailSetting,
  consultantData,
  consultant,
  consultantUser,
  manufacturerRef,
  manufacturerRefData,
  manufacturerData,
  socketConnection,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
});

export default rootReducer;
