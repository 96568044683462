import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import EnabledProductConfigurators from './enabled-product-configurators';
import EnabledProductConfiguratorsDetail from './enabled-product-configurators-detail';
import EnabledProductConfiguratorsUpdate from './enabled-product-configurators-update';
import EnabledProductConfiguratorsDeleteDialog from './enabled-product-configurators-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={EnabledProductConfiguratorsUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={EnabledProductConfiguratorsUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={EnabledProductConfiguratorsDetail} />
      <ErrorBoundaryRoute path={match.url} component={EnabledProductConfigurators} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={EnabledProductConfiguratorsDeleteDialog} />
  </>
);

export default Routes;
