import React from 'react';
import { Switch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Product from './product';
import Manufacturer from './manufacturer';
import UserConfiguration from './user-configuration';
import Folder from './folder';
import Version from './version';
import Dealer from './dealer';
import Leads from './leads';
import EnabledProductConfigurators from './enabled-product-configurators';
import LeadsProducts from './leads-products';
import EnableRegion from './enable-region';
import Notification from './notification';
import UserNotificationToken from './user-notification-token';
import UserSettings from './user-settings';
import DealerData from './dealer-data';
import Project from './project';
import ProjectProduct from './project-product';
import ProjectSubmissionLog from './project-submission-log';
import Country from './country';
import EmailSetting from './email-setting';
import ConsultantData from './consultant-data';
import Consultant from './consultant';
import ConsultantUser from './consultant-user';
import ManufacturerRef from './manufacturer-ref';
import ManufacturerRefData from './manufacturer-ref-data';
import ManufacturerData from './manufacturer-data';
import SocketConnection from './socket-connection';
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}product`} component={Product} />
      <ErrorBoundaryRoute path={`${match.url}manufacturer`} component={Manufacturer} />
      <ErrorBoundaryRoute path={`${match.url}user-configuration`} component={UserConfiguration} />
      <ErrorBoundaryRoute path={`${match.url}folder`} component={Folder} />
      <ErrorBoundaryRoute path={`${match.url}version`} component={Version} />
      <ErrorBoundaryRoute path={`${match.url}dealer`} component={Dealer} />
      <ErrorBoundaryRoute path={`${match.url}leads`} component={Leads} />
      <ErrorBoundaryRoute path={`${match.url}enabled-product-configurators`} component={EnabledProductConfigurators} />
      <ErrorBoundaryRoute path={`${match.url}leads-products`} component={LeadsProducts} />
      <ErrorBoundaryRoute path={`${match.url}enable-region`} component={EnableRegion} />
      <ErrorBoundaryRoute path={`${match.url}notification`} component={Notification} />
      <ErrorBoundaryRoute path={`${match.url}user-notification-token`} component={UserNotificationToken} />
      <ErrorBoundaryRoute path={`${match.url}user-settings`} component={UserSettings} />
      <ErrorBoundaryRoute path={`${match.url}dealer-data`} component={DealerData} />
      <ErrorBoundaryRoute path={`${match.url}project`} component={Project} />
      <ErrorBoundaryRoute path={`${match.url}project-product`} component={ProjectProduct} />
      <ErrorBoundaryRoute path={`${match.url}/project-submission-log`} component={ProjectSubmissionLog} />
      <ErrorBoundaryRoute path={`${match.url}/country`} component={Country} />
      <ErrorBoundaryRoute path={`${match.url}/email-setting`} component={EmailSetting} />
      <ErrorBoundaryRoute path={`${match.url}/consultant-data`} component={ConsultantData} />
      <ErrorBoundaryRoute path={`${match.url}/consultant`} component={Consultant} />
      <ErrorBoundaryRoute path={`${match.url}/consultant-user`} component={ConsultantUser} />
      <ErrorBoundaryRoute path={`${match.url}/manufacturer-ref`} component={ManufacturerRef} />
      <ErrorBoundaryRoute path={`${match.url}/manufacturer-ref-data`} component={ManufacturerRefData} />
      <ErrorBoundaryRoute path={`${match.url}/manufacturer-data`} component={ManufacturerData} />
      <ErrorBoundaryRoute path={`${match.url}/socket-connection`} component={SocketConnection} />
      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
