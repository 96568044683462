import React, { useEffect } from 'react';
import { CardImg, Badge, Button } from 'reactstrap';
import BaseLayout from '../BaseLayout';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { getConsultantUSerForCurrentUser } from 'app/entities/consultant-user/consultant-user.reducer';

export interface IManufacturerProps extends StateProps, DispatchProps {}

const MyConsultantAdmin = (props: IManufacturerProps) => {
  useEffect(() => {
    props.getConsultantUSerForCurrentUser();
  }, []);

  return (
    <BaseLayout title={'My Manufacturer'}>
      {!props.loading && props.consultantUser && (
        <div className="product-details-modal-container">
          <div className="product-details-modal-image-block">
            <CardImg top src={props.consultantUser.logo} alt="Missing image" className="image product-details-image" />
          </div>
          <div className="product-details-modal-image-block">
            <Button color="primary" tag={Link} id="cancel-save" to="/edit-my-manufacturer">
              <FontAwesomeIcon icon="save" />
              &nbsp; Edit
            </Button>
          </div>
          <div className="product-details-modal-data-block">
            <div className="product-details-modal-data-container">
              <div className="product-details-modal-product-name">
                <span className="product-details-modal-name-description">Manufacturer Name</span>
                <span className="product-details-modal-name-value">{props.consultantUser.name}</span>
                <br />
                <span className="product-details-modal-key">Manufacturer City</span>
                <span className="product-details-modal-manufacturer-value">{props.consultantUser.city}</span>
                <br />
                <span className="product-details-modal-key">State</span>
                <span className="product-details-modal-manufacturer-value">{props.consultantUser.state}</span>
                <br />
                <span className="product-details-modal-key">Website</span>
                <span className="product-details-modal-manufacturer-value">{props.consultantUser.websiteURL}</span>
                <br />
                <span className="product-details-modal-key">Status</span>
                <span className="product-details-modal-manufacturer-value">
                  {props.consultantUser.enabled ? <Badge color="success">Active</Badge> : <Badge color="danger">Inactive</Badge>}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </BaseLayout>
  );
};

const mapStateToProps = ({ consultantUser }: IRootState) => ({
  consultantUser: consultantUser.entity,
  loading: consultantUser.loading,
});

const mapDispatchToProps = {
  getConsultantUSerForCurrentUser,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MyConsultantAdmin);
