import React from 'react';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';
import './stepProgressBar.scss';

const StepProgressBar = props => {
  return (
    <ProgressBar percent={props.step} unfilledBackground={'#9FAAB5'} filledBackground={'#9FAAB5'} height={3}>
      <Step>
        {({ accomplished, index }) => (
          <div className="progress-step-container">
            <div className={`indexedStep ${accomplished ? 'accomplished bgOrange' : null}`}>{index + 1}</div>{' '}
            <span className={`progress-bar-step-title ${accomplished ? 'orange' : null}`}>Manufacturer</span>
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div className="progress-step-container">
            <div className={`indexedStep ${accomplished ? 'accomplished bgBlue' : null}`}>{index + 1}</div>{' '}
            <span className={`progress-bar-step-title ${accomplished ? 'blue' : null}`}>Dealers</span>
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div className="progress-step-container">
            <div className={`indexedStep ${accomplished ? 'accomplished bgPurple' : null}`}>{index + 1}</div>{' '}
            <span className={`progress-bar-step-title ${accomplished ? 'purple' : null}`}>Cart</span>
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div className="progress-step-container">
            <div className={`indexedStep ${accomplished ? 'accomplished bgBlack' : null}`}>{index + 1}</div>
            <span style={{ width: 80 }} className={`progress-bar-step-title ${accomplished ? 'black' : null}`}>
              User info
            </span>
          </div>
        )}
      </Step>
    </ProgressBar>
  );
};

export default StepProgressBar;
