// @ts-nocheck - may need to be at the start of file
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { setMarketPlaceMode } from 'app/entities/user-configuration/user-configuration.reducer';
import { getDealersForMarketplace } from 'app/entities/dealer/dealer.reducer';
import { getManufacturersForMarketplace } from 'app/entities/manufacturer/manufacturer.reducer';
import { getUserConfigurationsByDearels } from 'app/entities/user-configuration/user-configuration.reducer';
import { createLeadsCart } from 'app/entities/leads/leads.reducer';
import { resetMarketplaceForm } from 'app/entities/leads/leads.reducer';
import { resetUserConfigurationsByDealers } from 'app/entities/user-configuration/user-configuration.reducer';
import { resetManufacturersForMarketplace } from 'app/entities/manufacturer/manufacturer.reducer';
import { resetDealersForMarketplace } from 'app/entities/dealer/dealer.reducer';
import { connect } from 'react-redux';
import StepProgressBar from 'app/components/StepProgressBar/StepProgressBar';
import ThreeStepProgressBar from 'app/components/StepProgressBar/ThreeStepProgressBar';
import Search from 'app/components/Search/Search';
import MarketPlaceManufacturerCard from 'app/components/MarketPlaceManufacturerCard/MarketPlaceManufacturerCard';
import MarketPlaceManufacturerByMfrCard from 'app/components/MarketPlaceManufacturerCard/MarketplaceManufacturerByMfrCard';
import MarketPlaceDealerCard from 'app/components/MarketPlaceDealerCard/MarketPlaceDealerCard';
import MarketplaceDealerDetailsModal from 'app/components/MarketPlaceDealerCard/MarketplaceDealerDetailsModal';
import MarketplaceCartItem from 'app/components/MarketplaceCartItem/MarketplaceCartItem';
import MarketplaceFormPage from 'app/components/MarketplaceFormPage/MarketplaceFormPage';
import './marketplace.scss';

interface IMarketPlacePage extends StateProps, DispatchProps, RouteComponentProps<{ configurationId: string }> {}

const MarketplacePage = (props: IMarketPlacePage) => {
  const [step, setStep] = useState(25);
  const [isManufacturersByMfr, setIsManufacturersByMfr] = useState(false);
  const [isDealerDetailsOpen, setIsDealerDetailsOpen] = useState(false);
  const [dealerForDetailsModal, setDealerForDetailsModal] = useState(null);
  const [selectedManufacturers, setSelectedManufacturers] = useState([]);
  const [selectedDealers, setSelectedDealers] = useState([]);
  const [leadsProducts, setLidsProducts] = useState([]);
  const history = useHistory();

  const marketplaceSteps = {
    manufacturers: 25,
    dealers: 50,
    cart: 75,
    userInfo: 100,
  };

  useEffect(() => {
    props.setMarketPlaceMode(true);

    if (props.match.params.configurationManufacturer) {
      setStep(marketplaceSteps.dealers);
    } else {
      props.getManufacturersForMarketplace(props.match.params.configurationId);
    }

    return () => {
      props.setMarketPlaceMode(false);
    };
  }, []);

  useEffect(() => {
    if (step === marketplaceSteps.dealers && !props.dealers.length) {
      props.getDealersForMarketplace(props.match.params.configurationId);
    }
    if (step === marketplaceSteps.cart) {
      getUserConfigurationsBySelectedDealers();
    }
  }, [step]);

  useEffect(() => {
    const configuratorsArray = [];

    props.userConfigurationsByDealer.map(dealer => {
      dealer.userConfigurations.map(configuration => {
        const configuratorObject = {
          dealerId: dealer.id,
          quantity: 1,
          userConfigurationId: configuration.id,
        };
        configuratorsArray.push(configuratorObject);
      });
    });

    setLidsProducts(configuratorsArray);
  }, [props.userConfigurationsByDealer]);

  useEffect(() => {
    if (props.formSendingSuccess) {
      returnToKreator();
      props.resetMarketplaceForm();
      props.resetUserConfigurationsByDealers();
      props.resetManufacturersForMarketplace();
      props.resetDealersForMarketplace();
    }
  }, [props.formSendingSuccess]);

  const setNextStep = () => {
    if (step < 100) setStep(step + 25);
  };

  const setPreviousStep = () => {
    if (step > 25) setStep(step - 25);
  };

  const selectEntity = (e, selectedEntitiesArray, selectedEntity, setSelectFunction) => {
    if (e.target.nodeName !== 'I') {
      const exists = selectedEntitiesArray.includes(selectedEntity);
      const selectedArray = [...selectedEntitiesArray];

      if (exists) {
        setSelectFunction(
          selectedArray.filter(item => {
            return item !== selectedEntity;
          })
        );
      } else if (selectedArray.length < 3) {
        const result = selectedArray;
        result.push(selectedEntity);
        setSelectFunction(result);
      }
    }
  };

  const getUserConfigurationsBySelectedDealers = () => {
    const dataForRequest = {
      dealerIds: selectedDealers,
      userConfigurationId: props.match.params.configurationId,
    };

    props.getUserConfigurationsByDearels(dataForRequest);
  };

  const closeDealerDetailsModal = () => {
    setIsDealerDetailsOpen(false);
  };

  const openDealerDetailsModal = dealer => {
    setDealerForDetailsModal(dealer);
    setIsDealerDetailsOpen(true);
  };

  const returnToKreator = () => {
    history.goBack();
  };

  const changeProductCount = (productId, dealerId, quantity) => {
    const updatedLeadsProductsArray = leadsProducts.map(product => {
      if (product.userConfigurationId === productId && product.dealerId === dealerId) {
        return { ...product, quantity };
      } else {
        return product;
      }
    });
    setLidsProducts(updatedLeadsProductsArray);
  };

  const submitMarketplaceForm = formData => {
    const requestData = {
      dateTime: Date.now().toString(),
      leadsProducts,
      manufacturerIds: selectedManufacturers,
      ...formData,
      sameAsAbove: false,
    };

    props.createLeadsCart(requestData);
  };

  let buttonsClass = 'marketplace-buttons-container';

  if (props.loadingManufactirers || props.loadingDealers || props.loadingConfiguration) {
    buttonsClass += ' d-none';
  }

  return (
    <>
      {step === marketplaceSteps.cart && <div className="marketplace-cart-title">Cart</div>}
      <div className="step-progress-bar-container">
        {props.match.params.configurationManufacturer ? (
          <ThreeStepProgressBar className="step-progress-bar" step={step} />
        ) : (
          <StepProgressBar className="step-progress-bar" step={step} />
        )}
      </div>
      <div className="marketplace-list-container">
        <div className="marketplace-list-container-header">
          {step === marketplaceSteps.manufacturers && !isManufacturersByMfr && (
            <span className="marketplace-list-container-title">Select Manufacturer(s)</span>
          )}
          {step === marketplaceSteps.manufacturers && isManufacturersByMfr && (
            <span className="marketplace-list-container-title">Select Manufacturer by Mfr Rep</span>
          )}

          {step === marketplaceSteps.dealers && <span className="marketplace-list-container-title">Select Dealer(s)</span>}

          {step < marketplaceSteps.cart && <Search />}
        </div>
        <div className="marketplace-cart-tax-container">
          {step === marketplaceSteps.manufacturers && (
            <div className="marketplace-manufacturer-type-toggle-container">
              <p className="marketplace-manufacturer-type-toggle-or">or</p>
              {step === marketplaceSteps.manufacturers && isManufacturersByMfr ? (
                <p className="marketplace-manufacturer-type-toggle" onClick={() => setIsManufacturersByMfr(false)}>
                  Select by Manufacturer
                </p>
              ) : (
                <p className="marketplace-manufacturer-type-toggle" onClick={() => setIsManufacturersByMfr(true)}>
                  Select by Manufacturer by Mfr Rep
                </p>
              )}
            </div>
          )}
          {step === marketplaceSteps.cart && <span className="marketplace-cart-tax">Tax Charged by Dealer</span>}
        </div>

        <div className="marketplace-list">
          {step === marketplaceSteps.manufacturers &&
            !isManufacturersByMfr &&
            props.manufacturers.map(item => (
              <MarketPlaceManufacturerCard
                manufacturer={item}
                key={item.id}
                onSelect={selectEntity}
                selectedManufacturers={selectedManufacturers}
                setSelectedManufacturers={setSelectedManufacturers}
              />
            ))}
          {step === marketplaceSteps.manufacturers &&
            isManufacturersByMfr &&
            props.manufacturers.map(item => (
              <MarketPlaceManufacturerByMfrCard
                manufacturer={item}
                key={item.id}
                onSelect={selectEntity}
                selectedManufacturers={selectedManufacturers}
                setSelectedManufacturers={setSelectedManufacturers}
              />
            ))}

          {step === marketplaceSteps.dealers &&
            props.dealers.map(item => (
              <MarketPlaceDealerCard
                dealer={item}
                key={item.id}
                onSelect={selectEntity}
                selectedDealers={selectedDealers}
                setSelectedDealers={setSelectedDealers}
                openDetailsModal={openDealerDetailsModal}
              />
            ))}

          {step === marketplaceSteps.cart &&
            !props.loadingConfiguration &&
            props.userConfigurationsByDealer.map(item => (
              <MarketplaceCartItem changeProductCount={changeProductCount} item={item} key={item.id} />
            ))}
          {step === marketplaceSteps.userInfo && <MarketplaceFormPage handleSubmit={submitMarketplaceForm} />}
        </div>
      </div>

      <div className={buttonsClass}>
        {(step > marketplaceSteps.manufacturers && !props.match.params.configurationManufacturer) ||
        (step > marketplaceSteps.dealers && props.match.params.configurationManufacturer) ? (
          <button className="marketplace-button" onClick={() => setPreviousStep()}>
            <img src="../../../content/images/icons/previous-step-icon.svg" alt="next-step-icon" width="20" style={{ marginRight: 10 }} />
            Previous
          </button>
        ) : (
          <button className="marketplace-button" onClick={() => returnToKreator()}>
            Cancel
          </button>
        )}
        {step === marketplaceSteps.manufacturers && <p className="marketplace-navigation-description">Select up to 3 or skip</p>}
        {step === marketplaceSteps.dealers && <p className="marketplace-navigation-description">Select up to 3</p>}
        <div className="next-buttons-container">
          {step === marketplaceSteps.manufacturers && (
            <button className="marketplace-button" onClick={() => setNextStep()}>
              Skip
            </button>
          )}
          {step < marketplaceSteps.userInfo && (
            <button
              className="marketplace-button step-button"
              onClick={() => setNextStep()}
              disabled={step === marketplaceSteps.dealers && !selectedDealers.length}
            >
              Next
              <img src="../../../content/images/icons/next-step-icon.svg" alt="next-step-icon" width="20" style={{ marginLeft: 10 }} />
            </button>
          )}
        </div>
      </div>
      <MarketplaceDealerDetailsModal isOpen={isDealerDetailsOpen} closeModal={closeDealerDetailsModal} dealer={dealerForDetailsModal} />
    </>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  manufacturers: storeState.manufacturer.manufacturersForMarketplace,
  dealers: storeState.dealer.dealersForMarketplace,
  userConfigurationsByDealer: storeState.userConfiguration.userConfigurationsByDealer,
  loadingManufactirers: storeState.manufacturer.loading,
  loadingDealers: storeState.dealer.loading,
  loadingConfiguration: storeState.userConfiguration.loading,
  formSendingSuccess: storeState.leads.marketplaceFormSendingSuccess,
});

const mapDispatchToProps = {
  setMarketPlaceMode,
  getManufacturersForMarketplace,
  getDealersForMarketplace,
  getUserConfigurationsByDearels,
  createLeadsCart,
  resetMarketplaceForm,
  resetUserConfigurationsByDealers,
  resetManufacturersForMarketplace,
  resetDealersForMarketplace,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MarketplacePage);
