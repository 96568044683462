import React, { useState } from 'react';
import BaseLayout from '../BaseLayout';
import Search from 'app/components/Search/Search';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { getBusinessApprovals } from 'app/entities/dealer/dealer.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import DealerBusinessApprovals from 'app/pages/BusinessApprovals/DealerBusinessApprovals';
import ManufacturerBusinessApprovals from 'app/pages/BusinessApprovals/ManufacturerBusinessApprovals';
import ConsultantBusinessApprovals from "app/pages/BusinessApprovals/ConsultantBusinessApprovals";

export const BusinessApprovals = props => {
  const [activeMenu, setActive] = useState('manufacturer');

  const { dealerList, match, loading, totalItems } = props;
  return (
    <BaseLayout
      title={hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN]) ? 'Business Approvals' : ''}
      search={dealerList && dealerList.length > 0 && <Search />}
    >
      <div className="mt-5"></div>

      <Nav fill pills>
        <NavItem>
          <NavLink active={activeMenu === 'manufacturer'} href="#" onClick={() => setActive('manufacturer')}>
            Manufacturer Approval
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={activeMenu === 'dealer'} href="#" onClick={() => setActive('dealer')}>
            Dealers Approval
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={activeMenu === 'consultant'} href="#" onClick={() => setActive('consultant')}>
            Consultant Approval
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={activeMenu === 'manufacturerRef'} href="#" onClick={() => setActive('manufacturerRef')}>
            Manufacturer Ref Approval
          </NavLink>
        </NavItem>
      </Nav>

      <div>
        {activeMenu === 'manufacturer' && <ManufacturerBusinessApprovals />}
        {activeMenu === 'dealer' && <DealerBusinessApprovals />}
        {activeMenu === 'consultant' && <ConsultantBusinessApprovals />}
        {activeMenu === 'manufacturerRef' && <ManufacturerBusinessApprovals />}
      </div>
    </BaseLayout>
  );
};

const mapStateToProps = ({ dealer, authentication }: IRootState) => ({
  dealerList: dealer.entities,
  loading: dealer.loading,
  totalItems: dealer.totalItems,
  account: authentication.account,
});

const mapDispatchToProps = {
  getBusinessApprovals,
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessApprovals);
