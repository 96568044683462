import React, { memo } from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import './toggle.scss';

interface IToggle {
  rightButtonTitle: string;
  leftButtonTitle: string;
  loading: boolean;
  rightButtonAction: Function;
  leftButtonAction: Function;
  rightButtonActive: boolean;
  leftButtonActive: boolean;
}

const Toggle = memo((props: IToggle) => {
  return (
    <div className="toggle-container">
      <ButtonGroup className="main-theme-toggle">
        <Button
          onClick={props.leftButtonAction}
          className="toggle-button toggle-button-left"
          active={props.leftButtonActive}
          style={{ marginRight: 3 }}
          disabled={props.loading}
        >
          {props.leftButtonTitle}
        </Button>
        <Button
          onClick={props.rightButtonAction}
          className="toggle-button toggle-button-right"
          active={props.rightButtonActive}
          disabled={props.loading}
        >
          {props.rightButtonTitle}
        </Button>
      </ButtonGroup>
    </div>
  );
});

export default Toggle;
