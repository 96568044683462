import React from 'react';
import './marketplaceDealerCard.scss';

interface IMarketPlaceDealerCard {
  dealer: any;
  onSelect: Function;
  selectedDealers: any;
  setSelectedDealers: any;
  openDetailsModal: Function;
}

const MarketPlaceDealerCard = (props: IMarketPlaceDealerCard) => {
  const { dealer, selectedDealers, onSelect, setSelectedDealers, openDetailsModal } = props;

  let cardClass = 'marketplace-dealer-product-card';
  if (selectedDealers.includes(dealer.id)) {
    cardClass = cardClass + ' ' + 'selected-dealer-layout-card';
  }
  return (
    <React.Fragment>
      <div className={cardClass} onClick={e => onSelect(e, selectedDealers, dealer.id, setSelectedDealers)}>
        <i className="dealer-details-button" onClick={() => openDetailsModal(dealer)}>
          i
        </i>
        <div className="marketplace-dealer-footnote">
          <span>{dealer.qualifySpecialStatus.replace(/[^A-Z]+/g, '')}</span>
        </div>
        <div className="marketplace-dealer-status-footnote">
          <span>Silver Dealer</span>
        </div>
        <span className="marketplace-dealer-fast-response-label">Fast Response</span>
        <img src={dealer.logo} alt="Missing image" width="150" className="marketplace-card-image" />
        <span className="marketplace-dealer-card-title">{dealer.name}</span>
        <div className="d-flex align-items-center">
          <span className="marketplace-dealer-card-description">{dealer.city + ',' + dealer.state}</span>
          <span className="marketplace-dealer-card-miles">(10 miles from project)</span>
        </div>
        <div className="marketplace-dealer-card-icons-container">
          <img src="../../../content/images/icons/fast-delivery.svg" className="marketplace-dealer-card-icon" alt="fast-delivery" />
          <img src="../../../content/images/icons/Welding.svg" className="marketplace-dealer-card-icon" alt="welding" />
          <img src="../../../content/images/icons/fire-sprinkler.svg" className="marketplace-dealer-card-icon" alt="fire-sprinkler" />
          <img src="../../../content/images/icons/ion_snow.svg" className="marketplace-dealer-card-icon" alt="snow" />
          <img src="../../../content/images/icons/settings.svg" className="marketplace-dealer-card-icon" alt="repair" />
        </div>
        <div className="marketplace-dealer-card-instant-pricing-container">
          <span>Instant Prising</span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MarketPlaceDealerCard;
