import React, { useState, useEffect, useRef } from 'react';
import BaseLayout from '../BaseLayout';
import Search from 'app/components/Search/Search';
import Checkbox from 'app/components/Checkbox/Checkbox';
import ProductConfiguratorCard from 'app/components/ProductConfiguratorCard/ProductConfiguratorCard';
import ProductDetailsModal from 'app/entities/roomle-product/ProductDetailsModal';
import { getAllRegions } from 'app/entities/enable-region/enable-region.reducer';
import { getEntities } from 'app/entities/roomle-product/roomle-product.reducer';
import { enabledConfiguratorsForMarketplace } from 'app/entities/enabled-product-configurators/enabled-product-configurators.reducer';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import './productConfigurators.scss';
import AppSpinner from 'app/components/Spinner/Spinner';
import { getCurrentUserSubscriptionMetadata } from 'app/entities/stripe/stripe.reducer';
import { getEntity } from 'app/entities/product/product.reducer';
import { getManufacturerForCurrentUser } from 'app/entities/manufacturer/manufacturer.reducer';

interface IEnableProductProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string; mailsha1: string }> {}

const ProductConfigurators = (props: IEnableProductProps) => {
  const [configuratorsList, setConfiguratorsList] = useState([]);
  const [isProductDetailsModalOpen, setisProductDetailsModalOpen] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEnambeldConfiguratorsOnly, setIsEnambeldConfiguratorsOnly] = useState<boolean>(false);
  const configuratorsListBlock = useRef(null);

  useEffect(() => {
    props.getEntities();
    props.getAllRegions();
    props.enabledConfiguratorsForMarketplace();
    props.getCurrentUserSubscription();
  }, []);

  useEffect(() => {
    createConfiguratorsList();
  }, [props.productList, props.enabledForMarketplace]);

  useEffect(() => {
    if (configuratorsList.length) executeScroll();
  }, [configuratorsList]);

  useEffect(() => {
    if (isEnambeldConfiguratorsOnly) {
      setConfiguratorsList(configuratorsList.filter(item => item.isConfiguratorEnabled));
    } else {
      createConfiguratorsList();
    }
  }, [isEnambeldConfiguratorsOnly]);

  const createConfiguratorsList = () => {
    const list = props.productList.map(productItem => {
      const isConfiguratorEnabled = props.enabledForMarketplace.some(item => item.productId === productItem.id && item.activated);
      const settings = props.enabledForMarketplace.find(item => item.productId === productItem.id);
      return {
        ...productItem,
        isConfiguratorEnabled,
        enableRegions: settings != undefined ? settings.enableRegions : [],
      };
    });
    const sortedList = list.sort((a, b) => Number(b.isConfiguratorEnabled) - Number(a.isConfiguratorEnabled));
    setConfiguratorsList(sortedList);
  };

  const openProductDetailsModal = (productId: number) => {
    setSelectedProduct(productId);
    setisProductDetailsModalOpen(true);
  };

  const closeModal = () => {
    setisProductDetailsModalOpen(false);
  };

  const filterEnableOnly = () => {
    setIsEnambeldConfiguratorsOnly(!isEnambeldConfiguratorsOnly);
  };

  const executeScroll = () => {
    if (configuratorsList) {
      configuratorsListBlock.current.scrollIntoView({ behavior: 'smooth', inline: 'end' });
    }
  };

  return (
    <BaseLayout
      title={'My Configurators'}
      search={<Search />}
      checkbox={<Checkbox onChange={filterEnableOnly} label="Display Enabled Configurators Only" />}
    >
      <div className="mt-5"></div>

      <div className="my-configurators-page-content-container">
        <img src="../../../content/images/regionsMap.svg" alt="regions map" className="my-configurator-page-regions-map" width="40%" />
        <span ref={configuratorsListBlock} className="my-configurator-page-regions-map-subtitle">
          Receive leads from the following MASFI regions
        </span>

        {props.loading ? (
          <AppSpinner />
        ) : (
          configuratorsList.map(item => (
            <div style={{ position: 'relative' }} key={item.id}>
              <i className="configurator-details-button" onClick={e => openProductDetailsModal(item)}>
                i
              </i>
              <ProductConfiguratorCard
                image={item.image}
                manufacturer={item.manufacturerName}
                name={item.name}
                isConfiguratorEnabled={item.isConfiguratorEnabled}
                productId={item.id}
                enableRegions={item.enableRegions}
                id={item.id}
              />
            </div>
          ))
        )}
      </div>

      <ProductDetailsModal
        isOpen={isProductDetailsModalOpen}
        product={selectedProduct}
        closeModal={closeModal}
        userManufacturer={props.userManufacturer}
        loading={props.productLoading}
      />
    </BaseLayout>
  );
};

const mapStateToProps = ({ roomleProduct, enabledProductConfigurators, product, manufacturer }: IRootState) => ({
  productList: roomleProduct.entities,
  loading: roomleProduct.loading,
  totalItems: roomleProduct.totalItems,
  enabledForMarketplace: enabledProductConfigurators.enabledConfiguratorsForMarketplace,
  productLoading: product.loading,
  userManufacturer: manufacturer.entity,
});

const mapDispatchToProps = {
  getAllRegions,
  enabledConfiguratorsForMarketplace,
  getEntities,
  getCurrentUserSubscription: getCurrentUserSubscriptionMetadata,
  getEntity,
  getManufacturerForCurrentUser,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProductConfigurators);
