import React, { useState, useEffect } from 'react';
import { Modal } from 'reactstrap';
import { customersData } from './segments';
import axios, { Method } from 'axios';
import { mfrRepHead } from './mfrRepHead';
import './thermoKoolRequestFormModal.scss';

interface IThermoKoolRequestForm {
  isOpen: boolean;
  closeModal: Function;
  roomleConfiguration: any;
}

const ThermoKoolRequestForm = (props: IThermoKoolRequestForm) => {
  const defaultFormData = {
    marketSegment: 'School',
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    companyZipCode: '',
    phone: '',
    project: '',
    projectZipCode: '',
    followUp: 'no',
    requestedFiles: [],
    comments: '',
    itemNumber: '',
  };
  const apiKey = '19ee6630-fa98-4ad4-9d62-a53cf4fb2099';
  const [nameBySegment, setNameBySegment] = useState([]);
  const [formData, setFormData] = useState(defaultFormData);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  useEffect(() => {
    validateForm();
  }, [formData]);

  const handleInputChange = e => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? (checked ? [...prevData[name], value] : prevData[name].filter(item => item !== value)) : value,
    }));
  };

  const validateForm = () => {
    const requiredFields = ['marketSegment', 'firstName', 'lastName', 'email', 'company', 'companyZipCode', 'project', 'projectZipCode'];

    const isFormValid = requiredFields.every(field => Boolean(formData[field]));

    setIsFormValid(isFormValid);
  };

  const getSegment = (segment: string) => {
    setFormData(prevData => ({
      ...prevData,
      company: '',
      companyZipCode: '',
    }));

    const segmentMap = {
      Dealer: 'dealers',
      Consultant: 'consultants',
      'Manufacturer Rep': 'manufacturerReps',
    };

    const segmentData = customersData[segmentMap[segment]];
    if (segmentData) {
      const names = segmentData.map(item => item.name);
      setNameBySegment(names);
      setCompany(names[0]);
      setZipCode(names[0]);
    }
  };

  const setCompany = (company: string) => {
    setFormData(prevData => ({
      ...prevData,
      company,
    }));
  };

  const setZipCode = (company: string) => {
    for (let segment in customersData) {
      const customer = customersData[segment].find(item => item.name === company);

      if (customer) {
        setFormData(prevData => ({
          ...prevData,
          companyZipCode: customer.zipCode,
        }));

        return;
      }
    }
  };

  const handleSubmit = async e => {
    const preparedData = { ...formData, requestedFiles: formData.requestedFiles.toString() };

    e.preventDefault();
    await setMrfRepEmailData(props.roomleConfiguration);
    sendDataToBackend(preparedData, props.roomleConfiguration);
    saveDataInKreator(preparedData, props.roomleConfiguration);

    if (preparedData.requestedFiles.includes('REVIT')) {
      exportRevit(preparedData, props.roomleConfiguration);
    }

    props.closeModal();
    setFormData(defaultFormData);
    setIsFormValid(false);
  };

  const setMrfRepEmailData = async configurator => {
    const mfrEmailData = configurator.mailConfigParam.mfrEmailData;

    const mrfRepData = await getMfrRepSpecPathUserId(formData.projectZipCode);

    if (mrfRepData.email && mrfRepData.company) {
      // cc must be an array of objects.
      mfrEmailData.cc = [
        {
          email: mrfRepData.email,
          name: mrfRepData.company,
        },
      ];
    }

    const mfrRepHead = getMfrRepHeadBySpecPathUserId(mrfRepData.specPathUserId);
    mfrEmailData.to = {
      name: mfrRepHead.name,
      email: mfrRepHead.email,
    };
  };

  const getMfrRepHeadBySpecPathUserId = specPathUserId => {
    for (const head in mfrRepHead) {
      if (mfrRepHead[head].associates.includes(specPathUserId)) {
        return {
          name: mfrRepHead[head].name,
          email: head,
        };
      }
    }

    // In case of no email data found using specPathUserId, Ashley Kelly email will be used.
    return {
      name: Object.values(mfrRepHead)[0].name,
      email: Object.keys(mfrRepHead)[0],
    };
  };

  const getMfrRepSpecPathUserId = async zipCode => {
    let mrfRepData = undefined;
    const manufacturerName = 'Thermo-Kool';

    const config = {
      method: 'GET' as Method,
      url: `https://www.kreator.app/api/external/manufacturer-ref-data-by-zip-and-manufacturer?api_key=${apiKey}&zipCode=${zipCode}&manufacturerName=${manufacturerName}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    };

    await axios(config).then(function (response) {
      mrfRepData = {
        email: response.data.email,
        specPathUserId: response.data.specPathUserId,
        company: response.data.company,
      };
    });

    return mrfRepData;
  };

  // sending the data to the backend server - configure with your server:
  const sendDataToBackend = (formData, configurator) => {
    formData = updateUserName(formData);

    let data = JSON.stringify({
      formData: formData,
      configurator: configurator,
    });

    const config = {
      method: 'POST' as Method,
      url: 'https://kreator-krowne-email-api.azurewebsites.net/sendmail',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: data,
    };

    axios(config);
  };

  const updateUserName = formData => {
    formData.name = `${formData.firstName} ${formData.lastName}`;
    delete formData.firstName;
    delete formData.lastName;

    return formData;
  };

  const exportRevit = (formData, configurator) => {
    let data = JSON.stringify({
      email: formData.email,
      configuration: configurator.configurationId,
    });

    const config = {
      method: 'POST' as Method,
      url: `https://www.kreator.app/api/external/share-revit?api_key=${apiKey}`,
      maxBodyLength: Infinity,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config);
  };

  const saveDataInKreator = (formData, configurator) => {
    let data = JSON.stringify({
      projectName: formData.project,
      projectZipCode: formData.projectZipCode,
      projectImage: configurator.mailConfigParam.configImage,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      company: formData.company,
      marketSegment: formData.marketSegment,
      configurationsHash: [configurator.configurationId],
      itemNumber: formData.itemNumber,
    });

    let config = {
      method: 'POST' as Method,
      maxBodyLength: Infinity,
      url: `https://www.kreator.app/api/external/create-project?api_key=${apiKey}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios.request(config);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      centered
      fade={false}
      toggle={() => props.closeModal(false)}
      backdrop={true}
      style={
        window.screen.width >= 1024
          ? { maxWidth: window.screen.width - 820, width: '100%' }
          : { maxWidth: '95%', width: '95%', margin: 'auto' }
      }
    >
      <div className="client-form-data">
        <form onSubmit={handleSubmit}>
          {/* Item Number */}
          <div className="thermo-kool-form-group">
            <label htmlFor="phone">Item Number</label>
            <input
              type="text"
              className="thermo-kool-form-control"
              id="form-client-item-number"
              name="itemNumber"
              value={formData.itemNumber}
              onChange={handleInputChange}
            />
          </div>
          {/* Market Segment */}
          <div className="thermo-kool-form-group">
            <label htmlFor="marketSegment">
              Market Segment <strong style={{ color: 'red' }}>*</strong>
            </label>
            <select
              className="thermo-kool-form-control"
              id="form-market-segment"
              name="marketSegment"
              value={formData.marketSegment}
              onChange={e => {
                handleInputChange(e);
                getSegment(e.target.value);
              }}
            >
              <option value="School">School</option>
              <option value="Dealer">Dealer</option>
              <option value="Consultant">Consultant</option>
              <option value="Manufacturer Rep">Manufacturer Rep</option>
              <option value="Architect/Designer">Architect/Designer</option>
              <option value="Restaurant/Bar">Restaurant/Bar</option>
              <option value="Other">Other</option>
              <option value="BRestaurant (Chain)">Restaurant (Chain)</option>
              <option value="College & University">College & University</option>
              <option value="Thermo-Kool Team">Thermo-Kool Team</option>
            </select>
          </div>
          {/* Segment Name Selection */}
          <div
            className={`thermo-kool-form-group ${
              formData.marketSegment === 'Dealer' ||
              formData.marketSegment === 'Consultant' ||
              formData.marketSegment === 'Manufacturer Rep'
                ? ''
                : 'hide'
            }`}
            id="segmentName"
          >
            <label id="segmentNameLabel" htmlFor="segmentName">
              Name <strong style={{ color: 'red' }}>*</strong>
            </label>
            <select
              className="thermo-kool-form-control"
              id="form-segment-name-selection"
              name="company"
              value={formData.company}
              onChange={e => {
                handleInputChange(e);
                setZipCode(e.target.value);
              }}
            >
              {nameBySegment.map(item => (
                <option key={item + Math.random()} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          {/* Name */}
          <div className="form-group horizontal-form-group">
            <div className="horizontal-form-group-input-container thermo-kool-form-group">
              <label htmlFor="firstName">
                First Name <strong style={{ color: 'red' }}>*</strong>
              </label>
              <input
                type="text"
                className="thermo-kool-form-control"
                id="form-first-name"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
            </div>
            <div className="horizontal-form-group-input-container thermo-kool-form-group">
              <label htmlFor="lastName">
                Last Name <strong style={{ color: 'red' }}>*</strong>
              </label>
              <input
                type="text"
                className="thermo-kool-form-control"
                id="form-last-name"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          {/* Email */}
          <div className="thermo-kool-form-group">
            <label htmlFor="email">
              Email <strong style={{ color: 'red' }}>*</strong>
            </label>
            <input
              type="email"
              className="thermo-kool-form-control"
              id="form-client-email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          {/* Company & State Region */}
          {formData.marketSegment !== 'Manufacturer Rep' && formData.marketSegment !== 'Consultant' && formData.marketSegment !== 'Dealer' && (
            <div className="form-group horizontal-form-group" id="companyAndStateRegion">
              <div className="horizontal-form-group-input-container thermo-kool-form-group">
                <label htmlFor="company">
                  Company name <strong style={{ color: 'red' }}>*</strong>
                </label>
                <input
                  type="text"
                  className="thermo-kool-form-control"
                  id="form-client-company-name"
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                />
              </div>
              {/* State Region */}
              <div className="horizontal-form-group-input-container thermo-kool-form-group">
                <label htmlFor="companyZipCode">
                  Company Zip Code <strong style={{ color: 'red' }}>*</strong>
                </label>
                <input
                  type="text"
                  className="thermo-kool-form-control"
                  id="form-state-region"
                  name="companyZipCode"
                  value={formData.companyZipCode}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          )}
          {/* Phone */}
          <div className="thermo-kool-form-group">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              className="thermo-kool-form-control"
              id="form-client-phone"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
            />
          </div>
          {/* Project Name */}
          <div className="thermo-kool-form-group">
            <label htmlFor="project">
              Project Name <strong style={{ color: 'red' }}>*</strong>
            </label>
            <input
              type="text"
              className="thermo-kool-form-control"
              id="form-project-name"
              name="project"
              value={formData.project}
              onChange={handleInputChange}
            />
          </div>
          {/* Project Zip Code */}
          <div className="thermo-kool-form-group">
            <label htmlFor="projectZipCode">
              Project Zip Code<strong style={{ color: 'red' }}> *</strong>
            </label>
            <input
              type="text"
              className="thermo-kool-form-control"
              id="form-project-zip"
              name="projectZipCode"
              value={formData.projectZipCode}
              onChange={handleInputChange}
            />
          </div>
          {/* Follow Up */}
          <div>
            <label htmlFor="followup" style={{ fontSize: 12, fontWeight: 600 }}>
              Would you like someone from Thermo-Kool to follow up with you on this request?
            </label>
            <fieldset className="radioButtons-block" style={{ display: 'flex', justifyContent: 'space-between', width: 150 }}>
              <div>
                <input
                  type="radio"
                  id="yes-radio"
                  name="radioButton"
                  value="yes"
                  checked={formData.followUp === 'yes'}
                  onChange={handleInputChange}
                />
                <label htmlFor="yes-radio" className="radio-button-label">
                  Yes
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="no-radio"
                  name="radioButton"
                  value="no"
                  checked={formData.followUp === 'no'}
                  onChange={handleInputChange}
                />
                <label htmlFor="no-radio" className="radio-button-label">
                  No
                </label>
              </div>
            </fieldset>
          </div>
          {/* Requested Files */}
          <div className="thermo-kool-form-group">
            <label htmlFor="requestedFiles">Please Send: </label>
            <fieldset>
              <input
                type="checkbox"
                name="requestedFiles"
                value="QUOTE"
                checked={formData.requestedFiles.includes('QUOTE')}
                onChange={handleInputChange}
              />{' '}
              QUOTE
              <input
                type="checkbox"
                name="requestedFiles"
                style={{ marginLeft: '10px' }}
                value="REVIT"
                checked={formData.requestedFiles.includes('REVIT')}
                onChange={handleInputChange}
              />{' '}
              REVIT
              <input
                type="checkbox"
                name="requestedFiles"
                style={{ marginLeft: '10px' }}
                value="CAD"
                checked={formData.requestedFiles.includes('CAD')}
                onChange={handleInputChange}
              />{' '}
              CAD
            </fieldset>
          </div>
          {/* Message */}
          <div className="thermo-kool-form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              className="thermo-kool-form-control"
              id="form-message"
              name="message"
              value={formData.comments}
              onChange={handleInputChange}
            ></textarea>
          </div>
          <div className="submit" style={{ textAlign: 'center' }}>
            <button className="btn btn-default submit-form-btn" type="submit" disabled={!isFormValid}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ThermoKoolRequestForm;
