import { useState, useEffect } from 'react';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

export const useCreateDrawings = (project, account, forgeConnect, updateProject, updateProjectDetails) => {
  const [projectCodeValue, setProjectCodeValue] = useState('');
  const [isCreateDrawingsButtonWasPressed, setIsCreateDrawingsButtonWasPressed] = useState(false);
  const [drawingFileName, setDrawingFileName] = useState(null);
  const [drawingTypeValue, setDrawingTypeValue] = useState(null);
  const [isProjectCodeInputModalOpen, setIsProjectCodeInputModalOpen] = useState(false);
  const [isCreateDrawingsModalOpen, setIsCreateDrawingsModalOpen] = useState(false);
  // const [isEmptyFieldsData, setIsEmptyFieldsData] = useState(false);
  const [isNeedToFillTheDataForDrawings, setIsNeedToFillTheDataForDrawings] = useState(false);

  useEffect(() => {
    if (project?.projectCode && isCreateDrawingsButtonWasPressed) {
      forgeConnect(project.id, drawingTypeValue);
      updateProject({ ...project, revitProjectFile: drawingFileName });
      setDrawingFileName(null);
      setDrawingTypeValue(null);
      setIsCreateDrawingsButtonWasPressed(false);
    }
  }, [project]);

  const createDrawings = (fileName, drawingType) => {
    // do not delete for now
    // if (project.projectProducts && isEmptyFields(project)) {
    //   setIsEmptyFieldsData(true);
    //   return;
    // }

    forgeConnect(project.id, drawingType);
    updateProject({ ...project, revitProjectFile: fileName });
    setIsCreateDrawingsModalOpen(false);
  };

  const updateProjectCode = () => {
    updateProjectDetails({ id: project.id, projectCode: projectCodeValue });
    setIsProjectCodeInputModalOpen(false);
    setIsCreateDrawingsModalOpen(false);
  };

  //do not delete for now
  // const isEmptyFields = project => {
  //   return project.projectProducts.some(item => !item.itemNumber || !item.sectionPrefix);
  // };

  return {
    projectCodeValue,
    setProjectCodeValue,
    isProjectCodeInputModalOpen,
    setIsProjectCodeInputModalOpen,
    isCreateDrawingsModalOpen,
    setIsCreateDrawingsModalOpen,
    createDrawings,
    updateProjectCode,
    // isEmptyFieldsData,
    // setIsEmptyFieldsData,
    isNeedToFillTheDataForDrawings,
    setIsNeedToFillTheDataForDrawings,
  };
};
