import React, { Component } from 'react';
import './baseLayout.scss';

interface IBaseLayout {
  title: String;
  subtitle?: String;
  filter?: JSX.Element;
  search?: JSX.Element;
  createButton?: JSX.Element;
  selectButton?: JSX.Element;
  checkbox?: JSX.Element;
  backButton?: JSX.Element;
  datePicker?: JSX.Element;
  button?: JSX.Element;
  centerElement?: JSX.Element;
  children?: any;
  toggle?: any;
  sort?: JSX.Element;
}

const BaseLayout = (props: IBaseLayout) => {
  return (
    <>
      <div className="base-layout-header">
        {props.backButton}
        <div>
          <h6 className="page-title">{props.title}</h6>
          <span className="page-subtitle">{props.subtitle}</span>
        </div>
        <div className="base-layout-center-element-container">{props.centerElement}</div>
        <div className="base-layout-filters-container">
          {props.toggle}
          {props.checkbox}
          {props.datePicker}
          {props.search}
          {props.filter}
          {props.sort}
          {props.button}
          {props.createButton}
          {props.selectButton}
        </div>
      </div>
      <div className="base-layout-container">{props.children}</div>
    </>
  );
};

export default BaseLayout;
