import { Moment } from 'moment';

export interface IProjectSubmissionLog {
  id?: number;
  foodServiceUrl?: string;
  krowneDrawingUrl?: string;
  coronetUrl?: string;
  reportUrl?: string;
  guid?: string;
  autoDeskId?: string;
  dateCreated?: Moment;
  jobId?: number;
  progressPercentage?: number;
  userLogin?: string;
  userId?: number;
  projectProjectName?: string;
  projectId?: number;
  email?: string;
  projectCode?: number;
  clientName?: string;
  externalFileUrl?: string;
}

export const defaultValue: Readonly<IProjectSubmissionLog> = {};
