import React, { useState, useRef } from 'react';
import { Modal } from 'reactstrap';
import ReactSwipe from 'react-swipe';
import './videGuideModal.scss';

interface IVideoGuideModal {
  isVideoGuideModalOpen: boolean;
  setIsVideoGuideModalOpen: Function;
  updateUserSettings: Function;
  currentUserSettings: any;
}

const VideoGuideModal = (props: IVideoGuideModal) => {
  const [dontShowAgain, setIsDontShowAgain] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const reactSwipeEl = useRef(null);

  const videos: { title: string; id: string }[] = [
    { title: 'Product Options', id: 'rDv62fh3bGQ' },
    { title: 'Option Groups', id: 'naZiRsfmRuc' },
    { title: 'Add Accessories', id: '6wVnwzApoRo' },
    { title: 'Live 3D Preview', id: 'mdo7lPPoBQA' },
    { title: 'Undo Redo', id: 'qqHOYxLeZEY' },
    { title: 'Augmented Reality', id: 'eUNBJkR7Sww' },
    { title: 'Parts List', id: 'dScXnTj2oTg' },
    { title: 'Show Measurements', id: 'bZ31OSaMFCk' },
    { title: 'Preset Views', id: 'lR9yhwINfks' },
    { title: 'View Full Screen', id: 'YxuqfyhmCtg' },
    { title: 'Export 3D Files', id: 'oyKsmDV_pMs' },
    { title: 'List Pricing', id: 'M7xfVI1eRcQ' },
    { title: 'Save Drafts/Share Configuration', id: '7LKC0rRz3Zk' },
    { title: 'Add to Cart/Request Quote', id: 'q6fCWJbNqg4' },
  ];

  const closeWindow = () => {
    if (dontShowAgain) {
      props.updateUserSettings({
        ...props.currentUserSettings,
        onBoardingSeen: true,
      });
    }
    props.setIsVideoGuideModalOpen(false);
  };

  const handlePrevSlide = () => {
    if (reactSwipeEl.current) {
      reactSwipeEl.current.prev();
      setActiveIndex(activeIndex => Math.max(0, activeIndex - 1));
    }
  };

  const handleNextSlide = () => {
    if (reactSwipeEl.current) {
      reactSwipeEl.current.next();
      setActiveIndex(activeIndex => Math.min(videos.length - 1, activeIndex + 1));
    }
  };

  const goToSlide = index => {
    if (reactSwipeEl.current) {
      reactSwipeEl.current.slide(index);
      setActiveIndex(index);
    }
  };

  return (
    <Modal
      isOpen={props.isVideoGuideModalOpen}
      centered
      toggle={closeWindow}
      backdrop={true}
      style={
        window.screen.width >= 1024
          ? { maxWidth: window.screen.width - 650, width: '100%' }
          : { maxWidth: '90%', width: '100%', margin: 'auto' }
      }
    >
      <div className="video-guide-modal-container">
        <button className="video-guide-modal-container-close-button" onClick={closeWindow}>
          <img src="../../../content/images/icons/closeModalIcon.svg" alt="close button" />
        </button>
        <ReactSwipe className="carousel" swipeOptions={{ continuous: false }} ref={reactSwipeEl} childCount={videos.length}>
          {videos.map((video, index) => (
            <div className={`video-guide-modal-item ${index === activeIndex ? 'active' : ''}`} key={video.id}>
              <span className="video-guide-modal-item-title">{video.title}</span>
              <iframe width="100%" height="425" src={`https://www.youtube.com/embed/${video.id}`}></iframe>
            </div>
          ))}
        </ReactSwipe>
        <div className="video-guide-buttons-container">
          <button disabled={activeIndex === 0} onClick={handlePrevSlide} className="video-guide-modal-button transparrent-button">
            Previous
          </button>

          <div className="video-guide-modal-item-markers-container">
            {videos.map((item, index) => (
              <div
                className={`video-guide-modal-item-marker ${index === activeIndex ? 'video-guide-modal-item-marker-active' : ''}`}
                key={item.id}
                onClick={() => goToSlide(index)}
              ></div>
            ))}
          </div>
          <button disabled={activeIndex === videos.length - 1} onClick={handleNextSlide} className="video-guide-modal-button">
            Next
          </button>
        </div>
        <div className="video-guide-modal-dont-show-up-container">
          <input
            type="checkbox"
            onChange={() => {
              setIsDontShowAgain(!dontShowAgain);
            }}
          />
          <span className="video-guide-modal-dont-show-again">Don't show again</span>
        </div>
      </div>
    </Modal>
  );
};

export default VideoGuideModal;
