import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import UserNotificationToken from './user-notification-token';
import UserNotificationTokenDetail from './user-notification-token-detail';
import UserNotificationTokenUpdate from './user-notification-token-update';
import UserNotificationTokenDeleteDialog from './user-notification-token-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={UserNotificationTokenUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={UserNotificationTokenUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={UserNotificationTokenDetail} />
      <ErrorBoundaryRoute path={match.url} component={UserNotificationToken} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={UserNotificationTokenDeleteDialog} />
  </>
);

export default Routes;
