import React from 'react';
import BaseLayout from '../BaseLayout';
import Switch from 'react-switch';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { updateUserSettings } from 'app/entities/user-settings/user-settings.reducer';
import './notificationSettings.scss';

interface INotificationSettings extends StateProps, DispatchProps {
  updateUserSettings;
}

const NotificationSettings = (props: INotificationSettings) => {
  const updateNotificationSettings = (notificationType: string, value: boolean) => {
    props.updateUserSettings({
      ...props.currentUserSettings,
      [notificationType]: value,
    });
  };

  return (
    <BaseLayout title="Notification Settings">
      <div className="toggles-container">
        <div className="d-flex align-items-center">
          <Switch
            offHandleColor="#E2863C"
            onHandleColor="#E2863C"
            activeBoxShadow="0px 0px 1px 2px #FFFFFF"
            uncheckedIcon={false}
            checkedIcon={false}
            handleDiameter={20}
            height={10}
            width={35}
            className="roomle-action-bar-switch-toggle"
            onChange={value => updateNotificationSettings('generalNotification', value)}
            checked={props.currentUserSettings.generalNotification}
          />
          <span style={{ marginLeft: 10 }}>General Notifications</span>
        </div>

        {/* unncomment when the feature will be launched */}
        {/* <div className="d-flex align-items-center">
          <Switch
            offHandleColor="#E2863C"
            onHandleColor="#E2863C"
            activeBoxShadow="0px 0px 1px 2px #FFFFFF"
            uncheckedIcon={false}
            checkedIcon={false}
            handleDiameter={20}
            height={10}
            width={35}
            className="roomle-action-bar-switch-toggle"
            onChange={value => updateNotificationSettings('marketPlaceNotification', value)}
            checked={props.currentUserSettings.marketPlaceNotification}
          />
          <span style={{ marginLeft: 10 }}>Marketplace Status Notifications</span>
        </div> */}
      </div>
    </BaseLayout>
  );
};

const mapStateToProps = ({ authentication, userSettings }: IRootState) => ({
  account: authentication.account,
  isAuthenticated: authentication.isAuthenticated,
  currentUserSettings: userSettings.currentUserSettings,
});

const mapDispatchToProps = { updateUserSettings };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);
