import React, { useState, useEffect } from 'react';
import { NOTIFICATION_STATUS } from 'app/config/constants';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { readUnreadNotification, markNotificationAsRead, deleteNotification } from 'app/entities/notification/notification.reducer';
import { getProject } from 'app/entities/project/project.reducer';
import moment from 'moment';
import { IRootState } from 'app/shared/reducers';
import './notificationItem.scss';

interface INotificationItem extends StateProps, DispatchProps {
  item: any;
  readUnreadNotification: any;
  isSelectModeEnable: Boolean;
  selectedNotifications: any;
  selectNotification: Function;
  deleteNotification: any;
  mailsha1: string;
}

const NotificationItem = (props: INotificationItem) => {
  let history = useHistory();
  const data = JSON.parse(props.item.data);
  const [isProjectConfiguratorNotificationWasOpened, setIsProjectConfiguratorNotificationWasOpened] = useState<boolean>(false);

  useEffect(() => {
    if (isProjectConfiguratorNotificationWasOpened && props.currentProject) {
      const roomleComponentId = props.currentProject.projectProducts[0].userConfiguration.roomleComponentId;
      const configuration = props.currentProject.projectProducts[0].userConfiguration.configuration;

      history.push(`configurators/${props.mailsha1}/${props.currentProject.id}/${roomleComponentId}/${configuration}`);
    }
  }, [props.currentProject]);

  const notificationClickHandler = event => {
    if (event.target.nodeName === 'BUTTON') {
      return;
    }

    if (props.item.status === NOTIFICATION_STATUS.UNREAD) {
      markNotificationAsRead();
    }
    redirectToNotificationDetails();
  };

  const redirectToNotificationDetails = () => {
    try {
      switch (data.entity) {
        case 'project':
          history.push(`/edit-project/${data.id}`);
          break;
        case 'configurator':
          setIsProjectConfiguratorNotificationWasOpened(true);
          props.getProject(data.id);
          break;
        case 'leads':
          history.push(`/lead-details/${data.id}`);
          break;
        default:
      }
    } catch (error) {
      console.error('Error parsing data:', error);
    }
  };

  const markNotificationAsRead = () => {
    props.readUnreadNotification([props.item.id], true);
    props.markNotificationAsRead([props.item.id], NOTIFICATION_STATUS.READ);
  };

  const deleteNotificationItem = () => {
    props.deleteNotification(props.item.id);
  };

  let notificationClass = 'notification-item-container';
  if (props.item.status === NOTIFICATION_STATUS.UNREAD) {
    notificationClass += ' unread-notification';
  }
  if (props.selectedNotifications.includes(props.item.id)) {
    notificationClass += ' orange-border';
  }

  return (
    <div
      className={notificationClass}
      onClick={props.isSelectModeEnable ? () => props.selectNotification(props.item.id) : e => notificationClickHandler(e)}
    >
      <div className="notification-item-data-container">
        <img src={props.item.imageUrl} alt="notification image" width="60" />
        <div className="notification-item-info-container">
          <span className="notification-item-name">{props.item.title}</span>
          <span className="notification-item-info">{props.item.body}</span>
        </div>
      </div>
      <div>
        {props.item.createdAt && <span className="notification-item-time">{moment(props.item.createdAt).fromNow()}</span>}
        <button onClick={deleteNotificationItem} className="delete-notification-item">
          <img src="../../../content/images/icons/configuration-cart-delete-icon.svg" alt="delete" width="30" height="30" />
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ project }: IRootState) => ({
  currentProject: project.entity,
});

const mapDispatchToProps = {
  readUnreadNotification,
  markNotificationAsRead,
  deleteNotification,
  getProject,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NotificationItem);
