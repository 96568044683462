import React, { useState } from 'react';
import { CardImg } from 'reactstrap';
import '../InventoryProductCard/inventoryProductCard.scss';

interface IInventoryCard {
  userConfiguration: any;
  addInventoryToTheProject: Function;
}

const InventoryCard = (props: IInventoryCard) => {
  const { userConfiguration } = props;

  return (
    <React.Fragment>
      <div
        className="inventory-product-card add-to-project-inventory-card"
        onClick={() => props.addInventoryToTheProject(userConfiguration)}
      >
        <CardImg top src={userConfiguration.thumbnailImage} alt="Missing image" className="image add-inventory-to-the-project-image" />
        <span className="inventory-card-title add-inventory-to-the-project-title">{userConfiguration.manufacturerName}</span>
        <span className="inventory-card-subtitle add-inventory-to-the-project-subtitle">{userConfiguration.productName}</span>
        <span className="inventory-card-description add-inventory-to-the-project-description">{userConfiguration.description}</span>
      </div>
    </React.Fragment>
  );
};

export default InventoryCard;
