export interface IConsultantData {
  id?: number;
  company?: string;
  industryType?: string;
  companyType?: string;
  specPathId?: number;
  specPathUserId?: string;
  mailingAddress?: string;
  website?: string;
  email?: string;
  phone?: string;
  isDesignDealer?: boolean;
  zipCode?: string;
  city?: string;
  state?: string;
  country?: string;
  regionOne?: string;
  regionTwo?: string;
  regionThree?: string;
}

export const defaultValue: Readonly<IConsultantData> = {
  isDesignDealer: false,
};
