import React from 'react';
import { Table } from 'reactstrap';
import './downloadsTable.scss';
import { IVersion } from 'app/shared/model/version.model';

const DownloadsTable = props => {
  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  function formatDate(date) {
    /*return [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join('/');*/

    return date.toDateString();
  }
  return (
    <Table responsive borderless className="downloads-table">
      <thead>
        <tr className="downloads-table-header">
          <th>Version Number </th>
          <th>Name</th>
          <th>Release Notes</th>
          <th>Release Date </th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.versions.map((version: IVersion) => (
          <tr className="downloads-table-item" key={version.id}>
            <th>{version.versionNumber}</th>
            <td>{version.name}</td>
            <td>
              <div dangerouslySetInnerHTML={{ __html: version.releaseNotes.replace(/\n/g, '<br />') }} />
            </td>
            <td>{formatDate(new Date(version.releaseDate))}</td>
            <td>
              <button onClick={() => props.downloadVersion(version.filePath)} className="download-version-button">
                <img src="../../../content/images/icons/download-icon.svg" alt="download icon" />
                <span className="download-version-button-text">Download</span>
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DownloadsTable;
