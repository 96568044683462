import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntity, updateEntity, createEntity, reset } from './consultant.reducer';
import { IConsultant } from 'app/shared/model/consultant.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IConsultantUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IConsultantUpdateState {
  isNew: boolean;
  userId: string;
}

export class ConsultantUpdate extends React.Component<IConsultantUpdateProps, IConsultantUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      userId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getUsers();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { consultantEntity } = this.props;
      const entity = {
        ...consultantEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/consultant');
  };

  render() {
    const { consultantEntity, users, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="familyBuilderApiApp.consultant.home.createOrEditLabel">Create or edit a Consultant</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : consultantEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="consultant-id">ID</Label>
                    <AvInput id="consultant-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="nameLabel" for="consultant-name">
                    Name
                  </Label>
                  <AvField
                    id="consultant-name"
                    type="text"
                    name="name"
                    validate={{
                      required: { value: true, errorMessage: 'This field is required.' }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="enabledLabel" check>
                    <AvInput id="consultant-enabled" type="checkbox" className="form-control" name="enabled" />
                    Enabled
                  </Label>
                </AvGroup>
                <AvGroup>
                  <Label id="logoLabel" for="consultant-logo">
                    Logo
                  </Label>
                  <AvField id="consultant-logo" type="text" name="logo" />
                </AvGroup>
                <AvGroup>
                  <Label id="websiteURLLabel" for="consultant-websiteURL">
                    Website URL
                  </Label>
                  <AvField id="consultant-websiteURL" type="text" name="websiteURL" />
                </AvGroup>
                <AvGroup>
                  <Label id="cityLabel" for="consultant-city">
                    City
                  </Label>
                  <AvField id="consultant-city" type="text" name="city" />
                </AvGroup>
                <AvGroup>
                  <Label id="stateLabel" for="consultant-state">
                    State
                  </Label>
                  <AvField id="consultant-state" type="text" name="state" />
                </AvGroup>
                <AvGroup>
                  <Label id="businessNameLabel" for="consultant-businessName">
                    Business Name
                  </Label>
                  <AvField id="consultant-businessName" type="text" name="businessName" />
                </AvGroup>
                <AvGroup>
                  <Label id="skillsLabel" for="consultant-skills">
                    Skills
                  </Label>
                  <AvField id="consultant-skills" type="text" name="skills" />
                </AvGroup>
                <AvGroup>
                  <Label id="qualifySpecialStatusLabel" for="consultant-qualifySpecialStatus">
                    Qualify Special Status
                  </Label>
                  <AvField id="consultant-qualifySpecialStatus" type="text" name="qualifySpecialStatus" />
                </AvGroup>
                <AvGroup>
                  <Label id="cardNumberLabel" for="consultant-cardNumber">
                    Card Number
                  </Label>
                  <AvField id="consultant-cardNumber" type="text" name="cardNumber" />
                </AvGroup>
                <AvGroup>
                  <Label id="cardHolderLabel" for="consultant-cardHolder">
                    Card Holder
                  </Label>
                  <AvField id="consultant-cardHolder" type="text" name="cardHolder" />
                </AvGroup>
                <AvGroup>
                  <Label id="customerIdLabel" for="consultant-customerId">
                    Customer Id
                  </Label>
                  <AvField id="consultant-customerId" type="text" name="customerId" />
                </AvGroup>
                <AvGroup>
                  <Label id="monthLabel" for="consultant-month">
                    Month
                  </Label>
                  <AvField id="consultant-month" type="text" name="month" />
                </AvGroup>
                <AvGroup>
                  <Label id="yearLabel" for="consultant-year">
                    Year
                  </Label>
                  <AvField id="consultant-year" type="text" name="year" />
                </AvGroup>
                <AvGroup>
                  <Label id="cvcLabel" for="consultant-cvc">
                    Cvc
                  </Label>
                  <AvField id="consultant-cvc" type="text" name="cvc" />
                </AvGroup>
                <AvGroup>
                  <Label id="statusLabel" for="consultant-status">
                    Status
                  </Label>
                  <AvInput
                    id="consultant-status"
                    type="select"
                    className="form-control"
                    name="status"
                    value={(!isNew && consultantEntity.status) || 'IN_PROGRESS'}
                  >
                    <option value="IN_PROGRESS">IN_PROGRESS</option>
                    <option value="UPDATE_REQUESTED">UPDATE_REQUESTED</option>
                    <option value="REJECTED">REJECTED</option>
                    <option value="APPROVED">APPROVED</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="refConsultantIdLabel" for="consultant-refConsultantId">
                    Ref Consultant Id
                  </Label>
                  <AvField id="consultant-refConsultantId" type="text" name="refConsultantId" />
                </AvGroup>
                <AvGroup>
                  <Label id="subscriptionIdLabel" for="consultant-subscriptionId">
                    Subscription Id
                  </Label>
                  <AvField id="consultant-subscriptionId" type="text" name="subscriptionId" />
                </AvGroup>
                <AvGroup>
                  <Label id="payPerUsagesSubscriptionIdLabel" for="consultant-payPerUsagesSubscriptionId">
                    Pay Per Usages Subscription Id
                  </Label>
                  <AvField id="consultant-payPerUsagesSubscriptionId" type="text" name="payPerUsagesSubscriptionId" />
                </AvGroup>
                <AvGroup>
                  <Label for="consultant-user">User</Label>
                  <AvInput id="consultant-user" type="select" className="form-control" name="userId" required>
                    {users
                      ? users.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.login}
                          </option>
                        ))
                      : null}
                  </AvInput>
                  <AvFeedback>This field is required.</AvFeedback>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/consultant" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Save
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  users: storeState.userManagement.users,
  consultantEntity: storeState.consultant.entity,
  loading: storeState.consultant.loading,
  updating: storeState.consultant.updating,
  updateSuccess: storeState.consultant.updateSuccess
});

const mapDispatchToProps = {
  getUsers,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultantUpdate);
