import { IRootState } from 'app/shared/reducers';
import React, { useEffect } from 'react';
import { getUrlParameter } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { activateAction, reset } from './activate.reducer';
import './activate.scss';

const successAlert = (
  <div className="success-alert-container">
    <p className="success-alert-text">
      You user account has been activated. <br /> Please Sign In to continue<br></br>
    </p>
    <Link to="/login" className="success-link">
      Sign In
    </Link>
  </div>
);

const alreadyActivated = (
  <Alert color="danger">
    <strong>User already activated,</strong> Please Sign In to continue<br></br>

    <Link to="/login" className="success-link">
      Sign In
    </Link>
  </Alert>
);
const userNotFound = (
  <Alert color="danger">
    <strong>User not found with this key,</strong> Please Sign Up to continue.<br></br>
    <Link to="/account/register" className="alert-link">
      Register a new account
    </Link>
  </Alert>
);

export interface IActivateProps extends StateProps, DispatchProps, RouteComponentProps<{ key: any }> {}

export const ActivatePage = (props: IActivateProps) => {
  useEffect(() => {
    const key = getUrlParameter('key', props.location.search);
    props.activateAction(key);
    return () => {
      props.reset();
    };
  }, []);

  return (
    <div>
      <img src="../../../../content/images/circle-kreator-logo.svg" alt="Logo" width="100" />
      {props.activationData=="USER_ACTIVATED_SUCCESSFULLY" ? successAlert : undefined}
      {props.activationData=="USER_ALREADY_ACTIVATED"  ? alreadyActivated : undefined}
      {props.activationData=="USER_NOT_FOUND"  ? userNotFound : undefined}
    </div>
  );
};

const mapStateToProps = ({ activate }: IRootState) => ({
  activationSuccess: activate.activationSuccess,
  activationFailure: activate.activationFailure,
  activationData: activate.activationData
});

const mapDispatchToProps = { activateAction, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ActivatePage);
