import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IEnabledProductConfigurators } from 'app/shared/model/enabled-product-configurators.model';
import { getEntities as getEnabledProductConfigurators } from 'app/entities/enabled-product-configurators/enabled-product-configurators.reducer';
import { getEntity, updateEntity, createEntity, reset } from './enable-region.reducer';
import { IEnableRegion } from 'app/shared/model/enable-region.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IEnableRegionUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const EnableRegionUpdate = (props: IEnableRegionUpdateProps) => {
  const [enabledProductConfiguratorsId, setEnabledProductConfiguratorsId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { enableRegionEntity, enabledProductConfigurators, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/enable-region' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getEnabledProductConfigurators();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...enableRegionEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="familyBuilderApiApp.enableRegion.home.createOrEditLabel">Create or edit a EnableRegion</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : enableRegionEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="enable-region-id">ID</Label>
                  <AvInput id="enable-region-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup check>
                <Label id="activatedLabel">
                  <AvInput id="enable-region-activated" type="checkbox" className="form-check-input" name="activated" />
                  Activated
                </Label>
              </AvGroup>
              <AvGroup>
                <Label id="commissionLabel" for="enable-region-commission">
                  Commission
                </Label>
                <AvField
                  id="enable-region-commission"
                  type="string"
                  className="form-control"
                  name="commission"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' },
                    number: { value: true, errorMessage: 'This field should be a number.' },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="regionLabel" for="enable-region-region">
                  Region
                </Label>
                <AvInput
                  id="enable-region-region"
                  type="select"
                  className="form-control"
                  name="region"
                  value={(!isNew && enableRegionEntity.region) || 'REGION_1'}
                >
                  <option value="REGION_1">REGION_1</option>
                  <option value="REGION_2">REGION_2</option>
                  <option value="REGION_3">REGION_3</option>
                  <option value="REGION_4">REGION_4</option>
                  <option value="REGION_5">REGION_5</option>
                  <option value="REGION_6">REGION_6</option>
                  <option value="REGION_7">REGION_7</option>
                  <option value="REGION_8">REGION_8</option>
                  <option value="REGION_9">REGION_9</option>
                  <option value="REGION_10">REGION_10</option>
                  <option value="REGION_11">REGION_11</option>
                  <option value="REGION_12">REGION_12</option>
                  <option value="REGION_13">REGION_13</option>
                  <option value="REGION_14">REGION_14</option>
                  <option value="REGION_15">REGION_15</option>
                  <option value="REGION_16">REGION_16</option>
                  <option value="REGION_17">REGION_17</option>
                  <option value="REGION_18">REGION_18</option>
                  <option value="REGION_19">REGION_19</option>
                  <option value="REGION_20">REGION_20</option>
                  <option value="REGION_21">REGION_21</option>
                  <option value="REGION_22">REGION_22</option>
                  <option value="REGION_23">REGION_23</option>
                  <option value="REGION_24">REGION_24</option>
                  <option value="REGION_25">REGION_25</option>
                  <option value="REGION_26">REGION_26</option>
                  <option value="REGION_27">REGION_27</option>
                  <option value="REGION_28">REGION_28</option>
                  <option value="REGION_29">REGION_29</option>
                  <option value="REGION_30">REGION_30</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="enable-region-enabledProductConfigurators">Enabled Product Configurators</Label>
                <AvInput
                  id="enable-region-enabledProductConfigurators"
                  type="select"
                  className="form-control"
                  name="enabledProductConfiguratorsId"
                >
                  <option value="" key="0" />
                  {enabledProductConfigurators
                    ? enabledProductConfigurators.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.id}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/enable-region" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  enabledProductConfigurators: storeState.enabledProductConfigurators.entities,
  enableRegionEntity: storeState.enableRegion.entity,
  loading: storeState.enableRegion.loading,
  updating: storeState.enableRegion.updating,
  updateSuccess: storeState.enableRegion.updateSuccess,
});

const mapDispatchToProps = {
  getEnabledProductConfigurators,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EnableRegionUpdate);
