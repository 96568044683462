// @ts-nocheck
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CardImg } from 'reactstrap';
import ProductCardAction from '../ProductCardAction/ProductCardAction';
import FolderSelectModal from './FolderSelectModal';
import InventoryDetailsModal from '../InventoryDetailsModal/InventoryDetailsModal';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { operateUserConfiguration, getEntity, resetEntity } from 'app/entities/user-configuration/user-configuration.reducer';
import { getManufacturerForCurrentUser } from 'app/entities/manufacturer/manufacturer.reducer';
import { usePartsList } from 'app/customHooks/usePartsList';
import ConfirmationDialog from 'app/components/ConfirmationDialog/ConfirmationDialog';
import './inventoryProductCard.scss';
import { set } from 'lodash';

interface IInventoryProductCard extends DispatchProps, StateProps {
  userConfiguration: any;
  selectInventoryCard?: Function;
  selectedInventories?: any;
  isMultipleSelectModeActive?: boolean;
}

const InventoryProductCard = (props: IInventoryProductCard) => {
  let history = useHistory();
  const { isDetailsOpen, setisDetailsOpen, projectProductsPartsList, partsListLoading } = usePartsList([
    { userConfiguration: props.userConfiguration },
  ]);
  const { userConfiguration } = props;
  const [isFolderSelectModalOpen, setIsFolderSelectModalOpen] = useState<Boolean>(false);
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState<Boolean>(false);
  const [operationRequest, setOperationRequest] = useState({
    userConfigurationId: null,
    sourceFolderId: null,
    destinationFolderId: null,
    operationType: 'COPY',
  });

  const closeFolderSelectModal = () => {
    setIsFolderSelectModalOpen(false);
  };

  const openFolderSelectModal = (userConfigurationId: number, folderId: number) => {
    if (!props.isMultipleSelectModeActive) {
      setIsFolderSelectModalOpen(true);
      setOperationRequest({
        ...operationRequest,
        userConfigurationId,
        sourceFolderId: folderId,
      });
    }
  };

  const handleDeleteConfirmationDialog = e => {
    if (e.target.className === 'action-image delete-project-button-icon') {
      setIsDeleteConfirmationDialogOpen(true);
    }
  };

  const handleDeleteUserConfiguration = (id: number) => {
    if (!props.isMultipleSelectModeActive)
      props.operateUserConfiguration({ ...operationRequest, operationType: 'DELETE', userConfigurationId: id });
  };

  const closeConfiguratorDetailsModal = () => {
    setisDetailsOpen(false);
    props.resetEntity();
  };

  const openConfiguratorDetails = (e, userConfigurationId: number) => {
    if (
      e.target.className &&
      !e.target.className.includes('inventory-product-card-cart-icon') &&
      !e.target.className.includes('action-image')
    ) {
      props.getEntity(userConfigurationId);
      props.getManufacturerForCurrentUser();
      setisDetailsOpen(true);
    }
  };

  const redirectToMarketplace = e => {
    if ((e.target.className = 'inventory-product-card-cart-icon') && !props.isMultipleSelectModeActive) {
      history.push(`/marketplace/${userConfiguration.id}/${userConfiguration.manufacturerName}`);
    }
  };

  let productCardClass = 'inventory-product-card';
  if (props.selectedInventories?.includes(userConfiguration.id)) {
    productCardClass += ' orange-border';
  }

  return (
    <React.Fragment>
      <div
        className={productCardClass}
        onClick={
          props.isMultipleSelectModeActive
            ? () => props.selectInventoryCard(userConfiguration.id, userConfiguration.folderId)
            : e => openConfiguratorDetails(e, userConfiguration.id)
        }
      >
        {userConfiguration.showInMarketPlace != null && userConfiguration.showInMarketPlace && (
          <img
            src="../../../content/images/icons/inventoryCart.svg"
            className="inventory-product-card-cart-icon"
            alt="cart icon"
            width="40"
            onClick={e => redirectToMarketplace(e)}
          />
        )}
        <div className="inventory-card-actions-block">
          <ProductCardAction
            iconName="copyIcon"
            onClick={() => openFolderSelectModal(userConfiguration.id, userConfiguration.folderId)}
            bordered={true}
          />
          <ProductCardAction iconName="closeCardIcon" onClick={e => handleDeleteConfirmationDialog(e)} bordered={true} />
        </div>
        <CardImg top src={userConfiguration.thumbnailImage} alt="Missing image" className="image inventory-card-image" />
        <span className="inventory-card-title">{userConfiguration.manufacturerName}</span>
        <span className="inventory-card-subtitle">{userConfiguration.productName}</span>
        <span className="inventory-card-description">{userConfiguration.description}</span>
        <div className="inventory-card-unread-status-container">
          {userConfiguration.shareBy && !userConfiguration.seen && <div className="inventory-card-unread-status"></div>}
          <span className="inventory-card-shared-by">
            {userConfiguration.shareBy
              ? userConfiguration.shareBy.split(' ')[0] + ' ' + userConfiguration.shareBy.split(' ')[1][0] + '. ' + 'shared this with you.'
              : ' '}
          </span>
        </div>
      </div>
      <FolderSelectModal
        isOpen={isFolderSelectModalOpen}
        operationRequest={operationRequest}
        setOperationRequest={setOperationRequest}
        closeFolderSelectModal={closeFolderSelectModal}
      />
      <InventoryDetailsModal
        isOpen={isDetailsOpen}
        closeModal={closeConfiguratorDetailsModal}
        product={userConfiguration}
        projectProductsPartsList={projectProductsPartsList}
        partsListLoading={partsListLoading}
        userManufacturer={props.userManufacturer}
      />
      <ConfirmationDialog
        isOpen={isDeleteConfirmationDialogOpen}
        closeConfirmationDialog={() => setIsDeleteConfirmationDialogOpen(false)}
        confirmationInfo="Are you sure you want to delete this Project?"
        confirmAction={() => handleDeleteUserConfiguration(userConfiguration.id)}
      />
    </React.Fragment>
  );
};

const mapStateToProps = ({ manufacturer }: IRootState) => ({
  userManufacturer: manufacturer.entity,
});

const mapDispatchToProps = {
  operateUserConfiguration,
  getEntity,
  resetEntity,
  getManufacturerForCurrentUser,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(InventoryProductCard);
