import React from 'react';
import { Modal, Label, Input } from 'reactstrap';
import Select from 'react-select';
import { commission } from 'app/config/constants';
import './productConfiguratorCard.scss';

interface IRegionsSelectModal {
  isOpen: boolean;
  closeRegionsModal: Function;
  name: string;
  regions: any;
  enableRegions: any;
  handleRegionActivation: Function;
  enableConfigurator: Function;
}

const RegionsSelectModal = (props: IRegionsSelectModal) => {
  const customSelectStyles = {
    menuList: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      marginTop: -28,
      boxShadow: '0px 15px 30px #a1b0cf14',
      padding: 0,
      borderRadius: 10,
    }),
    option: (provided, state) => ({
      ...provided,
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 8,
      fontSize: 12,
      borderBottom: '1px solid #7070700d',
      color: 'black',

      fontWeight: 300,
      '&:hover': {
        background: 'transparent',
        color: '#E2863C',
      },
      background: 'transparent',
      cursor: 'pointer',
    }),
    singleValue: provided => ({
      ...provided,
      color: 'white',
      fontSize: 12,
    }),
  };

  return (
    <Modal
      isOpen={props.isOpen}
      centered
      style={
        window.screen.width >= 1024
          ? { maxWidth: window.screen.width - 450, width: '100%' }
          : { maxWidth: '95%', width: '95%', margin: 'auto' }
      }
      toggle={props.closeRegionsModal}
      backdrop={true}
    >
      <span className="regions-map-title-label">Configurator Name</span>
      <span className="regions-map-title">{props.name}</span>
      <img src="../../../content/images/regionsMap.svg" alt="regions map" width="35%" className="regions-map" />
      <span className="regions-map-subtitle">Receive leads from the following MASFI regions</span>
      {props.regions && (
        <div className="regions-selects-container">
          {props.regions.map((item, index) => {
            const region = props.enableRegions.find(activeRegion => activeRegion.region === item);
            return (
              <div key={index} className="commission-select-block">
                <span
                  className={`comission-select-region-label ${region && region.activated ? 'comission-select-region-label--selected' : ''}`}
                >
                  {'Region' + ' ' + item.split('_')[1]}
                </span>
                <Select
                  defaultValue={{
                    label: `$${(region && region.commission) || '0'}`,
                    value: (region && region.commission) || 0,
                  }}
                  options={commission}
                  onChange={value => props.handleRegionActivation(item, value.value)}
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  styles={customSelectStyles}
                  isSearchable={false}
                  className={`comission-select ${region && region.activated ? 'comission-select--selected' : ''}`}
                />
                <img src="../../../content/images/icons/comission-select-arrow.svg" alt="arrow down" className="comission-select-arrow" />
              </div>
            );
          })}
        </div>
      )}
      <div className="regions-modal-inputs-container">
        <div className="regions-modal-input-container">
          <Label for="discount">Discount</Label>
          <Input id="discount" className="regions-modal-input" />
        </div>
        <div className="regions-modal-input-container">
          <Label for="markup">Markup</Label>
          <Input id="markup" className="regions-modal-input" />
        </div>
      </div>
      <div className="regions-buttons-container">
        <button className="regions-button save-regions-button" type="button" onClick={() => props.enableConfigurator()}>
          Save
        </button>
        <button className="regions-button cancel-regions-button" type="button" onClick={() => props.closeRegionsModal()}>
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default RegionsSelectModal;
