import { defaultValue, IVersion } from 'app/shared/model/version.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';

export const ACTION_TYPES = {
  FETCH_VERSION_LIST: 'version/FETCH_VERSION_LIST',
  FETCH_VERSION: 'version/FETCH_VERSION',
  CREATE_VERSION: 'version/CREATE_VERSION',
  UPDATE_VERSION: 'version/UPDATE_VERSION',
  DELETE_VERSION: 'version/DELETE_VERSION',
  DOWNLOAD_FILE: 'version/DOWNLOAD_FILE',
  SET_BLOB: 'version/SET_BLOB',
  RESET: 'version/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IVersion>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type VersionState = Readonly<typeof initialState>;

// Reducer

export default (state: VersionState = initialState, action): VersionState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_VERSION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_VERSION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_VERSION):
    case REQUEST(ACTION_TYPES.UPDATE_VERSION):
    case REQUEST(ACTION_TYPES.DELETE_VERSION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_VERSION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_VERSION):
    case FAILURE(ACTION_TYPES.CREATE_VERSION):
    case FAILURE(ACTION_TYPES.UPDATE_VERSION):
    case FAILURE(ACTION_TYPES.DELETE_VERSION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_VERSION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_VERSION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_VERSION):
    case SUCCESS(ACTION_TYPES.UPDATE_VERSION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_VERSION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + 'ContentType']: contentType,
        },
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/versions';

// Actions

export const getEntities: ICrudGetAllAction<IVersion> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_VERSION_LIST,
    payload: axios.get<IVersion>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getVersionByReleaseType = (releaseType, page?, size?, sort?) => {
  const requestUrl = `api/versions-by-release-type/${releaseType}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_VERSION_LIST,
    payload: axios.get<IVersion>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

const getDownloadFile = (id: number) => {
  const url = `${apiUrl}/${id}/signed-url`;
  return {
    type: ACTION_TYPES.DOWNLOAD_FILE,
    payload: fetch(url, {
      redirect: 'follow',
    })
      .then(response => {
        // When redirected download the file
        if (response.redirected) {
          window.location.href = response.url;
        }
      })
      .catch(function (err) {
        console.info(err); // eslint-disable-line no-console
      }),
  };
};

export const getEntity: ICrudGetAction<IVersion> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_VERSION,
    payload: axios.get<IVersion>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IVersion> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_VERSION,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IVersion> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_VERSION,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IVersion> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_VERSION,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
  },
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const downloadVersionFile = (id: number) => {
  return getDownloadFile(id);
};
