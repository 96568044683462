import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Input } from 'reactstrap';
import './dealerRegistrationProcessPages.scss';
import { IRootState } from 'app/shared/reducers';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';

interface IAdressInformation {
  setBusinessMember: Function;
  dealerDatas: any;
  businessMember: any;
  stateData: any;
  account: any;
  selectedBusiness: any;
}

const AdressInformation = (props: IAdressInformation) => {
  const [dealersData, setDealersData] = useState([]);

  useEffect(() => {
    if (props.dealerDatas.length > 0) {
      const results = props.dealerDatas.map(item => {
        return { value: item.specPathUserId, label: item.company };
      });
      setDealersData(results);
    }
  }, [props.dealerDatas]);

  const customSelectStyles = {
    placeholder(base, state) {
      return {
        ...base,
        opacity: 0.5,
      };
    },
    menuList: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      marginTop: -8,
      boxShadow: '0px 15px 30px #a1b0cf14',
      padding: 0,
      borderRadius: 10,
    }),
    option: (provided, state) => ({
      ...provided,
      paddingTop: 13,
      paddingBottom: 13,
      borderBottom: '1px solid #7070700d',
      color: '#9FAAB5',
      fontWeight: 300,
      '&:hover': {
        background: 'transparent',
        color: '#E2863C',
      },
      background: 'transparent',
      cursor: 'pointer',
    }),
  };

  const findSelectedOne = () => {
    const result = props.dealerDatas.find(function (option) {
      if (
        (props.account.type === 'ROLE_DEALER_ADMIN' && option.specPathUserId === props?.businessMember?.refDealerId) ||
        (props.stateData != null && option.specPathUserId === props.stateData.value)
      ) {
        return option;
      } else if (
        (props.account.type === 'ROLE_MANUFACTURER_ADMIN' && option.specPathUserId === props?.businessMember?.refManufacturerId) ||
        (props.stateData != null && option.specPathUserId === props.stateData.value)
      ) {
        return option;
      }
    });
    if (result) {
      return { value: result.specPathUserId, label: result.company };
    } else {
      return '';
    }
  };

  const primaryRegionValue = () => {
    return props.selectedBusiness?.value && findSelectedOne()
      ? props.dealerDatas.find(item => item.specPathUserId === props.selectedBusiness.value).regionOne
      : '';
  };

  return (
    <div className="position-relative">
      <span className="business-field-label">Company Name</span>
      <Select
        options={dealersData}
        value={findSelectedOne()}
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        className="business-name-select"
        placeholder={'Select Company Name'}
        styles={customSelectStyles}
        onChange={value => props.setBusinessMember(value)}
      />
      <img src="../../../content/images/icons/arrow_down.svg" alt="arrow down" className="business-name-select-arrow-down" />
      <span className="business-field-label">Primary Region</span>
      <Input value={primaryRegionValue()} readOnly className="dealer-setup-primary-region-input" />
    </div>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  account: authentication.account,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(AdressInformation));
