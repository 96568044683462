import React, { memo } from 'react';
import { CardImg, Badge, Button } from 'reactstrap';
import ProductCardAction from '../ProductCardAction/ProductCardAction';
import moment from 'moment';
import Tippy from '@tippyjs/react';
import './floorPlanCard.scss';

interface IFloorPlanCard {
  project: any;
  forgeConnect: Function;
  setSelectedProjectId: Function;
  setIsExportRevitModalOpen: Function;
  setIsCreateDrawingsModalOpen: Function;
  setIsDeleteConfirmationDialogOpen: Function;
  setSelectedProjectObject: Function;
  setIsProjectDetailsModalOpen: Function;
  openProject: Function;
  setIsOpenProjectDetailsButtonWasPressed: Function;
}

const FloorPlanCard = memo((props: IFloorPlanCard) => {
  const goToProject = e => {
    if (
      e.target.nodeName === 'DIV' ||
      e.target.nodeName === 'SPAN' ||
      (e.target.nodeName === 'IMG' &&
        !e.target.className.includes('floor-plan-card-bottom-action-button-image') &&
        !e.target.className.includes('action-image') &&
        !e.target.className.includes('bordere-action-button'))
    ) {
      props.openProject(props.project.id);
    }
  };

  return (
    <div className="floor-plan-product-card" onClick={e => goToProject(e)}>
      <div className="floor-plan-card-actions-block">
        <Tippy content="Project Details" placement="bottom" theme="light-border">
          <button
            className="floor-plan-card-action-button project-details-button"
            onClick={() => {
              props.setSelectedProjectObject(props.project);
              props.setSelectedProjectId(props.project.id);
              props.setIsProjectDetailsModalOpen(true);
              props.setIsOpenProjectDetailsButtonWasPressed(true);
            }}
          >
            <img className="action-image" src="../../../content/images/icons/project-details-orange-icon.svg" />
          </button>
        </Tippy>
        <div className="floor-plan-card-right-actions">
          {/* <ProductCardAction iconName="copyIcon" bordered={true} /> */}
          <ProductCardAction
            iconName="closeCardIcon"
            bordered={true}
            onClick={() => {
              props.setIsDeleteConfirmationDialogOpen(true);
              props.setSelectedProjectObject(props.project);
            }}
          />
        </div>
      </div>
      {props.project.thumbnailImage ? (
        <CardImg top src={props.project.thumbnailImage} alt="Missing image" className="image floorplan-card-image" />
      ) : (
        <span className="floor-plan-card-products-number">
          {props.project.totalProductQuantity || 0} {props.project.totalProductQuantity === 1 ? 'Product' : 'Products'}
        </span>
      )}
      <span className="floor-plan-card-title ellipsis">{props.project.projectName}</span>
      <span className="floor-plan-card-subtitle">
        Last Modified: <span style={{ fontWeight: 600 }}>{moment(props.project.lastModified).format('MMMM DD, YYYY, HH:mm a')}</span>
      </span>

      <span className="floor-plan-card-subtitle">
        {props.project.forgeStatus == 'IN_PROGRESS' ? (
          <Badge style={{ fontWeight: 600 }}>Preparing Drawing</Badge>
        ) : props.project.forgeStatus == 'COMPLETE' ? (
          <Badge color={'success'} style={{ fontWeight: 600 }}>
            Drawing Completed
          </Badge>
        ) : props.project.forgeStatus == 'FAILURE' ? (
          <Badge color={'danger'} style={{ fontWeight: 600 }}>
            Failed
          </Badge>
        ) : (
          ''
        )}
      </span>

      <div className="floor-plan-card-bottom-actions-block">
        {props.project.forgeStatus !== 'IN_PROGRESS' ? (
          <Tippy content="Create Drawings" placement="bottom" theme="light-border">
            <button
              className="floor-plan-card-bottom-action-button"
              onClick={() => {
                props.setSelectedProjectId(props.project.id);
                props.setSelectedProjectObject(props.project);
                props.setIsCreateDrawingsModalOpen(true);
              }}
            >
              <img
                src="../../../content/images/icons/exportPdfBlue.svg"
                alt="export pdf"
                width="30"
                className="floor-plan-card-bottom-action-button-image"
              />
            </button>
          </Tippy>
        ) : (
          ''
        )}

        <Tippy content="Share Revit" placement="bottom" theme="light-border">
          <button
            className="floor-plan-card-bottom-action-button"
            onClick={() => {
              props.setSelectedProjectId(props.project.id);
              props.setIsExportRevitModalOpen(true);
            }}
          >
            <img
              src="../../../content/images/icons/exportRevitBlue.svg"
              alt="Share Revit"
              width="40"
              className="floor-plan-card-bottom-action-button-image"
            />
          </button>
        </Tippy>
      </div>
      <div className="inventory-card-unread-status-container">
        <span className="inventory-card-shared-by">
          {props.project.shareBy
            ? props.project.shareBy.split(' ')[0] + ' ' + props.project.shareBy.split(' ')[1][0] + '. ' + 'shared this with you.'
            : ' '}
        </span>
      </div>
    </div>
  );
});

export default FloorPlanCard;
