export const enum Region {
  REGION_1 = 'REGION_1',
  REGION_2 = 'REGION_2',
  REGION_3 = 'REGION_3',
  REGION_4 = 'REGION_4',
  REGION_5 = 'REGION_5',
  REGION_6 = 'REGION_6',
  REGION_7 = 'REGION_7',
  REGION_11 = 'REGION_11',
  REGION_12 = 'REGION_12',
  REGION_13 = 'REGION_13',
  REGION_14 = 'REGION_14',
  REGION_15 = 'REGION_15',
  REGION_16 = 'REGION_16',
  REGION_17 = 'REGION_17',
  REGION_18 = 'REGION_18',
  REGION_19 = 'REGION_19',
  REGION_20 = 'REGION_20',
  REGION_21 = 'REGION_21',
  REGION_22 = 'REGION_22',
  REGION_24 = 'REGION_24',
  REGION_25 = 'REGION_25',
  REGION_26a = 'REGION_26a',
  REGION_26b = 'REGION_26b',
  REGION_26c = 'REGION_26c',
  REGION_26d = 'REGION_26d',
  REGION_26e = 'REGION_26e',
  REGION_26f = 'REGION_26f',
}

export interface IEnableRegion {
  id?: number;
  activated?: boolean;
  commission?: number;
  region?: Region;
  enabledProductConfiguratorsId?: number;
}

export const defaultValue: Readonly<IEnableRegion> = {
  activated: false,
};
