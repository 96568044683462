import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';

export const ACTION_TYPES = {
  FILL_USER_INFO: 'account/FILL_USER_INFO',
  RESET: 'account/RESET',
};

const initialState = {
  loading: false,
  fillUserInfoSuccess: false,
  fillUserInfoFailure: false,
  errorMessage: null,
};

export type FillUserInfoState = Readonly<typeof initialState>;

// Reducer
export default (state: FillUserInfoState = initialState, action): FillUserInfoState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FILL_USER_INFO):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FILL_USER_INFO):
      return {
        ...initialState,
        fillUserInfoFailure: true,
        errorMessage: action.payload.response.data.errorKey,
      };
    case SUCCESS(ACTION_TYPES.FILL_USER_INFO):
      return {
        ...initialState,
        fillUserInfoSuccess: true,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Actions
export const handleRegister = (password, firstName, lastName, company, key, login, langKey = 'en') => ({
  type: ACTION_TYPES.FILL_USER_INFO,
  payload: axios.post(`api/fill-user-info?key=${key}`, { login, password, langKey, firstName, lastName, company }),
  meta: {
    successMessage: 'Your user information was saved! Now you can login.',
  },
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
