import React, {useEffect} from 'react';
import {Badge, Button, CardImg} from 'reactstrap';
import BaseLayout from '../BaseLayout';
import {IRootState} from 'app/shared/reducers';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import {getManufacturerRefForCurrentUser} from "app/entities/manufacturer-ref/manufacturer-ref.reducer";

export interface IManufacturerProps extends StateProps, DispatchProps {
}

const MyManufacturerRef = (props: IManufacturerProps) => {

  useEffect(() => {
    props.getManufacturerRefForCurrentUser();
  }, []);

  return (
    <BaseLayout title={'My Manufacturer'}>
      {!props.loading && props.manufacturerRef && (
        <div className="product-details-modal-container">
          <div className="product-details-modal-image-block">
            <CardImg top src={props.manufacturerRef.logo} alt="Missing image"
                     className="image product-details-image"/>
          </div>
          <div className="product-details-modal-image-block">
            <Button color="primary" tag={Link} id="cancel-save" to="/edit-my-manufacturer">
              <FontAwesomeIcon icon="save"/>
              &nbsp; Edit
            </Button>
          </div>
          <div className="product-details-modal-data-block">
            <div className="product-details-modal-data-container">
              <div className="product-details-modal-product-name">
                <span className="product-details-modal-name-description">Manufacturer Name</span>
                <span className="product-details-modal-name-value">{props.manufacturerRef.name}</span>
                <br/>
                <span className="product-details-modal-key">Manufacturer City</span>
                <span className="product-details-modal-manufacturer-value">{props.manufacturerRef.city}</span>
                <br/>
                <span className="product-details-modal-key">State</span>
                <span className="product-details-modal-manufacturer-value">{props.manufacturerRef.state}</span>
                <br/>
                <span className="product-details-modal-key">Website</span>
                <span className="product-details-modal-manufacturer-value">{props.manufacturerRef.websiteURL}</span>
                <br/>
                <span className="product-details-modal-key">Status</span>
                <span className="product-details-modal-manufacturer-value">{props.manufacturerRef.enabled ?
                  <Badge color="success">Active</Badge> : <Badge color="danger">Inactive</Badge>}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </BaseLayout>
  );
};

const mapStateToProps = ({manufacturerRef}: IRootState) => ({
  manufacturerRef: manufacturerRef.entity,
  loading: manufacturerRef.loading,
});

const mapDispatchToProps = {
  getManufacturerRefForCurrentUser,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MyManufacturerRef);
