import React from 'react';
import { Modal } from 'reactstrap';
import './marketplaceModal.scss';

interface IMarketplacePopover {
  handleShopEquipmentDealersButton: Function;
  loading: boolean;
  loadingProduct: boolean;
  updating: boolean;
  isOpen: boolean;
  setIsMarketplaceModalOpen: boolean;
}

const MarketplaceButtonsModal = (props: IMarketplacePopover) => {
  return (
    <Modal
      isOpen={props.isOpen}
      centered
      style={{ maxWidth: 700, width: window.screen.width - 900 }}
      toggle={props.setIsMarketplaceModalOpen}
      backdrop={true}
    >
      <div className="configurator-marketplace-buttons-container">
        <button
          className="find-equipment-button"
          onClick={() => props.handleShopEquipmentDealersButton()}
          disabled={props.loading || props.loadingProduct || props.updating}
        >
          <img src="../../../content/images/icons/marketplace-cart.svg" alt="marketplace cart" width="30" /> Shop Equipment Dealers
        </button>
        <button className="find-equipment-button" disabled={props.loading || props.loadingProduct || props.updating}>
          <img src="../../../content/images/icons/marketplace-cart.svg" alt="marketplace cart" width="30" /> Shop Foodservice Consultants
        </button>
        <button className="find-equipment-button" disabled={props.loading || props.loadingProduct || props.updating}>
          <img src="../../../content/images/icons/marketplace-cart.svg" alt="marketplace cart" width="30" /> Find Test Kitchens
        </button>
      </div>
    </Modal>
  );
};

export default MarketplaceButtonsModal;
