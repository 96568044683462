import { IUserConfiguration } from 'app/shared/model/user-configuration.model';

export interface IFolder {
  id?: number;
  name?: string;
  userLogin?: string;
  userId?: number;
  userConfigurations?: IUserConfiguration[];
  tags?: any;
}

export const defaultValue: Readonly<IFolder> = {};
