import React from 'react';
import './selectButton.scss';

interface ISelectButton {
  title: string;
  onClick: any;
}

const SelectButton = (props: ISelectButton) => {
  return (
    <button className="select-button" onClick={props.onClick}>
      {props.title}
    </button>
  );
};

export default SelectButton;
