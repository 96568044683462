export const enum ProfileStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  UPDATE_REQUESTED = 'UPDATE_REQUESTED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

export interface IManufacturerRef {
  id?: number;
  name?: string;
  enabled?: boolean;
  logo?: string;
  websiteURL?: string;
  city?: string;
  state?: string;
  businessName?: string;
  skills?: string;
  qualifySpecialStatus?: string;
  cardNumber?: string;
  cardHolder?: string;
  customerId?: string;
  month?: string;
  year?: string;
  cvc?: string;
  status?: ProfileStatus;
  refManufacturerRefId?: string;
  subscriptionId?: string;
  payPerUsagesSubscriptionId?: string;
  userLogin?: string;
  userId?: number;
}

export const defaultValue: Readonly<IManufacturerRef> = {
  enabled: false,
};
