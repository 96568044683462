import React, { useEffect, useState } from 'react';
import BaseLayout from '../BaseLayout';
import Search from 'app/components/Search/Search';
import PaymentDetailsModal from 'app/components/PaymentsTable/PaymentDetailsModal';
import PaymentsTable from 'app/components/PaymentsTable/PaymentsTable';
import DatePickerFilter from 'app/components/DatePicker/DatePickerFilter';
import { connect } from 'react-redux';
import AppSpinner from 'app/components/Spinner/Spinner';
import './payments.scss';
import { IRootState } from 'app/shared/reducers';
import { getSubscriptionList } from 'app/entities/stripe/stripe.reducer';

interface IPaymentsPage {
  subscriptionList: any;
  getSubscriptionList: any;
  loading: any;
}

const PaymentsPage = (props: IPaymentsPage) => {
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    props.getSubscriptionList();
  }, []);

  return (
    <BaseLayout title="Payments" search={<Search />} datePicker={<DatePickerFilter startDate={startDate} setStartDate={setStartDate} />}>
      <div className="mt-5"></div>
      {props.loading && <AppSpinner />}
      <PaymentsTable subscriptionList={props.subscriptionList} />
      {/* <NoDataBanner title="You have no Payments to display yet!" /> */}
    </BaseLayout>
  );
};

const mapStateToProps = ({ stripe }: IRootState) => ({
  subscriptionList: stripe.subscriptionList,
  loading: stripe.loading,
});

const mapDispatchToProps = {
  getSubscriptionList,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsPage);
