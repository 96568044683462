import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import EnableRegion from './enable-region';
import EnableRegionDetail from './enable-region-detail';
import EnableRegionUpdate from './enable-region-update';
import EnableRegionDeleteDialog from './enable-region-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={EnableRegionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={EnableRegionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={EnableRegionDetail} />
      <ErrorBoundaryRoute path={match.url} component={EnableRegion} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={EnableRegionDeleteDialog} />
  </>
);

export default Routes;
