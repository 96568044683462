import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './leads.reducer';
import { ILeads } from 'app/shared/model/leads.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ILeadsDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const LeadsDetail = (props: ILeadsDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { leadsEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          Leads [<b>{leadsEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="status">Status</span>
          </dt>
          <dd>{leadsEntity.status}</dd>
          <dt>
            <span id="originFirstName">Origin First Name</span>
          </dt>
          <dd>{leadsEntity.originFirstName}</dd>
          <dt>
            <span id="originLastName">Origin Last Name</span>
          </dt>
          <dd>{leadsEntity.originLastName}</dd>
          <dt>
            <span id="originEmail">Origin Email</span>
          </dt>
          <dd>{leadsEntity.originEmail}</dd>
          <dt>
            <span id="originStreetAddress">Origin Street Address</span>
          </dt>
          <dd>{leadsEntity.originStreetAddress}</dd>
          <dt>
            <span id="originZipCode">Origin Zip Code</span>
          </dt>
          <dd>{leadsEntity.originZipCode}</dd>
          <dt>
            <span id="originCity">Origin City</span>
          </dt>
          <dd>{leadsEntity.originCity}</dd>
          <dt>
            <span id="originState">Origin State</span>
          </dt>
          <dd>{leadsEntity.originState}</dd>
          <dt>
            <span id="originCountry">Origin Country</span>
          </dt>
          <dd>{leadsEntity.originCountry}</dd>
          <dt>
            <span id="originPhone">Origin Phone</span>
          </dt>
          <dd>{leadsEntity.originPhone}</dd>
          <dt>
            <span id="sameAsAbove">Same As Above</span>
          </dt>
          <dd>{leadsEntity.sameAsAbove ? 'true' : 'false'}</dd>
          <dt>
            <span id="projectFirstName">Project First Name</span>
          </dt>
          <dd>{leadsEntity.projectFirstName}</dd>
          <dt>
            <span id="projectLastName">Project Last Name</span>
          </dt>
          <dd>{leadsEntity.projectLastName}</dd>
          <dt>
            <span id="projectEmail">Project Email</span>
          </dt>
          <dd>{leadsEntity.projectEmail}</dd>
          <dt>
            <span id="projectStreetAddress">Project Street Address</span>
          </dt>
          <dd>{leadsEntity.projectStreetAddress}</dd>
          <dt>
            <span id="projectZipCode">Project Zip Code</span>
          </dt>
          <dd>{leadsEntity.projectZipCode}</dd>
          <dt>
            <span id="projectCity">Project City</span>
          </dt>
          <dd>{leadsEntity.projectCity}</dd>
          <dt>
            <span id="projectState">Project State</span>
          </dt>
          <dd>{leadsEntity.projectState}</dd>
          <dt>
            <span id="projectCountry">Project Country</span>
          </dt>
          <dd>{leadsEntity.projectCountry}</dd>
          <dt>
            <span id="dateTime">Date Time</span>
          </dt>
          <dd>{leadsEntity.dateTime}</dd>
          <dt>
            <span id="versionNumber">Version Number</span>
          </dt>
          <dd>{leadsEntity.versionNumber}</dd>
          <dt>
            <span id="notes">Notes</span>
          </dt>
          <dd>{leadsEntity.notes}</dd>
          <dt>
            <span id="projectName">Project Name</span>
          </dt>
          <dd>{leadsEntity.projectName}</dd>
          <dt>Manufacturer</dt>
          <dd>{leadsEntity.manufacturerName ? leadsEntity.manufacturerName : ''}</dd>
          <dt>Dealer</dt>
          <dd>{leadsEntity.dealerName ? leadsEntity.dealerName : ''}</dd>
          <dt>User</dt>
          <dd>{leadsEntity.userLogin ? leadsEntity.userLogin : ''}</dd>
        </dl>
        <Button tag={Link} to="/leads" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/leads/${leadsEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ leads }: IRootState) => ({
  leadsEntity: leads.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LeadsDetail);
