import React from 'react';
import { useHistory } from 'react-router-dom';
import { setIsDealerAccountBannerShown } from 'app/modules/account/register/register.reducer';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import './manufacturerAccountSetupBanner.scss';

interface IManufacturerAccountSetupBanner extends DispatchProps {}

const ManufacturerAccountSetupBanner = (props: IManufacturerAccountSetupBanner) => {
  let history = useHistory();

  const goToManufacturerAccountSetup = () => {
    history.push('/manufacturer-account-setup');
    props.setIsDealerAccountBannerShown(false);
  };
  return (
    <div className="dealer-registration-banner">
      <span className="dealer-registration-banner-content">
        Your account setup is incomplete. You need to enter your CC info and other profile info like their full address.
        <button onClick={goToManufacturerAccountSetup} className="dealer-registration-banner-redirect-button">
          Click here
        </button>
        to fill details
      </span>
    </div>
  );
};

const mapDispatchToProps = {
  setIsDealerAccountBannerShown,
};

type DispatchProps = typeof mapDispatchToProps;

export default connect(null, mapDispatchToProps)(ManufacturerAccountSetupBanner);
