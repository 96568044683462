import { IEnableRegion } from 'app/shared/model/enable-region.model';

export interface IEnabledProductConfigurators {
  id?: number;
  activated?: boolean;
  manufacturerName?: string;
  manufacturerId?: number;
  dealerName?: string;
  dealerId?: number;
  userLogin?: string;
  userId?: number;
  productName?: string;
  productId?: number;
  enableRegions?: IEnableRegion[];
}

export const defaultValue: Readonly<IEnabledProductConfigurators> = {
  activated: false,
};
