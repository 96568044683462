import React from 'react';
import './noDataBanner.scss';

interface INoDataBanner {
  title: string;
}

const NoDataBanner = (props: INoDataBanner) => {
  return (
    <div className="no-data-block">
      <span className="no-data-message">{props.title}</span>
    </div>
  );
};

export default NoDataBanner;
