export const enum NotificationType {
  DRAWING = 'DRAWING',
  NOTIFICATION = 'NOTIFICATION',
}

export interface ISocketConnection {
  id?: number;
  connectionId?: string;
  userId?: number;
  project?: number;
  notificationType?: NotificationType;
}

export const defaultValue: Readonly<ISocketConnection> = {};
