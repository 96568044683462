import React, { memo, useCallback } from 'react';
import { Button, ButtonGroup, Modal } from 'reactstrap';
import FolderSelectCard from './FolderSelectCard';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { operateUserConfiguration } from 'app/entities/user-configuration/user-configuration.reducer';

interface IFolderSelectModal extends StateProps {
  isOpen: boolean;
  closeFolderSelectModal: Function;
  operationRequest: any;
  setOperationRequest: any;
  operateUserConfiguration: Function;
  isMultipleSelectModeActive?: boolean;
}

const FolderSelectModal = memo((props: IFolderSelectModal) => {
  const { operationRequest, setOperationRequest } = props;

  const selectFolder = useCallback(
    (id: number) => {
      setOperationRequest({ ...operationRequest, destinationFolderId: id });
    },
    [operationRequest]
  );

  const handleCopy = () => {
    setOperationRequest({ ...operationRequest, operationType: props.isMultipleSelectModeActive ? 'COPY_ALL' : 'COPY' });
  };

  const handleMove = () => {
    setOperationRequest({ ...operationRequest, operationType: props.isMultipleSelectModeActive ? 'MOVE_ALL' : 'MOVE' });
  };
  const handleSubmit = () => {
    props.operateUserConfiguration(operationRequest);
    props.closeFolderSelectModal();
  };

  return (
    <Modal
      isOpen={props.isOpen}
      centered
      style={{ width: window.screen.width - 1000, maxWidth: 800 }}
      toggle={() => props.closeFolderSelectModal()}
      backdrop={true}
    >
      <span className="select-folder-modal-title">Select Folder</span>
      <div className="folders-container">
        {props.folderList.map(item => (
          <FolderSelectCard
            cardData={item}
            key={item.id}
            selectedFolder={operationRequest.destinationFolderId}
            setSelectedFolder={() => selectFolder(item.id)}
          />
        ))}
      </div>
      <ButtonGroup className="copy-move-folder-buttons-group">
        <Button
          onClick={() => handleCopy()}
          className="copy-move-folder-button"
          active={operationRequest.operationType === 'COPY' || operationRequest.operationType === 'COPY_ALL'}
          style={{ marginRight: 15 }}
        >
          Copy
        </Button>
        <Button
          onClick={() => handleMove()}
          className="copy-move-folder-button"
          active={operationRequest.operationType === 'MOVE' || operationRequest.operationType === 'MOVE_ALL'}
        >
          Move
        </Button>
      </ButtonGroup>

      <Button className="select-folder-button" type={'button'} onClick={() => handleSubmit()}>
        Done
      </Button>
    </Modal>
  );
});

const mapStateToProps = ({ folder }: IRootState) => ({
  folderList: folder.entities,
});

const mapDispatchToProps = {
  operateUserConfiguration,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(FolderSelectModal);
