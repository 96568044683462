import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './manufacturer-ref.reducer';
import { IManufacturerRef } from 'app/shared/model/manufacturer-ref.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IManufacturerRefDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ManufacturerRefDetail extends React.Component<IManufacturerRefDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { manufacturerRefEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            ManufacturerRef [<b>{manufacturerRefEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="name">Name</span>
            </dt>
            <dd>{manufacturerRefEntity.name}</dd>
            <dt>
              <span id="enabled">Enabled</span>
            </dt>
            <dd>{manufacturerRefEntity.enabled ? 'true' : 'false'}</dd>
            <dt>
              <span id="logo">Logo</span>
            </dt>
            <dd>{manufacturerRefEntity.logo}</dd>
            <dt>
              <span id="websiteURL">Website URL</span>
            </dt>
            <dd>{manufacturerRefEntity.websiteURL}</dd>
            <dt>
              <span id="city">City</span>
            </dt>
            <dd>{manufacturerRefEntity.city}</dd>
            <dt>
              <span id="state">State</span>
            </dt>
            <dd>{manufacturerRefEntity.state}</dd>
            <dt>
              <span id="businessName">Business Name</span>
            </dt>
            <dd>{manufacturerRefEntity.businessName}</dd>
            <dt>
              <span id="skills">Skills</span>
            </dt>
            <dd>{manufacturerRefEntity.skills}</dd>
            <dt>
              <span id="qualifySpecialStatus">Qualify Special Status</span>
            </dt>
            <dd>{manufacturerRefEntity.qualifySpecialStatus}</dd>
            <dt>
              <span id="cardNumber">Card Number</span>
            </dt>
            <dd>{manufacturerRefEntity.cardNumber}</dd>
            <dt>
              <span id="cardHolder">Card Holder</span>
            </dt>
            <dd>{manufacturerRefEntity.cardHolder}</dd>
            <dt>
              <span id="customerId">Customer Id</span>
            </dt>
            <dd>{manufacturerRefEntity.customerId}</dd>
            <dt>
              <span id="month">Month</span>
            </dt>
            <dd>{manufacturerRefEntity.month}</dd>
            <dt>
              <span id="year">Year</span>
            </dt>
            <dd>{manufacturerRefEntity.year}</dd>
            <dt>
              <span id="cvc">Cvc</span>
            </dt>
            <dd>{manufacturerRefEntity.cvc}</dd>
            <dt>
              <span id="status">Status</span>
            </dt>
            <dd>{manufacturerRefEntity.status}</dd>
            <dt>
              <span id="refManufacturerRefId">Ref Manufacturer Ref Id</span>
            </dt>
            <dd>{manufacturerRefEntity.refManufacturerRefId}</dd>
            <dt>
              <span id="subscriptionId">Subscription Id</span>
            </dt>
            <dd>{manufacturerRefEntity.subscriptionId}</dd>
            <dt>
              <span id="payPerUsagesSubscriptionId">Pay Per Usages Subscription Id</span>
            </dt>
            <dd>{manufacturerRefEntity.payPerUsagesSubscriptionId}</dd>
            <dt>User</dt>
            <dd>{manufacturerRefEntity.userLogin ? manufacturerRefEntity.userLogin : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/manufacturer-ref" replace color="info">
            <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/manufacturer-ref/${manufacturerRefEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ manufacturerRef }: IRootState) => ({
  manufacturerRefEntity: manufacturerRef.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManufacturerRefDetail);
