import React from 'react';
import { Collapse, CardBody, Card } from 'reactstrap';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light-border.css';
import '../navbar/navbar.scss';
import './style.scss';

export interface IDropDownProps {
  isDropdownOpen: boolean;
  icon: string;
  setIsDropdownOpen: Function;
  title: string;
  children: any;
  iconClass?: string;
  tooltipTitle?: string;
}

const NavDropdown = (props: IDropDownProps) => {
  return (
    <div className="navigation-dropdown">
      <button
        className={`navbar-dropdown-button ${props.isDropdownOpen ? 'navbar-item--active' : ' '}`}
        onClick={() => props.setIsDropdownOpen(!props.isDropdownOpen)}
      >
        <Tippy content={props.tooltipTitle} placement="right" theme="light-border">
          <img src={`../../../../content/images/icons/${props.icon}.svg`} className={props.iconClass} alt="Logo" width="25" height="25" />
        </Tippy>
        <span id="admin" className="navigation-dropdown-title">
          {props.title}
        </span>
        {!props.isDropdownOpen ? (
          <img src="../../../../content/images/icons/arrow_down.svg" alt="icon-down" className="arrow-icon" />
        ) : (
          <img src="../../../../content/images/icons/Icon ionic-ios-arrow-up.svg" alt="icon-down" className="arrow-icon" />
        )}
      </button>
      <Collapse isOpen={props.isDropdownOpen} className="product-configurators-dropdown">
        <Card>
          <CardBody>{props.children}</CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

export default NavDropdown;
