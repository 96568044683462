import { IUser } from 'app/shared/model/user.model';

export interface IUserSettings {
  id?: number;
  generalNotification?: boolean;
  marketPlaceNotification?: boolean;
  hideManufacturers?: any;
  projectStartWith?: boolean;
  drawnBy?: string;
  onBoardingSeen?: boolean;
  user?: IUser;
}

export const defaultValue: Readonly<IUserSettings> = {
  generalNotification: false,
  marketPlaceNotification: false,
  projectStartWith: false,
  onBoardingSeen: false,
};
