import React, { useState, useEffect } from 'react';
import BaseLayout from '../BaseLayout';
import MainThemeButton from 'app/components/MainThemeButton/MainThemeButton';
import { Button } from 'reactstrap';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import {
  getProjectSubmissionDetails,
  resetProjectSubmissionDetails,
} from 'app/entities/project-submission-log/project-submission-log.reducer';
import { getProject } from 'app/entities/project/project.reducer';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import AppSpinner from 'app/components/Spinner/Spinner';
import { usePartsList } from 'app/customHooks/usePartsList';
import useViewConfiguration from 'app/customHooks/useSharePageRedirect';
import { PROJECT_STATUSES_TRANSLATION } from 'app/config/constants';
import moment from 'moment';
import './projectSubmissionDetails.scss';
import ClientDetails from './ClientDetails';
import UserInfo from './UserInfo';
import ProductConfigurationItem from './ProductConfigurationItem';

interface IProjectSubmissionDetails extends StateProps, DispatchProps, RouteComponentProps<{ projectId: string }> {
  details: any;
}

const ProjectSubmissionDetails = (props: IProjectSubmissionDetails) => {
  let history = useHistory();
  const [openProductIndex, setOpenProductIndex] = useState(null);
  const openProduct = openProductIndex !== null ? props.details?.project?.projectProducts[openProductIndex] : null;
  const { isDetailsOpen, setisDetailsOpen, projectProductsPartsList, partsListLoading } = usePartsList([openProduct]);
  const { setIsViewConfigurationButtonWasPressed, getCurrentProject } = useViewConfiguration(props);
  const [openStates, setOpenStates] = useState<boolean[]>([]);

  useEffect(() => {
    if (props.details?.project?.projectProducts) {
      setOpenStates(props.details.project.projectProducts.map(() => false));
    }
  }, [props.details]);

  const toggleOpen = index => {
    setOpenStates(prevStates => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
    setOpenProductIndex(index);
  };

  useEffect(() => {
    props.getProjectSubmissionDetails(props.match.params.projectId);

    return () => {
      props.resetProjectSubmissionDetails();
    };
  }, []);

  useEffect(() => {
    if (openProduct) {
      setisDetailsOpen(true);
    }
  }, [openProduct]);

  const statusesColors = {
    IN_PROGRESS: 'status-yellow',
    SENT: 'status-blue',
    APPROVED: 'status-green',
    RESUBMIT: 'status-grey',
    CLOSED: 'status-red',
  };

  return (
    <BaseLayout
      backButton={
        <Button className="back-to-project-submission-button" onClick={() => history.push('/project-submission')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M10.5 19L3.5 12L10.5 5M20.5 12L4 12" stroke="black" stroke-width="2" />
          </svg>
        </Button>
      }
      title={props.details?.project?.projectName}
      subtitle={`Last Update – ${moment(props.details?.project?.lastModified).format('MMMM DD, YYYY, HH:mm a')}`}
      button={
        <div>
          <MainThemeButton
            title="View Configurator"
            onClick={() => {
              getCurrentProject(props.details?.project?.id);
              setIsViewConfigurationButtonWasPressed(true);
            }}
          />
        </div>
      }
    >
      {props.details === null || props.loading ? (
        <AppSpinner />
      ) : (
        <>
          <div className="project-submission-log-details-page-container">
            <div style={{ width: '75%', marginRight: 12 }}>
              {props.details?.project?.projectProducts.map((product, index) => (
                <ProductConfigurationItem
                  key={product.id}
                  product={product}
                  isOpen={openStates[index]}
                  toggleOpen={() => toggleOpen(index)}
                  partsListLoading={partsListLoading}
                  projectProductsPartsList={projectProductsPartsList}
                />
              ))}
            </div>
            <div style={{ width: '25%' }}>
              {props.details.project.thumbnailImage && (
                <div className="project-submission-log-details-page-image-container">
                  <img src={props.details?.project?.thumbnailImage} alt="project thumbnail image" width="100%" />
                  <div className={`${statusesColors[props.details.project?.status]} project-settings-project-status`}>
                    <img
                      src={`../../../content/images/icons/${PROJECT_STATUSES_TRANSLATION[props.details.project?.status].icon}.svg`}
                      alt="status icon"
                      style={{ marginRight: 4 }}
                    />
                    {PROJECT_STATUSES_TRANSLATION[props.details.project?.status].status}
                  </div>
                </div>
              )}
              <ClientDetails details={props.details} />
              <UserInfo details={props.details} />
            </div>
          </div>
        </>
      )}
    </BaseLayout>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  details: storeState.projectSubmissionLog.projectSubmissionDetails,
  loading: storeState.projectSubmissionLog.loadingDetails,
  currentProject: storeState.project.entity,
});

const mapDispatchToProps = {
  getProjectSubmissionDetails,
  resetProjectSubmissionDetails,
  getProject,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSubmissionDetails);
