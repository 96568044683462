import { useState, useEffect } from 'react';
import _ from 'lodash';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

const useCompanySetUp = props => {
  const [dataForCompaniesSelect, setDataForCompaniesSelect] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [selectedAccountType, setSelectedAccountType] = useState(AUTHORITIES.USER);
  const [isCompanyLoading, setIsCompanyLoading] = useState(false);

  const industryOptions = [
    { value: 'Foodservice', label: 'Foodservice', iconName: 'foodservice' },
    { value: 'Healthcare', label: 'Healthcare', iconName: 'healthcare' },
    { value: 'Commercial', label: 'Commercial', iconName: 'commercial' },
    { value: 'Residential', label: 'Residential', iconName: 'residential' },
    { value: 'Cannabis', label: 'Indoor Grow', iconName: 'cannabis' },
    { value: 'Other', label: 'Other', iconName: 'other' },
  ];

  const options = [
    { value: AUTHORITIES.ROLE_DEALER_USER, label: 'Dealer', specialization: 'Dealer' },
    { value: AUTHORITIES.ROLE_MANUFACTURER_USER, label: 'Manufacturer', specialization: 'Manufacturer' },
    { value: AUTHORITIES.ROLE_CONSULTANT_USER, label: 'Consultant', specialization: 'Consultant' },
    { value: AUTHORITIES.ROLE_MANUFACTURER_REP_USER, label: 'Manufacturer Representative', specialization: 'Manufacturer Representative' },
    { value: AUTHORITIES.USER, label: 'Architect/Interior Designer', specialization: 'Architect/Interior Designer' },
    { value: AUTHORITIES.USER, label: 'Engineer', specialization: 'Engineer' },
    { value: AUTHORITIES.USER, label: 'Construction', specialization: 'Construction' },
    { value: AUTHORITIES.USER, label: 'Chef', specialization: 'Chef' },
    { value: AUTHORITIES.USER, label: 'Bartender', specialization: 'Bartender' },
    { value: AUTHORITIES.USER, label: 'Restaurateur', specialization: 'Restaurateur' },
    { value: AUTHORITIES.USER, label: 'FB Director Manager', specialization: 'FB Director Manager' },
    { value: AUTHORITIES.USER, label: 'Other', specialization: 'Other' },
  ];

  useEffect(() => {
    if (
      hasAnyAuthority(props.account?.authorities, [AUTHORITIES.ROLE_DEALER_USER]) ||
      selectedAccountType === AUTHORITIES.ROLE_DEALER_USER
    ) {
      setDataForCompaniesSelect(props.dealerData);
    } else if (
      hasAnyAuthority(props.account?.authorities, [AUTHORITIES.ROLE_CONSULTANT_USER]) ||
      selectedAccountType === AUTHORITIES.ROLE_CONSULTANT_USER
    ) {
      setDataForCompaniesSelect(props.consultantData);
    } else if (
      hasAnyAuthority(props.account?.authorities, [AUTHORITIES.ROLE_MANUFACTURER_USER]) ||
      selectedAccountType === AUTHORITIES.ROLE_MANUFACTURER_USER
    ) {
      setDataForCompaniesSelect(props.manufacturerData);
    } else if (
      hasAnyAuthority(props.account?.authorities, [AUTHORITIES.ROLE_MANUFACTURER_REP_USER]) ||
      selectedAccountType === AUTHORITIES.ROLE_MANUFACTURER_REP_USER
    ) {
      setDataForCompaniesSelect(props.manufacturerRefData);
    }
    setIsCompanyLoading(false);
  }, [props.dealerData, props.consultantData, props.manufacturerData, props.manufacturerRefData]);

  const findCompanyDataByName = name => {
    if (name.trim()) {
      setIsCompanyLoading(true);
      if (
        hasAnyAuthority(props.account?.authorities, [AUTHORITIES.ROLE_DEALER_USER]) ||
        selectedAccountType === AUTHORITIES.ROLE_DEALER_USER
      ) {
        props.getDealerDataByName(name);
      } else if (
        hasAnyAuthority(props.account?.authorities, [AUTHORITIES.ROLE_CONSULTANT_USER]) ||
        selectedAccountType === AUTHORITIES.ROLE_CONSULTANT_USER
      ) {
        props.getConsultantDataByName(name);
      } else if (
        hasAnyAuthority(props.account?.authorities, [AUTHORITIES.ROLE_MANUFACTURER_USER]) ||
        selectedAccountType === AUTHORITIES.ROLE_MANUFACTURER_USER
      ) {
        props.getManufacturerDataByName(name);
      } else if (
        hasAnyAuthority(props.account?.authorities, [AUTHORITIES.ROLE_MANUFACTURER_REP_USER]) ||
        selectedAccountType === AUTHORITIES.ROLE_MANUFACTURER_REP_USER
      ) {
        props.getManufacturerRefByName(name);
      }
    }
  };

  const debouncedFindCompanyByName = _.debounce(findCompanyDataByName, 500);

  return {
    dataForCompaniesSelect,
    selectedIndustry,
    setSelectedIndustry,
    selectedAccountType,
    setSelectedAccountType,
    isCompanyLoading,
    debouncedFindCompanyByName,
    industryOptions,
    options,
    setDataForCompaniesSelect,
  };
};

export default useCompanySetUp;
