import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAllAction, getSortState, IPaginationBaseState, JhiPagination } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './consultant-data.reducer';
import { IConsultantData } from 'app/shared/model/consultant-data.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface IConsultantDataProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type IConsultantDataState = IPaginationBaseState;

export class ConsultantData extends React.Component<IConsultantDataProps, IConsultantDataState> {
  state: IConsultantDataState = {
    ...getSortState(this.props.location, ITEMS_PER_PAGE)
  };

  componentDidMount() {
    this.getEntities();
  }

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    const { activePage, itemsPerPage, sort, order } = this.state;
    this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
  };

  render() {
    const { consultantDataList, match, totalItems } = this.props;
    return (
      <div>
        <h2 id="consultant-data-heading">
          Consultant Data
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp; Create new Consultant Data
          </Link>
        </h2>
        <div className="table-responsive">
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={this.sort('id')}>
                  ID <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('company')}>
                  Company <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('industryType')}>
                  Industry Type <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('companyType')}>
                  Company Type <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('specPathId')}>
                  Spec Path Id <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('specPathUserId')}>
                  Spec Path User Id <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('mailingAddress')}>
                  Mailing Address <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('website')}>
                  Website <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('email')}>
                  Email <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('phone')}>
                  Phone <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('isDesignDealer')}>
                  Is Design Dealer <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('zipCode')}>
                  Zip Code <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('city')}>
                  City <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('state')}>
                  State <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('country')}>
                  Country <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('regionOne')}>
                  Region One <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('regionTwo')}>
                  Region Two <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('regionThree')}>
                  Region Three <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {consultantDataList.map((consultantData, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${consultantData.id}`} color="link" size="sm">
                      {consultantData.id}
                    </Button>
                  </td>
                  <td>{consultantData.company}</td>
                  <td>{consultantData.industryType}</td>
                  <td>{consultantData.companyType}</td>
                  <td>{consultantData.specPathId}</td>
                  <td>{consultantData.specPathUserId}</td>
                  <td>{consultantData.mailingAddress}</td>
                  <td>{consultantData.website}</td>
                  <td>{consultantData.email}</td>
                  <td>{consultantData.phone}</td>
                  <td>{consultantData.isDesignDealer ? 'true' : 'false'}</td>
                  <td>{consultantData.zipCode}</td>
                  <td>{consultantData.city}</td>
                  <td>{consultantData.state}</td>
                  <td>{consultantData.country}</td>
                  <td>{consultantData.regionOne}</td>
                  <td>{consultantData.regionTwo}</td>
                  <td>{consultantData.regionThree}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${consultantData.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${consultantData.id}/edit`} color="primary" size="sm">
                        <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${consultantData.id}/delete`} color="danger" size="sm">
                        <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Delete</span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Row className="justify-content-center">
          <JhiPagination
            activePage={this.state.activePage}
            onSelect={this.handlePagination}
            maxButtons={5}
            itemsPerPage={this.state.itemsPerPage}
            totalItems={totalItems}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ consultantData }: IRootState) => ({
  consultantDataList: consultantData.entities,
  totalItems: consultantData.totalItems
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultantData);
