import React, { memo } from 'react';
import './confirmButton.scss';

interface IConfirmButton {
  title: string;
  confirmAction?: Function;
  disabled?: any;
}

const ConfirmButton = memo((props: IConfirmButton) => {
  return (
    <button onClick={() => props.confirmAction()} className="confirm-button" disabled={props.disabled}>
      {props.title}
    </button>
  );
});

export default ConfirmButton;
