import { Moment } from 'moment';
import { IUser } from 'app/shared/model/user.model';
import { NotificationStatus } from 'app/shared/model/enumerations/notification-status.model';
import { NotificationProgress } from 'app/shared/model/enumerations/notification-progress.model';

export interface INotification {
  id?: number;
  title?: string;
  body?: string;
  data?: string;
  status?: NotificationStatus;
  progress?: NotificationProgress;
  imageUrl?: string;
  createdAt?: Moment;
  user?: IUser;
}

export const defaultValue: Readonly<INotification> = {};
