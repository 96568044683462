import React from 'react';
import './sign-up-steps-navigation.scss';

interface ISignUpStepsNavigation {
  step: number;
  isOutOfUSA: string | boolean;
  formValues: any;
  selectedIndustry: string;
  userSpecialization: string;
  userCompanyId: string;
  userCountry: string;
}

interface IStep {
  title: string;
  subItems: { label: string; isFilling: boolean; filled: boolean }[];
}

const SignUpStepsNavigation = (props: ISignUpStepsNavigation) => {
  const steps: Record<string, IStep> = {
    step1: {
      title: 'Location and Industry',
      subItems: [
        {
          label: 'What’s your location',
          isFilling: props.step === 1 && typeof props.isOutOfUSA !== 'boolean',
          filled: typeof props.isOutOfUSA === 'boolean',
        },
        {
          label: 'What’s your Zip Code',
          isFilling: props.step === 1 && typeof props.isOutOfUSA === 'boolean' && !props.formValues.zipCode && !props.userCountry,
          filled: props.formValues.zipCode || props.userCountry,
        },
        {
          label: 'Select Industry',
          isFilling: props.step === 1 && !props.selectedIndustry && typeof props.isOutOfUSA === 'boolean' && props.formValues.zipCode,
          filled: props.selectedIndustry !== null,
        },
      ],
    },
    step2: {
      title: 'Company Name',
      subItems: [
        {
          label: 'Account Type',
          isFilling: props.step === 2 && !props.userSpecialization && props.selectedIndustry && props.selectedIndustry === 'Foodservice',
          filled: props.userSpecialization !== null || (props.selectedIndustry && props.selectedIndustry !== 'Foodservice'),
        },
        {
          label: 'Company Name',
          isFilling:
            props.step === 2 &&
            !props.formValues.company &&
            !props.userCompanyId &&
            (props.userSpecialization !== null || (props.selectedIndustry && props.selectedIndustry !== 'Foodservice')),
          filled: props.formValues.company || props.userCompanyId,
        },
      ],
    },
    step3: {
      title: 'Provide Your Details',
      subItems: [
        { label: 'First Name', isFilling: props.step === 3 && !props.formValues.firstName, filled: props.formValues.firstName },
        {
          label: 'Last Name',
          isFilling: props.step === 3 && !props.formValues.lastName && props.formValues.firstName,
          filled: props.formValues.lastName,
        },
        {
          label: 'Email',
          isFilling: props.step === 3 && !props.formValues.email && props.formValues.lastName,
          filled: props.formValues.email,
        },
        {
          label: 'Password',
          isFilling:
            props.step === 3 &&
            props.formValues.email &&
            props.formValues.lastName &&
            (!props.formValues.password ||
              !props.formValues.passwordConfirmation ||
              props.formValues.password !== props.formValues.passwordConfirmation),

          filled:
            props.formValues.password &&
            props.formValues.passwordConfirmation &&
            props.formValues.password === props.formValues.passwordConfirmation,
        },
      ],
    },
  };

  return (
    <div className="sign-up-page-content-block">
      {Object.keys(steps).map((stepKey, index) => (
        <div
          className={`sign-up-page-step-block d-flex ${props.step === index + 1 ? 'sign-up-page-step-block--opacity-0' : ''} ${
            index === 2 ? 'sign-up-page-step-block--last' : ''
          }`}
          key={stepKey}
        >
          <div className="sign-up-page-step-block-icon-container">
            {props.step === index + 1 ? (
              <img src="../../../../content/images/icons/sign-up-active-step.svg" alt="step icon" />
            ) : (
              <img src="../../../../content/images/icons/sign-up-innactive-step.svg" alt="step icon" />
            )}
          </div>
          <div className="sign-up-page-step-block-text-container d-flex flex-column">
            <div className="d-flex flex-column">
              <span className="sign-up-page-step-block-text-step-number">{`Step ${index + 1}`}</span>
              <span className="sign-up-page-step-block-text-step-title">{steps[stepKey].title}</span>
            </div>
            <div className="d-flex flex-column">
              {steps[stepKey].subItems.map((subItem, subIndex) => (
                <div className="sign-up-page-step-block-text-step-subitem-container" key={subIndex}>
                  {!subItem.isFilling && !subItem.filled && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      style={{ marginTop: -2 }}
                    >
                      <path
                        fill="#221f1d80"
                        d="M0 12a1.5 1.5 0 0 1 1.5-1.5h16.755l-7.32-7.32a1.5 1.5 0 1 1 2.122-2.122l9.529 9.528a2 2 0 0 1 0 2.828l-9.528 9.528a1.496 1.496 0 0 1-2.117-2.115l7.314-7.327H1.5A1.5 1.5 0 0 1 0 12Z"
                      />
                    </svg>
                  )}
                  {subItem.isFilling && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      style={{ marginTop: -2 }}
                    >
                      <path
                        fill="#eb8031"
                        d="M0 12a1.5 1.5 0 0 1 1.5-1.5h16.755l-7.32-7.32a1.5 1.5 0 1 1 2.122-2.122l9.529 9.528a2 2 0 0 1 0 2.828l-9.528 9.528a1.496 1.496 0 0 1-2.117-2.115l7.314-7.327H1.5A1.5 1.5 0 0 1 0 12Z"
                      />
                    </svg>
                  )}
                  {subItem.filled && props.step === index + 1 && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" style={{ marginTop: -2 }}>
                      <path
                        fill="#EB8031"
                        d="M12 0a12 12 0 1 0 12 12A12.015 12.015 0 0 0 12 0ZM5.734 12.327a1.383 1.383 0 0 1 1.959 0l2.77 2.769 5.362-7.152a1.385 1.385 0 0 1 2.216 1.662l-5.364 7.152a2.769 2.769 0 0 1-4.175.293l-2.77-2.77a1.383 1.383 0 0 1 .002-1.954Z"
                      />
                    </svg>
                  )}
                  {subItem.filled && props.step !== index + 1 && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" style={{ marginTop: -2 }}>
                      <path
                        fill="#221f1d80"
                        d="M12 0a12 12 0 1 0 12 12A12.015 12.015 0 0 0 12 0ZM5.734 12.327a1.383 1.383 0 0 1 1.959 0l2.77 2.769 5.362-7.152a1.385 1.385 0 0 1 2.216 1.662l-5.364 7.152a2.769 2.769 0 0 1-4.175.293l-2.77-2.77a1.383 1.383 0 0 1 .002-1.954Z"
                      />
                    </svg>
                  )}
                  {/* <img src="../../../../content/images/icons/sign-up-step-arrow.svg" width="18" /> */}
                  <span
                    className="sign-up-page-step-block-text-step-subitem-title"
                    style={{ color: (subItem.isFilling || subItem.filled) && props.step === index + 1 ? '#eb8031' : '' }}
                  >
                    {subItem.label}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SignUpStepsNavigation;
