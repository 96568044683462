import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import LeadsProducts from './leads-products';
import LeadsProductsDetail from './leads-products-detail';
import LeadsProductsUpdate from './leads-products-update';
import LeadsProductsDeleteDialog from './leads-products-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={LeadsProductsUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={LeadsProductsUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={LeadsProductsDetail} />
      <ErrorBoundaryRoute path={match.url} component={LeadsProducts} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={LeadsProductsDeleteDialog} />
  </>
);

export default Routes;
