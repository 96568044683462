import React, { useState } from 'react';
import { Modal } from 'reactstrap';
import { Label, Input, FormGroup } from 'reactstrap';
import './projectMarketplace.scss';

interface IDeliveryDetailsModal {
  isOpen: boolean;
  closeModal: Function;
}

const DeliveryDetailsModal = (props: IDeliveryDetailsModal) => {
  const [deliveryType, setDeliveryType] = useState(null);
  // checkboxes
  const [isAddLiftgate, setIsAddLiftgate] = useState<boolean>(false);
  const [isRequestSiteVisit, setIsRequestSiteVisit] = useState<boolean>(false);
  const [isElectricalUtilityRequired, setIsElectricalUtilityRequired] = useState<boolean>(false);
  const [isPlumbingConnectionsRequired, setIsPlumbingConnectionsRequired] = useState<boolean>(false);

  return (
    <Modal
      isOpen={props.isOpen}
      centered
      style={{ maxWidth: 1200, width: window.screen.width - 600 }}
      toggle={props.closeModal}
      backdrop={true}
    >
      <div>
        <div className="marketplace-delivery-details-modal-header">
          <span className="marketplace-form-block-title marketplace-delivery-details-modal-title">Delivery & Installation</span>
          <img
            src="../../../content/images/icons/closeModalIcon.svg"
            className="marketplace-delivery-details-modal-close-icon"
            onClick={() => props.closeModal()}
          />
        </div>
        <div className="marketplace-delivery-details-modal-container">
          <FormGroup className="mt-4">
            <Input
              onChange={() => {
                setDeliveryType(1);
                setIsAddLiftgate(false);
                setIsRequestSiteVisit(false);
                setIsElectricalUtilityRequired(false);
                setIsPlumbingConnectionsRequired(false);
              }}
              name="radio"
              type="radio"
            />
            <Label check className="marketplace-radio-label">
              Drop-Ship via Common Carrier
            </Label>
            {deliveryType === 1 && (
              <div className="marketplace-delivery-checkboxes-container">
                <Input
                  value={isAddLiftgate}
                  onChange={() => setIsAddLiftgate(!isAddLiftgate)}
                  type="checkbox"
                  className="remember-me-checkbox"
                />
                <Label check className="marketplace-checkbox-label">
                  Add Liftgate
                </Label>
              </div>
            )}
          </FormGroup>
          <FormGroup>
            <Input
              onChange={() => {
                setDeliveryType(2);
                setIsAddLiftgate(false);
                setIsRequestSiteVisit(false);
                setIsElectricalUtilityRequired(false);
                setIsPlumbingConnectionsRequired(false);
              }}
              name="radio"
              type="radio"
            />
            <Label check className="marketplace-radio-label">
              Store at Warehouse for Scheduled Delivery
            </Label>
          </FormGroup>
          <FormGroup>
            <Input
              onChange={() => {
                setDeliveryType(3);
                setIsAddLiftgate(false);
                setIsRequestSiteVisit(false);
                setIsElectricalUtilityRequired(false);
                setIsPlumbingConnectionsRequired(false);
              }}
              name="radio"
              type="radio"
            />
            <Label check className="marketplace-radio-label">
              Standard Installation (Unbox, assemble and set-in-peace)
            </Label>
            {deliveryType === 3 && (
              <div className="marketplace-delivery-checkboxes-container">
                <Input
                  value={isRequestSiteVisit}
                  onChange={() => {
                    setIsRequestSiteVisit(!isRequestSiteVisit);
                  }}
                  type="checkbox"
                  className="remember-me-checkbox"
                />
                <Label check className="marketplace-checkbox-label">
                  Request Site Visit
                </Label>
              </div>
            )}
          </FormGroup>
          <FormGroup>
            <Input
              onChange={() => {
                setDeliveryType(4);
                setIsAddLiftgate(false);
                setIsRequestSiteVisit(false);
                setIsElectricalUtilityRequired(false);
                setIsPlumbingConnectionsRequired(false);
              }}
              name="radio"
              type="radio"
            />
            <Label check className="marketplace-radio-label">
              Turnkey Installation (Unbox, assemble and set-in-peace, make electrical connections within 3ft of existing utilities, make
              plumbing connections within 3ft of existing utilities, equipment setup and ready for use)(site visit required)
            </Label>
            {deliveryType === 4 && (
              <div className="marketplace-delivery-checkboxes-container">
                <FormGroup>
                  <Input
                    value={isElectricalUtilityRequired}
                    onChange={() => setIsElectricalUtilityRequired(!isElectricalUtilityRequired)}
                    type="checkbox"
                    className="remember-me-checkbox"
                  />
                  <Label check className="marketplace-checkbox-label">
                    New electrical utility from breaker panel required
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Input
                    value={isPlumbingConnectionsRequired}
                    onChange={() => setIsPlumbingConnectionsRequired(!isPlumbingConnectionsRequired)}
                    type="checkbox"
                    className="remember-me-checkbox"
                  />
                  <Label check className="marketplace-checkbox-label">
                    New plumbing connections from existing utilities required
                  </Label>
                </FormGroup>
              </div>
            )}
          </FormGroup>
        </div>
      </div>
    </Modal>
  );
};

export default DeliveryDetailsModal;
