import React, { useState, useEffect } from 'react';
import { IRootState } from 'app/shared/reducers';
import { login, sendVerificationEmail } from 'app/shared/reducers/authentication';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';
import { Alert, Button, Label, Modal } from 'reactstrap';
import UnauthorizedPagesContainer from './UnauthorizedPagesContainer';
import { setNotificationsToken, setUserNotificationToken } from 'app/entities/user-settings/user-settings.reducer';
import { setIsLoginButtonWasPressed } from '../account/register/register.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import SignInCarousel from '../account/register/SignInCarousel';
import './login.scss';
import AppSpinner from 'app/components/Spinner/Spinner';

export interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps<{}> {
  loginError: boolean;
  errorMessage: string;
  setNotificationsToken: any;
  setUserNotificationTokenL: any;
}

export const Login = (props: ILoginProps) => {
  const { location, isAuthenticated, errorMessage } = props;
  const { from } = (location.state as any) || { from: { pathname: '/', search: location.search } };
  const [email, setEmail] = useState(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [formValid, setFormValid] = useState<boolean>(false);

  if (isAuthenticated) {
    return <Redirect to={from} />;
  }

  const handleLogin = (username, password, rememberMe = false) => {
    props.login(username, password, rememberMe);
    props.setIsLoginButtonWasPressed(true);
    setFormValid(username !== '' && password !== '');
  };

  const handleSubmit = (event, { username, password, rememberMe }) => {
    setEmail(username);
    handleLogin(username, password, rememberMe);
  };

  const sendVerificationMail = () => {
    props.sendVerificationEmail(email);
  };

  const formModel = {
    rememberMe: false,
  };

  return (
    <div>
      <UnauthorizedPagesContainer className="sign-in-backgournd-image" content={<SignInCarousel />}>
        <img src="../../../content/images/kreator-navbar-logo.svg" alt="kreator logo" className="login-page-kreator-logo" />
        <div>
          <h1 className="sign-in-title">Welcome to Kreator</h1>
          <p className="sign-in-welcome-description">
            Kreator is a commercial foodservice equipment design platform that combines 3D product configurators with a 3D floorplanner,
            AR/VR visualization, and Revit exporting.
          </p>
        </div>
        <AvForm onValidSubmit={handleSubmit} model={formModel} className="sign-in-form">
          {props.loginError && errorMessage ? (
            <Alert color="danger">
              <p className={'mb-0'}>{errorMessage.toString()}</p>
              {/* {errorMessage.includes('activated') ? (
              <Button className={'pl-0'} color="link" type={'button'} onClick={() => sendVerificationMail()}>
                Resend
              </Button>
            ) : (
              ''
            )} */}
            </Alert>
          ) : null}
          <div className="field-block sign-in-email-fields-block">
            <span className="sign-in-field-label">Email</span>
            <AvField
              name="username"
              placeholder="Enter Your Email"
              className="sign-in-field"
              validate={{
                required: { value: true, errorMessage: 'Email cannot be empty!' },
                pattern: { value: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$', errorMessage: 'Invalid email address' },
              }}
            />
          </div>

          <div className="field-block" style={{ margin: 0 }}>
            <span className="sign-in-field-label">Password</span>
            <div className="password-input-container">
              <AvField
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter Your Password"
                className="sign-in-field"
                validate={{
                  minLength: { value: 4, errorMessage: 'Your password is required to be at least 4 characters.' },
                }}
              />
              <button
                className="password-toggle-button"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                aria-label={showPassword ? 'Hide password' : 'Show password'}
              >
                {showPassword ? (
                  <FontAwesomeIcon size="lg" color="#EB8031" icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon size="lg" icon={faEye} />
                )}
                {showPassword ? <span className="password-show-text">Hide</span> : <span className="password-show-text">Show</span>}
              </button>
            </div>
          </div>

          <div className="forgot-password-block">
            <AvGroup check inline>
              <AvInput type="checkbox" name="rememberMe" className="remember-me-checkbox" />
              <Label className="remember-me-label">Remember me</Label>
            </AvGroup>

            <Link className="forgot-password-link" to="/account/reset/request">
              Forgot password?
            </Link>
          </div>

          <Button id="login-button" type="submit" className="sign-in-button sign-in-page-button">
            Sign In
          </Button>
          <div className="do-not-have-an-account-block">
            <span className="do-not-have-an-account-block-text">Don’t Have an Account?</span>
            <button type="button" className="do-not-have-an-account-button">
              <Link className="do-not-have-an-account" to="/account/register">
                Sign Up
              </Link>
            </button>
          </div>
        </AvForm>
        {/* <div className="download-badges-container"> */}
        {/* <Button className="sign-in-download-button">
          <img src="../../../content/images/download_from_appstore.svg" alt="download from app store" />
        </Button>
        <Button className="sign-in-download-button">
          <img src="../../../content/images/download_revit.svg" alt="download revit app" />
        </Button>
        <Button className="sign-in-download-button">
          <img src="../../../content/images/download_from_web.svg" alt="download from web browser" />
        </Button> */}
        {/* </div> */}
        {/* <Modal
        isOpen={isRegisterSuccessModalOpen}
        centered
        toggle={() => setIsRegisterSuccessModalOpen(false)}
        backdrop={true}
        style={{ maxWidth: window.screen.width - 1000, width: '100%' }}
        clssName="registration-success-modal"
      >
        <p className="registration-success-modal-text">Kreator is Coming Soon! You will receive an email when we launch!</p>
        <button onClick={() => setIsRegisterSuccessModalOpen(false)} className="registration-success-modal-button">
          Ok
        </button>
      </Modal> */}
      </UnauthorizedPagesContainer>
    </div>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loginError: authentication.loginError,
  showModal: authentication.showModalLogin,
  errorMessage: authentication.errorMessage,
});

const mapDispatchToProps = { login, sendVerificationEmail, setNotificationsToken, setUserNotificationToken, setIsLoginButtonWasPressed };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Login);
