import React, { memo } from 'react';
import { Modal, CardImg } from 'reactstrap';
import AppSpinner from 'app/components/Spinner/Spinner';
import './products.scss';

interface IProductDetailsModal {
  isOpen: Boolean;
  product: any;
  userManufacturer: any;
  closeModal: Function;
  redirectToDetails?: Function;
  loading: boolean;
}

const ProductDetailsModal = memo((props: IProductDetailsModal) => {
  return (
    <Modal
      isOpen={props.isOpen}
      centered
      toggle={() => props.closeModal()}
      backdrop={true}
      style={
        window.screen.width >= 1024
          ? { maxWidth: window.screen.width - 500, width: '100%' }
          : { maxWidth: '90%', width: '100%', margin: 'auto' }
      }
      className="product-details-modal"
    >
      {props.product && !props.loading ? (
        <div className="product-details-modal-container">
          <div className="product-details-modal-image-block">
            {props.product.image && <CardImg top src={props.product.image} alt="Missing image" className="image product-details-image" />}
            {/* <img src="../../../content/images/icons/pdf-file.svg" alt="pdf-icon" width="30" height="30" className="pdf-icon" /> */}
          </div>
          <div className="product-details-modal-data-block">
            <div className="product-details-modal-header">
              <img
                src="../../../content/images/icons/closeModalIcon.svg"
                alt="close modal icon"
                className="close-modal-image"
                width="25"
                height="25"
                onClick={() => props.closeModal()}
              />
            </div>
            <div className="product-details-modal-data-container">
              <div className="product-details-modal-product-name">
                <span className="product-details-modal-name-description">Product Name</span>
                <span className="product-details-modal-name-value">{props.product.name}</span>
              </div>
              <div className="product-details-modal-manufacturer">
                <span className="product-details-modal-key">Manufacturer Name:</span>
                <span className="product-details-modal-manufacturer-value">{props.product.manufacturerName}</span>
              </div>
              {props.userManufacturer.showMrfInventoryDetails && (
                <div className="product-details-modal-manufacturer">
                  <span className="product-details-modal-key">Manufacturer Rep Name:</span>
                  <span className="product-details-modal-manufacturer-value">{props.product?.manufacturerRefData?.company}</span>
                </div>
              )}
              <div className="product-details-modal-description">
                <span className="product-details-modal-key">Product Description:</span>
                <p className="product-details-modal-description-value">{props.product.description}</p>
              </div>
              <div className="product-details-modal-manufacturer-link-container">
                <div className="product-details-modal-manufacturer-link"></div>
                <div className="product-details-modal-manufacturer-link-value">
                  <a
                    href={props.product.manufacturerWebsiteURL}
                    target={'_blank'}
                    className="product-details-modal-manufacturer-link-button"
                  >
                    <img src={props.product.manufacturerLogo} alt="manufacturer-logo" width="100" />
                    <span className="product-details-modal-manufacturer-link-text">Click to view website</span>
                  </a>
                </div>
              </div>
              {props.product.parameterValues && (
                <div className="product-details-modal-options">
                  <span className="product-details-modal-key">You can change the following options in this configurator:</span>
                  <p className="product-details-modal-description-value options-container">
                    {Object.keys(props.product.parameterValues).map(item => (
                      <span key={item} className="product-details-modal-options-item">
                        {item}
                      </span>
                    ))}
                  </p>
                </div>
              )}
              <button onClick={e => props.redirectToDetails(props.product, e)} className="product-details-modal-configure-button">
                Configure
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ paddingBottom: 150 }}>
          <AppSpinner />
        </div>
      )}
    </Modal>
  );
});

export default ProductDetailsModal;
