import React, { useState } from 'react';
import MainThemeButton from '../MainThemeButton/MainThemeButton';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light-border.css';
import './configurationProjectBar.scss';

interface IConfigurationProjectBar {
  project: any;
  openProjectDetailsModal: Function;
  openCreateDrawingsModal: Function;
  resetCurrentProject: Function;
  account: any;
  createFloorPlanForProject: Function;
  loadingValuesArray: any;
  roomleLoaded: boolean;
}

const ConfigurationProjectBar = (props: IConfigurationProjectBar) => {
  const [isCreateFloorPlanConfirmationDialogOpen, setIsCreateFloorPlanConfirmationDialogOpen] = useState(false);

  return (
    <div className="roomle-actions-bar">
      <div>
        <Tippy content="Project Details" placement="bottom" theme="light-border">
          <button className="project-details-button" onClick={() => props.openProjectDetailsModal()}>
            <img src="../../../content/images/icons/project-details-orange-icon.svg" width="30" />
          </button>
        </Tippy>
      </div>
      <span className="roomle-actions-bar-project-name ellipsis">{props.project.projectName}</span>
      <div className="d-flex">
        <div className="mr-2">
          <MainThemeButton
            onClick={() => setIsCreateFloorPlanConfirmationDialogOpen(true)}
            title="Create Floor Plan"
            iconName="create-floor-plan-icon"
            fontSize={14}
            disabled={props.loadingValuesArray.some(item => item) || !props.roomleLoaded}
          />
        </div>
        {hasAnyAuthority(props.account.authorities, [
          AUTHORITIES.ROLE_CORONET_DRAWING,
          AUTHORITIES.ROLE_KROWNE_DRAWING,
          AUTHORITIES.ROLE_FOOD_SERVICE_DRAWING,
        ]) && (
          <Tippy
            content="You must add a configuration using Add to Project before you can Create Shop Drawings"
            disabled={props.project.projectProducts?.length > 0 || props.loadingValuesArray.some(item => item)}
            placement="bottom"
            theme="light-border"
          >
            <div>
              <MainThemeButton
                title="Create Shop Drawings"
                iconName="project-shop-drawings-icon"
                fontSize={14}
                onClick={() => props.openCreateDrawingsModal()}
                disabled={props.project.projectProducts.length === 0 || props.loadingValuesArray.some(item => item) || !props.roomleLoaded}
              />
            </div>
          </Tippy>
        )}
      </div>
      <ConfirmationDialog
        isOpen={isCreateFloorPlanConfirmationDialogOpen}
        closeConfirmationDialog={() => setIsCreateFloorPlanConfirmationDialogOpen(false)}
        confirmationInfo="Would you like to create Floor Plan for this project?"
        confirmAction={props.createFloorPlanForProject}
      />
    </div>
  );
};

export default ConfigurationProjectBar;
