const config = {
  VERSION: process.env.VERSION,
};

export default config;

export const SERVER_API_URL = process.env.SERVER_API_URL;

export const AUTHORITIES = {
  ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  ADMIN_PORTAL: 'ROLE_ADMIN_PORTAL',
  ROLE_MANAGER: 'ROLE_MANAGER',
  ROLE_DEALER_ADMIN: 'ROLE_DEALER_ADMIN',
  ROLE_DEALER_USER: 'ROLE_DEALER_USER',
  ROLE_MANUFACTURER_ADMIN: 'ROLE_MANUFACTURER_ADMIN',
  ROLE_MANUFACTURER_USER: 'ROLE_MANUFACTURER_USER',
  ROLE_FOOD_SERVICE_DRAWING: 'ROLE_FOOD_SERVICE_DRAWING',
  ROLE_PROJECT_DESIGNER: 'ROLE_PROJECT_DESIGNER',
  ROLE_KROWNE_DRAWING: 'ROLE_KROWNE_DRAWING',
  ROLE_CORONET_DRAWING: 'ROLE_CORONET_DRAWING',
  ROLE_CONSULTANT_ADMIN: 'ROLE_CONSULTANT_ADMIN',
  ROLE_CONSULTANT_USER: 'ROLE_CONSULTANT_USER',
  ROLE_MANUFACTURER_REP_ADMIN: 'ROLE_MANUFACTURER_REP_ADMIN',
  ROLE_MANUFACTURER_REP_USER: 'ROLE_MANUFACTURER_REP_USER',
};

export const LEADS_STATUSES = [
  { title: 'In Progress', value: 'IN_PROGRESS' },
  { title: 'Completed', value: 'COMPLETED' },
  { title: 'Rejected', value: 'REJECTED' },
  { title: 'Closed', value: 'CLOSED' },
];

export const LEAD_STATUSES_TRANSLATION = {
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  REJECTED: 'Rejected',
  CLOSED: 'Closed',
};

export const PROJECT_STATUSES = [
  { title: 'In Progress', value: 'IN_PROGRESS' },
  { title: 'Sent', value: 'SENT' },
  { title: 'Approved', value: 'APPROVED' },
  { title: 'Revise/Resubmit', value: 'RESUBMIT' },
  { title: 'Closed', value: 'CLOSED' },
];

export const PROJECT_STATUSES_TRANSLATION = {
  IN_PROGRESS: { status: 'In Progress', icon: 'in-progress-icon' },
  SENT: { status: 'Sent', icon: 'project-sent-icon' },
  APPROVED: { status: 'Approved', icon: 'project-approved-icon' },
  RESUBMIT: { status: 'Resubmit', icon: 'resubmit-icon' },
  CLOSED: { status: 'Closed', icon: 'closed-icon' },
};

export const DEALER_STATUSES = [
  { title: 'In Progress', value: 'IN_PROGRESS' },
  { title: 'Update Requested', value: 'UPDATE_REQUESTED' },
  { title: 'Rejected', value: 'REJECTED' },
  { title: 'Approved', value: 'APPROVED' },
];

export const DEALER_STATUSES_TRANSLATION = {
  IN_PROGRESS: 'In Progress',
  APPROVED: 'Approved',
  UPDATE_REQUESTED: 'Update Requested',
  REJECTED: 'Rejected',
};
export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';

const arr = length => {
  let arr = [];
  for (let i = 0; i < length + 1; i++) {
    arr.push({ label: `$${i}`, value: i });
  }

  return arr;
};

export const commission = arr(99);

export const VERSIONS = {
  CURRENT_VERSION: 'CURRENT_VERSION',
  PAST_VERSION: 'PAST_VERSION',
};

export const DEFAULT_FOLDERS = {
  INBOX: 'Inbox',
  TRASH: 'Trash',
};

export const SPECIALITY_EQUIPMENT = 'Specialty Equipment';
export const FOOD_SERVICE_EQUIPMENT = 'Food Service Equipment';

export const NOTIFICATION_STATUS = {
  READ: 'READ',
  UNREAD: 'UNREAD',
};

export async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/png' });
}

export const PaymentsStatusesColors = {
  REFUNDED: 'blue',
  NOT_PAID: 'red',
  PAID: 'orange',
};
