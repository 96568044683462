import React, { useEffect, useState } from 'react';
import DealerRegistrationStepProgressBar from 'app/components/StepProgressBar/DealerRegistrationStepProgressBar';
import BaseLayout from '../BaseLayout';
import AdressInformation from 'app/components/DealerRegistrationProcessPages/AdressInformation';
import BusinessSkills from 'app/components/DealerRegistrationProcessPages/BusinessSkills';
import CreditCardInformation from 'app/components/DealerRegistrationProcessPages/CreditCardInformation';
import Approval from 'app/components/DealerRegistrationProcessPages/Approval';
import './manufacturerRegistrationProcess.scss';
import { IRootState } from 'app/shared/reducers';
import { getManufacturerForCurrentUser, updateEntity } from 'app/entities/manufacturer/manufacturer.reducer';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAllDealersData } from 'app/entities/dealer-data/dealer-data.reducer';
import { ProfileStatus } from 'app/shared/model/manufacturer.model';

export interface IManufacturerProps extends StateProps, DispatchProps {}

const ManufacturerRegistrationProcess = (props: IManufacturerProps) => {
  let history = useHistory();
  const [step, setStep] = useState(25);
  const [business, setBusiness] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedBusinessStatus, setSelectedBusinessStatus] = useState([]);
  const [cardNumber, setCardNumber] = useState(null);
  const [cardHolder, setCardHolder] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [cvc, setCvc] = useState(null);

  useEffect(() => {
    if (props.manufacturer.skills != null) {
      setSelectedSkills(props.manufacturer.skills.split(','));
    }

    if (props.manufacturer.qualifySpecialStatus != null) {
      setSelectedBusinessStatus(props.manufacturer.qualifySpecialStatus.split(','));
    }
    if (props.manufacturer) {
      setCardNumber(props.manufacturer.cardNumber);
      setCardHolder(props.manufacturer.cardHolder);
      setMonth(props.manufacturer.month);
      setYear(props.manufacturer.year);
      setCvc(props.manufacturer.cvc);
      setBusiness({ value: props.manufacturer.refManufacturerId, name: '' });
    }
  }, [props.manufacturer]);

  useEffect(() => {
    props.getAllDealersData();
  }, []);

  const processSteps = {
    businessInfo: 25,
    businessSkills: 50,
    creditCard: 75,
    approval: 100,
  };

  const processStepsTitles = {
    25: 'Address Information',
    50: 'Business Skills',
    75: 'Credit Card Information',
    100: 'Approval',
  };

  const setNextStep = () => {
    if (step < 100) {
      setStep(step + 25);
      const entity = {
        ...props.manufacturer,
        skills: selectedSkills.toString(),
        qualifySpecialStatus: selectedBusinessStatus.toString(),
        cardNumber: cardNumber,
        cardHolder: cardHolder,
        month: month,
        year: year,
        cvc: cvc,
        refManufacturerId: business?.value,
      };
      props.updateEntity(entity);
    }
  };

  const handleFinish = () => {
    const entity = {
      ...props.manufacturer,
      status: ProfileStatus.UPDATE_REQUESTED,
    };
    props.updateEntity(entity);
    history.push('/business-account-setup-success');
  };

  const setPreviousStep = () => {
    if (step > 25) {
      setStep(step - 25);
    }
  };

  useEffect(() => {
    props.getManufacturerForCurrentUser();
  }, []);

  const selectBusinessStatus = statusName => {
    if (selectedBusinessStatus.includes(statusName)) {
      setSelectedBusinessStatus([]);
    } else {
      setSelectedBusinessStatus([statusName]);
    }
  };

  return (
    <>
      <div className="dealer-registration-step-progress-bar">
        <DealerRegistrationStepProgressBar step={step} />
      </div>
      <BaseLayout title={processStepsTitles[step]}>
        {step === processSteps.businessInfo && (
          <AdressInformation
            businessMember={props.manufacturer}
            stateData={business}
            setBusinessMember={setBusiness}
            dealerDatas={props.dealerDatas}
            selectedBusiness={business}
          />
        )}
        {step === processSteps.businessSkills && (
          <BusinessSkills
            selectedSkills={selectedSkills}
            selectedBusinessStatus={selectedBusinessStatus}
            setSelectedSkills={setSelectedSkills}
            setSelectedBusinessStatus={selectBusinessStatus}
          />
        )}
        {step === processSteps.creditCard && (
          <CreditCardInformation
            updateEntity={props.updateEntity}
            businessMember={props.manufacturer}
            cardNumber={cardNumber}
            setCardNumber={setCardNumber}
            cardHolder={cardHolder}
            setCardHolder={setCardHolder}
            month={month}
            setMonth={setMonth}
            year={year}
            setYear={setYear}
            cvc={cvc}
            setCvc={setCvc}
          />
        )}
        {step === processSteps.approval && <Approval handleFinish={handleFinish} />}
        <div className="dealer-registration-navigation-buttons-container">
          {step > processSteps.businessInfo && (
            <button className="marketplace-button" onClick={() => setPreviousStep()}>
              <img src="../../../content/images/icons/previous-step-icon.svg" alt="next-step-icon" style={{ marginRight: 10 }} width="20" />
              Previous
            </button>
          )}
          {step < processSteps.approval && (
            <button className="marketplace-button step-button" onClick={() => setNextStep()}>
              Next
              <img src="../../../content/images/icons/next-step-icon.svg" alt="next-step-icon" style={{ marginLeft: 10 }} width="20" />
            </button>
          )}
        </div>
      </BaseLayout>
    </>
  );
};

const mapStateToProps = ({ manufacturer, dealerData }: IRootState) => ({
  dealerDatas: dealerData.entities,
  manufacturer: manufacturer.entity,
  loading: manufacturer.loading,
});

const mapDispatchToProps = {
  getManufacturerForCurrentUser,
  updateEntity,
  getAllDealersData,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManufacturerRegistrationProcess);
