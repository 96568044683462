import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IDealer, defaultValue } from 'app/shared/model/dealer.model';

export const ACTION_TYPES = {
  FETCH_DEALER_LIST: 'stripe/FETCH_DEALER_LIST',
  FETCH_DEALER: 'stripe/FETCH_DEALER',
  GET_SUBSCRIPTION_PRODUCT_METADATA: 'stripe/GET_SUBSCRIPTION_PRODUCT_METADATA',
  CREATE_DEALER: 'stripe/CREATE_DEALER',
  UPDATE_DEALER: 'stripe/UPDATE_DEALER',
  DELETE_DEALER: 'stripe/DELETE_DEALER',
  RESET: 'stripe/RESET',
  PROCESS_SUBSCRIPTION: 'stripe/PROCESS_SUBSCRIPTION',
  SUBSCRIPTION_LIST: 'stripe/SUBSCRIPTION_LIST',
  CUSTOMER_LIST: 'stripe/CUSTOMER_LIST',
  PRODUCT_LIST: 'stripe/PRODUCT_LIST',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IDealer>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  dealerForCurrentUser: null,
  dealersForMarketplace: [],
  subscriptionList: [],
  customers: [],
  products: [],
  hasMore: false,
  subscription: null,
  subscribedProductMetadata: null,
};

export type StripeState = Readonly<typeof initialState>;

export default (state: StripeState = initialState, action): StripeState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.PRODUCT_LIST):
    case REQUEST(ACTION_TYPES.CUSTOMER_LIST):
    case REQUEST(ACTION_TYPES.SUBSCRIPTION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_DEALER_LIST):
    case REQUEST(ACTION_TYPES.FETCH_DEALER):
    case REQUEST(ACTION_TYPES.GET_SUBSCRIPTION_PRODUCT_METADATA):
    case REQUEST(ACTION_TYPES.PROCESS_SUBSCRIPTION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_DEALER):
    case REQUEST(ACTION_TYPES.UPDATE_DEALER):
    case REQUEST(ACTION_TYPES.DELETE_DEALER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.PRODUCT_LIST):
    case FAILURE(ACTION_TYPES.CUSTOMER_LIST):
    case FAILURE(ACTION_TYPES.SUBSCRIPTION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DEALER_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DEALER):
    case FAILURE(ACTION_TYPES.GET_SUBSCRIPTION_PRODUCT_METADATA):
    case FAILURE(ACTION_TYPES.CREATE_DEALER):
    case FAILURE(ACTION_TYPES.UPDATE_DEALER):
    case FAILURE(ACTION_TYPES.DELETE_DEALER):
    case FAILURE(ACTION_TYPES.PROCESS_SUBSCRIPTION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DEALER_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.SUBSCRIPTION_LIST):
      return {
        ...state,
        loading: false,
        hasMore: action.payload.data.has_more,
        subscriptionList: action.payload.data.data,
      };
    case SUCCESS(ACTION_TYPES.CUSTOMER_LIST):
      return {
        ...state,
        loading: false,
        hasMore: action.payload.data.has_more,
        customers: action.payload.data.data,
      };

    case SUCCESS(ACTION_TYPES.PRODUCT_LIST):
      return {
        ...state,
        loading: false,
        products: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DEALER):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_SUBSCRIPTION_PRODUCT_METADATA):
      return {
        ...state,
        loading: false,
        subscribedProductMetadata: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.PROCESS_SUBSCRIPTION):
      return {
        ...state,
        loading: false,
        subscription: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_DEALER):
    case SUCCESS(ACTION_TYPES.UPDATE_DEALER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_DEALER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/stripe';

// Actions

export const getEntities: ICrudGetAllAction<IDealer> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_DEALER_LIST,
    payload: axios.get<IDealer>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IDealer> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_DEALER,
    payload: axios.get<IDealer>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IDealer> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DEALER,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IDealer> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_DEALER,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IDealer> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_DEALER,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const processSubscription = cardToken => {
  const requestUrl = `${apiUrl}/process-subscription/${cardToken}`;
  return {
    type: ACTION_TYPES.PROCESS_SUBSCRIPTION,
    payload: axios.get(requestUrl),
  };
};

export const getSubscriptionList = () => {
  const requestUrl = `${apiUrl}/subscriber-list`;
  return {
    type: ACTION_TYPES.SUBSCRIPTION_LIST,
    payload: axios.get(requestUrl),
  };
};

export const getCustomerList = () => {
  const requestUrl = `${apiUrl}/customer-list`;
  return {
    type: ACTION_TYPES.CUSTOMER_LIST,
    payload: axios.get(requestUrl),
  };
};

export const getProductList = () => {
  const requestUrl = `${apiUrl}/products`;
  return {
    type: ACTION_TYPES.PRODUCT_LIST,
    payload: axios.get(requestUrl),
  };
};

export const getCurrentUserSubscriptionMetadata = () => {
  const requestUrl = `${apiUrl}/get-subscription-by-dealer-order-manufacturer`;
  return {
    type: ACTION_TYPES.GET_SUBSCRIPTION_PRODUCT_METADATA,
    payload: axios.get(requestUrl),
  };
};
