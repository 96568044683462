export const downloadPartList = (partlist, configurationLink: string, productManufacturer: string) => {
  const link = document.createElement('a');
  const content = getCSVFileContent(partlist, configurationLink, productManufacturer);
  const file = new Blob([content], { type: 'text/csv' });

  link.href = URL.createObjectURL(file);
  link.download = 'AQimport.csv';

  link.click();
  URL.revokeObjectURL(link.href);
};

const getCSVFileContent = (fullList, configurationLink: string, productManufacturer: string) => {
  let csvData = [];

  for (const [index, part] of fullList.entries()) {
    if (part.articleNr === undefined || part.articleNr === null) {
      part.articleNr = '';
    }

    csvData.push([index + 1, part.count, productManufacturer, part.articleNr.trim(), part.label]);
  }
  csvData.push([fullList.length + 1, '1', productManufacturer, 'CONFIGURATOR', configurationLink]);

  return csvData.map(e => e.join(',')).join('\n');
};
