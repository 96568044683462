import { AUTHORITIES } from 'app/config/constants';
import Entities from 'app/entities';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import Register from 'app/modules/account/register/register';
import Home from 'app/modules/home/home';
import Login from 'app/modules/login/login';
import Logout from 'app/modules/login/logout';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import React from 'react';
import Loadable from 'react-loadable';
import { Switch } from 'react-router-dom';
import RoomleProduct from './entities/roomle-product';
import VersionAll from './entities/version/version-all';
import FillUserInfo from './modules/account/fill-user-info/fill-user-info';
import Dashboard from './pages/Dashboard/Dashboard';
import MyLeads from './pages/MyLeads/MyLeads';
import MyDealerUpdate from './pages/MyDealer/dealer-update';
import MyManufacturerUpdate from './pages/MyManufacturer/manufacturer-update';
import MyManufacturer from 'app/pages/MyManufacturer/MyManufacturer';
import MyInventory from './pages/MyInventory/MyInventory';
import RoomDesigner from './pages/RoomDesigner/RoomDesigner';
import ProductConfigurators from './pages/ProductConfigurators/ProductConfigurators';
import EmailSettings from './pages/EmailSettings/EmailSettings';
import FeatureRequest from './pages/FeatureRequest/FeatureRequest';
import MarketplacePage from './pages/Marketplace/Marketplace';
import Notifications from './pages/Notifications/Notifications';
import LeadDetailsPage from './pages/LeadDetails/LeadDetailsPage';
import DealerRegistrationProcess from './pages/DealerRegistrationProcess/DealerRegistrationProcess';
import DealerRegistrationFinal from './components/DealerRegistrationProcessPages/DealerRegistrationProcessFinal';
import Project from 'app/pages/Project/Project';
import BusinessApprovals from 'app/pages/BusinessApprovals/BusinessApprovals';
import PaymentsPage from './pages/Payments/Payments';
import ProjectSubmission from './pages/ProjectSubmission/ProjectSubmission';
import ProjectSubmissionDetails from './pages/ProjectSubmissionDetails/ProjectSubmissionDetails';
import ManufacturerRegistrationProcess from 'app/pages/ManufacturerRegistrationProcess/ManufacturerRegistrationProcess';
import NotificationSettings from './pages/NotificationSettings/NotificationSettings';
import MyConsultantAdmin from 'app/pages/MyConsultantAdmin/MyConsultantAdmin';
import MyConsultantUser from 'app/pages/MyConsultantUser/MyConsultantUser';
import MyManufacturerRef from 'app/pages/MyManufacturerRef/MyManufacturerRef';
import ConsultantAdminRegistrationProcess from 'app/pages/ConsultantAdminProcess/ConsultantAdminRegistrationProcess';
import InstantPricing from './pages/InstantPricing/InstantPricing';
import ConfiguratorSettings from './pages/ConfiguratorSettings/ConfiguratorSettings';
import ProjectMarketplace from './pages/ProjectMarketplace/ProjectMarketplace';
import ManufacturerApi from 'app/pages/ManufacturerApi/ManufacturerApi';
import SharedConfiguration from './pages/SharedConfiguration/SharedConfiguration';
import FindManufacturerRep from './pages/FindManufacturerRep/FindManufacturerRep';
import DrawingsLanding from './pages/DrawingsLanding/DrawingsLanding';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions';
import Maintenance from './pages/TermsAndConditions/Maintenance';

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => <div>loading ...</div>,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => <div>loading ...</div>,
});

const Routes = () => (
  <div className="view-routes">
    <Switch>
      <ErrorBoundaryRoute path="/login" component={Login} />
      <ErrorBoundaryRoute path="/logout" component={Logout} />
      <ErrorBoundaryRoute path="/account/register" component={Register} />
      <ErrorBoundaryRoute path="/account/fill-user-info/:key?" component={FillUserInfo} />
      <ErrorBoundaryRoute path="/account/activate/:key?" component={Activate} />
      <ErrorBoundaryRoute path="/account/reset/request" component={PasswordResetInit} />
      <ErrorBoundaryRoute path="/account/reset/finish/:key?" component={PasswordResetFinish} />
      <ErrorBoundaryRoute path="/notification-settings" component={NotificationSettings} />
      <ErrorBoundaryRoute path="/configuration" component={SharedConfiguration} />
      <ErrorBoundaryRoute path="/drawings-request" component={DrawingsLanding} />
      <ErrorBoundaryRoute path="/terms-and-conditions" component={TermsAndConditions} />
      <ErrorBoundaryRoute path="/maintenance" component={Maintenance} />

      <PrivateRoute
        path="/consultant"
        component={ConsultantAdminRegistrationProcess}
        hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ROLE_CONSULTANT_ADMIN, AUTHORITIES.ROLE_CONSULTANT_USER]}
      />
      <PrivateRoute
        path="/manufacturer-ref"
        component={ManufacturerRegistrationProcess}
        hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN, AUTHORITIES.ROLE_MANUFACTURER_REP_USER]}
      />
      <PrivateRoute
        path="/dealer-account-setup"
        component={DealerRegistrationProcess}
        hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ROLE_DEALER_ADMIN, AUTHORITIES.ROLE_DEALER_USER]}
      />
      <PrivateRoute
        path="/manufacturer-account-setup"
        component={ManufacturerRegistrationProcess}
        hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ROLE_MANUFACTURER_ADMIN]}
      />

      <PrivateRoute
        path="/room-designer"
        component={RoomDesigner}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.USER,
          AUTHORITIES.ROLE_CONSULTANT_USER,
          AUTHORITIES.ROLE_MANUFACTURER_USER,
          AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
          AUTHORITIES.ROLE_DEALER_USER,
        ]}
      />
      <PrivateRoute
        path="/create-project"
        component={Project}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.USER,
          AUTHORITIES.ROLE_CONSULTANT_USER,
          AUTHORITIES.ROLE_MANUFACTURER_USER,
          AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
          AUTHORITIES.ROLE_DEALER_USER,
        ]}
      />
      <PrivateRoute
        path="/edit-project/:id"
        component={Project}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.USER,
          AUTHORITIES.ROLE_CONSULTANT_USER,
          AUTHORITIES.ROLE_MANUFACTURER_USER,
          AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
          AUTHORITIES.ROLE_DEALER_USER,
        ]}
      />

      <PrivateRoute
        path="/business-account-setup-success"
        component={DealerRegistrationFinal}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.ADMIN_PORTAL,
          AUTHORITIES.ROLE_DEALER_ADMIN,
          AUTHORITIES.ROLE_DEALER_USER,
          AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_USER,
          AUTHORITIES.ROLE_CONSULTANT_ADMIN,
          AUTHORITIES.ROLE_CONSULTANT_USER,
          AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
        ]}
      />
      {/* do not delete!!! needs to be uncomment after feature release */}
      {/* <PrivateRoute
        path="/dashboard"
        component={Dashboard}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_DEALER_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
          AUTHORITIES.ROLE_CONSULTANT_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN,
        ]}
      /> */}
      <PrivateRoute path="/instant-pricing" component={InstantPricing} hasAnyAuthorities={[AUTHORITIES.ROLE_DEALER_ADMIN]} />
      <PrivateRoute path="/business-approvals" component={BusinessApprovals} hasAnyAuthorities={[AUTHORITIES.ROLE_MANAGER]} />
      <PrivateRoute path="/payments" component={PaymentsPage} hasAnyAuthorities={[AUTHORITIES.ROLE_MANAGER]} />
      <PrivateRoute
        path="/project-submission"
        component={ProjectSubmission}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_DEALER_USER,
          AUTHORITIES.ROLE_CONSULTANT_USER,
          AUTHORITIES.ROLE_MANUFACTURER_USER,
          AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
          AUTHORITIES.ROLE_PROJECT_DESIGNER,
          AUTHORITIES.USER,
          AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
        ]}
      />
      <PrivateRoute
        path="/project-submission-details/:projectId"
        component={ProjectSubmissionDetails}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.USER,
          AUTHORITIES.ROLE_DEALER_USER,
          AUTHORITIES.ROLE_CONSULTANT_USER,
          AUTHORITIES.ROLE_MANUFACTURER_USER,
          AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
          AUTHORITIES.ROLE_PROJECT_DESIGNER,
          AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
        ]}
      />
      {/* do not delete!!! needs to be uncomment after feature release */}
      {/* <PrivateRoute
        path="/my-leads"
        component={MyLeads}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.USER,
          AUTHORITIES.ROLE_DEALER_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
          AUTHORITIES.ROLE_CONSULTANT_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN,
        ]}
      /> */}
      {/* <PrivateRoute
        path="/lead-details/:leadId"
        component={LeadDetailsPage}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.USER,
          AUTHORITIES.ROLE_DEALER_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
          AUTHORITIES.ROLE_CONSULTANT_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN,
        ]}
      /> */}
      <PrivateRoute
        path="/my-configurators"
        component={ProductConfigurators}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_DEALER_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
          AUTHORITIES.ROLE_CONSULTANT_ADMIN,
        ]}
      />
      <PrivateRoute
        path="/notifications"
        component={Notifications}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.USER,
          AUTHORITIES.ROLE_DEALER_ADMIN,
          AUTHORITIES.ROLE_DEALER_USER,
          AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
          AUTHORITIES.ROLE_CONSULTANT_ADMIN,
          AUTHORITIES.ROLE_CONSULTANT_USER,
          AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
        ]}
      />
      <PrivateRoute path="/my-dealer" component={MyDealerUpdate} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute
        path="/edit-my-manufacturer"
        component={MyManufacturerUpdate}
        hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ROLE_MANUFACTURER_ADMIN]}
      />
      <PrivateRoute path="/my-manufacturer" component={MyManufacturer} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute
        path="/my-inventory"
        component={MyInventory}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.USER,
          AUTHORITIES.ROLE_CONSULTANT_ADMIN,
          AUTHORITIES.ROLE_CONSULTANT_USER,
          AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
          AUTHORITIES.ROLE_MANUFACTURER_USER,
          AUTHORITIES.ROLE_DEALER_USER,
        ]}
      />
      <PrivateRoute path="/admin" component={Admin} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SUPER_ADMIN]} />

      <PrivateRoute path="/my-consultant-admin" component={MyConsultantAdmin} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute path="/my-consultant-user" component={MyConsultantUser} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute path="/my-manufacturer-ref" component={MyManufacturerRef} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />

      <PrivateRoute
        path="/account"
        component={Account}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.USER,
          AUTHORITIES.ROLE_DEALER_ADMIN,
          AUTHORITIES.ROLE_DEALER_USER,
          AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
          AUTHORITIES.ROLE_CONSULTANT_ADMIN,
          AUTHORITIES.ROLE_CONSULTANT_USER,
          AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
          AUTHORITIES.ROLE_MANUFACTURER_USER,
        ]}
      />

      <PrivateRoute
        path="/email-settings"
        component={EmailSettings}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.USER,
          AUTHORITIES.ROLE_DEALER_USER,
          AUTHORITIES.ROLE_CONSULTANT_USER,
          AUTHORITIES.ROLE_MANUFACTURER_USER,
          AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
          AUTHORITIES.ROLE_DEALER_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
          AUTHORITIES.ROLE_CONSULTANT_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN,
        ]}
      />

      <PrivateRoute
        path="/find-manufacturer-rep"
        component={FindManufacturerRep}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.USER,
          AUTHORITIES.ROLE_DEALER_ADMIN,
          AUTHORITIES.ROLE_DEALER_USER,
          AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
          AUTHORITIES.ROLE_CONSULTANT_ADMIN,
          AUTHORITIES.ROLE_CONSULTANT_USER,
          AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
          AUTHORITIES.ROLE_MANUFACTURER_USER,
        ]}
      />
      <PrivateRoute
        path="/configurator-settings"
        component={ConfiguratorSettings}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.USER,
          AUTHORITIES.ROLE_DEALER_USER,
          AUTHORITIES.ROLE_CONSULTANT_USER,
          AUTHORITIES.ROLE_MANUFACTURER_USER,
          AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
        ]}
      />
      <ErrorBoundaryRoute path="/" exact component={Home} />
      <PrivateRoute
        path="/configurators"
        component={RoomleProduct}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.USER,
          AUTHORITIES.ROLE_DEALER_ADMIN,
          AUTHORITIES.ROLE_DEALER_USER,
          AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
          AUTHORITIES.ROLE_CONSULTANT_ADMIN,
          AUTHORITIES.ROLE_CONSULTANT_USER,
          AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
          AUTHORITIES.ROLE_MANUFACTURER_USER,
        ]}
      />
      <PrivateRoute
        path="/marketplace/:configurationId?/:configurationManufacturer?"
        component={MarketplacePage}
        hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PORTAL]}
      />
      <PrivateRoute
        path="/project-marketplace"
        component={ProjectMarketplace}
        hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.ADMIN_PORTAL]}
      />

      <PrivateRoute path="/manufacturer-api" component={ManufacturerApi} hasAnyAuthorities={[AUTHORITIES.ROLE_MANUFACTURER_ADMIN]} />

      <ErrorBoundaryRoute path="/version/all" component={VersionAll} />
      <ErrorBoundaryRoute path="/feature-request" component={FeatureRequest} />
      <PrivateRoute path="/" component={Entities} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <ErrorBoundaryRoute component={PageNotFound} />
    </Switch>
  </div>
);

export default Routes;
