// @ts-nocheck

export const customersData = {
  consultants: [
    {
      custCode: '',
      name: '3PM DESIGN, INC.',
      zipCode: '80012',
    },
    {
      custCode: 'ADWH',
      name: 'AULD & WHITE CONTRUCTORS, LLC',
      zipCode: '32216',
    },
    {
      custCode: 'FINK',
      name: 'BOB FINKELSTEIN & ASSOCIATES, INC.',
      zipCode: '07005',
    },
    {
      custCode: '',
      name: 'BOSMA DESIGN SOLUTIONS',
      zipCode: '75022',
    },
    {
      custCode: 'CBWD',
      name: 'CABLE-WIEDEMER, INC.',
      zipCode: '14564',
    },
    {
      custCode: 'CMCH',
      name: 'CAMACHO FOODSERVICE DESIGN & CONSULTING',
      zipCode: '30071',
    },
    {
      custCode: 'CSPR',
      name: 'CASPERS SERVICE COMPANY',
      zipCode: '33610',
    },
    {
      custCode: '',
      name: 'CINI-LITTLE INTERNATIONAL, INC.',
      zipCode: '91203',
    },
    {
      custCode: '',
      name: 'CINI-LITTLE INTERNATIONAL, INC.',
      zipCode: '33309',
    },
    {
      custCode: '',
      name: 'CINI-LITTLE INTERNATIONAL, INC.',
      zipCode: '32259',
    },
    {
      custCode: '',
      name: 'CINI-LITTLE INTERNATIONAL, INC.',
      zipCode: '60622',
    },
    {
      custCode: '',
      name: 'CLEVENGER ASSOCIATES',
      zipCode: '52202',
    },
    {
      custCode: '',
      name: 'COSPER & ASSOCIATES',
      zipCode: '78223',
    },
    {
      custCode: '',
      name: 'CS ENTERPRISES, LLC',
      zipCode: '80104',
    },
    {
      custCode: 'CLAD',
      name: 'CULINARY ADVISORS',
      zipCode: '21042',
    },
    {
      custCode: '',
      name: 'DAVELLA STUDIOS',
      zipCode: '10017',
    },
    {
      custCode: '',
      name: 'DESIGN SALES GROUP, INC.',
      zipCode: '33436',
    },
    {
      custCode: 'DVIN',
      name: 'DEVIN FOOD EQUIPMENT',
      zipCode: '19067',
    },
    {
      custCode: '',
      name: 'DIELE MURAWKA HOWE, INC.',
      zipCode: '',
    },
    {
      custCode: '',
      name: 'DM FOODSERVICE DESIGN CONSULTING',
      zipCode: '76134',
    },
    {
      custCode: 'EFRD',
      name: 'E. FRIEDMAN ASSOCIATES',
      zipCode: '11234',
    },
    {
      custCode: 'BRSK',
      name: 'E.M. BARTIKOWSKY, INC.',
      zipCode: '18640-2264',
    },
    {
      custCode: 'SCHF',
      name: 'F.G. SCHAEFER CO.',
      zipCode: '45206-2052',
    },
    {
      custCode: '',
      name: 'FCA DESIGN, INC.',
      zipCode: '77587',
    },
    {
      custCode: 'FDSD',
      name: 'FDS DESIGN STUDIO',
      zipCode: '20007',
    },
    {
      custCode: '',
      name: 'FETECH, INC.',
      zipCode: '113032',
    },
    {
      custCode: '',
      name: 'FOODESIGN ASSOCIATES',
      zipCode: '28227',
    },
    {
      custCode: '',
      name: 'FOODLINES ',
      zipCode: '68508',
    },
    {
      custCode: 'FCST',
      name: 'FOODSERVICE CONSULTANTS STUDIO',
      zipCode: '23233',
    },
    {
      custCode: 'FDP',
      name: 'FOODSERVICE DESIGN PROFESSIONALS',
      zipCode: '75234',
    },
    {
      custCode: 'FDP1',
      name: 'FOODSERVICE DESIGN PROFESSIONALS.',
      zipCode: '75205',
    },
    {
      custCode: '',
      name: 'FOODSERVICE DESIGN PROFESSIONALS.',
      zipCode: '77380',
    },
    {
      custCode: '',
      name: 'FOODSPACE',
      zipCode: '95134',
    },
    {
      custCode: '',
      name: 'FOODSPACE',
      zipCode: '83616',
    },
    {
      custCode: 'FTCH',
      name: 'FUTCH DESIGN ASSOCIATES, INC.',
      zipCode: '70806',
    },
    {
      custCode: '',
      name: 'GALVIN DESIGN GROUP',
      zipCode: '34787',
    },
    {
      custCode: '',
      name: 'HOPKINS FOODSERVICE SPECIALISTS, INC.',
      zipCode: '20818',
    },
    {
      custCode: 'INMN',
      name: 'INMAN FOODSERVICES GROUP, LLC',
      zipCode: '37209',
    },
    {
      custCode: '',
      name: 'INNOVATIVE FOODSERVICE DESIGN',
      zipCode: '33617',
    },
    {
      custCode: '',
      name: 'JBK CONSULTING & DESIGN',
      zipCode: '97124',
    },
    {
      custCode: '',
      name: 'JME DESIGN',
      zipCode: '73201',
    },
    {
      custCode: '',
      name: 'JMK FOODSERVICE CONSULTING & DESIGN, LLC',
      zipCode: '75088',
    },
    {
      custCode: 'JRSA',
      name: 'JRS ASSOCIATES, INC.',
      zipCode: '21075',
    },
    {
      custCode: '',
      name: 'LANDMARK KITCHEN DESIGN',
      zipCode: '80011',
    },
    {
      custCode: 'MCKA',
      name: 'MCKAY CONSULTING LTD.',
      zipCode: '72901',
    },
    {
      custCode: 'MLBL',
      name: 'MELBIL, LLC',
      zipCode: '89030',
    },
    {
      custCode: '',
      name: 'MULLER DESIGN',
      zipCode: '89423',
    },
    {
      custCode: 'MRCB',
      name: 'MURRAY CORBAN',
      zipCode: '38655',
    },
    {
      custCode: 'MRCB1',
      name: 'MURRAY-CORBAN',
      zipCode: '71373',
    },
    {
      custCode: 'NXST',
      name: 'NEXT STEP DESIGN',
      zipCode: '21401',
    },
    {
      custCode: '',
      name: 'NYIKOS GARCIA FOODSERVICE DESIGN',
      zipCode: '21774',
    },
    {
      custCode: '',
      name: 'ORLANDO ESPINOSA + ASSOCIATES',
      zipCode: '19342',
    },
    {
      custCode: '',
      name: 'PES DESIGN GROUP',
      zipCode: '34232',
    },
    {
      custCode: '',
      name: 'RAS DESIGN GROUP',
      zipCode: '94553',
    },
    {
      custCode: 'RDTC',
      name: 'RDT CONSULTING LLC',
      zipCode: ' 36108',
    },
    {
      custCode: '',
      name: 'REALFOOD HOSPITALITY STRATEGY & DESIGN',
      zipCode: '2468',
    },
    {
      custCode: '',
      name: 'REITANO DESIGN GROUP',
      zipCode: '46202',
    },
    {
      custCode: 'RCCA',
      name: 'RICCA DESIGN STUDIOS',
      zipCode: '80111',
    },
    {
      custCode: 'RCCA',
      name: 'RICCA DESIGN STUDIOS',
      zipCode: '80111',
    },
    {
      custCode: '',
      name: 'RIPPE ASSOCIATES',
      zipCode: '55343',
    },
    {
      custCode: 'RGKR',
      name: 'ROGER KRUSE ASSOCIATES',
      zipCode: '68106',
    },
    {
      custCode: '',
      name: 'ROMANO GATLAND',
      zipCode: '11747',
    },
    {
      custCode: '',
      name: 'S2O CONSULTANTS, INC.',
      zipCode: '60622',
    },
    {
      custCode: '',
      name: 'SCOPOS HOSPITALITY GROUP',
      zipCode: '17522',
    },
    {
      custCode: '',
      name: 'SFG CONSULTING',
      zipCode: '74103',
    },
    {
      custCode: '',
      name: 'STAFFORD DESIGN GROUP',
      zipCode: '89423',
    },
    {
      custCode: '',
      name: 'STIPE CONSULTANTS, INC.',
      zipCode: '37709',
    },
    {
      custCode: '',
      name: 'STUART DAVIS DESIGN',
      zipCode: '60068',
    },
    {
      custCode: 'STDD',
      name: 'STUDIO DnD LLC',
      zipCode: '80528',
    },
    {
      custCode: 'STRM',
      name: 'STURM CONSULTING, INC.',
      zipCode: '74134',
    },
    {
      custCode: '',
      name: 'SURCANA FOODSERVICE DESIGN',
      zipCode: '77024',
    },
    {
      custCode: '',
      name: 'TJM CONSULTING',
      zipCode: '04005',
    },
    {
      custCode: '',
      name: 'VISION BUILDERS',
      zipCode: '34785',
    },
    {
      custCode: '',
      name: 'VORDRAN & ASSOCIATES FSC, INC.',
      zipCode: '46217',
    },
    {
      custCode: '',
      name: 'WEBB FOODSERVICE DESIGN',
      zipCode: '92805',
    },
    {
      custCode: 'WRRL',
      name: 'WORRELL DESIGN GROUP',
      zipCode: '77042',
    },
    {
      custCode: '',
      name: 'YOUNG CARUSO',
      zipCode: '80237',
    },
  ],
  dealers: [
    {
      custCode: 'ELVN',
      name: '11400/ELEVEN FOUR HUNDRED, INC.',
      zipCode: '17601',
    },
    {
      custCode: 'FSCH',
      name: '1ST CHOICE RESTAURANT EQUIPMENT',
      zipCode: '78249',
    },
    {
      custCode: 'ANRS',
      name: 'A & N RESTAURANT EQUIPMENT',
      zipCode: '44481-1030',
    },
    {
      custCode: 'ABED',
      name: 'A.B.E. DISTRIBUTORS',
      zipCode: '33173',
    },
    {
      custCode: 'ADER',
      name: 'A.D.E. RESTAURANT SERVICES, INC.',
      zipCode: '60101',
    },
    {
      custCode: 'ATFD',
      name: 'A.T. FOOD SERVICE EQUIPMENT',
      zipCode: '42001',
    },
    {
      custCode: 'AAAS',
      name: 'AAA RESTAURANT SUPPLY',
      zipCode: '67202-1061',
    },
    {
      custCode: 'ABCO',
      name: 'ABCO CORPORATION',
      zipCode: '22312',
    },
    {
      custCode: 'ABLN',
      name: 'ABILENE COMMERCIAL KITCHENS',
      zipCode: '79601',
    },
    {
      custCode: 'ACMT',
      name: 'ACE MART',
      zipCode: '78218-0100',
    },
    {
      custCode: 'ACRF',
      name: 'ACE REFRIGERATION CO. OF SIOUX CITY, INC.',
      zipCode: '51102',
    },
    {
      custCode: 'ACMD',
      name: 'ACME RESTAURANT EQUIP. & SMALLWARES',
      zipCode: '20763-0422',
    },
    {
      custCode: 'ACME',
      name: 'ACME RESTAURANT SUPPLY',
      zipCode: '29413-0129',
    },
    {
      custCode: 'ADTC',
      name: 'ADVANCETEC, LLC',
      zipCode: '23236',
    },
    {
      custCode: 'ADEQ',
      name: 'ADVANTAGE EQUIPMENT & DESIGN',
      zipCode: '33065',
    },
    {
      custCode: 'ADES',
      name: 'ADVANTAGE EQUIPMENT SALES',
      zipCode: '95742-6557',
    },
    {
      custCode: 'AMCO',
      name: 'AIMCO FOODSERVICE EQUIPMENT & SUPPLIES',
      zipCode: '72204',
    },
    {
      custCode: 'AJPR',
      name: 'AJ PREMIER FOODSERVICE EQUIPMENT, INC.',
      zipCode: '78606',
    },
    {
      custCode: 'ALAK',
      name: 'ALACK REFRIGERATION',
      zipCode: '70401',
    },
    {
      custCode: 'SRTA',
      name: 'ALACK REFRIGERATION-SARASOTA DIVISION',
      zipCode: '70401',
    },
    {
      custCode: 'ALDN',
      name: 'ALADDIN TEMP-RITE',
      zipCode: '37077',
    },
    {
      custCode: 'ALKM',
      name: "ALAKA'I MECHANICAL CORPORATION",
      zipCode: '96819',
    },
    {
      custCode: 'ALBA',
      name: 'ALBAR RESTAURANT EQUIPMENT',
      zipCode: '02863',
    },
    {
      custCode: 'ALFL',
      name: 'ALL FLORIDA, INC.',
      zipCode: '32205',
    },
    {
      custCode: 'ALFS',
      name: 'ALL STATE FOOD SERVICE',
      zipCode: '44902-1394',
    },
    {
      custCode: 'ALLS',
      name: 'ALL STATE RESTAURANT',
      zipCode: '29169',
    },
    {
      custCode: 'ALFD',
      name: 'ALLIANCE FOOD EQUIPMENT',
      zipCode: '75006',
    },
    {
      custCode: 'SCHP',
      name: 'ALLIANCE PAPER & FOODSERVICE EQUIPMENT',
      zipCode: '60148',
    },
    {
      custCode: 'ALST',
      name: 'ALLSTATE-LEONARD RESTAURANT SUPPLY',
      zipCode: '02860',
    },
    {
      custCode: 'ATRS',
      name: 'ALTANTIC RESTAURANT SUPPLY',
      zipCode: '29588',
    },
    {
      custCode: 'ALTV',
      name: 'ALTERNATIVE SALES',
      zipCode: '03848',
    },
    {
      custCode: 'ALTO',
      name: 'ALTO-HARTLEY, INCORPORATED',
      zipCode: '22304',
    },
    {
      custCode: 'AMCE',
      name: 'AM COMMERCIAL EQUIPMENT LLC',
      zipCode: '32223',
    },
    {
      custCode: 'AMEG',
      name: 'AMERICAN ENERGY REST. EQUIPMENT',
      zipCode: '22153',
    },
    {
      custCode: 'AFNJ',
      name: 'AMERICAN RESTAURANT EQUIPMENT & SUPPLY',
      zipCode: '07602',
    },
    {
      custCode: 'UPMK',
      name: "AMERICA'S FOOD EQUIPMENT",
      zipCode: '29349',
    },
    {
      custCode: 'AMSV',
      name: 'AMERISERV',
      zipCode: '75380-9056',
    },
    {
      custCode: 'AMUN',
      name: 'AMUNDSEN FOOD EQUIPMENT',
      zipCode: '73112',
    },
    {
      custCode: 'ANCH',
      name: 'ANCHOR RESTAURANT SUPPLY',
      zipCode: '33458',
    },
    {
      custCode: 'AGLA',
      name: "ANGELA'S RESTAURANT SUPPLIES",
      zipCode: '32114',
    },
    {
      custCode: 'AGLO',
      name: 'ANGELO COMMERCIAL RESTAURANT EQUIP.',
      zipCode: '76905',
    },
    {
      custCode: 'ANGL',
      name: 'ANGELO REFRIG. & REST. SUPPLY',
      zipCode: '76903',
    },
    {
      custCode: 'ARBT',
      name: 'ARBUTUS REFRIGERATION, INC.',
      zipCode: '21227-1006',
    },
    {
      custCode: 'ARPA',
      name: 'ARIMAS LLC',
      zipCode: '15102',
    },
    {
      custCode: 'AZBV',
      name: 'ARIZONA BEVERAGE CONTROLS',
      zipCode: '85281',
    },
    {
      custCode: 'AZRS',
      name: 'ARIZONA RESTAURANT SUPPLY, INC.',
      zipCode: '85741',
    },
    {
      custCode: 'ASVL',
      name: 'ASHEVILLE SHOWCASE & FIXTURE',
      zipCode: '28801',
    },
    {
      custCode: 'ASFD',
      name: 'ASSOCIATED FOOD EQ. & SUPP.',
      zipCode: '39503',
    },
    {
      custCode: 'ATEX',
      name: 'ATEX RESTAURANT SUPPLY, INC.',
      zipCode: '76903',
    },
    {
      custCode: 'ATIE',
      name: 'ATI ENGINEERING',
      zipCode: '01770',
    },
    {
      custCode: 'ATFX',
      name: 'ATLANTA FIXTURE & SALES CO.',
      zipCode: '30341',
    },
    {
      custCode: 'AKIT',
      name: 'ATLANTA KITCHEN EQUIPMENT',
      zipCode: '30134',
    },
    {
      custCode: 'ATCL',
      name: 'ATLANTIC CULINARY ENVIRONMENTS, INC.',
      zipCode: '17821-0348',
    },
    {
      custCode: 'ATDB',
      name: 'ATLANTIC DISTRIBUTORS, INC.',
      zipCode: '32254',
    },
    {
      custCode: 'ATDD',
      name: 'ATLANTIC DRY DOCK CORP.',
      zipCode: '32226',
    },
    {
      custCode: 'ATMR',
      name: 'ATLANTIC MARINE',
      zipCode: '32226',
    },
    {
      custCode: 'ATPC',
      name: 'ATLANTIC PURCHASING',
      zipCode: '03801',
    },
    {
      custCode: 'ALTC',
      name: 'ATLANTIC STORE FIXTURE CO., INC.',
      zipCode: '01888',
    },
    {
      custCode: 'ATLS',
      name: 'ATLANTIS ENTERPRISES, INC.',
      zipCode: '04072',
    },
    {
      custCode: 'ATFL',
      name: 'ATLAS FOOD SERVICE EQUIPMENT',
      zipCode: '32206',
    },
    {
      custCode: 'ALRS',
      name: 'ATLAS RESTAURANT SUPPLY',
      zipCode: '46634',
    },
    {
      custCode: 'AVTI',
      name: 'AVANTI INTERNATIONAL',
      zipCode: '33168',
    },
    {
      custCode: 'AVCA',
      name: 'AVANTI RESTAURANT SOLUTIONS',
      zipCode: '92626-3414',
    },
    {
      custCode: 'AVND',
      name: 'AVENDRA REPLENISHMENT LLC',
      zipCode: '20850',
    },
    {
      custCode: 'BBRE',
      name: 'B & B RESTAURANT EQUIPMENT',
      zipCode: '32204',
    },
    {
      custCode: 'BGRS',
      name: 'B & G RESTAURANT',
      zipCode: '01201',
    },
    {
      custCode: 'BJFS',
      name: 'B & J FOOD SERVICE EQUIPMENT, INC.',
      zipCode: '66101',
    },
    {
      custCode: 'BLMK',
      name: 'B & L MARKETING GROUP',
      zipCode: '90638',
    },
    {
      custCode: 'BWSP',
      name: 'B & W SUPPLY COMPANY',
      zipCode: '14850',
    },
    {
      custCode: 'BRCC',
      name: 'B.R.C. COMMERCIAL KITCHEN CORP.',
      zipCode: '07407',
    },
    {
      custCode: 'BLTN',
      name: 'BALLENTINE EQUIPMENT CO., INC.',
      zipCode: '29602-0476',
    },
    {
      custCode: 'BNER',
      name: 'BANNER SALES COMPANY',
      zipCode: '87114',
    },
    {
      custCode: 'BRBY',
      name: 'BAR BOY PRODUCTS',
      zipCode: '11735',
    },
    {
      custCode: 'BSTN',
      name: 'BARESCO',
      zipCode: '37202',
    },
    {
      custCode: 'BRGN',
      name: 'BARGREEN-ELLINGSON, INC.',
      zipCode: '98409',
    },
    {
      custCode: 'BARG',
      name: 'BARING INDUSTRIES, INC.',
      zipCode: '33312',
    },
    {
      custCode: 'BLTM',
      name: 'BELTRAM FOODSERVICE GROUP',
      zipCode: '33604',
    },
    {
      custCode: 'BNKE',
      name: 'BEN E. KEITH FOODS-LITTLE ROCK',
      zipCode: '79108',
    },
    {
      custCode: 'BHMK',
      name: 'BENCHMARK EQUIPMENT',
      zipCode: '77380',
    },
    {
      custCode: 'BNDT',
      name: 'BENEDICT REFRIGERATION SERVICE, INC.',
      zipCode: '54720',
    },
    {
      custCode: 'BRLN',
      name: "BERLIN'S RESTAURANT SUPPLY",
      zipCode: '29406',
    },
    {
      custCode: 'BEST',
      name: 'BEST RESTAURANT EQUIPMENT & DESIGN CO.',
      zipCode: '43204',
    },
    {
      custCode: 'BZAC',
      name: 'BEZAC EQUIPMENT COMPANY',
      zipCode: '44515',
    },
    {
      custCode: 'BIGA',
      name: 'BIG A DISTRIBUTING',
      zipCode: '30287',
    },
    {
      custCode: 'BGBN',
      name: 'BIG BEND RESTAURANT SUPPLY',
      zipCode: '32301',
    },
    {
      custCode: 'BGER',
      name: 'BIG ERICS, INC.',
      zipCode: 'B3B 0A8',
    },
    {
      custCode: 'BRSC',
      name: 'BIRMINGHAM REST. SUPPLY, INC.',
      zipCode: '35233',
    },
    {
      custCode: 'BMVE',
      name: 'BMV EQUIPMENT CORP.',
      zipCode: '11793',
    },
    {
      custCode: 'PRMN',
      name: 'BOELTER PREMIER',
      zipCode: '55428',
    },
    {
      custCode: 'BTON',
      name: 'BOLTON & HAY, INC.',
      zipCode: '50317-3533',
    },
    {
      custCode: 'BRDR',
      name: 'BORDER STATES ELECTRIC SUPPLY',
      zipCode: '58108-2767',
    },
    {
      custCode: 'BOST',
      name: 'BOSTON SHOWCASE COMPANY',
      zipCode: '02461-0254',
    },
    {
      custCode: 'MACR',
      name: 'BOTTLES, LLC',
      zipCode: '29464',
    },
    {
      custCode: 'BCPA',
      name: 'BOVA CORP.',
      zipCode: '16059',
    },
    {
      custCode: 'BRSO',
      name: 'BRASCO SERVICE, INC.',
      zipCode: '72403-1413',
    },
    {
      custCode: 'BRAN',
      name: 'BRAUN EQUIPMENT COMPANY',
      zipCode: '07662',
    },
    {
      custCode: 'BREK',
      name: 'BRECKENRIDGE KITCHEN EQUIP. & DESIGN',
      zipCode: '44839',
    },
    {
      custCode: 'BRBR',
      name: 'BRISCO & BRISCO, INC.',
      zipCode: '92648',
    },
    {
      custCode: 'BRTN',
      name: 'BRITTAN & ASSOCIATES, INC.',
      zipCode: '98020',
    },
    {
      custCode: 'BRRF',
      name: "BROWN'S REFRIGERATION & EQUIP.",
      zipCode: '32314',
    },
    {
      custCode: 'BYCO',
      name: 'BRYSCO FOODSERVICE',
      zipCode: '15205',
    },
    {
      custCode: 'BRSN',
      name: 'BRYSON REFRIGERATION',
      zipCode: '28779',
    },
    {
      custCode: 'BKHD',
      name: 'BUCKHEAD REST. EQUIPMENT',
      zipCode: '30082',
    },
    {
      custCode: 'BDGT',
      name: 'BUDGET RESTAURANT SUPPLY',
      zipCode: '77099',
    },
    {
      custCode: 'BFLO',
      name: 'BUFFALO HOTEL SUPPLY CO., INC.',
      zipCode: '14226',
    },
    {
      custCode: 'BULR',
      name: 'BULLER FIXTURE COMPANY',
      zipCode: '68117',
    },
    {
      custCode: 'BURB',
      name: 'BURBANK, DOUGLASS & COMPANY',
      zipCode: '04105',
    },
    {
      custCode: 'BRKT',
      name: 'BURKETT RESTAURANT EQUIPMENT & SUPPLIES',
      zipCode: '43551',
    },
    {
      custCode: 'BUSH',
      name: 'BUSHONG RESTAURANT EQUIPMENT & CO.',
      zipCode: '45402',
    },
    {
      custCode: 'CTDE',
      name: 'C&T DESIGN & EQUIPMENT',
      zipCode: '46219',
    },
    {
      custCode: 'CCPR',
      name: 'C. CAPRARA FOODSERVICE EQUIPMENT',
      zipCode: '04364-0140',
    },
    {
      custCode: 'CRHT',
      name: 'CAIRE HOTEL & RESTAURANT SUPPLY, INC.',
      zipCode: '70119',
    },
    {
      custCode: 'CLTR',
      name: 'CALCATERRA FOOD COMPANY',
      zipCode: '62948',
    },
    {
      custCode: 'CLDW',
      name: 'CALDWELL-TUCKER EQUIPMENT',
      zipCode: '19971',
    },
    {
      custCode: 'CARE',
      name: 'CAREY SALES & SERVICES',
      zipCode: '21229',
    },
    {
      custCode: 'CASA',
      name: 'CASA IMPORTS',
      zipCode: '13504',
    },
    {
      custCode: 'CSTN',
      name: 'CASTINO RESTAURANT DESIGN, EQUIP. & SUPPLY',
      zipCode: '94928',
    },
    {
      custCode: 'CYRD',
      name: "CAYARD'S, INC.",
      zipCode: '70805-6797',
    },
    {
      custCode: 'CFEQ',
      name: 'CENTRAL FOOD EQUIPMENT, INC.',
      zipCode: '33803',
    },
    {
      custCode: 'CTRL',
      name: 'CENTRAL RESTAURANT PRODUCTS',
      zipCode: '46268',
    },
    {
      custCode: 'CNTL',
      name: 'CENTRAL RESTAURANT SUPPLY, INC.',
      zipCode: '13218',
    },
    {
      custCode: 'CHLK',
      name: 'CHAIN LINK SERVICES',
      zipCode: '76106',
    },
    {
      custCode: 'CHFX',
      name: "CHARLIE'S FIXTURES, LLC.",
      zipCode: '90006',
    },
    {
      custCode: 'CHTK',
      name: 'CHEFTEK, LLC',
      zipCode: '80204',
    },
    {
      custCode: 'CHNY',
      name: 'CHENEY BROTHERS',
      zipCode: '33404',
    },
    {
      custCode: 'CHVA',
      name: 'CHESAPEAKE DESIGN & SALES CO., INC.',
      zipCode: '23112',
    },
    {
      custCode: 'CHPK',
      name: 'CHESAPEAKE EQUIPMENT COMPANY',
      zipCode: '21613',
    },
    {
      custCode: 'CHIL',
      name: 'CHILL-CRAFT COMPANY, INC.',
      zipCode: '21076',
    },
    {
      custCode: 'FDGA',
      name: 'CITISCO FOOD SERVICE',
      zipCode: '30096',
    },
    {
      custCode: 'CLRK',
      name: 'CLARK FOOD SERVICE EQUIPMENT',
      zipCode: '17601',
    },
    {
      custCode: 'CRKF',
      name: 'CLARK PAPER PLUS, INC.',
      zipCode: '7008',
    },
    {
      custCode: 'CLNR',
      name: "CLEANER'S CLOSET",
      zipCode: '33711',
    },
    {
      custCode: 'CTSI',
      name: 'CLIMATIC TESTING SYSTEMS, INC.',
      zipCode: '19440',
    },
    {
      custCode: 'CLUB',
      name: 'CLUB WHOLESALERS OF AMERICA',
      zipCode: '75381',
    },
    {
      custCode: 'CLDR',
      name: 'COLD RUSH ICE, LLC',
      zipCode: '39402',
    },
    {
      custCode: 'COLN',
      name: 'COLONIAL FOOD SERVICE EQUIPMENT, INC.',
      zipCode: '25701',
    },
    {
      custCode: 'CMAP',
      name: 'COMMERCIAL APPLIANCE CONTRACTS',
      zipCode: '16127',
    },
    {
      custCode: 'CMTX',
      name: 'COMMERCIAL ICE MACHINE COMPANY',
      zipCode: '79761',
    },
    {
      custCode: 'CMNY',
      name: 'COMMERCIAL KITCHEN DESIGN',
      zipCode: '11237',
    },
    {
      custCode: 'CMKT',
      name: 'COMMERCIAL KITCHEN EQUIPMENT',
      zipCode: '44147',
    },
    {
      custCode: 'CKUT',
      name: 'COMMERCIAL KITCHEN SUPPLY',
      zipCode: '84014',
    },
    {
      custCode: 'CKNG',
      name: 'COMMERCIAL KITCHENEERING, INC.',
      zipCode: '46227-1163',
    },
    {
      custCode: 'CMFL',
      name: 'COMMERCIAL KITCHENS UNLIMITED',
      zipCode: '32308',
    },
    {
      custCode: 'CRSI',
      name: 'COMPLETE RESTAURANT SERVICES',
      zipCode: '75220',
    },
    {
      custCode: 'CNCP',
      name: 'CONCEPT SERVICES',
      zipCode: '78727-4011',
    },
    {
      custCode: 'CNNC',
      name: 'CONNECTICUT BAR & RESTAURANT SUPPLY',
      zipCode: '06511',
    },
    {
      custCode: 'CNCO',
      name: 'CONSOLIDATED COMPANIES, INC.',
      zipCode: '70161',
    },
    {
      custCode: 'COOL',
      name: "COOLER'S INCORPORATED",
      zipCode: '77081',
    },
    {
      custCode: 'CLEP',
      name: 'COOLING ENTERPRISES, INC.',
      zipCode: '77584',
    },
    {
      custCode: 'CREQ',
      name: 'COSTAL RESTAURANT EQUIPMENT AND DESIGN',
      zipCode: '32346',
    },
    {
      custCode: 'CNRS',
      name: 'COUNTY RESTAURANT SUPPLY',
      zipCode: '94070',
    },
    {
      custCode: 'CWTN',
      name: 'COWTOWN RESTAURANT SUPPLY',
      zipCode: '76164',
    },
    {
      custCode: 'CYFM',
      name: "COY'S HONEY FARM, INC.",
      zipCode: '72401',
    },
    {
      custCode: 'CRCA',
      name: 'CRESCO',
      zipCode: '93721',
    },
    {
      custCode: 'CRST',
      name: 'CREST FOODSERVICE EQUIPMENT COMPANY',
      zipCode: '23454-3367',
    },
    {
      custCode: 'CLDP',
      name: 'CULINARY DEPOT',
      zipCode: '10952',
    },
    {
      custCode: 'CLNX',
      name: 'CULINEX',
      zipCode: '58108-2925',
    },
    {
      custCode: 'CRTS',
      name: 'CURTIS EQUIPMENT, INC.',
      zipCode: '20011',
    },
    {
      custCode: 'CROR',
      name: 'CURTIS RESTAURANT EQUIPMENT',
      zipCode: '97477',
    },
    {
      custCode: 'CURT',
      name: 'CURTIS RESTAURANT SUPPLY COMPANY',
      zipCode: '74145',
    },
    {
      custCode: 'CSTM',
      name: 'CUSTOM KITCHEN EQUIPMENT CO., INC.',
      zipCode: '77347',
    },
    {
      custCode: 'CSOK',
      name: 'CUSTOM STAINLESS',
      zipCode: '73107-6832',
    },
    {
      custCode: 'CYPS',
      name: 'CYPRUS RESTAURANT SUPPLY, INC.',
      zipCode: '28208',
    },
    {
      custCode: 'DLRY',
      name: 'DALY REFRIGERATION',
      zipCode: '44105',
    },
    {
      custCode: 'DCON',
      name: 'DEACON FOODSERVICE SOLUTIONS',
      zipCode: '28269',
    },
    {
      custCode: 'DEEP',
      name: 'DEEP SOUTH REFRIGERATION',
      zipCode: '70119',
    },
    {
      custCode: 'DEIF',
      name: 'DEI FOODSERVICE EQUIPMENT & DESIGN',
      zipCode: '33309',
    },
    {
      custCode: 'DLAW',
      name: 'DELAWARE FOOD SERVICE',
      zipCode: '19901',
    },
    {
      custCode: 'DELT',
      name: 'DELTA RESTAURANT SUPPLY',
      zipCode: '78401',
    },
    {
      custCode: 'DNVE',
      name: 'DENVER EQUIPMENT COMPANY',
      zipCode: '28037',
    },
    {
      custCode: 'DGFS',
      name: 'DESIGN FOOD SERVICE EQUIPMENT, INC.',
      zipCode: '7024',
    },
    {
      custCode: 'DSFR',
      name: 'DESIGNS, FURNISHINGS & EQUIPMENT',
      zipCode: '32117',
    },
    {
      custCode: 'DTRY',
      name: 'DIETARY EQUIPMENT CO., INC.',
      zipCode: '29201',
    },
    {
      custCode: 'DINE',
      name: 'DINE COMPANY',
      zipCode: '40232-4038',
    },
    {
      custCode: 'DSEQ',
      name: 'DISCOUNT EQUIPMENT INTERNATIONAL',
      zipCode: '33020',
    },
    {
      custCode: 'DVSF',
      name: 'DIVERSIFIED PRODUCTS & CONSULTING, LLC.',
      zipCode: '32309',
    },
    {
      custCode: 'DIXI',
      name: 'DIXIE STORE FIXTURES & SALES',
      zipCode: '35203',
    },
    {
      custCode: 'DMHP',
      name: 'DMHOPE, LLC',
      zipCode: '62711',
    },
    {
      custCode: 'DGVN',
      name: 'DONG VINH, INC.',
      zipCode: '95112',
    },
    {
      custCode: 'DGEQ',
      name: 'DOUGLAS EQUIPMENT',
      zipCode: '24701-4048',
    },
    {
      custCode: 'DCTE',
      name: "DUCOTE'S REST. & REFR. EQUIP., INC.",
      zipCode: '70806',
    },
    {
      custCode: 'DRAY',
      name: 'DURAY J.F. DUNCAN INDUSTRIES, INC.',
      zipCode: '90241',
    },
    {
      custCode: 'DKAL',
      name: 'DYKES FOODSERVICE SOLUTIONS, INC.',
      zipCode: '35816',
    },
    {
      custCode: 'EAST',
      name: 'EAST BAY RESTAURANT SUPPLY, INC.',
      zipCode: '94607-4603',
    },
    {
      custCode: 'ESTC',
      name: 'EAST COAST RESTAURANT EQUIPMENT CO.',
      zipCode: '32082',
    },
    {
      custCode: 'ESBK',
      name: 'EASTERN BAKERS SUPPLY CO, INC.',
      zipCode: '02114-2150',
    },
    {
      custCode: 'ESNC',
      name: 'EASTERN FOOD EQUIPMENT, INC.',
      zipCode: '27103',
    },
    {
      custCode: 'ESTR',
      name: 'EASTERN RESOURCES, INC.',
      zipCode: '17815',
    },
    {
      custCode: 'ESVA',
      name: 'EASTERN SALES & EQUIPMENT COMPANY',
      zipCode: '24019-7715',
    },
    {
      custCode: 'ECON',
      name: 'ECOMONY RESTAURANT EQUIPMENT CO., INC.',
      zipCode: '32806-4563',
    },
    {
      custCode: 'ECNY',
      name: 'ECONOMY PAPER & RESTAURANT SUPPLIES',
      zipCode: '7013',
    },
    {
      custCode: 'ECCA',
      name: 'ECONOMY RESTAURANT EQUIPMENT & SUPPLY CO.',
      zipCode: '92078',
    },
    {
      custCode: 'ECFL',
      name: 'ECONOMY RESTAURANT SUPPLY CO., INC.',
      zipCode: '32810',
    },
    {
      custCode: 'ECRV',
      name: 'ECR, INC.',
      zipCode: '22980',
    },
    {
      custCode: 'EDDY',
      name: 'EDDY  PRODUCTS CO., INC.',
      zipCode: '64145',
    },
    {
      custCode: 'EDGE',
      name: 'EDGE FOOD EQUIPMENT',
      zipCode: '',
    },
    {
      custCode: 'EDON',
      name: 'EDWARD DON & COMPANY',
      zipCode: '60517-8136',
    },
    {
      custCode: 'ELFS',
      name: 'ELITE FOODSERVICE EQUIPMENT CO., INC.',
      zipCode: '11735',
    },
    {
      custCode: 'EMNV',
      name: 'EMCOR SERVICES',
      zipCode: '89120',
    },
    {
      custCode: 'ENRS',
      name: 'ENID RESTAURANT',
      zipCode: '737002',
    },
    {
      custCode: 'EGCH',
      name: 'ENVIRONMENTAL GROWTH CHAMBER',
      zipCode: '44022',
    },
    {
      custCode: 'ENSP',
      name: 'ENVIRONMENTAL SPECIALTIES, INC.',
      zipCode: '27606-4218',
    },
    {
      custCode: 'ENVR',
      name: 'ENVIRONMENTAL SUPPLY',
      zipCode: '60050',
    },
    {
      custCode: 'ENVS',
      name: 'ENVISE',
      zipCode: '89118',
    },
    {
      custCode: 'EQDY',
      name: 'EQUIPMENT DYNAMICS, INC.',
      zipCode: '60611',
    },
    {
      custCode: 'ESSB',
      name: 'ESSBAR EQUIPMENT COMPANY',
      zipCode: '19850-0085',
    },
    {
      custCode: 'ESMD',
      name: 'ESSBAR SOUTH COMPANY',
      zipCode: '21114',
    },
    {
      custCode: 'EVGR',
      name: 'EVERGREEN SPECIALTY SERVICES',
      zipCode: '11725',
    },
    {
      custCode: 'MRTX',
      name: 'EXPRESSIONS HOME GALLERY',
      zipCode: '75050-1224',
    },
    {
      custCode: 'FCLT',
      name: 'FACILITIES SERVICES INCORPORATED',
      zipCode: '21403',
    },
    {
      custCode: 'FTDR',
      name: 'FACTORY DIRECT FOOD SERVICE',
      zipCode: '23294',
    },
    {
      custCode: 'FLCN',
      name: 'FALCON PLUMBING & HEATING',
      zipCode: '81504',
    },
    {
      custCode: 'FDRL',
      name: 'FEDERAL EQUIPMENT DEALERS',
      zipCode: '38122',
    },
    {
      custCode: 'FELR',
      name: 'FELLERS FOOD SERVICE EQUIPMENT',
      zipCode: '65802',
    },
    {
      custCode: 'FISH1',
      name: 'FISHER & ASSOCIATES, INC.',
      zipCode: '38112',
    },
    {
      custCode: 'FMAN',
      name: 'FISHMAN & ASSOCIATES',
      zipCode: '34295',
    },
    {
      custCode: 'FIXW',
      name: 'FIXTUR-WORLD, INC.',
      zipCode: '38501',
    },
    {
      custCode: 'FFDS',
      name: 'FLEMING FOODS',
      zipCode: '30531',
    },
    {
      custCode: 'FLCH',
      name: 'FLETCHER RESTAURANT EQUIPMENT',
      zipCode: '27858',
    },
    {
      custCode: 'FDFL',
      name: 'FLORIDA DISTRIBUTING SOURCE',
      zipCode: '33760',
    },
    {
      custCode: 'FDSC',
      name: 'FOOD EQUIPMENT COMPANY, INC.',
      zipCode: '29650',
    },
    {
      custCode: 'FDLQ',
      name: 'FOOD EQUIPMENT LIQUIDATORS',
      zipCode: '61102',
    },
    {
      custCode: 'FDTX',
      name: 'FOOD EQUIPMENT SPECIALISTS',
      zipCode: '77071',
    },
    {
      custCode: 'FDPA',
      name: 'FOOD EQUIPMENT SPECIALISTS LTD',
      zipCode: '19038',
    },
    {
      custCode: 'FSCP',
      name: 'FOOD SERVICE CONCEPTS',
      zipCode: '75229',
    },
    {
      custCode: 'FDOH',
      name: 'FOOD SERVICE EQUIPMENT',
      zipCode: '44128',
    },
    {
      custCode: 'FSVC',
      name: 'FOOD SERVICE, INC.',
      zipCode: '29072',
    },
    {
      custCode: 'FDSV',
      name: 'FOODSERVCO',
      zipCode: '60618',
    },
    {
      custCode: 'FDCP',
      name: 'FOODSERVICE CO-OP OF AMERICA',
      zipCode: '33326-2996',
    },
    {
      custCode: 'FDC',
      name: 'FOODSERVICE DESIGN CONCEPTS',
      zipCode: '32303',
    },
    {
      custCode: 'FDAR',
      name: 'FOODSERVICE EQUIPMENT BROKERS',
      zipCode: '72762',
    },
    {
      custCode: 'FDMO',
      name: 'FORD HOTEL SUPPLY CO.',
      zipCode: '63102',
    },
    {
      custCode: 'FOTN',
      name: 'FOUNTAIN PRODUCTS, INC.',
      zipCode: '15102',
    },
    {
      custCode: 'FTHD',
      name: 'FOUNTAINHEAD FOODSERVICE GROUP',
      zipCode: '01803',
    },
    {
      custCode: 'FXRV',
      name: 'FOX RIVER FOODS, INC.',
      zipCode: '60538',
    },
    {
      custCode: 'FNKE',
      name: 'FRANKE CONTRACT GROUP',
      zipCode: '37167',
    },
    {
      custCode: 'FRSI',
      name: 'FRS INCORPORATED',
      zipCode: '29405',
    },
    {
      custCode: 'FSWS',
      name: 'FSW FOODSERVICE WAREHOUSE',
      zipCode: '80112',
    },
    {
      custCode: 'GCRE',
      name: 'G & C RESTAURANT EQUIPMENT',
      zipCode: '03101-2792',
    },
    {
      custCode: 'GSGR',
      name: 'G & S RESTAURANT EQUIPMENT, INC.',
      zipCode: '32204',
    },
    {
      custCode: 'GDBT',
      name: 'GARDNER & BENOIT, INC.',
      zipCode: '28273',
    },
    {
      custCode: 'GTGM',
      name: 'GATE GOURMET, INC.',
      zipCode: '20191',
    },
    {
      custCode: 'GELL',
      name: 'GELL SERVICE INDUSTRIES,  INC.',
      zipCode: '78220',
    },
    {
      custCode: 'GELM',
      name: 'GELMARK DISTRIBUTORS',
      zipCode: '08034-0037',
    },
    {
      custCode: 'GNHC',
      name: 'GENERAL HEATING & COOLING CO.',
      zipCode: '60062',
    },
    {
      custCode: 'GNHT',
      name: 'GENERAL HOTEL & RESTAURANT SUPPLY',
      zipCode: '33016',
    },
    {
      custCode: 'GSCO',
      name: 'GENERAL SALES COMPANY',
      zipCode: '29290',
    },
    {
      custCode: 'GRHZ',
      name: 'GERHARZ EQUIPMENT, INC.',
      zipCode: '13057',
    },
    {
      custCode: 'GNBG',
      name: "GINSBERG'S",
      zipCode: '12534',
    },
    {
      custCode: 'GLOB',
      name: 'GLOBAL ENGINEERING CORP.',
      zipCode: '90621',
    },
    {
      custCode: 'GOBL',
      name: 'GLOBAL RESTAURANT EQUIP. & SUPPLIES, INC.',
      zipCode: '33168',
    },
    {
      custCode: 'GDLT',
      name: 'GOLDEN LIGHT EQUIPMENT CO.',
      zipCode: '79105',
    },
    {
      custCode: 'GRDN',
      name: 'GORDON FOOD SERVICE',
      zipCode: '49501-2825',
    },
    {
      custCode: 'GMRE',
      name: 'GOURMET RECIPE EXPERTS',
      zipCode: '75785',
    },
    {
      custCode: 'GTLK',
      name: 'GREAT LAKES HOTEL SUPPLY',
      zipCode: '48033',
    },
    {
      custCode: 'GNVL',
      name: 'GREENVILLE RESTAURANT EQUIPMENT',
      zipCode: '27834',
    },
    {
      custCode: 'GRVR',
      name: 'GROVER BROTHERS',
      zipCode: '39402',
    },
    {
      custCode: 'GSTI',
      name: 'GUSTI RESTAURANT EQUIPMENT',
      zipCode: '23230',
    },
    {
      custCode: 'HMAK',
      name: 'H. MAK, INC.',
      zipCode: '15238',
    },
    {
      custCode: 'WSNY',
      name: 'H. WEISS EQUIPMENT CORPORATION',
      zipCode: '10504',
    },
    {
      custCode: 'LAYN',
      name: 'H.B. LAYNON & SONS',
      zipCode: '06902',
    },
    {
      custCode: 'HAFC',
      name: 'HAFSCO',
      zipCode: '06516',
    },
    {
      custCode: 'HSSY',
      name: 'HALLSMITH SYSCO',
      zipCode: '02766',
    },
    {
      custCode: 'HBFS',
      name: 'HARBOUR FOOD SERVICE',
      zipCode: '02150',
    },
    {
      custCode: 'HMFL',
      name: "HARRELL'S MECHANICAL, INC.",
      zipCode: '32220',
    },
    {
      custCode: 'HRIS',
      name: 'HARRIS ENVIRONMENTAL SYSTEMS',
      zipCode: '01810',
    },
    {
      custCode: 'HRPK',
      name: 'HARRY PARKER, INC.',
      zipCode: '28206',
    },
    {
      custCode: 'HRWL',
      name: 'HARWILL FOODSERVICE EQUIPMENT COMPANY',
      zipCode: '22603',
    },
    {
      custCode: 'HAWK',
      name: 'HAWK, INC.',
      zipCode: '36124-1247',
    },
    {
      custCode: 'HBRS',
      name: "HERB'S RESTAURANT SUPPLY & EQUIPMENT",
      zipCode: '24201',
    },
    {
      custCode: 'HFFW',
      name: 'HERITAGE FOOD SERVICE EQUIPMENT',
      zipCode: '46898-8710',
    },
    {
      custCode: 'HEYE',
      name: 'HEYE REFRIGERATION COMPANY',
      zipCode: '78205',
    },
    {
      custCode: 'HLRS',
      name: 'HIGHLAND RESTAURANT SUPPLY',
      zipCode: '02720',
    },
    {
      custCode: 'HOOT',
      name: 'HOOTEN EQUIPMENT COMPANY, LLC',
      zipCode: '25321',
    },
    {
      custCode: 'HRZN',
      name: 'HORIZON EQUIPMENT LLC',
      zipCode: '55122',
    },
    {
      custCode: 'HORT',
      name: 'HORTON LEWIS EQUIPMENT',
      zipCode: '12204',
    },
    {
      custCode: 'HRTN',
      name: 'HORTON SALES',
      zipCode: '75401',
    },
    {
      custCode: 'HBE',
      name: 'HOSPITAL BUILDING EQUIPMENT CO.',
      zipCode: '63141',
    },
    {
      custCode: 'HSPT',
      name: 'HOSPITALITY SUPPLY',
      zipCode: '55112',
    },
    {
      custCode: 'HTRS',
      name: 'HOTEL & RESTAURANT SUPPLY',
      zipCode: '39302',
    },
    {
      custCode: 'HSIA',
      name: 'HUSSMANN SERV.',
      zipCode: '52233',
    },
    {
      custCode: 'IDEA',
      name: 'IDEAL RESTAURANT SUPPLY CO., INC.',
      zipCode: '11021',
    },
    {
      custCode: 'INDA',
      name: 'INDIANA RESTAURANT EQUIPMENT CORP.',
      zipCode: '46581-1004',
    },
    {
      custCode: 'INFT',
      name: 'INFINITY MARINE OFFSHORE, LLC',
      zipCode: '77213',
    },
    {
      custCode: 'INWR',
      name: 'INSTAWARES/SUPPLIES ON THE FLY',
      zipCode: '30144',
    },
    {
      custCode: 'INTG',
      name: 'INTEGRATED FOODSERVICE SOLUTIONS',
      zipCode: '80110',
    },
    {
      custCode: 'ISCI',
      name: 'INTEGRATED SERVICES CORPORATION',
      zipCode: '10019',
    },
    {
      custCode: 'IMCR',
      name: 'INTERSTATE MECHANICAL CORP.',
      zipCode: '85034',
    },
    {
      custCode: 'ISRE',
      name: 'INTERSTATE RESTAURANT EQUIPMENT',
      zipCode: '03102',
    },
    {
      custCode: 'IRDI',
      name: 'IRD',
      zipCode: '32704',
    },
    {
      custCode: 'ISRD',
      name: 'IS RESTAURANT DESIGN EQUIP. & SUPPLY',
      zipCode: '57104-0315',
    },
    {
      custCode: 'ISTX',
      name: 'ISI COMMERCIAL REFRIGERATION, LLC',
      zipCode: '75067',
    },
    {
      custCode: 'INSP',
      name: 'ISLAND SUPPLY COMPANY',
      zipCode: '33073',
    },
    {
      custCode: 'JVRE',
      name: 'J & V RESTAURANT SUPPLY',
      zipCode: '59715-3729',
    },
    {
      custCode: 'JRSP',
      name: 'J&R SPECIALTY CO.',
      zipCode: '37077-2346',
    },
    {
      custCode: 'HAAR',
      name: 'J.H. HAAR & SONS',
      zipCode: '7032',
    },
    {
      custCode: 'JSDS',
      name: 'J.S. DESIGNS, INC.',
      zipCode: '60462',
    },
    {
      custCode: 'JACK',
      name: 'JACKSON RESTAURANT SUPPLY',
      zipCode: '38301-3255',
    },
    {
      custCode: 'LCHT',
      name: 'JACOB LIGHT DIVISION',
      zipCode: '02903',
    },
    {
      custCode: 'JBLW',
      name: 'JACOBI-LEWIS COMPANY, INC.',
      zipCode: '28401',
    },
    {
      custCode: 'JAKE',
      name: "JAKE'S FINER FOODS",
      zipCode: '77270-0467',
    },
    {
      custCode: 'JEAN',
      name: "JEAN'S RESTAURANT SUPPLY",
      zipCode: '78401',
    },
    {
      custCode: 'JESR',
      name: 'JES RESTAURANT EQUIPMENT, INC.',
      zipCode: '29649',
    },
    {
      custCode: 'JMSN',
      name: 'JIMSON, INC.',
      zipCode: '78416',
    },
    {
      custCode: 'CICI',
      name: 'JMC RESTAURANT DISTRIBUTION',
      zipCode: '75019-4427',
    },
    {
      custCode: 'HARD',
      name: 'JOE HARDING, INC.',
      zipCode: '64802',
    },
    {
      custCode: 'JORE',
      name: "JOE'S RESTAURANT EQUIPMENT",
      zipCode: '52142',
    },
    {
      custCode: 'JHTX',
      name: 'JOHNSON CONSTRUCTION SPECIALTIES, INC.',
      zipCode: '77251-1360',
    },
    {
      custCode: 'AMER',
      name: 'JOHNSON-LANCASTER & ASSOCIATES',
      zipCode: '34695',
    },
    {
      custCode: 'JNLC',
      name: 'JOHNSON-LANCASTER & ASSOCIATES, INC.',
      zipCode: '33764',
    },
    {
      custCode: 'JHRS',
      name: "JOHNSON'S RESTAURANT EQUIPMENT, INC.",
      zipCode: '07753',
    },
    {
      custCode: 'JSPH',
      name: 'JOSEPH FLIHAN COMPANY',
      zipCode: '13504',
    },
    {
      custCode: 'JOYC',
      name: 'JOYCE EQUIPMENT COMPANY',
      zipCode: '29609',
    },
    {
      custCode: 'KAMM',
      name: 'KAEMMERLEN PARTS & SERVICE, INC.',
      zipCode: '63110',
    },
    {
      custCode: 'KMRN',
      name: 'KAMRAN & COMPANY, INC.',
      zipCode: '93101-1718',
    },
    {
      custCode: 'KARP',
      name: 'KARPOUZIS COMM. REFRIGERATION, INC.',
      zipCode: '01702',
    },
    {
      custCode: 'BBEQ',
      name: 'KATOM RESTAURANT SUPPLY, INC.',
      zipCode: '37764',
    },
    {
      custCode: 'KVGH',
      name: 'KAVANAUGH RESTAURANT SUPPLY, INC.',
      zipCode: '53713-3004',
    },
    {
      custCode: 'KECD',
      name: 'KEC DESIGN',
      zipCode: '61822',
    },
    {
      custCode: 'JETR',
      name: 'KEN JETER STORE EQUIPMENT, INC.',
      zipCode: '38801',
    },
    {
      custCode: 'KNAR',
      name: 'KEN-AIR REFRIGERATION',
      zipCode: '70033',
    },
    {
      custCode: 'KTRF',
      name: 'KENT REFRIGERATION COMPANY',
      zipCode: '41012',
    },
    {
      custCode: 'KSCO',
      name: 'KESCO SUPPLY, INC.',
      zipCode: '77802',
    },
    {
      custCode: 'KESN',
      name: "KESSENICH'S LTD.",
      zipCode: '53704',
    },
    {
      custCode: 'KRBY',
      name: 'KIRBY RESTAURANT SUPPLY',
      zipCode: '75602',
    },
    {
      custCode: 'KSFL',
      name: 'KITCHEN EQUIPMENT & SUPPLY CO.',
      zipCode: '32507',
    },
    {
      custCode: 'KTCH',
      name: 'KITCHEN EQUIPMENT FABRICATING COMPANY',
      zipCode: '77021',
    },
    {
      custCode: 'KTRC',
      name: 'KITCHEN RESOURCES, LP',
      zipCode: '78550',
    },
    {
      custCode: 'KTSP',
      name: 'KITCHEN SPIN',
      zipCode: '7601',
    },
    {
      custCode: 'KCHN',
      name: 'KITCHENEERING OF WACO',
      zipCode: '76714',
    },
    {
      custCode: 'KITC',
      name: 'KITCHENEERING, INC.',
      zipCode: '44112',
    },
    {
      custCode: 'KTCR',
      name: 'KITCOR CORPORATION',
      zipCode: '91352',
    },
    {
      custCode: 'KITR',
      name: 'SINGER MA, LLC',
      zipCode: '01001',
    },
    {
      custCode: 'KNBC',
      name: 'KOINONIA BAPTIST CHURCH',
      zipCode: '39442',
    },
    {
      custCode: 'KMKT',
      name: 'KOMMERCIAL KITCHENS',
      zipCode: '77703',
    },
    {
      custCode: 'KLEZ',
      name: 'KOOLA EZE, LLC',
      zipCode: '39194',
    },
    {
      custCode: 'KREB',
      name: 'KREBS BROTHERS, INC.',
      zipCode: '72117',
    },
    {
      custCode: 'LACE',
      name: 'LACE FOODSERVICE',
      zipCode: '33265-2953',
    },
    {
      custCode: 'LRSI',
      name: 'LAFAYETTE RESTAURANT SUPPLY, INC.',
      zipCode: '70508',
    },
    {
      custCode: 'LSEQ',
      name: 'LANCASTER SCHERT EQUIPMENT, LLC.',
      zipCode: '46278',
    },
    {
      custCode: 'LNCR',
      name: 'LANCER EQUIPMENT COMPANY',
      zipCode: '44125',
    },
    {
      custCode: 'LSCO',
      name: 'LESCO SUPPLY COMPANY',
      zipCode: '60406',
    },
    {
      custCode: 'LTRS',
      name: 'LETTIERIS, LLC',
      zipCode: '55379-1896',
    },
    {
      custCode: 'LIPP',
      name: 'LIPPERT, INC.',
      zipCode: '60473',
    },
    {
      custCode: 'LKID',
      name: 'LK INDUSTRIES',
      zipCode: '32209',
    },
    {
      custCode: 'ABC',
      name: 'LONE STAR RESTAURANT SUPPLY',
      zipCode: '78753',
    },
    {
      custCode: 'LOSR',
      name: 'LOSURDO, INC.',
      zipCode: '60101',
    },
    {
      custCode: 'POLS',
      name: 'LOUIS R. POLSTER COMPANY',
      zipCode: '43216',
    },
    {
      custCode: 'WOHL',
      name: 'LOUIS WOHL & SONS, INC.',
      zipCode: '33617-2091',
    },
    {
      custCode: 'THMP',
      name: 'LOUIS WOHL & SONS, INC./EHT',
      zipCode: '33617',
    },
    {
      custCode: 'LAFS',
      name: 'LOUISIANA FOOD SERVICE EQUIPMENT, INC.',
      zipCode: '70126',
    },
    {
      custCode: 'LUBK',
      name: 'LUBBOCK RESTAURANT SUPPLY',
      zipCode: '79452',
    },
    {
      custCode: 'LUMI',
      name: 'LUMMI ISLAND WILD CO-OP',
      zipCode: '98229',
    },
    {
      custCode: 'RNGO',
      name: 'M.L. RONGO, INC.',
      zipCode: '60160-2750',
    },
    {
      custCode: 'JNST',
      name: 'M.S. JOHNSTON COMPANY',
      zipCode: '21742',
    },
    {
      custCode: 'MCKY',
      name: "MAC'S NEW USED & ABUSED",
      zipCode: '42134',
    },
    {
      custCode: 'MGEQ',
      name: 'MAGNUM EQUIPMENT, INC.',
      zipCode: '70123',
    },
    {
      custCode: 'GNSP',
      name: 'MAIN FORD GENERAL SUPPLY CO., INC.',
      zipCode: '14606-1695',
    },
    {
      custCode: 'MANA',
      name: 'MANNA DISTRIBUTORS, INC.',
      zipCode: '77063',
    },
    {
      custCode: 'MANG',
      name: 'MANNING BROTHERS FOOD EQ',
      zipCode: '30607',
    },
    {
      custCode: 'MRKT',
      name: 'MARKET SOURCE',
      zipCode: '73118',
    },
    {
      custCode: 'MARK',
      name: 'MARKHAM RESTAURANT',
      zipCode: '72906',
    },
    {
      custCode: 'MRTN',
      name: 'MARTIN BROS. DIST. CO. INC.',
      zipCode: '50613-0069',
    },
    {
      custCode: 'MTHS',
      name: 'MATHIAS FOODSERVICE EQUIPMENT CO.',
      zipCode: '34748',
    },
    {
      custCode: 'MTRX',
      name: 'MATRIX MECHANICAL, INC.',
      zipCode: '84115-3442',
    },
    {
      custCode: 'MAVN',
      name: 'MAVEN GROUP FOOD SERVICE',
      zipCode: '77477',
    },
    {
      custCode: 'MAYE',
      name: 'MAY FOODSERVICE EQUIP & DESIGN CORP.',
      zipCode: '02920',
    },
    {
      custCode: 'FXCR',
      name: 'MAYNARD-FIXTURCRAFT, INC.',
      zipCode: '37204',
    },
    {
      custCode: 'METC',
      name: 'METAL CONCEPTS',
      zipCode: '77864',
    },
    {
      custCode: 'MPLX',
      name: 'METROPLEX REFRIG. EQUIPMENT',
      zipCode: '76107-1453',
    },
    {
      custCode: 'MDRS',
      name: 'MID-CITY RESTAURANT SUPPLY',
      zipCode: '96817',
    },
    {
      custCode: 'MDWE',
      name: 'MIDWEST EQUIPMENT SALES',
      zipCode: '60007',
    },
    {
      custCode: 'MDWI',
      name: 'MIDWEST INTERIORS, LLC',
      zipCode: '60516',
    },
    {
      custCode: 'MWRS',
      name: 'MIDWEST RESTAURANT SUPPLY',
      zipCode: '68803',
    },
    {
      custCode: 'MILO',
      name: 'MILO FOOD SERVICE EQUIPMENT, INC.',
      zipCode: '33415',
    },
    {
      custCode: 'MIST',
      name: 'MISSION RESTAURANT SUPPLY',
      zipCode: '78210',
    },
    {
      custCode: 'MMCC',
      name: 'MMC CONTRACTORS',
      zipCode: '89118',
    },
    {
      custCode: 'MOBL',
      name: 'MOBILE FIXTURE & EQUIPMENT CO., INC.',
      zipCode: '36609',
    },
    {
      custCode: 'MORE',
      name: 'MOORE-WARREN EQUIPMENT CO., INC.',
      zipCode: '36302-1001',
    },
    {
      custCode: 'MTNR',
      name: 'MOUNTAIN RESTAURANT SUPPLY',
      zipCode: '10940',
    },
    {
      custCode: 'MVPS',
      name: 'MVP SERVICES GROUP',
      zipCode: '52001',
    },
    {
      custCode: 'MWMC',
      name: 'MWM CORPORATION',
      zipCode: '85043',
    },
    {
      custCode: 'MYER',
      name: 'MYERS RESTAURANT SUPPLY, LLC',
      zipCode: '95401',
    },
    {
      custCode: 'WSON',
      name: 'N. WASSERSTROM & SONS',
      zipCode: '43207',
    },
    {
      custCode: 'FREQ',
      name: 'N. WASSERSTROM & SONS.',
      zipCode: '43207',
    },
    {
      custCode: 'GIII',
      name: 'N. WASSERSTROM & SONS-GEN III GROUP',
      zipCode: '43207',
    },
    {
      custCode: 'NAEQ',
      name: 'NATIONAL EQUIPMENT COMPANY',
      zipCode: '26003',
    },
    {
      custCode: 'NELP',
      name: 'NATIONAL RESTAURANT SUPPLY CO. INC.',
      zipCode: '79915-1215',
    },
    {
      custCode: 'NAWL',
      name: 'NATIONAL WHOLESALE HOSPITALITY',
      zipCode: '76244',
    },
    {
      custCode: 'NATW',
      name: 'NATIONWIDE REFRIGERATION, INC.',
      zipCode: '20110',
    },
    {
      custCode: 'NELA',
      name: 'NELLA CUTLERY & FOOD EQUIPMENT, INC.',
      zipCode: 'L4W 1A2',
    },
    {
      custCode: 'NNNR',
      name: 'NEW & NEARLY NEW RESTAURANT EQUIPMENT',
      zipCode: '34689-3635',
    },
    {
      custCode: 'NERS',
      name: 'NEW ENGLAND RESTAURANT',
      zipCode: '06702',
    },
    {
      custCode: 'NSRG',
      name: 'NEW SOUTH RESTAURANT GROUP',
      zipCode: '39402',
    },
    {
      custCode: 'BNSG',
      name: 'NEXT DAY GOURMET',
      zipCode: '55164-0177',
    },
    {
      custCode: 'SUPD',
      name: 'NEXT DAY GOURMET/SUPERIOR PRODUCTS',
      zipCode: '55164-0177',
    },
    {
      custCode: 'TSPA',
      name: 'NEXT DAY GOURMET/U.S. PITTSTON',
      zipCode: '55161-0177',
    },
    {
      custCode: 'FLHN',
      name: 'NJ FLIHAN AND COMPANY, INC.',
      zipCode: '13501',
    },
    {
      custCode: 'NSWM',
      name: 'NORTHSHORE WHOLESALE MARKETPLACE, INC.',
      zipCode: '01960-1339',
    },
    {
      custCode: 'NWRS',
      name: 'NORTHWEST RESTAURANT SUPPLY',
      zipCode: '77092',
    },
    {
      custCode: 'NRFX',
      name: 'NORVELL FIXTURE & EQUIPMENT',
      zipCode: '30919-0838',
    },
    {
      custCode: 'NPIZ',
      name: 'NPC INTERNATIONAL',
      zipCode: '66762',
    },
    {
      custCode: 'NUIT',
      name: 'NU-INTERNATIONAL RESTAURANT SUPPLY',
      zipCode: '11418',
    },
    {
      custCode: 'NYDS',
      name: 'NY DESIGN',
      zipCode: '77098',
    },
    {
      custCode: 'OCON',
      name: 'OCONNECO, INC.',
      zipCode: '22301',
    },
    {
      custCode: 'OESS',
      name: 'OES SPECIALTIES',
      zipCode: '92121',
    },
    {
      custCode: 'OKRS',
      name: 'OKLAHOMA RESTAURANT SUPPLY',
      zipCode: '73107',
    },
    {
      custCode: 'OLMP',
      name: 'OLYMPIC STORE FIXTURES, INC.',
      zipCode: '60638',
    },
    {
      custCode: 'OSAL',
      name: 'OSWALT RESTAURANT SUPPLY',
      zipCode: '73128',
    },
    {
      custCode: 'OWEN',
      name: 'OWENS EQUIPMENT CO., INC.',
      zipCode: '30043',
    },
    {
      custCode: 'PARM',
      name: 'PARAMOUNT RESTAURANT SUPPLY',
      zipCode: '02885',
    },
    {
      custCode: 'PSCO',
      name: 'PASCO BROKERAGE, INC.',
      zipCode: '75026-0399',
    },
    {
      custCode: 'PTSN',
      name: 'PATTERSON SCALE & REST. EQUIP., INC.',
      zipCode: '32236-1827',
    },
    {
      custCode: 'PEER',
      name: 'PEERLESS RESTAURANT SUPPLIES',
      zipCode: '63110',
    },
    {
      custCode: 'PLAR',
      name: 'PELICAN AIRE COMMERCIAL SERVICE, INC.',
      zipCode: '33762',
    },
    {
      custCode: 'PJPR',
      name: 'PENN JERSEY PAPER COMPANY',
      zipCode: '19154',
    },
    {
      custCode: 'PRFC',
      name: 'PERFECTION STAINLESS FABRICATION, INC.',
      zipCode: '93305-5253',
    },
    {
      custCode: 'PRFS',
      name: 'PERFORMANCE FOODSERVICE',
      zipCode: '64082',
    },
    {
      custCode: 'PERK',
      name: 'PERKINS PAPER, INC.',
      zipCode: '02780',
    },
    {
      custCode: 'PNTR',
      name: 'PINE TREE FOOD EQUIPMENT',
      zipCode: '04039',
    },
    {
      custCode: 'PNER',
      name: 'PIONEER DISTRIBUTING CO.',
      zipCode: '72113',
    },
    {
      custCode: 'PLAT',
      name: 'PLATTSBURGH RESTAURANT',
      zipCode: '05446',
    },
    {
      custCode: 'PRTR',
      name: 'PORTER KHOUW CONSULTING',
      zipCode: '21114',
    },
    {
      custCode: 'PREC',
      name: 'PRECISION UNLIMITED',
      zipCode: '79602',
    },
    {
      custCode: 'PRED',
      name: 'PREFERRED FOODSERVICE DESIGN/SUPPLY, INC.',
      zipCode: '77025',
    },
    {
      custCode: 'PRMR',
      name: 'PREMIER COMMERCIAL REFRIGERATION, INC.',
      zipCode: '32254',
    },
    {
      custCode: 'PRCL',
      name: 'PREMIER CULINARY SOLUTIONS',
      zipCode: '92805',
    },
    {
      custCode: 'PRDS',
      name: 'PREMIER DESIGN & EQUIPMENT',
      zipCode: '60103',
    },
    {
      custCode: 'PFSE',
      name: 'PREMIER FOOD SERVICE',
      zipCode: '11710-2204',
    },
    {
      custCode: 'PREM',
      name: 'PREMIER FOOD SERVICE EQUIPMENT & DESIGN',
      zipCode: '33409',
    },
    {
      custCode: 'PRSP',
      name: 'PREMIUM SUPPLY CO.',
      zipCode: '11729',
    },
    {
      custCode: 'PFDI',
      name: 'PROFESSIONAL FOODSERVICE DESIGN, INC.',
      zipCode: '44137',
    },
    {
      custCode: 'PFKT',
      name: 'PROFESSIONAL KITCHENS, INC.',
      zipCode: '32713',
    },
    {
      custCode: 'PGFD',
      name: 'PROGRESSIVE FOOD, INC.',
      zipCode: '02379',
    },
    {
      custCode: 'PBLO',
      name: "PUEBLO HOTEL SUPPLY Dba GRADY'S",
      zipCode: '81004',
    },
    {
      custCode: 'QQFL',
      name: 'Q & Q, INC.',
      zipCode: '33840',
    },
    {
      custCode: 'QLEQ',
      name: 'QUALITY EQUIPMENT COMPANY',
      zipCode: '45662',
    },
    {
      custCode: 'QLFD',
      name: 'QUALITY FOOD EQUIPMENT',
      zipCode: '73149',
    },
    {
      custCode: 'QLRS',
      name: 'QUALITY RESTAURANT SUPPLY, LLC',
      zipCode: '75904',
    },
    {
      custCode: 'QUAL',
      name: 'QUALITY SUPPLY COMPANY',
      zipCode: '45232',
    },
    {
      custCode: 'QTHS',
      name: 'QUEST HOSPITALITY SUPPLIERS, LLC',
      zipCode: '57402',
    },
    {
      custCode: 'RJPH',
      name: 'R.J. PHILLIPS, INC.',
      zipCode: '33487-1354',
    },
    {
      custCode: 'RKMC',
      name: 'R.K. MECHANICAL',
      zipCode: '77485',
    },
    {
      custCode: 'FLAG',
      name: 'R.M. FLAGG COMPANY',
      zipCode: '04401',
    },
    {
      custCode: 'RHEQ',
      name: 'RAH EQUIPMENT',
      zipCode: '60073',
    },
    {
      custCode: 'RAPD',
      name: 'RAPIDS WHOLESALE EQUIPMENT',
      zipCode: '52302',
    },
    {
      custCode: 'RECO',
      name: 'REFRIGERATION EQUIPMENT, INC.',
      zipCode: '81004',
    },
    {
      custCode: 'RPAR',
      name: 'REPAIR 24',
      zipCode: '20198',
    },
    {
      custCode: 'RSTE',
      name: 'RESTAURANT & STORE EQUIPMENT',
      zipCode: '84101',
    },
    {
      custCode: 'RESC',
      name: 'RESTAURANT DESIGN & EQUIPMENT CORP.',
      zipCode: '06371',
    },
    {
      custCode: 'RSTX',
      name: 'RESTAURANT EQUIPMENT CO., INC.',
      zipCode: '77035',
    },
    {
      custCode: 'REDE',
      name: 'RESTAURANT EQUIPMENT SALES',
      zipCode: '19804-0003',
    },
    {
      custCode: 'RTSV',
      name: 'RESTAURANT SERVICES, INC.',
      zipCode: '76011',
    },
    {
      custCode: 'RTSU',
      name: 'RESTAURANT SOLUTIONS, INC.',
      zipCode: '30008',
    },
    {
      custCode: 'RSDP',
      name: 'RESTAURANT SUPPLY DEPOT',
      zipCode: '33773',
    },
    {
      custCode: 'RTOP',
      name: 'RESTAURANTS OPERATORS, INC.',
      zipCode: '00936-6308',
    },
    {
      custCode: 'RICH',
      name: 'RICH SERVICES',
      zipCode: '08232',
    },
    {
      custCode: 'RVER',
      name: 'RIVERS CONSTRUCTORS, INC.',
      zipCode: '32244',
    },
    {
      custCode: 'GILL',
      name: 'ROBERT GILL & COMPANY,INC.',
      zipCode: '60462',
    },
    {
      custCode: 'SYTN',
      name: 'ROBERT ORR/SYSCO',
      zipCode: '37230',
    },
    {
      custCode: 'RBRT',
      name: 'ROBERTS ENERGY PRODUCT SERVICES, LLC',
      zipCode: '76011',
    },
    {
      custCode: 'RBHD',
      name: 'ROBINHOOD INTERIORS',
      zipCode: '24011',
    },
    {
      custCode: 'RBTR',
      name: 'ROBISON TIRE COMPANY',
      zipCode: '39441',
    },
    {
      custCode: 'RHST',
      name: 'ROCHESTER STORE FIXTURE CO. INC.',
      zipCode: '14605',
    },
    {
      custCode: 'RGSN',
      name: "ROGER & SON'S INC.",
      zipCode: '10012',
    },
    {
      custCode: 'ROSE',
      name: "ROSE'S EQUIPMENT & SUPPLY, INC.",
      zipCode: '97214',
    },
    {
      custCode: 'RYMW',
      name: 'ROYAL METAL WORKS, INC.',
      zipCode: '89109',
    },
    {
      custCode: 'RUDI',
      name: 'RUDI FODDS',
      zipCode: '02150',
    },
    {
      custCode: 'RSSO',
      name: "RUSSO'S RESTAURANT EQUIPMENT & SUPPLY",
      zipCode: '34234',
    },
    {
      custCode: 'SVRE',
      name: 'S & V RESTAURANT EQUIPMENT MFG',
      zipCode: '10457',
    },
    {
      custCode: 'SGMG',
      name: 'S&G MANUFACTURING GROUP',
      zipCode: '43026',
    },
    {
      custCode: 'HTPC',
      name: 'S.P. INDUSTRIES, INC.',
      zipCode: '18974',
    },
    {
      custCode: 'SAIC',
      name: 'SAIC',
      zipCode: '07004',
    },
    {
      custCode: 'SLMN',
      name: 'SALOMONE BROTHERS, INC.',
      zipCode: '7470',
    },
    {
      custCode: 'SMTL',
      name: 'SAM TELL AND SON, INC.',
      zipCode: '11735',
    },
    {
      custCode: 'SMCO',
      name: 'SAMCO, INC.',
      zipCode: '60515',
    },
    {
      custCode: 'SDRS',
      name: 'SAN DIEGO RESTAURANT SUPPLY',
      zipCode: '92101-7386',
    },
    {
      custCode: 'SRTG',
      name: 'SARATOGA RESTAURANT EQUIPMENT SALES',
      zipCode: '12831',
    },
    {
      custCode: 'SHRF',
      name: 'SCHAFFER FOOD SERVICE',
      zipCode: '10805',
    },
    {
      custCode: 'SCEQ',
      name: 'SCIENTIFIC EQUIP. REPAIR SERVICE',
      zipCode: '77578',
    },
    {
      custCode: 'SDMN',
      name: 'SEIDMAN BROTHERS, INC.',
      zipCode: '02150',
    },
    {
      custCode: 'SVCO',
      name: 'SERVCO EQUIPMENT CO.',
      zipCode: '63139',
    },
    {
      custCode: 'SRVM',
      name: 'SERVICE AMERICA',
      zipCode: '06902',
    },
    {
      custCode: 'SRVU',
      name: 'SERV-U',
      zipCode: '61826',
    },
    {
      custCode: 'SVGN',
      name: '7-GENS, LLC',
      zipCode: '14081',
    },
    {
      custCode: 'SWLM',
      name: 'SEWELL MECHANICAL',
      zipCode: '74146',
    },
    {
      custCode: 'SHPD',
      name: 'SHEPHERD FOOD EQUIPMENT',
      zipCode: '75227',
    },
    {
      custCode: 'BSVN',
      name: 'SHERVAN COLONEL EQUIPMENT CORP.',
      zipCode: '11753',
    },
    {
      custCode: 'SHGR',
      name: 'SHIFTING GEARS, LLC',
      zipCode: '32244',
    },
    {
      custCode: 'EVI',
      name: 'SINGER / EVI',
      zipCode: '21737',
    },
    {
      custCode: 'SING',
      name: 'SINGER EQUIPMENT COMPANY, INC.',
      zipCode: '19520',
    },
    {
      custCode: 'AEMD',
      name: 'SINGER MD, LLC',
      zipCode: '21017',
    },
    {
      custCode: 'MTUK',
      name: 'SINGER NY, LLC',
      zipCode: '07503',
    },
    {
      custCode: 'SKBV',
      name: 'SKAL BEVERAGE EAST',
      zipCode: '02334',
    },
    {
      custCode: 'SKPK',
      name: 'SKIPPACK BUILDING CORPORATION',
      zipCode: '19422',
    },
    {
      custCode: 'SMGR',
      name: 'SMITH & GREENE CO.',
      zipCode: '98032',
    },
    {
      custCode: 'SOLO',
      name: 'SO LO COMPANY, INC.',
      zipCode: '24115',
    },
    {
      custCode: 'SODX',
      name: 'SODEXO PERFORMANCE INTERIORS',
      zipCode: '20878',
    },
    {
      custCode: 'SCRD',
      name: 'SOUTHERN CALIFORNIA RESTAURANT DESIGN GROUP',
      zipCode: '92024',
    },
    {
      custCode: 'SOBV',
      name: 'SOUTHERN BEVERAGECOMPANY',
      zipCode: '39215',
    },
    {
      custCode: 'STWE',
      name: 'SOUTHWEST DESIGN & SUPPLY',
      zipCode: '85040',
    },
    {
      custCode: 'SPEQ',
      name: 'SPECIALTY EQUIPMENT SALES CO.',
      zipCode: '44142',
    },
    {
      custCode: 'SRRG',
      name: 'SRRG RESTAURANTS, LLC',
      zipCode: '75244',
    },
    {
      custCode: 'SSPA',
      name: 'SSP AMERICA, INC.',
      zipCode: '33602',
    },
    {
      custCode: 'STCF',
      name: 'ST. CLAIR FOODS',
      zipCode: '38116',
    },
    {
      custCode: 'STFD',
      name: 'STAFFORD-SMITH, INC.',
      zipCode: '49001',
    },
    {
      custCode: 'SSFX',
      name: 'STAINLESS FIXTURE, INC.',
      zipCode: '91766',
    },
    {
      custCode: 'STLS',
      name: 'STAINLESS, INC.',
      zipCode: '33441-4798',
    },
    {
      custCode: 'STND',
      name: 'STANDARD RESTAURANT EQUIPMENT',
      zipCode: '84104',
    },
    {
      custCode: 'SDRE',
      name: 'STANDARD RESTAURANT EQUIPMENT',
      zipCode: '20018',
    },
    {
      custCode: 'STRS',
      name: 'STANDARD RESTAURANT EQUIPMENT.',
      zipCode: '85719',
    },
    {
      custCode: 'STLN',
      name: 'STATELINE MARKETING, LLC',
      zipCode: '32309',
    },
    {
      custCode: 'STNS',
      name: 'STEINS FOOD SERVICE EQUIPMENT, INC.',
      zipCode: '08232',
    },
    {
      custCode: 'STCR',
      name: 'STORECRAFT, INC.',
      zipCode: '78469',
    },
    {
      custCode: 'STCH',
      name: 'STREICH EQUIPMENT, INC.',
      zipCode: '54401',
    },
    {
      custCode: 'SUCS',
      name: 'SUCCESS PLUS & CO.',
      zipCode: '37617',
    },
    {
      custCode: 'SFLR',
      name: 'SUNFLOWER RESTAURANT SUPPLY, INC.',
      zipCode: '67402-1277',
    },
    {
      custCode: 'SUPM',
      name: 'SUPREME FIXTURE COMPANY',
      zipCode: '72219',
    },
    {
      custCode: 'SYGA',
      name: 'SYSCO ATLANTA, LLC',
      zipCode: '30337',
    },
    {
      custCode: 'SYHQ',
      name: 'SYSCO FOOD SERVICE',
      zipCode: '77077-2099',
    },
    {
      custCode: 'SYAU',
      name: 'SYSCO FOOD SERVICE',
      zipCode: '78714',
    },
    {
      custCode: 'SYPA',
      name: 'SYSCO FOOD SERVICE',
      zipCode: '18640',
    },
    {
      custCode: 'SYCO',
      name: 'SYSCO FOOD SERVICE, INC.',
      zipCode: '77220-5316',
    },
    {
      custCode: 'SYIA',
      name: 'SYSCO FOOD SERVICE, INC.',
      zipCode: '50304-9974',
    },
    {
      custCode: 'SYSA',
      name: 'SYSCO FOOD SERVICES',
      zipCode: '78244',
    },
    {
      custCode: 'SYNY',
      name: 'SYSCO FOOD SERVICES OF ALBANY',
      zipCode: '12065',
    },
    {
      custCode: 'SYAR',
      name: 'SYSCO FOOD SERVICES OF ARKANSAS, LLC',
      zipCode: '72219',
    },
    {
      custCode: 'SYDT',
      name: 'SYSCO FOOD SERVICES OF DALLAS, LP',
      zipCode: '75056',
    },
    {
      custCode: 'MDSY',
      name: 'SYSCO FOOD SERVICES OF KANASA CITY, INC.',
      zipCode: '66061',
    },
    {
      custCode: 'SYNO',
      name: 'SYSCO FOOD SERVICES OF NEW ORLEANS, LLC',
      zipCode: '70181',
    },
    {
      custCode: 'SYOK',
      name: 'SYSCO FOOD SERVICES OF OK',
      zipCode: '73070',
    },
    {
      custCode: 'SYHV',
      name: 'SYSCO FOOD SERVICES OF VIRGINIA',
      zipCode: '22801',
    },
    {
      custCode: 'SYFL',
      name: 'SYSCO FOOD SVCS. OF CENTRAL FL',
      zipCode: '34761-0130',
    },
    {
      custCode: 'SYMO',
      name: 'SYSCO FOODSERVICE',
      zipCode: '63301',
    },
    {
      custCode: 'SFFH',
      name: 'SYSCO FROSTED FOODS',
      zipCode: '14845',
    },
    {
      custCode: 'PGLR',
      name: 'SYSCO LINCOLN, INC.',
      zipCode: '68521',
    },
    {
      custCode: 'SYHB',
      name: 'SYSCO OF CENTRAL PA',
      zipCode: '17109',
    },
    {
      custCode: 'SYOH',
      name: 'SYSCO OF CLEVELAND',
      zipCode: '44146',
    },
    {
      custCode: 'SYVA',
      name: 'SYSCO OF HAMPTON ROADS',
      zipCode: '23435',
    },
    {
      custCode: 'SYGF',
      name: 'SYSCO/GULF ATLANTIC',
      zipCode: '32236',
    },
    {
      custCode: 'TRKT',
      name: 'T & R KITCHEN CONCEPT',
      zipCode: '11554',
    },
    {
      custCode: 'TASS',
      name: 'TASSONE EQUPIMENT CORP.',
      zipCode: '11791',
    },
    {
      custCode: 'TLRI',
      name: 'TAYLOR INDUSTRIES',
      zipCode: '50322-2013',
    },
    {
      custCode: 'TDRF',
      name: 'TD REFRIGERATION ',
      zipCode: '71103',
    },
    {
      custCode: 'JNKS',
      name: 'TECH X / JENKINS FOOD SERVICE',
      zipCode: '32254',
    },
    {
      custCode: 'TSTX',
      name: 'TEXAS FOOD SERVICE EQUIPMENT CO., INC.',
      zipCode: '77578-1449',
    },
    {
      custCode: 'TXHT',
      name: 'TEXAS HOTEL & RESTAURANT, INC.',
      zipCode: '76110',
    },
    {
      custCode: 'TMEC',
      name: 'TEXAS METAL EQUIPMENT COMPANY, LTD',
      zipCode: '77375',
    },
    {
      custCode: 'BOLT',
      name: 'THE BOELTER COMPANIES, INC.',
      zipCode: '53188',
    },
    {
      custCode: 'HLDN',
      name: 'THE HOLDEN COMPANY',
      zipCode: '04104',
    },
    {
      custCode: 'SNBY',
      name: 'THE SAN BAY COMPANY',
      zipCode: '44870',
    },
    {
      custCode: 'TLOR',
      name: 'THE TAYLOR GROUP',
      zipCode: '30312',
    },
    {
      custCode: 'KFC',
      name: 'THE UNIFIED FOODSERVICE PURCHASING COOP, LLC.',
      zipCode: '40232',
    },
    {
      custCode: 'WASS',
      name: 'THE WASSERSTROM COMPANY',
      zipCode: '43218-2056',
    },
    {
      custCode: 'DGCH',
      name: 'THE WASSERSTROM COMPANY.',
      zipCode: '85345',
    },
    {
      custCode: 'TRMX',
      name: 'THERMMAX SCIENTIFIC PRODUCTS',
      zipCode: '18915',
    },
    {
      custCode: 'THUN',
      name: 'THOMAS-UNITED, INC.',
      zipCode: '08234-5149',
    },
    {
      custCode: 'TPLE',
      name: 'SINGER NC, LLC.',
      zipCode: '28305',
    },
    {
      custCode: 'TLMN',
      name: "TILLMAN'S RESTAURANT EQUIPMENT & SUPPLIES",
      zipCode: '27532',
    },
    {
      custCode: 'TONY',
      name: 'TONY SMITH, LLC',
      zipCode: '70605',
    },
    {
      custCode: 'TLEQ',
      name: 'TOTAL EQUIPMENT SALES & SERVICES',
      zipCode: '74145',
    },
    {
      custCode: 'TRED',
      name: 'TRENDCO SUPPLY, INC.',
      zipCode: '45103',
    },
    {
      custCode: 'VICK',
      name: 'TRENDCO-VICK WHOLESALE',
      zipCode: '45103',
    },
    {
      custCode: 'TREX',
      name: 'TREX EQUIPMENT COMPANY',
      zipCode: '79102',
    },
    {
      custCode: 'TCTY',
      name: 'TRI-CITY EQUIPMENT CO.',
      zipCode: '52801',
    },
    {
      custCode: 'TRDT',
      name: 'TRIDENT FOOD SERVICE EQUIPMENT',
      zipCode: '23236',
    },
    {
      custCode: 'CTCP',
      name: 'TRIMARK CENTURY CONCEPTS, INC.',
      zipCode: '75067',
    },
    {
      custCode: 'TMCH',
      name: "TRIMARK CHEFS' TOYS",
      zipCode: '92728',
    },
    {
      custCode: 'TMER',
      name: 'TRIMARK ECONOMY, INC.',
      zipCode: '94107',
    },
    {
      custCode: 'FDCR',
      name: 'TRIMARK FOODCRAFT',
      zipCode: '27103-6703',
    },
    {
      custCode: 'GLGP',
      name: 'TRIMARK GILL GROUP, INC.',
      zipCode: '21114',
    },
    {
      custCode: 'HKDM',
      name: 'TRIMARK HOCKENBERG EQUIPMENT CO.',
      zipCode: '50310',
    },
    {
      custCode: 'HKEN',
      name: 'TRIMARK HOCKENBERGS ',
      zipCode: '68138',
    },
    {
      custCode: 'MRLN',
      name: 'TRIMARK MARLINN',
      zipCode: '60638',
    },
    {
      custCode: 'RYGL',
      name: 'TRIMARK ORANGE COUNTY',
      zipCode: '92602',
    },
    {
      custCode: 'RWSM',
      name: 'TRIMARK R.W. SMITH AND COMPANY',
      zipCode: '92196',
    },
    {
      custCode: 'KEMP',
      name: 'TRIMARK S.S. KEMP',
      zipCode: '44125',
    },
    {
      custCode: 'TMGA',
      name: 'TRIMARK SOUTH',
      zipCode: '60638',
    },
    {
      custCode: 'MDLY',
      name: 'TRIMARK STRATEGIC',
      zipCode: '75019-2229',
    },
    {
      custCode: 'WHRN',
      name: 'TRIMARK STRATEGIC EQUIPMENT & SUPPLY',
      zipCode: '75019',
    },
    {
      custCode: 'PALM',
      name: 'TRIMARK STRATEGIC,',
      zipCode: '75019-2229',
    },
    {
      custCode: 'SCRG',
      name: 'TRIMARK STRATEGIC.',
      zipCode: '75067',
    },
    {
      custCode: 'UNRI',
      name: 'TRIMARK UNITED EAST',
      zipCode: '02048',
    },
    {
      custCode: 'TRIT',
      name: 'TRINITY RESTAURANT EQUIPMENT, INC.',
      zipCode: '76010',
    },
    {
      custCode: 'TDRA',
      name: 'TUNDRA RESTAURANT SUPPLY',
      zipCode: '80301-2566',
    },
    {
      custCode: 'USFS',
      name: 'U.S. FOODSERVICE',
      zipCode: '78702',
    },
    {
      custCode: 'USAT',
      name: 'U.S. FOODSERVICE ALLENTOWN',
      zipCode: '18109',
    },
    {
      custCode: 'USVA',
      name: 'U.S. FOODSERVICE/ROANOKE DIV.',
      zipCode: '24153',
    },
    {
      custCode: 'ALNT',
      name: 'U.S. FOODSERVICE-DALLAS',
      zipCode: '85038',
    },
    {
      custCode: 'UNPA',
      name: 'UNITED REFRIGERATION, INC.',
      zipCode: '19154',
    },
    {
      custCode: 'UNPN',
      name: 'UNITED RESTAURANT - PA',
      zipCode: '17106-1980',
    },
    {
      custCode: 'UNNC',
      name: 'UNITED RESTAURANT EQUIPMENT COMPANY',
      zipCode: '27602',
    },
    {
      custCode: 'UNCO',
      name: 'UNITED RESTAURANT SUPPLY, LLC',
      zipCode: '80915',
    },
    {
      custCode: 'UNO',
      name: 'UNO RESTAURANT INC.',
      zipCode: '02132',
    },
    {
      custCode: 'UPST',
      name: 'UPSTATE FOOD EQUIPMENT MARKETING',
      zipCode: '13027',
    },
    {
      custCode: 'USFC',
      name: 'US FOODS CULINARY E & S',
      zipCode: '55164',
    },
    {
      custCode: 'USIL',
      name: 'US FOODS CULINARY E & S.',
      zipCode: '55164',
    },
    {
      custCode: 'USPA',
      name: 'US FOODSERVICE',
      zipCode: '16603-0632',
    },
    {
      custCode: 'USJN',
      name: 'US JOINER LLC',
      zipCode: '22932-3173',
    },
    {
      custCode: 'MVFS',
      name: 'VALLEY INNOVATIVE SERVICE, INC.',
      zipCode: '39288-5454',
    },
    {
      custCode: 'VNHK',
      name: 'VAN HOOK SERVICE CO., INC.',
      zipCode: '14621-2317',
    },
    {
      custCode: 'VFPI',
      name: 'VFP, INC.',
      zipCode: '24019-8226',
    },
    {
      custCode: 'VCTR',
      name: 'VICTOR PRODUCTS CORP.',
      zipCode: '23223',
    },
    {
      custCode: 'VITT',
      name: 'VITTITOW REFRIGERATION',
      zipCode: '40213',
    },
    {
      custCode: 'VOSS',
      name: 'VOSS INTEGRITY HVAC',
      zipCode: '78063',
    },
    {
      custCode: 'WWST',
      name: 'W. WEST EQUIPMENT & FURNISHINGS CO.',
      zipCode: '80238',
    },
    {
      custCode: 'ZABL',
      name: 'W.C.  ZABEL COMPANY, INC.',
      zipCode: '44502',
    },
    {
      custCode: 'HOBN',
      name: 'W.P. HOBAN RESTAURANT EQUIPMENT',
      zipCode: '18503',
    },
    {
      custCode: 'WACO',
      name: 'WACO HOTEL SUPPLY COMPANY',
      zipCode: '76710',
    },
    {
      custCode: 'WGNR',
      name: 'WAGONER RESTAURANT EQUIPMENT',
      zipCode: '76301',
    },
    {
      custCode: 'WARS',
      name: 'WAREHOUSE STORE FIXTURE COMPANY',
      zipCode: '06705',
    },
    {
      custCode: 'WRSW',
      name: 'WARREN SOUTHWEST REFRIGERATION',
      zipCode: '77041',
    },
    {
      custCode: 'WEBR',
      name: 'WEBER EQUIPMENT COMPANY',
      zipCode: '47719-0169',
    },
    {
      custCode: 'WESD',
      name: 'WES DESIGN & SUPPLY CO., LTD',
      zipCode: '11735',
    },
    {
      custCode: 'WEST',
      name: 'WEST QUALITY FOODS',
      zipCode: '39440',
    },
    {
      custCode: 'WSMN',
      name: 'WESTERMAN STORE EQUIPMENT, INC.',
      zipCode: '01604',
    },
    {
      custCode: 'WHSL',
      name: 'WHOLESALE RESTAURANT EQUIPMENT & SUPPLY',
      zipCode: '17901',
    },
    {
      custCode: 'WHOL',
      name: 'WHOLESALE RESTAURANT SUPPLY',
      zipCode: '77055',
    },
    {
      custCode: 'WHTA',
      name: 'WICHITA RESTAURANT SUPPLY, INC.',
      zipCode: '76307',
    },
    {
      custCode: 'WLNY',
      name: 'WILSON RESTAURANT EQUIPMENT',
      zipCode: '14845',
    },
    {
      custCode: 'WLRS',
      name: 'WILSON RESTAURANT SUPPLY',
      zipCode: '50613',
    },
    {
      custCode: 'WOOD',
      name: 'WOODBURN & ASSOCIATES, INC.',
      zipCode: '22003',
    },
    {
      custCode: 'YKRV',
      name: 'YORK RIVER ELECTRIC, INC.',
      zipCode: '23690',
    },
    {
      custCode: 'ZOLL',
      name: 'ZESCO PRODUCTS',
      zipCode: '46204',
    },
  ],
  manufacturerReps: [
    {
      custCode: 'ABRP',
      name: 'ABILITY REPS',
      zipCode: '01930',
    },
    {
      custCode: 'PETR',
      name: 'C.R. PETERSON ASSOCIATES',
      zipCode: '02322',
    },
    {
      custCode: 'PROL',
      name: "CASSIDY'S PRO-LINE, INC.",
      zipCode: '60148',
    },
    {
      custCode: 'DSCO',
      name: 'DESERT PEAK MARKETING',
      zipCode: '80223',
    },
    {
      custCode: 'DSPK',
      name: 'DESERT PEAK MARKETING',
      zipCode: '85029',
    },
    {
      custCode: 'ETON',
      name: 'EATON MKTG. & ASSOC.',
      zipCode: '33760',
    },
    {
      custCode: 'ERAS',
      name: 'ETTINGER-ROSINI & ASSOCIATES',
      zipCode: '75238',
    },
    {
      custCode: 'INFM',
      name: 'INFORM MARKETING GROUP, LLC',
      zipCode: '94501',
    },
    {
      custCode: 'KFMN',
      name: 'KAUFMANN AND ASSOCIATES',
      zipCode: '11726',
    },
    {
      custCode: 'OSRP',
      name: 'ONE SOURCE REPS',
      zipCode: '19079',
    },
    {
      custCode: 'PGLO',
      name: 'PAGLIO & ASSOCIATES',
      zipCode: '44022',
    },
    {
      custCode: 'PRGN',
      name: 'PARAGON MARKETING',
      zipCode: '66219',
    },
    {
      custCode: 'PMR',
      name: 'PMR, INC.',
      zipCode: '30043',
    },
    {
      custCode: 'SQER',
      name: 'SQUIER ASSOCIATES, INC.',
      zipCode: '20850',
    },
  ],
};
