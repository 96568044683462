import React from 'react';

const DeleteAlert = () => {
  return (
    <div className="d-flex align-items-center">
      <img src="../../../content/images/icons/black-alert-icon.svg" />
      <span style={{ fontSize: 18, fontWeight: 500 }}>this cannot be undone</span>
    </div>
  );
};

export default DeleteAlert;
