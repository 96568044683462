import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getProject } from './project.reducer';
import { IProject } from 'app/shared/model/project.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IProjectDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ProjectDetail extends React.Component<IProjectDetailProps> {
  componentDidMount() {
    this.props.getProject(this.props.match.params.id);
  }

  render() {
    const { projectEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            Project [<b>{projectEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="projectName">Project Name</span>
            </dt>
            <dd>{projectEntity.projectName}</dd>
            <dt>
              <span id="isRoomDesign">Is Room Design</span>
            </dt>
            <dd>{projectEntity.isRoomDesign ? 'true' : 'false'}</dd>
            <dt>
              <span id="roomlePlanId">Roomle Plan Id</span>
            </dt>
            <dd>{projectEntity.roomlePlanId}</dd>
            <dt>
              <span id="jobId">Job Id</span>
            </dt>
            <dd>{projectEntity.jobId}</dd>
            <dt>
              <span id="revitProjectFile">Revit Project File</span>
            </dt>
            <dd>{projectEntity.revitProjectFile}</dd>
            <dt>
              <span id="thumbnailImage">Thumbnail Image</span>
            </dt>
            <dd>{projectEntity.thumbnailImage}</dd>
            <dt>
              <span id="clientName">Client Name</span>
            </dt>
            <dd>{projectEntity.clientName}</dd>
            <dt>
              <span id="dateCreated">Date Created</span>
            </dt>
            <dd>
              <TextFormat value={projectEntity.dateCreated} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="lastModified">Last Modified</span>
            </dt>
            <dd>
              <TextFormat value={projectEntity.lastModified} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="status">Status</span>
            </dt>
            <dd>{projectEntity.status}</dd>
            <dt>
              <span id="guid">Guid</span>
            </dt>
            <dd>{projectEntity.guid}</dd>
            <dt>
              <span id="autoDeskId">Auto Desk Id</span>
            </dt>
            <dd>{projectEntity.autoDeskId}</dd>
            <dt>
              <span id="outputZipUrl">Output Zip Url</span>
            </dt>
            <dd>{projectEntity.outputZipUrl}</dd>
            <dt>
              <span id="outputReportUrl">Output Report Url</span>
            </dt>
            <dd>{projectEntity.outputReportUrl}</dd>
            <dt>
              <span id="forgeStatus">Forge Status</span>
            </dt>
            <dd>{projectEntity.forgeStatus}</dd>
            <dt>
              <span id="projectCode">Project Code</span>
            </dt>
            <dd>{projectEntity.projectCode}</dd>
            <dt>
              <span id="projectStreetAddress">Project Street Address</span>
            </dt>
            <dd>{projectEntity.projectStreetAddress}</dd>
            <dt>
              <span id="projectCity">Project City</span>
            </dt>
            <dd>{projectEntity.projectCity}</dd>
            <dt>
              <span id="projectState">Project State</span>
            </dt>
            <dd>{projectEntity.projectState}</dd>
            <dt>
              <span id="projectZipCode">Project Zip Code</span>
            </dt>
            <dd>{projectEntity.projectZipCode}</dd>
            <dt>
              <span id="userCompanyName">User Company Name</span>
            </dt>
            <dd>{projectEntity.userCompanyName}</dd>
            <dt>
              <span id="userStreetAddress">User Street Address</span>
            </dt>
            <dd>{projectEntity.userStreetAddress}</dd>
            <dt>
              <span id="userCity">User City</span>
            </dt>
            <dd>{projectEntity.userCity}</dd>
            <dt>
              <span id="userState">User State</span>
            </dt>
            <dd>{projectEntity.userState}</dd>
            <dt>
              <span id="userZipCode">User Zip Code</span>
            </dt>
            <dd>{projectEntity.userZipCode}</dd>
            <dt>
              <span id="userCompanyUrl">User Company Url</span>
            </dt>
            <dd>{projectEntity.userCompanyUrl}</dd>
            <dt>
              <span id="userPhone">User Phone</span>
            </dt>
            <dd>{projectEntity.userPhone}</dd>
            <dt>User</dt>
            <dd>{projectEntity.userLogin ? projectEntity.userLogin : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/project" replace color="info">
            <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/project/${projectEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ project }: IRootState) => ({
  projectEntity: project.entity,
});

const mapDispatchToProps = { getProject };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetail);
