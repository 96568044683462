import React,{useEffect,useState} from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { ICrudGetAllAction, TextFormat, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './project.reducer';
import { IProject } from 'app/shared/model/project.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';

export interface IProjectProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const Project = (props: IProjectProps) => {
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );

  const getAllEntities = () => {
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const { projectList, match, loading, totalItems } = props;
  return (
    <div>
      <h2 id="project-heading">
        Projects
        <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp; Create new Project
        </Link>
      </h2>
      <div className="table-responsive">
        {projectList && projectList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={this.sort('id')}>
                  ID <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('projectName')}>
                  Project Name <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('isRoomDesign')}>
                  Is Room Design <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('roomlePlanId')}>
                  Roomle Plan Id <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('jobId')}>
                  Job Id <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('revitProjectFile')}>
                  Revit Project File <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('thumbnailImage')}>
                  Thumbnail Image <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('clientName')}>
                  Client Name <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('dateCreated')}>
                  Date Created <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('lastModified')}>
                  Last Modified <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('status')}>
                  Status <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('guid')}>
                  Guid <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('autoDeskId')}>
                  Auto Desk Id <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('outputZipUrl')}>
                  Output Zip Url <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('outputReportUrl')}>
                  Output Report Url <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('forgeStatus')}>
                  Forge Status <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  User <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {projectList.map((project, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${project.id}`} color="link" size="sm">
                      {project.id}
                    </Button>
                  </td>
                  <td>{project.projectName}</td>
                  <td>{project.isRoomDesign ? 'true' : 'false'}</td>
                  <td>{project.roomlePlanId}</td>
                  <td>{project.jobId}</td>
                  <td>{project.revitProjectFile}</td>
                  <td>{project.thumbnailImage}</td>
                  <td>{project.clientName}</td>
                  <td>
                    <TextFormat type="date" value={project.dateCreated} format={APP_DATE_FORMAT} />
                  </td>
                  <td>
                    <TextFormat type="date" value={project.lastModified} format={APP_DATE_FORMAT} />
                  </td>
                  <td>{project.status}</td>
                  <td>{project.guid}</td>
                  <td>{project.autoDeskId}</td>
                  <td>{project.outputZipUrl}</td>
                  <td>{project.outputReportUrl}</td>
                  <td>{project.forgeStatus}</td>
                  <td>{project.userLogin ? project.userLogin : ''}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${project.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${project.id}/edit`} color="primary" size="sm">
                        <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${project.id}/delete`} color="danger" size="sm">
                        <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Delete</span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Projects found</div>
        )}
      </div>
      {props.totalItems ? (
        <div className={projectList && projectList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ project }: IRootState) => ({
  projectList: project.entities,
  loading: project.loading,
  totalItems: project.totalItems,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Project);
