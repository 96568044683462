import { defaultValue, IProduct } from 'app/shared/model/product.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, IPayload, IPayloadResult, Storage } from 'react-jhipster';
import { IProjectSubmissionLog } from 'app/shared/model/project-submission-log.model';

export const ACTION_TYPES = {
  FETCH_PRODUCT_LIST: 'product/FETCH_PRODUCT_LIST',
  FETCH_PRODUCT: 'product/FETCH_PRODUCT',
  CREATE_PRODUCT: 'product/CREATE_PRODUCT',
  UPDATE_PRODUCT: 'product/UPDATE_PRODUCT',
  DELETE_PRODUCT: 'product/DELETE_PRODUCT',
  RESET: 'product/RESET',
  DOWNLOAD_FILE: 'product/DOWNLOAD_FILE',
  SET_FILTER: 'product/SET_FILTER',
  RESET_FILTERS: 'products/RESET_FILTERS',
};

const defaultFilters = {
  name: '',
  isMOC: false,
  isActive: false,
  visibleToAdmin: false,
  showInMarketPlace: false,
  revitExportEnable: false,
  cleanRevitFamily: false,
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IProduct>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  filters: defaultFilters,
};

const startYear = 2017;
const endYear = 2022;

export type ProductState = Readonly<typeof initialState>;

export declare type ICrudCreateProductAction<T> = (data?: T, familyFiles?: any, typeCatalogFile?: File) => IPayload<T> | IPayloadResult<T>;

// Reducer

export default (state: ProductState = initialState, action): ProductState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PRODUCT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PRODUCT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PRODUCT):
    case REQUEST(ACTION_TYPES.UPDATE_PRODUCT):
    case REQUEST(ACTION_TYPES.DELETE_PRODUCT):
    case FAILURE(ACTION_TYPES.FETCH_PRODUCT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PRODUCT):
    case FAILURE(ACTION_TYPES.CREATE_PRODUCT):
    case FAILURE(ACTION_TYPES.UPDATE_PRODUCT):
    case FAILURE(ACTION_TYPES.DELETE_PRODUCT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PRODUCT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_PRODUCT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PRODUCT):
    case SUCCESS(ACTION_TYPES.UPDATE_PRODUCT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PRODUCT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: action.payload.filterValue,
        },
      };
    case ACTION_TYPES.RESET_FILTERS:
      return {
        ...state,
        filters: defaultFilters,
      };

    default:
      return state;
  }
};

const apiUrl = 'api/products';

// Actions

export const getEntities: ICrudGetAllAction<IProduct> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_PRODUCT_LIST,
    payload: axios.get<IProduct>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

// @ts-ignore
export const getEntitiesWithFilter: ICrudGetAllAction<IProduct> = (page, size, sort, filters) => {
  const params = prepareQueryParams(page, size, sort, filters);
  return {
    type: ACTION_TYPES.FETCH_PRODUCT_LIST,
    payload: axios.get<IProjectSubmissionLog>(`${apiUrl}${params ? `?${params}` : ''}`),
  };
};

export const getEntity: ICrudGetAction<IProduct> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PRODUCT,
    payload: axios.get<IProduct>(requestUrl),
  };
};

export const getEntityByComponentId: ICrudGetAction<IProduct> = componentId => {
  const requestUrl = `${apiUrl}/by-componentId/${componentId}`;
  return {
    type: ACTION_TYPES.FETCH_PRODUCT,
    payload: axios.get<IProduct>(requestUrl),
  };
};

export const getSignedLinks = (id: number) => {
  return;
};

export const createEntity: ICrudCreateProductAction<IProduct> = (entity, familyFiles) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_PRODUCT,
    payload: axios.post(apiUrl, cleanEntity(entity)).then(async response => {
      /*if (familyFiles) {
        const entityId = response.data.id;
        // Upload all the files
        // Get all needed links to upload the files
        await axios.get(`${apiUrl}/${entityId}/links/${startYear}/${endYear}`).then(async links => {
          // For each family file do a request uploading the file to S3
          const keys = Object.keys(familyFiles);
          const requests = [];
          keys.forEach(year => {
            requests.push(
              fetch(links.data[year], {
                method: 'PUT',
                body: familyFiles[year],
                headers: {
                  'Content-Type': 'application/octet-stream',
                },
              })
            );
          });

          // If no file fails then try to update the available revit versions
          if (requests.length > 0) {
            await Promise.all(requests).then(async _ => {
              // await axios.post(`${apiUrl}/${entityId}/refresh-available-file-versions`);
            });
          }
        });
      }*/

      return response;
    }),
  });

  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudCreateProductAction<IProduct> = (entity, familyFiles) => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.UPDATE_PRODUCT,
    payload: axios.put(apiUrl, cleanEntity(entity)).then(async response => {
      /*if (familyFiles) {
        const entityId = response.data.id;
        // Upload all the files
        // Get all needed links to upload the files
        await axios.get(`${apiUrl}/${entityId}/links/${startYear}/${endYear}`).then(async links => {
          // For each family file do a request uploading the file to S3
          const keys = Object.keys(familyFiles);
          const requests = [];
          keys.forEach(year => {
            requests.push(
              fetch(links.data[year], {
                method: 'PUT',
                body: familyFiles[year],
                headers: {
                  'Content-Type': 'application/octet-stream',
                },
              })
            );
          });


          // If no file fails then try to update the available revit versions
          if (requests.length > 0) {
            await Promise.all(requests).then(async _ => {
              // await axios.post(`${apiUrl}/${entityId}/refresh-available-file-versions`);
            });
          }
        });
      }*/

      return response;
    }),
  });
};

export const deleteEntity: ICrudDeleteAction<IProduct> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PRODUCT,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const getDownloadFile = (id: number, additionalPath: string = null, year: string = null) => {
  const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');

  const url = year == null ? `${apiUrl}/${id}/signed-url` : `${apiUrl}/${id}/${additionalPath}/${year}/signed-url`;
  return {
    type: ACTION_TYPES.DOWNLOAD_FILE,
    payload: fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      redirect: 'follow',
    })
      .then(response => {
        // When redirected download the file
        if (response.redirected) {
          window.location.href = response.url;
        }
      })
      .catch(function (err) {
        console.info(err); // eslint-disable-line no-console
      }),
  };
};

export const getDownloadFileSecond = (id: number, additionalPath: string = null, year: string = null, fileName: string = null) => {
  const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');

  const url = year == null ? `${apiUrl}/${id}/signed-url` : `${apiUrl}/${id}/${additionalPath}/${year}/${fileName}/download-component`;
  return {
    type: ACTION_TYPES.DOWNLOAD_FILE,
    payload: fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      redirect: 'follow',
    })
      .then(response => {
        // When redirected download the file
        if (response.redirected) {
          window.location.href = response.url;
        }
      })
      .catch(function (err) {
        console.info(err); // eslint-disable-line no-console
      }),
  };
};

export const setFilter = (filterName, filterValue) => dispatch => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER,
    payload: { filterName, filterValue },
  });
};

export const resetFilters = () => ({
  type: ACTION_TYPES.RESET_FILTERS,
});

const prepareQueryParams = (page, size, sort, filter) => {
  const params = [];
  params.push(encodeURIComponent('page') + '=' + encodeURIComponent(page ? page : 0));
  if (size) {
    params.push(encodeURIComponent('size') + '=' + encodeURIComponent(size));
  }
  if (sort) {
    params.push(encodeURIComponent('sort') + '=' + encodeURIComponent(sort));
  }
  if (filter) {
    if (filter.name && filter.name.length) {
      params.push(encodeURIComponent('name.contains') + '=' + encodeURIComponent(filter.name));
    }
    if (filter.isMOC) {
      params.push(encodeURIComponent('isMOC.equals') + '=' + encodeURIComponent(filter.isMOC));
    }
    if (filter.isActive) {
      params.push(encodeURIComponent('active.equals') + '=' + encodeURIComponent(filter.isActive));
    }
    if (filter.visibleToAdmin) {
      params.push(encodeURIComponent('visibleToAdmin.equals') + '=' + encodeURIComponent(filter.visibleToAdmin));
    }

    if (filter.showInMarketPlace) {
      params.push(encodeURIComponent('showInMarketPlace.equals') + '=' + encodeURIComponent(filter.showInMarketPlace));
    }

    if (filter.revitExportEnable) {
      params.push(encodeURIComponent('revitExportEnable.equals') + '=' + encodeURIComponent(filter.revitExportEnable));
    }

    if (filter.cleanRevitFamily) {
      params.push(encodeURIComponent('cleanRevitFamily.equals') + '=' + encodeURIComponent(filter.cleanRevitFamily));
    }

    params.push(encodeURIComponent('cacheBuster') + '=' + encodeURIComponent(`${new Date().getTime()}`));
    return params.join('&');
  }
};
