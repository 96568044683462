import React from 'react';
import { Modal } from 'reactstrap';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import Select from 'react-select';
import './addToProject.scss';

interface IAddToProjectModal {
  isOpen: boolean;
  setIsAddToProjectModalOpen: Function;
  projectsForSelect: any;
  setProjectsForSelect: Function;
  setSelectedProjectId: Function;
  createProject: Function;
  projectUpdating: boolean;
  selectedProjectId: Number;
  setIsCreateNewProjectModalOpen: Function;
}

const AddToProjectModal = (props: IAddToProjectModal) => {
  const customSelectStyles = {
    placeholder(base, state) {
      return {
        ...base,
        opacity: 0.5,
      };
    },
    menuList: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      marginTop: -8,
      boxShadow: '0px 15px 30px #a1b0cf14',
      padding: 0,
      borderRadius: 10,
    }),
    option: (provided, state) => ({
      ...provided,
      paddingTop: 13,
      paddingBottom: 13,
      borderBottom: '1px solid #7070700d',
      color: '#9FAAB5',
      fontWeight: 300,
      '&:hover': {
        background: 'transparent',
        color: '#E2863C',
      },
      background: 'transparent',
      cursor: 'pointer',
    }),
  };

  return (
    <Modal
      isOpen={props.isOpen}
      centered
      style={{ maxWidth: 700, width: window.screen.width - 1000 }}
      toggle={props.setIsAddToProjectModalOpen}
      backdrop={true}
    >
      <div className="create-folder-modal-container">
        <span className="create-folder-modal-title">Add to Project</span>

        <div>
          <Select
            options={props.projectsForSelect}
            className="business-name-select"
            onChange={value => props.setSelectedProjectId(value)}
            styles={customSelectStyles}
            placeholder="Select Project"
          />
          <p style={{ textAlign: 'center' }}>or</p>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
            <ConfirmButton
              title="Create New Project"
              disabled={props.projectUpdating}
              confirmAction={() => props.setIsCreateNewProjectModalOpen(true)}
            />
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <ConfirmButton title="Add" disabled={props.projectUpdating || !props.selectedProjectId} confirmAction={props.createProject} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddToProjectModal;
