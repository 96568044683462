import React, { useEffect, useState, memo, lazy, Suspense, useCallback } from 'react';
import { Collapse } from 'reactstrap';
import InventoryProductCard from '../InventoryProductCard/InventoryProductCard';
const ConfirmationDialog = lazy(() => import('../ConfirmationDialog/ConfirmationDialog'));
import { createEntity, deleteEntity, getEntities, renameFolder, resetFolders } from 'app/entities/folder/folder.reducer';
import { connect } from 'react-redux';
import { getUserConfigurationsByFolderId, reset } from 'app/entities/user-configuration/user-configuration.reducer';
import { DEFAULT_FOLDERS } from 'app/config/constants';
import AppSpinner from 'app/components/Spinner/Spinner';
import { useInventoryFolderOpening } from 'app/customHooks/useInventoryFolderOpening';
import { useFolderUserConfigurations } from 'app/customHooks/useFolderUserConfigurations';
import NoDataBanner from '../NoDataBanner/NoDataBanner';
import './inventoryFolder.scss';

export interface IFolderProps extends DispatchProps {
  folderList: any;
  renameFolder: any;
  userConfigurations: any;
  loadingFolders: any;
  searchValue: string;
  selectInventoryCard: Function;
  selectedInventories: any;
  isMultipleSelectModeActive: boolean;
  loading: boolean;
}

const InventoryFolder = memo((props: IFolderProps) => {
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState<boolean>(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const folderUserConfigurations = useFolderUserConfigurations(props.userConfigurations, props.searchValue);
  const [isFolderOpen, folderOpenId, toggleFolder] = useInventoryFolderOpening(
    props.folderList,
    DEFAULT_FOLDERS.INBOX,
    props.getUserConfigurationsByFolderId,
    props.reset
  );

  useEffect(() => {
    if (!props.folderList.length) {
      props.getEntities();
    }

    return () => props.resetFolders();
  }, []);

  const deleteFolder = useCallback(() => {
    props.deleteEntity(folderOpenId);
    closeEditFolderModal();
    setIsDeleteConfirmationDialogOpen(false);
  }, [props.deleteEntity]);

  const closeEditFolderModal = () => {
    setIsEditDialogOpen(false);
  };

  return (
    <>
      {props.loadingFolders ? (
        <AppSpinner />
      ) : (
        <div className="inventory-folders-container">
          {props.folderList.map(folder => (
            <div key={folder.id}>
              <div
                className="inventory-folder-bar"
                onClick={e => toggleFolder(folder.id, folderOpenId === folder.id ? !isFolderOpen : true)}
              >
                <div className="inventory-folder-bar-title-container">
                  <span className="inventory-folder-name">{folder.name}</span>
                  {folder.tags.includes('isDeletable') && (
                    <div>
                      <button onClick={() => setIsEditDialogOpen(true)} className="delete-folder-button">
                        <img src="../../../content/images/icons/edit-folder.svg" alt="edit icon" />
                      </button>
                      <button onClick={() => setIsDeleteConfirmationDialogOpen(true)} className="delete-folder-button">
                        <img src="../../../content/images/icons/black-trash.svg" alt="delete icon" />
                      </button>
                    </div>
                  )}
                </div>

                <button className="inventory-folder-open-button">
                  {isFolderOpen && folder.id === folderOpenId ? (
                    <img src="../../../content/images/icons/folder-minus.svg" className="close-folde-image" alt="open folder button" />
                  ) : (
                    <img src="../../../content/images/icons/folder-plus.svg" className="open-folde-image" alt="open folder button" />
                  )}
                </button>
              </div>
              <Collapse isOpen={folder.id === folderOpenId}>
                <div className="inventory-cards-container">
                  {folderUserConfigurations.length !== 0 &&
                    folderUserConfigurations.map((item, index) => (
                      <InventoryProductCard
                        selectInventoryCard={props.selectInventoryCard}
                        selectedInventories={props.selectedInventories}
                        isMultipleSelectModeActive={props.isMultipleSelectModeActive}
                        userConfiguration={item}
                        key={item.id}
                      />
                    ))}
                  {!folderUserConfigurations.length && !props.loading && !props.loadingFolders && folderOpenId !== folder.id && (
                    <NoDataBanner title="There are no Inventories!" />
                  )}
                  {(props.loading || props.loadingFolders) && <AppSpinner />}
                </div>
              </Collapse>
            </div>
          ))}
          <Suspense fallback={<span></span>}>
            <ConfirmationDialog
              isOpen={isDeleteConfirmationDialogOpen}
              closeConfirmationDialog={() => setIsDeleteConfirmationDialogOpen(false)}
              confirmationInfo="Are you sure you want to delete this Folder?"
              confirmAction={deleteFolder}
            />
          </Suspense>
        </div>
      )}
    </>
  );
});

const mapStateToProps = state => ({
  userConfigurations: state.userConfiguration.entities,
  loading: state.userConfiguration.loading,
  folderList: state.folder.entities,
  loadingFolders: state.folder.loading,
});

const mapDispatchToProps = {
  deleteEntity,
  getUserConfigurationsByFolderId,
  renameFolder,
  reset,
  getEntities,
  createEntity,
  resetFolders,
};

type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(InventoryFolder);
