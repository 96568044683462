import React, { useState } from 'react';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { Label, Input, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import './marketplaceFormPage.scss';

interface IMarketplaceFormPage extends StateProps, DispatchProps {
  handleSubmit: Function;
  isProjectMarketplaceProcced?: boolean;
}

const MarketplaceFormPage = (props: IMarketplaceFormPage) => {
  const [isUseSameData, setIsUseSameData] = useState<boolean>(false);
  const [emailForSameData, setEmailForSameData] = useState(props.account.email);
  const [streetForSameData, setStreetForSameData] = useState(' ');
  const [zipForSameData, setZipForSameData] = useState(' ');
  const [cityForSameData, setCityForSameData] = useState(' ');
  const [stateForSameData, setStateForSameData] = useState(' ');
  const [countryForSameData, setCountryForSameData] = useState(' ');
  const [deliveryType, setDeliveryType] = useState(null);
  // checkboxes
  const [isAddLiftgate, setIsAddLiftgate] = useState(false);
  const [isRequestSiteVisit, setIsRequestSiteVisit] = useState<boolean>(false);
  const [isElectricalUtilityRequired, setIsElectricalUtilityRequired] = useState<boolean>(false);
  const [isPlumbingConnectionsRequired, setIsPlumbingConnectionsRequired] = useState<boolean>(false);

  const defaultValues = {
    originFirstName: props.account.firstName,
    originLastName: props.account.lastName,
    originEmail: props.account.email,
    originStreetAddress: ' ',
    originZipCode: ' ',
    originCity: ' ',
    originState: ' ',
    originCountry: ' ',
    originPhone: ' ',
    projectName: ' ',
    projectEmail: ' ',
    projectStreetAddress: ' ',
    projectZipCode: ' ',
    projectCity: ' ',
    projectState: ' ',
    projectCountry: ' ',
    addLiftgate: false,
  };

  const handleValidSubmit = (event, values, errors) => {
    props.handleSubmit({ ...values, sameAsAbove: isUseSameData });
  };

  // TODO: Delivery & Installation checkboxes are not completed

  return (
    <div className="marketplace-form-page-container">
      <AvForm className="marketplace-form" onValidSubmit={handleValidSubmit} model={defaultValues}>
        {!props.isProjectMarketplaceProcced && <span className="marketplace-form-block-title">Delivery & Installation</span>}
        {!props.isProjectMarketplaceProcced && (
          <div style={{ paddingLeft: 20 }}>
            <FormGroup className="mt-4">
              <Input
                onChange={() => {
                  setDeliveryType(1);
                  setIsAddLiftgate(false);
                  setIsRequestSiteVisit(false);
                  setIsElectricalUtilityRequired(false);
                  setIsPlumbingConnectionsRequired(false);
                }}
                name="radio"
                type="radio"
              />
              <Label check className="marketplace-radio-label">
                Drop-Ship via Common Carrier
              </Label>
              {deliveryType === 1 && (
                <div className="marketplace-delivery-checkboxes-container">
                  <Input
                    value={isAddLiftgate}
                    onChange={() => setIsAddLiftgate(!isAddLiftgate)}
                    type="checkbox"
                    className="remember-me-checkbox"
                  />
                  <Label check className="marketplace-checkbox-label">
                    Add Liftgate
                  </Label>
                </div>
              )}
            </FormGroup>
            <FormGroup>
              <Input
                onChange={() => {
                  setDeliveryType(2);
                  setIsAddLiftgate(false);
                  setIsRequestSiteVisit(false);
                  setIsElectricalUtilityRequired(false);
                  setIsPlumbingConnectionsRequired(false);
                }}
                name="radio"
                type="radio"
              />
              <Label check className="marketplace-radio-label">
                Store at Warehouse for Scheduled Delivery
              </Label>
            </FormGroup>
            <FormGroup>
              <Input
                onChange={() => {
                  setDeliveryType(3);
                  setIsAddLiftgate(false);
                  setIsRequestSiteVisit(false);
                  setIsElectricalUtilityRequired(false);
                  setIsPlumbingConnectionsRequired(false);
                }}
                name="radio"
                type="radio"
              />
              <Label check className="marketplace-radio-label">
                Standard Installation (Unbox, assemble and set-in-peace)
              </Label>
              {deliveryType === 3 && (
                <div className="marketplace-delivery-checkboxes-container">
                  <Input
                    value={isRequestSiteVisit}
                    onChange={() => {
                      setIsRequestSiteVisit(!isRequestSiteVisit);
                    }}
                    type="checkbox"
                    className="remember-me-checkbox"
                  />
                  <Label check className="marketplace-checkbox-label">
                    Request Site Visit
                  </Label>
                </div>
              )}
            </FormGroup>
            <FormGroup>
              <Input
                onChange={() => {
                  setDeliveryType(4);
                  setIsAddLiftgate(false);
                  setIsRequestSiteVisit(false);
                  setIsElectricalUtilityRequired(false);
                  setIsPlumbingConnectionsRequired(false);
                }}
                name="radio"
                type="radio"
              />
              <Label check className="marketplace-radio-label">
                Turnkey Installation (Unbox, assemble and set-in-peace, make electrical connections within 3ft of existing utilities, make
                plumbing connections within 3ft of existing utilities, equipment setup and ready for use)(site visit required)
              </Label>
              {deliveryType === 4 && (
                <div className="marketplace-delivery-checkboxes-container">
                  <FormGroup>
                    <Input
                      value={isElectricalUtilityRequired}
                      onChange={() => setIsElectricalUtilityRequired(!isElectricalUtilityRequired)}
                      type="checkbox"
                      className="remember-me-checkbox"
                    />
                    <Label check className="marketplace-checkbox-label">
                      New electrical utility from breaker panel required
                    </Label>
                  </FormGroup>
                  <FormGroup>
                    <Input
                      value={isPlumbingConnectionsRequired}
                      onChange={() => setIsPlumbingConnectionsRequired(!isPlumbingConnectionsRequired)}
                      type="checkbox"
                      className="remember-me-checkbox"
                    />
                    <Label check className="marketplace-checkbox-label">
                      New plumbing connections from existing utilities required
                    </Label>
                  </FormGroup>
                </div>
              )}
            </FormGroup>
          </div>
        )}
        <span className="marketplace-form-block-title">User Information</span>

        <div className="marketplace-form-block">
          <div className="marketplace-form-fields-block">
            <div className="marketplace-marketplace-name-field sign-up-name-field">
              <span className="marketplace-form-field-label">First Name</span>
              <AvField
                name="originFirstName"
                type="text"
                className="marketplace-form-field"
                validate={{
                  required: { value: true, errorMessage: 'First Name is required.' },
                  maxLength: { value: 254, errorMessage: 'First Name cannot be longer than 50 characters.' },
                }}
              />
            </div>

            <div className="marketplace-marketplace-name-field sign-up-name-field">
              <span className="marketplace-form-field-label">Last Name</span>
              <AvField
                name="originLastName"
                type="text"
                className="marketplace-form-field"
                validate={{
                  required: { value: true, errorMessage: 'Last Name is required.' },
                  maxLength: { value: 254, errorMessage: 'Last Name cannot be longer than 50 characters.' },
                }}
              />
            </div>
          </div>

          <div className="marketplace-form-fields-block">
            <div className="marketplace-marketplace-name-field sign-up-name-field">
              <span className="marketplace-form-field-label">Email</span>
              <AvField
                name="originEmail"
                type="email"
                onChange={e => setEmailForSameData(e.target.value)}
                className="marketplace-form-field"
                validate={{
                  required: { value: true, errorMessage: 'Last Name is required.' },
                  maxLength: { value: 254, errorMessage: 'Company Name cannot be longer than 50 characters.' },
                }}
              />
            </div>
            <div className="marketplace-marketplace-name-field sign-up-name-field">
              <span className="marketplace-form-field-label">Street Address</span>
              <AvField
                name="originStreetAddress"
                type="text"
                className="marketplace-form-field"
                onChange={e => setStreetForSameData(e.target.value)}
              />
            </div>
          </div>

          <div className="marketplace-form-fields-block">
            <div className="marketplace-marketplace-name-field sign-up-name-field">
              <div className="marketplace-form-field-label">
                Zip Code <span className="marketplace-zip-code-label">*</span>
              </div>
              <AvField
                name="originZipCode"
                type="text"
                className="marketplace-form-field"
                onChange={e => setZipForSameData(e.target.value)}
                validate={{
                  required: { value: true, errorMessage: 'Last Name is required.' },
                  maxLength: { value: 254, errorMessage: 'Company Name cannot be longer than 50 characters.' },
                }}
              />
            </div>
            <div className="marketplace-marketplace-name-field sign-up-name-field">
              <span className="marketplace-form-field-label">City</span>
              <AvField
                name="originCity"
                type="text"
                className="marketplace-form-field"
                onChange={e => setCityForSameData(e.target.value)}
              />
            </div>
          </div>

          <div className="marketplace-form-fields-block">
            <div className="marketplace-marketplace-name-field sign-up-name-field">
              <span className="marketplace-form-field-label">State</span>
              <AvField
                name="originState"
                type="text"
                className="marketplace-form-field"
                onChange={e => setStateForSameData(e.target.value)}
              />
            </div>
            <div className="marketplace-marketplace-name-field sign-up-name-field">
              <span className="marketplace-form-field-label">Country</span>
              <AvField
                name="originCountry"
                type="text"
                className="marketplace-form-field"
                onChange={e => setCountryForSameData(e.target.value)}
              />
            </div>
          </div>

          <div className="marketplace-form-fields-block">
            <div className="marketplace-marketplace-name-field sign-up-name-field">
              <span className="marketplace-form-field-label">Phone</span>
              <AvField name="originPhone" type="text" className="marketplace-form-field" />
            </div>
          </div>
        </div>

        <FormGroup check style={{ marginTop: 20, marginBottom: 40 }}>
          <Input type="checkbox" className="same-as-above-checkbox" onChange={() => setIsUseSameData(!isUseSameData)} />
          <Label check className="same-as-above-label">
            Same as above
          </Label>
        </FormGroup>

        <span className="marketplace-form-block-title">Project Information</span>
        <div className="marketplace-form-block">
          <div className="marketplace-marketplace-name-field sign-up-name-field" style={{ width: '100%' }}>
            <span className="marketplace-form-field-label">Project Name</span>
            <AvField
              name="projectName"
              type="text"
              className="marketplace-form-field"
              validate={{
                required: { value: true, errorMessage: 'First Name is required.' },
                maxLength: { value: 254, errorMessage: 'First Name cannot be longer than 50 characters.' },
              }}
            />
          </div>

          <div className="marketplace-form-fields-block">
            <div className="marketplace-marketplace-name-field sign-up-name-field">
              <span className="marketplace-form-field-label">Email</span>
              <AvField
                name="projectEmail"
                type="email"
                className="marketplace-form-field"
                value={isUseSameData ? emailForSameData : ' '}
                validate={{
                  required: { value: true, errorMessage: 'Last Name is required.' },
                  maxLength: { value: 254, errorMessage: 'Company Name cannot be longer than 50 characters.' },
                }}
              />
            </div>
            <div className="marketplace-marketplace-name-field sign-up-name-field">
              <span className="marketplace-form-field-label">Street Address</span>
              <AvField
                name="projectStreetAddress"
                type="text"
                className="marketplace-form-field"
                value={isUseSameData ? streetForSameData : ' '}
              />
            </div>
          </div>

          <div className="marketplace-form-fields-block">
            <div className="marketplace-marketplace-name-field sign-up-name-field">
              <div className="marketplace-form-field-label">
                Zip Code <span className="marketplace-zip-code-label">*</span>
              </div>
              <AvField
                name="projectZipCode"
                type="text"
                className="marketplace-form-field"
                value={isUseSameData ? zipForSameData : ' '}
                validate={{
                  required: { value: true, errorMessage: 'Last Name is required.' },
                  maxLength: { value: 254, errorMessage: 'Company Name cannot be longer than 50 characters.' },
                }}
              />
            </div>
            <div className="marketplace-marketplace-name-field sign-up-name-field">
              <span className="marketplace-form-field-label">City</span>
              <AvField name="projectCity" type="text" className="marketplace-form-field" value={isUseSameData ? cityForSameData : ' '} />
            </div>
          </div>

          <div className="marketplace-form-fields-block">
            <div className="marketplace-marketplace-name-field sign-up-name-field">
              <span className="marketplace-form-field-label">State</span>
              <AvField name="projectState" type="text" className="marketplace-form-field" value={isUseSameData ? stateForSameData : ' '} />
            </div>
            <div className="marketplace-marketplace-name-field sign-up-name-field">
              <span className="marketplace-form-field-label">Country</span>
              <AvField
                name="projectCountry"
                type="text"
                className="marketplace-form-field"
                value={isUseSameData ? countryForSameData : ' '}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <button className="marketplace-button step-button marketplace-submit-button" type="submit">
              Submit
            </button>
          </div>
        </div>
      </AvForm>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  account: storeState.authentication.account,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MarketplaceFormPage);
