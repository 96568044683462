import { IUser } from 'app/shared/model/user.model';
import { IUserConfiguration } from 'app/shared/model/user-configuration.model';
import { ILeads } from 'app/shared/model/leads.model';

export interface ILeadsProducts {
  id?: number;
  quantity?: number;
  user?: IUser;
  userConfiguration?: IUserConfiguration;
  leads?: ILeads;
}

export const defaultValue: Readonly<ILeadsProducts> = {};
