import React from 'react';
import { useHistory } from 'react-router-dom';
import './dealerRegistrationProcessPages.scss';

const Approval = ({ handleFinish }) => {
  let history = useHistory();

  return (
    // <div className="dealer-registration-information-container">
    <div className="dealer-setup-approval-container">
      <p className="dealer-setup-approval-description">
        Click on button to <br /> send details for Approval by Kreator Staff
      </p>
      <button onClick={handleFinish} className="dealer-setup-submit-button">
        Submit for Approval by Kreator Staff
      </button>
    </div>
    // </div>
  );
};

export default Approval;
