import React, { useEffect } from 'react';
import PricingCard from './PricingCard';
import { Row } from 'reactstrap';
import './pricing.scss';
import { IRootState } from 'app/shared/reducers';
import { getProductList } from 'app/entities/stripe/stripe.reducer';
import { connect } from 'react-redux';

interface IDealerPricing {
  products?: any;
  getProductList?: any;
  setProduct?: any;
  subscriptionDetails?: any;
  product?: any;
}

const DealerPricing = (props: IDealerPricing) => {
  const { products, getProductList, setProduct, subscriptionDetails, product } = props;
  useEffect(() => {
    getProductList();
  }, []);
  return (
    <Row>
      {products && products.length > 0
        ? products.map(stripeProduct => {
            if (stripeProduct.prices[0].billingScheme == 'tiered') {
              return;
            }
            return (
              <PricingCard
                stripeProduct={stripeProduct}
                key={stripeProduct.id}
                isDealerPricingMode={true}
                setProduct={setProduct}
                subscriptionDetails={subscriptionDetails}
                selectedProduct={product}
              />
            );
          })
        : ''}
    </Row>
  );
};

const mapStateToProps = ({ authentication, stripe }: IRootState) => ({
  products: stripe.products,
});

const mapDispatchToProps = {
  getProductList,
};

export default connect(mapStateToProps, mapDispatchToProps)(DealerPricing);
