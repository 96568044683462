import React, { useEffect, useState } from 'react';
import BusinessApprovalTable from '../../components/BusinessApprovalTable/BusinessApproval';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { JhiPagination } from 'react-jhipster';
import { getBusinessApprovals } from 'app/entities/manufacturer/manufacturer.reducer';
import AppSpinner from 'app/components/Spinner/Spinner';
import NoDataBanner from 'app/components/NoDataBanner/NoDataBanner';

export const ManufacturerBusinessApprovals = props => {
  const [activePage, setActivePage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const getAllEntities = () => {
    props.getBusinessApprovals(activePage - 1, itemsPerPage);
  };

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    sortEntities();
  }, [activePage]);

  const handlePagination = currentPage => setActivePage(currentPage);
  const { manufacturerList, totalItems } = props;

  return (
    <>
      <div className="mt-5"></div>
      {props.loading && <AppSpinner />}
      {!props.loading && manufacturerList && manufacturerList.length > 0 && (
        <BusinessApprovalTable members={manufacturerList} type={'manufacturer'} />
      )}
      {!props.loading && manufacturerList && manufacturerList.length > 0 && (
        <>
          {totalItems ? (
            <div className={manufacturerList && manufacturerList.length > itemsPerPage ? 'pagination-container' : 'd-none'}>
              <Row className="justify-content-center">
                <JhiPagination
                  activePage={activePage}
                  onSelect={handlePagination}
                  maxButtons={5}
                  itemsPerPage={itemsPerPage}
                  totalItems={props.totalItems}
                />
              </Row>
            </div>
          ) : (
            ''
          )}
        </>
      )}
      {!props.loading && manufacturerList && manufacturerList.length === 0 && (
        <NoDataBanner title="You have no Business approvals to display yet!" />
      )}
    </>
  );
};

const mapStateToProps = ({ manufacturer, authentication }: IRootState) => ({
  manufacturerList: manufacturer.entities,
  loading: manufacturer.loading,
  totalItems: manufacturer.totalItems,
  account: authentication.account,
});

const mapDispatchToProps = {
  getBusinessApprovals,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManufacturerBusinessApprovals);
