import React from 'react';
import { useHistory } from 'react-router-dom';

const AccountSetupBanner = () => {
  let history = useHistory();

  const goToAccountSetup = () => {
    history.push('/account/settings');
  };
  return (
    <div className="dealer-registration-banner">
      <span className="dealer-registration-banner-content">
        Your account setup is incomplete. You need to complete your User Profile.
        <button onClick={goToAccountSetup} className="dealer-registration-banner-redirect-button">
          Click here
        </button>
        to finish setting up your account.
      </span>
    </div>
  );
};

export default AccountSetupBanner;
