import { useState, useEffect } from 'react';

export const useFolderUserConfigurations = (userConfigurations, searchValue) => {
  const [folderUserConfigurations, setFolderUserConfigurations] = useState(userConfigurations);

  useEffect(() => {
    const filteredArray = userConfigurations.filter(item => item.productName?.toLowerCase().includes(searchValue.toLowerCase()));
    setFolderUserConfigurations(filteredArray);
  }, [searchValue, userConfigurations]);

  return folderUserConfigurations;
};
