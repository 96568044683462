import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import ProductItem from './ProductItem';
import './marketplaceCartItem.scss';

const MarketplaceCartItem = props => {
  const [isItemOpen, setIsItemOpen] = useState<boolean>(false);

  return (
    <>
      <div className="marketplace-cart-item" onClick={() => setIsItemOpen(!isItemOpen)}>
        <div className="cart-product-image-container" style={{ background: 'transparent' }}>
          <img src={props.item.logo} alt="logo" className="marketplace-cart-item-logo" />
        </div>
        <div className="cart-product-name-container">
          <span className="marketplace-cart-item-name">{props.item.name}</span>
        </div>
        <div className="cart-product-data-container">
          <span className="marketplace-cart-item-location">{props.item.city + ' ' + props.item.state}</span>
        </div>

        {!isItemOpen && (
          <img src="../../../content/images/icons/marketplace-cart-arrow-down.svg" alt="button" className="marketplace-cart-item-arrow" />
        )}
        {isItemOpen && (
          <img src="../../../content/images/icons/marketplace-cart-arrow-up.svg" alt="button" className="marketplace-cart-item-arrow" />
        )}
      </div>
      <Collapse isOpen={isItemOpen} className="cart-item-collapse" fluid>
        <div className="marketplace-cart-collapse-container">
          {props.item.userConfigurations.map(configuration => (
            <ProductItem
              item={configuration}
              dealerId={props.item.id}
              changeProductCount={props.changeProductCount}
              key={configuration.id}
            />
          ))}
        </div>
      </Collapse>
    </>
  );
};

export default MarketplaceCartItem;
