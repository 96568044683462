import React, { useState } from 'react';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { Nav, NavLink, Button } from 'reactstrap';
import { adminSubmenus, entitiesSubmenus } from './submenus';
import NavDropdown from 'app/shared/layout/menus/SubMenuDropdown';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import './navbar.scss';
import audits from 'app/modules/administration/audits/audits';

export interface INavBarProps extends StateProps {
  account: any;
  isAuthenticated: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isSwaggerEnabled: boolean;
  closeBurgerMenu?: Function;
  projectOpened: Boolean;
  notificationsCount: any;
}

const NavbarResponsive = (props: INavBarProps) => {
  let location = useLocation();
  const [isEntitiesDropdownOpen, setIsEntitiesDropdownOpen] = useState(false);
  const [isAdminDropdownOpen, setIsAdminDropdownOpen] = useState(false);

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">Development</a>
      </div>
    ) : null;
  return (
    <div className="navbar-container">
      {renderDevRibbon()}
      <div className="navbar_logo-container">
        <Button className="close-burger-button" onClick={props.closeBurgerMenu}>
          <img src="../../../../content/images/icons/closeCardIcon.svg" alt="" />
        </Button>
        <img src="../../../../content/images/kreator-navbar-logo.png" alt="Logo" width="110" />
      </div>
      <Nav vertical onClick={props.closeBurgerMenu}>
        {hasAnyAuthority(props.account.authorities, [
          AUTHORITIES.ADMIN,
          AUTHORITIES.USER,
          AUTHORITIES.ROLE_CONSULTANT_USER,
          AUTHORITIES.ROLE_MANUFACTURER_USER,
          AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
        ]) && (
          <NavLink
            className={
              (props.projectOpened && location.pathname.includes('configurators')) ||
              location.pathname.includes('create-project') ||
              location.pathname.includes('edit-project')
                ? 'navbar-item--active navbar-item'
                : 'navbar-item'
            }
            activeClassName="navbar-item--active"
            tag={Link}
            to="/room-designer"
          >
            <img src="../../../../content/images/icons/pen-tool.svg" className="navbar-item-icon" alt="Logo" width="25" height="25" />
            <span id="room-designer">Project Designer</span>
          </NavLink>
        )}

        {hasAnyAuthority(props.account.authorities, [
          AUTHORITIES.ADMIN,
          AUTHORITIES.USER,
          AUTHORITIES.ROLE_CONSULTANT_USER,
          AUTHORITIES.ROLE_MANUFACTURER_USER,
          AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
        ]) && (
          <NavLink
            className={
              location.pathname.includes('configurators') && !props.projectOpened ? 'navbar-item--active navbar-item' : 'navbar-item'
            }
            activeClassName="navbar-item--active"
            exact
            tag={Link}
            to="/"
          >
            <img
              src="../../../../content/images/icons/product_configurators.svg"
              className="navbar-item-icon"
              alt="Logo"
              width="25"
              height="25"
            />
            <span id="configurators">Configurators</span>
          </NavLink>
        )}

        {hasAnyAuthority(props.account.authorities, [
          AUTHORITIES.ADMIN,
          AUTHORITIES.USER,
          AUTHORITIES.ROLE_CONSULTANT_USER,
          AUTHORITIES.ROLE_MANUFACTURER_USER,
          AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
        ]) && (
          <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/my-inventory">
            {props.notificationsCount?.userConfiguration > 0 && props.notificationsCount?.project > 0 && (
              <div className="online-status notifications-status unread-inventories-status">
                {props.notificationsCount?.userConfiguration + props.notificationsCount?.project > 99
                  ? '99+'
                  : props.notificationsCount?.userConfiguration + props.notificationsCount?.project}
              </div>
            )}
            <img src="../../../../content/images/icons/light.svg" className="navbar-item-icon" alt="Logo" width="25" height="25" />
            <span id="my-inventory">My Inventory</span>
          </NavLink>
        )}
        {hasAnyAuthority(props.account.authorities, [
          AUTHORITIES.ADMIN,
          AUTHORITIES.USER,
          AUTHORITIES.ROLE_DEALER_ADMIN,
          AUTHORITIES.ROLE_DEALER_USER,
          AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_USER,
          AUTHORITIES.ROLE_CONSULTANT_ADMIN,
          AUTHORITIES.ROLE_CONSULTANT_USER,
          AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
          AUTHORITIES.ROLE_SUPER_ADMIN,
        ]) && (
          <NavLink
            className={'navbar-item'}
            activeClassName="navbar-item--active"
            tag={Link}
            to="/version/all?page=1&sort=releaseDate,desc"
          >
            <img src="../../../../content/images/icons/download.svg" className="navbar-item-icon" alt="Logo" width="25" height="25" />
            <span id="my-inventory">Download Revit Add-in</span>
          </NavLink>
        )}

        {/* do not delete!!! needs to be uncomment after feature release */}

        {/* {hasAnyAuthority(props.account.authorities, [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_DEALER_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
          AUTHORITIES.ROLE_CONSULTANT_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN,
        ]) && (
          <>
            <div className="divider"></div>
            <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/dashboard">
              <img
                src="../../../../content/images/icons/Dashboard_active.svg"
                className="navbar-item-icon"
                alt="Logo"
                width="25"
                height="25"
              />
              <span id="dashboard">Dashboard</span>
            </NavLink>
          </>
        )} */}

        {/* {hasAnyAuthority(props.account.authorities, [
          AUTHORITIES.ADMIN,
          AUTHORITIES.USER,
          AUTHORITIES.ROLE_DEALER_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
          AUTHORITIES.ROLE_CONSULTANT_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN,
        ]) &&
          !hasAnyAuthority(props.account.authorities, [AUTHORITIES.ROLE_CORONET_DRAWING]) && (
            <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/my-leads">
              <img src="../../../../content/images/icons/my_leads.svg" className="navbar-item-icon" alt="Logo" width="25" height="25" />
              {hasAnyAuthority(props.account.authorities, [AUTHORITIES.USER]) ? (
                <span id="my-leads">My Leads</span>
              ) : (
                <span id="my-leads">My Quotes</span>
              )}
            </NavLink>
          )} */}

        {hasAnyAuthority(props.account.authorities, [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_DEALER_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
          AUTHORITIES.ROLE_CONSULTANT_ADMIN,
        ]) && (
          <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/my-configurators">
            <img
              src="../../../../content/images/icons/product_configurators.svg"
              className="navbar-item-icon"
              alt="Logo"
              width="25"
              height="25"
            />
            <span id="my-leads">Product Configurators</span>
          </NavLink>
        )}

        {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN]) && (
          <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/my-dealer">
            <img src="../../../../content/images/icons/email_settings.svg" className="navbar-item-icon" alt="Logo" width="25" height="25" />
            <span id="email-settings">My Dealer</span>
          </NavLink>
        )}

        {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN]) && (
          <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/my-manufacturer">
            <img src="../../../../content/images/icons/email_settings.svg" className="navbar-item-icon" alt="Logo" width="25" height="25" />
            <span id="email-settings">My Manufacturer</span>
          </NavLink>
        )}

        {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN]) && (
          <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/my-consultant-admin">
            <img src="../../../../content/images/icons/email_settings.svg" className="navbar-item-icon" alt="Logo" width="25" height="25" />
            <span id="email-settings">My Consultant</span>
          </NavLink>
        )}

        {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN]) && (
          <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/my-manufacturer-ref">
            <img src="../../../../content/images/icons/email_settings.svg" className="navbar-item-icon" alt="Logo" width="25" height="25" />
            <span id="email-settings">My Manufacturer Rep</span>
          </NavLink>
        )}

        {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN]) && (
          <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/email-settings">
            <img src="../../../../content/images/icons/email_settings.svg" className="navbar-item-icon" alt="Logo" width="25" height="25" />
            <span id="email-settings">Email Settings</span>
          </NavLink>
        )}

        <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/find-manufacturer-rep">
          <img
            src="../../../../content/images/icons/find-mfr-reps-icon.svg"
            className="navbar-item-icon"
            alt="Logo"
            width="25"
            height="25"
          />
          <span id="email-settings">Find Manufacturer Reps</span>
        </NavLink>

        {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_MANAGER]) && (
          <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/business-approvals">
            <img
              src="../../../../content/images/icons/business-approvals-icon.svg"
              className="navbar-item-icon"
              alt="Logo"
              width="25"
              height="25"
            />
            <span id="email-settings">Business Approvals</span>
          </NavLink>
        )}

        {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_MANAGER]) && (
          <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/payments">
            <img src="../../../../content/images/icons/payments-icon.svg" className="navbar-item-icon" alt="Logo" width="25" height="25" />
            <span id="email-settings">Payments</span>
          </NavLink>
        )}

        {hasAnyAuthority(props.account.authorities, [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_DEALER_USER,
          AUTHORITIES.ROLE_CONSULTANT_USER,
          AUTHORITIES.ROLE_MANUFACTURER_USER,
          AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
          AUTHORITIES.ROLE_PROJECT_DESIGNER,
        ]) && (
          <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/project-submission">
            <img
              src="../../../../content/images/icons/project-submission-icon.svg"
              className="navbar-item-icon"
              alt="Logo"
              width="25"
              height="25"
            />
            <span id="email-settings">Project Submission</span>
          </NavLink>
        )}

        {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_DEALER_ADMIN]) && (
          <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/instant-pricing">
            <img
              src="../../../../content/images/icons/instant-pricing-icon.svg"
              className="navbar-item-icon"
              alt="Logo"
              width="25"
              height="25"
            />
            <span id="email-settings">Instant Pricing</span>
          </NavLink>
        )}

        {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN]) && (
          <>
            <div className="divider"></div>
            <NavDropdown
              icon={'network'}
              isDropdownOpen={isEntitiesDropdownOpen}
              setIsDropdownOpen={setIsEntitiesDropdownOpen}
              title={'Entities'}
              iconClass="navbar-item-icon"
            >
              {entitiesSubmenus.map(item => {
                return (
                  <NavLink className={'navbar-subitem'} activeClassName="navbar-item--active" tag={Link} to={item.route} key={item.title}>
                    <span>{item.title}</span>
                  </NavLink>
                );
              })}
            </NavDropdown>
          </>
        )}

        {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SUPER_ADMIN]) && (
          <NavDropdown
            icon={'admin'}
            isDropdownOpen={isAdminDropdownOpen}
            setIsDropdownOpen={setIsAdminDropdownOpen}
            title={'Administration'}
            iconClass="navbar-item-icon"
          >
            {adminSubmenus.map(item => {
              return (
                <NavLink className={'navbar-subitem'} activeClassName="navbar-item--active" tag={Link} to={item.route} key={item.title}>
                  <span>{item.title}</span>
                </NavLink>
              );
            })}
            {props.isSwaggerEnabled && (
              <NavLink className={'navbar-subitem'} activeClassName="navbar-item--active" tag={Link} to="/admin/docs">
                API
              </NavLink>
            )}
          </NavDropdown>
        )}
      </Nav>
    </div>
  );
};

const mapStateToProps = ({ notification }: IRootState) => ({
  notificationsList: notification.notifications,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(NavbarResponsive);
