import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ILeads, defaultValue } from 'app/shared/model/leads.model';

export const ACTION_TYPES = {
  FETCH_LEADS_LIST: 'leads/FETCH_LEADS_LIST',
  FETCH_LEADS: 'leads/FETCH_LEADS',
  CREATE_LEADS: 'leads/CREATE_LEADS',
  UPDATE_LEADS: 'leads/UPDATE_LEADS',
  DELETE_LEADS: 'leads/DELETE_LEADS',
  RESET: 'leads/RESET',
  CREATE_LEADS_CART: 'leads/CREATE_LEADS_CART',
  RESET_MARKETPLACE_FORM: 'leads/RESET_MARKETPLACE_FORM',
  CLEAR_REQUESTED_LEAD: 'CLEAR_REQUESTED_LEAD',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ILeads>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  marketplaceFormSendingSuccess: false,
};

export type LeadsState = Readonly<typeof initialState>;

// Reducer

export default (state: LeadsState = initialState, action): LeadsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_LEADS_LIST):
    case REQUEST(ACTION_TYPES.FETCH_LEADS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_LEADS):
    case REQUEST(ACTION_TYPES.UPDATE_LEADS):
    case REQUEST(ACTION_TYPES.DELETE_LEADS):
    case REQUEST(ACTION_TYPES.CREATE_LEADS_CART):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_LEADS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_LEADS):
    case FAILURE(ACTION_TYPES.CREATE_LEADS):
    case FAILURE(ACTION_TYPES.UPDATE_LEADS):
    case FAILURE(ACTION_TYPES.DELETE_LEADS):
    case FAILURE(ACTION_TYPES.CREATE_LEADS_CART):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_LEADS_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_LEADS):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_LEADS):
    case SUCCESS(ACTION_TYPES.UPDATE_LEADS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_LEADS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case SUCCESS(ACTION_TYPES.CREATE_LEADS_CART):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        marketplaceFormSendingSuccess: true,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.RESET_MARKETPLACE_FORM:
      return {
        ...state,
        marketplaceFormSendingSuccess: false,
      };
    case ACTION_TYPES.CLEAR_REQUESTED_LEAD:
      return {
        ...state,
        entity: null,
      };

    default:
      return state;
  }
};

const apiUrl = 'api/leads';

// Actions

export const getEntities: ICrudGetAllAction<ILeads> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_LEADS_LIST,
    payload: axios.get<ILeads>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getBusinessLeads: ICrudGetAllAction<ILeads> = (page, size, sort) => {
  const requestUrl = `${apiUrl}/business-leads${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_LEADS_LIST,
    payload: axios.get<ILeads>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<ILeads> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_LEADS,
    payload: axios.get<ILeads>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<ILeads> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_LEADS,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ILeads> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_LEADS,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ILeads> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_LEADS,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const clearRequestedLead = () => ({
  type: ACTION_TYPES.CLEAR_REQUESTED_LEAD,
});

export const resetMarketplaceForm = () => ({
  type: ACTION_TYPES.RESET_MARKETPLACE_FORM,
});

export const createLeadsCart = data => {
  const apiUrl = 'api/marketplace/leads-cart';
  return {
    type: ACTION_TYPES.CREATE_LEADS_CART,
    payload: axios.post(apiUrl, data),
  };
};
