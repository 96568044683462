import React from 'react';

interface IEnabledProductButton {
  onClick: any;
  title: string;
}

const EnabledProductButton = (props: IEnabledProductButton) => {
  return (
    <button onClick={props.onClick} className="enabled-product-button">
      {props.title}
    </button>
  );
};

export default EnabledProductButton;
