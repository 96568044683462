// @ts-nocheck

import { createEntity, getEntity, reset, updateEntity } from 'app/entities/user-configuration/user-configuration.reducer';
import { IRootState } from 'app/shared/reducers';
import Roomle from 'app/shared/util/roomle';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

export interface IRoomleProductUpdateProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ user_configuration_id: string; mailsha1: string }> {}

export const RoomleProductUpdate = (props: IRoomleProductUpdateProps) => {
  const handleClose = () => {
    props.history.push('/configurators/' + props.match.params.mailsha1);
  };

  useEffect(() => {
    props.getEntity(props.match.params.user_configuration_id);
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      props.reset();
      handleClose();
    }
  }, [props.updateSuccess]);

  const getTenant = configId => {
    if (configId == null) return '';
    else return configId.split(':')[0];
  };

  const saveConfiguration = roomleConfiguration => {
    if (roomleConfiguration === null || props.userConfigurationEntity === null) return;

    const parsedJson = JSON.parse(roomleConfiguration.configuration);
    const partsJson = JSON.parse(roomleConfiguration.parts);

    const childrenElements = parsedJson.children;

    const components = [];
    if (childrenElements != undefined) {
      childrenElements.map(element => {
        const positionString: string = element.dockPosition;
        let position =
          positionString &&
          positionString
            .replace('{', '')
            .replace('}', '')
            .split(',')
            .map(p => {
              return parseFloat(p);
            });

        if (position.length !== 3) {
          position = [0.0, 0.0, 0.0];
        }

        components.push({
          filename:
            'QF_' +
            props.userConfigurationEntity.manufacturer +
            '_' +
            props.userConfigurationEntity.productName +
            '_' +
            props.userConfigurationEntity.modelNumber +
            '.rfa',
          offsetX: position[0],
          offsetY: position[1],
          offsetZ: position[2],
          rotationAngle: 0,
          baseFamily: element?.parameters?.baseFamily || '',
          typecatalog: element?.parameters?.typecatalog || '',
          typecatalogheader: element?.parameters?.typecatalogheader || '',
        });
      });
    }

    const composite = {
      components,
    };

    const configSize =
      parsedJson.parameters && parsedJson.parameters.width && parsedJson.parameters.length
        ? `${parsedJson.parameters.width}mm x ${parsedJson.parameters.length}mm`
        : '';
    const configSizeInch =
      parsedJson.parameters && parsedJson.parameters.width && parsedJson.parameters.length
        ? `${(parsedJson.parameters.width / 25.4).toFixed(2)}" x ${(parsedJson.parameters.length / 25.4).toFixed(2)}"`
        : '';
    const typecatalog = parsedJson.parameters ? parsedJson.parameters.typecatalog : '';
    const typecatalogheader = parsedJson.parameters ? parsedJson.parameters.typecatalogheader : '';
    const baseFamily = parsedJson.parameters ? parsedJson.parameters.baseFamily : '';
    const parameters = parsedJson.parameters ? `${parsedJson.parameters.parameterlabel}\n${parsedJson.parameters.parameterlabelvalue}` : '';

    const newEntity = {
      modelNumber: partsJson.originPart.articleNr || '',
      thumbnailImage: roomleConfiguration.perspectiveImage,
      configSize,
      configSizeInch,
      userHash: props.match.params.mailsha1,
      typecatalog,
      typecatalogheader,
      baseFamily,
      configuration: roomleConfiguration.configurationHash,
      parameters,
      composite,
    };

    const entity = {
      ...props.userConfigurationEntity,
      ...newEntity,
    };

    props.updateEntity(entity);
  };

  const { loading } = props;

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <h2 style={{ margin: '10px' }}>Reconfigure Product</h2>
          <Roomle
            handleFinishLoading={null}
            configurationId={props.userConfigurationEntity.configuration}
            clientId={getTenant(props.userConfigurationEntity.configuration)}
            saveConfiguration={saveConfiguration}
            saveButton={true}
            isInProduction={props.isInProduction}
            manufacturerName={props.userConfigurationEntity.manufacturerName}
            isSideBarCollapsed={props.isSideBarCollapsed}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  userConfigurationEntity: storeState.userConfiguration.entity,
  loading: storeState.userConfiguration.loading,
  updating: storeState.userConfiguration.updating,
  updateSuccess: storeState.userConfiguration.updateSuccess,
  isInProduction: storeState.applicationProfile.inProduction,
  isSideBarCollapsed: storeState.userSettings.isSidebarCollapsed,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RoomleProductUpdate);
