import React, { useState } from 'react';
import { Modal } from 'reactstrap';
import axios, { Method } from 'axios';
import './krowneBarFlexForm.scss';

interface IKrowneBarFlexFormModal {
  isOpen: boolean;
  closeModal: Function;
  roomleConfiguration: any;
}

const KrowneBarFlexFormModal = (props: IKrowneBarFlexFormModal) => {
  const defaultFormData = {
    name: '',
    company: '',
    equipmentDealer: '',
    email: '',
    phone: '',
    projectName: '',
    projectZip: '',
    occupation: 'Owner',
    followup: 'Yes',
    comments: '',
  };
  const [formData, setFormData] = useState(defaultFormData);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (formData.name && formData.company && formData.equipmentDealer && formData.email && formData.phone) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    sendDataToBackend(formData, props.roomleConfiguration);

    const fileInput = document.getElementById('upload-file') as HTMLInputElement;

    if (fileInput.files !== undefined && fileInput.files.length > 0) {
      // @ts-ignore
      uploadFile(fileInput.files[0], formDataValues);
    }
    setFormData(defaultFormData);
    props.closeModal();
  };

  const sendDataToBackend = (formData, configurator) => {
    let data = JSON.stringify({
      formData: formData,
      configurator: configurator,
    });

    const config = {
      method: 'POST' as Method,
      url: 'https://kreator-krowne-email-api.azurewebsites.net/sendmail',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: data,
    };

    axios(config);
  };

  const uploadFile = (file, formDataValues) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('configName', 'BeerFlex');
    formData.append('projectName', formDataValues.project);
    formData.append('userName', formDataValues.name);

    const config = {
      method: 'POST' as Method,
      url: 'https://kreator-krowne-email-api.azurewebsites.net/upload',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    };

    const kreatorConfig = {
      method: 'POST' as Method,
      url: 'https://kreator.app/api/external/generateFileUploadSignedLink',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    };

    axios(config);
    axios(kreatorConfig);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      centered
      fade={false}
      toggle={() => props.closeModal(false)}
      backdrop={true}
      style={
        window.screen.width >= 1324
          ? { maxWidth: window.screen.width - 820, width: '100%' }
          : { maxWidth: '95%', width: '95%', margin: 'auto' }
      }
    >
      <form onSubmit={handleSubmit} className="barflex-form">
        <div className="barflex-form-group">
          <label htmlFor="form-client-name">
            Name <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="form-client-name"
            placeholder="Enter Your Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="barflex-form-group">
          <label htmlFor="form-client-company">
            Company <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="form-client-company"
            placeholder="Enter Your Company Name"
            name="company"
            value={formData.company}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="barflex-form-group">
          <label htmlFor="form-client-equipment-dealer">
            Equipment Dealer <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="form-client-equipment-dealer"
            placeholder="Enter your Equipment Dealer"
            name="equipmentDealer"
            value={formData.equipmentDealer}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="barflex-form-group">
          <label htmlFor="form-client-email">
            Email <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="email"
            className="form-control"
            id="form-client-email"
            placeholder="Enter Your Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="barflex-form-group">
          <label htmlFor="form-client-phone">
            Phone Number <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="tel"
            className="form-control"
            id="form-client-phone"
            placeholder="Enter Phone Number"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="barflex-form-group">
          <label htmlFor="form-project">Project Name:</label>
          <input
            type="text"
            className="form-control"
            id="form-project"
            placeholder="Enter Your Job Name"
            name="projectName"
            value={formData.projectName}
            onChange={handleInputChange}
          />
        </div>
        <div className="barflex-form-group">
          <label htmlFor="form-project-zip">Project Zip Code:</label>
          <input
            type="text"
            className="form-control"
            id="form-project-zip"
            placeholder="Enter Your Project Zip Code"
            name="projectZip"
            value={formData.projectZip}
            onChange={handleInputChange}
          />
        </div>
        <div className="barflex-form-group">
          <label htmlFor="form-occupation">Occupation:</label>
          <select className="form-control" id="form-occupation" name="occupation" value={formData.occupation} onChange={handleInputChange}>
            <option value="Owner">Owner</option>
            <option value="Dealer">Dealer</option>
            <option value="Consultant">Consultant</option>
            <option value="Mfr Rep">Mfr Rep</option>
            <option value="Architect">Architect</option>
            <option value="Bartender">Bartender</option>
          </select>
        </div>
        <div className="barflex-form-group">
          <label htmlFor="form-followup">Would you like someone from Krowne to follow up with you on this request?:</label>
          <select className="form-control" id="form-followup" name="followup" value={formData.followup} onChange={handleInputChange}>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div className="barflex-form-group">
          <label htmlFor="form-comments">Comments:</label>
          <input
            type="text"
            className="form-control"
            id="form-comments"
            placeholder="Enter Your Comments"
            name="comments"
            value={formData.comments}
            onChange={handleInputChange}
          />
        </div>
        <div className="barflex-form-group">
          <label htmlFor="upload-file">Upload Project File:</label>
          <input
            style={{ textAlign: 'right' }}
            type="file"
            className="form-control"
            id="upload-file"
            name="uploadFile"
            onChange={handleInputChange}
          />
        </div>
        <div className="submit submit-button-container" style={{ textAlign: 'center' }}>
          <button type="submit" className="btn btn-default submit-data" disabled={!isFormValid}>
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default KrowneBarFlexFormModal;
