import React from 'react';
import { Modal } from 'reactstrap';
import MarketPlaceDealerCard from 'app/components/MarketPlaceDealerCard/MarketPlaceDealerCard';
import './projectMarketplace.scss';
import { useState } from 'react';

interface IDealersModal {
  isOpen: boolean;
  closeModal: Function;
  dealers: any;
  selectedDealers: any;
  setSelectedDealers: Function;
  selectEntity: Function;
}

const DealersModal = (props: IDealersModal) => {
  return (
    <Modal
      isOpen={props.isOpen}
      centered
      style={{ maxWidth: 1400, width: window.screen.width - 200 }}
      toggle={props.closeModal}
      backdrop={true}
    >
      <div className="marketplace-delivery-details-modal-header">
        <span className="marketplace-form-block-title marketplace-delivery-details-modal-title">Select Dealer(s)</span>
        <img
          src="../../../content/images/icons/closeModalIcon.svg"
          className="marketplace-delivery-details-modal-close-icon"
          onClick={() => props.closeModal()}
        />
      </div>
      <div className="project-marketplace-dealers-modal-container">
        {props.dealers.map(item => (
          <MarketPlaceDealerCard
            dealer={item}
            key={item.id}
            onSelect={props.selectEntity}
            selectedDealers={props.selectedDealers}
            setSelectedDealers={props.setSelectedDealers}
            openDetailsModal={() => console.log('test')}
          />
        ))}
      </div>
    </Modal>
  );
};

export default DealersModal;
