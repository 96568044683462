import { IRootState } from 'app/shared/reducers';
import { logout } from 'app/shared/reducers/authentication';
import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { deleteNotificationsToken } from 'app/entities/user-settings/user-settings.reducer';
import { useEffect } from 'react';
import { AUTHORITIES } from 'app/config/constants';

export interface ILogoutProps extends StateProps, DispatchProps {
  idToken: string;
  logoutUrl: string;
  deleteNotificationsToken: any;
}

export const Logout = (props: ILogoutProps) => {
  useLayoutEffect(() => {
    props.deleteNotificationsToken(props.userNotificationToken);
  });

  useEffect(() => {
    if (!props.userNotificationToken) {
      props.logout();
      window.location.href = '/login';
      sessionStorage.removeItem(AUTHORITIES.ROLE_SUPER_ADMIN);
    }
  }, [props.userNotificationToken]);

  return null;
};

const mapStateToProps = (storeState: IRootState) => ({
  logoutUrl: storeState.authentication.logoutUrl,
  idToken: storeState.authentication.idToken,
  userNotificationToken: storeState.userSettings.userNotificationToken,
});

const mapDispatchToProps = { logout, deleteNotificationsToken };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
