export interface ICountry {
  id?: number;
  country?: string;
  state?: string;
  city?: string;
  region?: string;
  zip?: string;
}

export const defaultValue: Readonly<ICountry> = {};
