import React from 'react';
import { Modal } from 'reactstrap';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import './exportRevitModal.scss';

const ForbiddenExportModal = props => {
  return (
    <Modal
      isOpen={props.isOpen}
      centered
      style={{ maxWidth: 800, width: window.screen.width - 1000 }}
      toggle={() => props.closeModal()}
      backdrop={true}
    >
      <div className="export-revit-modal-actions-block">
        <span className="export-revit-modal-or" style={{ marginBottom: 20 }}>
          Revit is not currently available for this Product
        </span>
        <div className="d-flex justify-content-center" style={{ width: '40%' }}>
          <ConfirmButton title="Ok" confirmAction={props.closeModal} />
        </div>
      </div>
    </Modal>
  );
};

export default ForbiddenExportModal;
