import { Moment } from 'moment';
import { VersionType } from 'app/shared/model/enumerations/version-type.model';
import { ReleaseType } from 'app/shared/model/enumerations/release-type.model';

export interface IVersion {
  id?: number;
  type?: VersionType;
  name?: string;
  versionNumber?: string;
  fileContentType?: string;
  file?: any;
  filePath?: string;
  releaseNotes?: string;
  releaseDate?: string;
  fileName?: string;
  fileHasChanged?: boolean;
  releaseType?: ReleaseType;
}

export const defaultValue: Readonly<IVersion> = {
  versionNumber: '',
  releaseNotes: '',
};
