import React from 'react';
import { connect } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { DEALER_STATUSES, DEALER_STATUSES_TRANSLATION } from 'app/config/constants';
import { updateEntity as updateDealer } from 'app/entities/dealer/dealer.reducer';
import { updateEntity as updateManufacturer } from 'app/entities/manufacturer/manufacturer.reducer';
import { updateEntity as updateConsultant } from 'app/entities/consultant/consultant.reducer';
import { updateEntity as updateManufacturerRef } from 'app/entities/manufacturer-ref/manufacturer-ref.reducer';
import './BusinessApprovalStatus.scss';

const BusinessApprovalStatusSelect = props => {
  const statusesColors = {
    IN_PROGRESS: 'yellow',
    APPROVED: 'green',
    REJECTED: 'red',
    UPDATE_REQUESTED: 'orange',
  };

  const updateLeadStatus = (status: string) => {
    const updatedLead = {
      ...props.member,
      status,
    };

    if (props.type == 'manufacturer') {
      props.updateManufacturer(updatedLead);
    } else if (props.type == 'dealer') {
      props.updateDealer(updatedLead);
    } else if (props.type == 'consultant') {
      props.updateConsultant(updatedLead);
    } else if (props.type == 'manufacturerRef') {
      props.updateManufacturerRef(updatedLead);
    }
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle nav className="lead-status-select-toggle">
        <span className={statusesColors[props.member.status]}>{DEALER_STATUSES_TRANSLATION[props.member.status]}</span>
        <img src="../../../content/images/icons/arrow_down.svg" alt="icon-down" className="drop-down-icon" />
      </DropdownToggle>
      <DropdownMenu className="lead-status-dropdown">
        {DEALER_STATUSES.map(status => (
          <DropdownItem key={status.value} className="lead-status-dropdown-item">
            <span onClick={() => updateLeadStatus(status.value)} className={statusesColors[status.value]}>
              {status.title}
            </span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const mapDispatchToProps = {
  updateDealer,
  updateManufacturer,
  updateConsultant,
  updateManufacturerRef,
};

type DispatchProps = typeof mapDispatchToProps;

export default connect(null, mapDispatchToProps)(BusinessApprovalStatusSelect);
