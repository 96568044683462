import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './manufacturer-ref-data.reducer';
import { IManufacturerRefData } from 'app/shared/model/manufacturer-ref-data.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IManufacturerRefDataUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IManufacturerRefDataUpdateState {
  isNew: boolean;
}

export class ManufacturerRefDataUpdate extends React.Component<IManufacturerRefDataUpdateProps, IManufacturerRefDataUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { manufacturerRefDataEntity } = this.props;
      const entity = {
        ...manufacturerRefDataEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/manufacturer-ref-data');
  };

  render() {
    const { manufacturerRefDataEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="familyBuilderApiApp.manufacturerRefData.home.createOrEditLabel">Create or edit a ManufacturerRefData</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : manufacturerRefDataEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="manufacturer-ref-data-id">ID</Label>
                    <AvInput id="manufacturer-ref-data-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="companyLabel" for="manufacturer-ref-data-company">
                    Company
                  </Label>
                  <AvField id="manufacturer-ref-data-company" type="text" name="company" />
                </AvGroup>
                <AvGroup>
                  <Label id="industryTypeLabel" for="manufacturer-ref-data-industryType">
                    Industry Type
                  </Label>
                  <AvField id="manufacturer-ref-data-industryType" type="text" name="industryType" />
                </AvGroup>
                <AvGroup>
                  <Label id="companyTypeLabel" for="manufacturer-ref-data-companyType">
                    Company Type
                  </Label>
                  <AvField id="manufacturer-ref-data-companyType" type="text" name="companyType" />
                </AvGroup>
                <AvGroup>
                  <Label id="specPathIdLabel" for="manufacturer-ref-data-specPathId">
                    Spec Path Id
                  </Label>
                  <AvField id="manufacturer-ref-data-specPathId" type="string" className="form-control" name="specPathId" />
                </AvGroup>
                <AvGroup>
                  <Label id="specPathUserIdLabel" for="manufacturer-ref-data-specPathUserId">
                    Spec Path User Id
                  </Label>
                  <AvField id="manufacturer-ref-data-specPathUserId" type="text" name="specPathUserId" />
                </AvGroup>
                <AvGroup>
                  <Label id="mailingAddressLabel" for="manufacturer-ref-data-mailingAddress">
                    Mailing Address
                  </Label>
                  <AvField id="manufacturer-ref-data-mailingAddress" type="text" name="mailingAddress" />
                </AvGroup>
                <AvGroup>
                  <Label id="websiteLabel" for="manufacturer-ref-data-website">
                    Website
                  </Label>
                  <AvField id="manufacturer-ref-data-website" type="text" name="website" />
                </AvGroup>
                <AvGroup>
                  <Label id="emailLabel" for="manufacturer-ref-data-email">
                    Email
                  </Label>
                  <AvField id="manufacturer-ref-data-email" type="text" name="email" />
                </AvGroup>
                <AvGroup>
                  <Label id="phoneLabel" for="manufacturer-ref-data-phone">
                    Phone
                  </Label>
                  <AvField id="manufacturer-ref-data-phone" type="text" name="phone" />
                </AvGroup>
                <AvGroup>
                  <Label id="isDesignDealerLabel" check>
                    <AvInput id="manufacturer-ref-data-isDesignDealer" type="checkbox" className="form-control" name="isDesignDealer" />
                    Is Design Dealer
                  </Label>
                </AvGroup>
                <AvGroup>
                  <Label id="zipCodeLabel" for="manufacturer-ref-data-zipCode">
                    Zip Code
                  </Label>
                  <AvField id="manufacturer-ref-data-zipCode" type="text" name="zipCode" />
                </AvGroup>
                <AvGroup>
                  <Label id="cityLabel" for="manufacturer-ref-data-city">
                    City
                  </Label>
                  <AvField id="manufacturer-ref-data-city" type="text" name="city" />
                </AvGroup>
                <AvGroup>
                  <Label id="stateLabel" for="manufacturer-ref-data-state">
                    State
                  </Label>
                  <AvField id="manufacturer-ref-data-state" type="text" name="state" />
                </AvGroup>
                <AvGroup>
                  <Label id="countryLabel" for="manufacturer-ref-data-country">
                    Country
                  </Label>
                  <AvField id="manufacturer-ref-data-country" type="text" name="country" />
                </AvGroup>
                <AvGroup>
                  <Label id="regionOneLabel" for="manufacturer-ref-data-regionOne">
                    Region One
                  </Label>
                  <AvField id="manufacturer-ref-data-regionOne" type="text" name="regionOne" />
                </AvGroup>
                <AvGroup>
                  <Label id="regionTwoLabel" for="manufacturer-ref-data-regionTwo">
                    Region Two
                  </Label>
                  <AvField id="manufacturer-ref-data-regionTwo" type="text" name="regionTwo" />
                </AvGroup>
                <AvGroup>
                  <Label id="regionThreeLabel" for="manufacturer-ref-data-regionThree">
                    Region Three
                  </Label>
                  <AvField id="manufacturer-ref-data-regionThree" type="text" name="regionThree" />
                </AvGroup>
                <AvGroup>
                  <Label id="manufacturerAToKLabel" for="manufacturer-ref-data-manufacturerAToK">
                    Manufacturer A To K
                  </Label>
                  <AvInput id="manufacturer-ref-data-manufacturerAToK" type="textarea" name="manufacturerAToK" />
                </AvGroup>
                <AvGroup>
                  <Label id="manufacturerIToZLabel" for="manufacturer-ref-data-manufacturerIToZ">
                    Manufacturer I To Z
                  </Label>
                  <AvInput id="manufacturer-ref-data-manufacturerIToZ" type="textarea" name="manufacturerIToZ" />
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/manufacturer-ref-data" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Save
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  manufacturerRefDataEntity: storeState.manufacturerRefData.entity,
  loading: storeState.manufacturerRefData.loading,
  updating: storeState.manufacturerRefData.updating,
  updateSuccess: storeState.manufacturerRefData.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManufacturerRefDataUpdate);
