import { IRootState } from 'app/shared/reducers';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react';
import { getUrlParameter } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button } from 'reactstrap';
import { handlePasswordResetFinish, reset } from '../password-reset.reducer';
import UnauthorizedPagesContainer from 'app/modules/login/UnauthorizedPagesContainer';
import SignInCarousel from '../../register/SignInCarousel';

export interface IPasswordResetFinishProps extends StateProps, DispatchProps, RouteComponentProps<{ key: string }> {}

export const PasswordResetFinishPage = (props: IPasswordResetFinishProps) => {
  const [password, setPassword] = useState('');
  const [key] = useState(getUrlParameter('key', props.location.search));

  useEffect(
    () => () => {
      props.reset();
    },
    []
  );

  useEffect(() => {
    if (props.resetPasswordSuccess) {
      props.history.push('/');
    }
  }, [props.resetPasswordSuccess]);

  const handleValidSubmit = (event, values) => {
    props.handlePasswordResetFinish(key, values.newPassword);
  };

  const updatePassword = event => setPassword(event.target.value);

  const getResetForm = () => {
    return (
      <UnauthorizedPagesContainer content={<SignInCarousel />}>
        <img
          src="../../../content/images/kreator-navbar-logo.svg"
          alt="kreator logo"
          className="login-page-kreator-logo"
          style={{ marginBottom: 50 }}
        />
        <div style={{ height: '100%' }} className="d-flex flex-column justify-content-center">
          <p className="reset-password-title">Reset your password</p>
          <AvForm onValidSubmit={handleValidSubmit} className="sign-in-form">
            <div className="field-block">
              <span className="sign-in-field-label">New Password</span>
              <AvField
                name="newPassword"
                placeholder={'New password'}
                type="password"
                validate={{
                  required: { value: true, errorMessage: 'Your password is required.' },
                  minLength: { value: 4, errorMessage: 'Your password is required to be at least 4 characters.' },
                  maxLength: { value: 50, errorMessage: 'Your password cannot be longer than 50 characters.' },
                }}
                onChange={updatePassword}
              />
            </div>
            <div className="field-block">
              <span className="sign-in-field-label">Confirm New Password</span>
              <AvField
                name="confirmPassword"
                placeholder="Confirm the new password"
                type="password"
                validate={{
                  required: { value: true, errorMessage: 'Your confirmation password is required.' },
                  minLength: { value: 4, errorMessage: 'Your confirmation password is required to be at least 4 characters.' },
                  maxLength: { value: 50, errorMessage: 'Your confirmation password cannot be longer than 50 characters.' },
                  match: { value: 'newPassword', errorMessage: 'The password and its confirmation do not match!' },
                }}
              />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 70 }}>
              <Button type="submit" className="sign-in-button reset-password-button" disabled={props.loading}>
                Set Password
              </Button>
            </div>
          </AvForm>
        </div>
      </UnauthorizedPagesContainer>
    );
  };

  return <div>{key ? getResetForm() : null}</div>;
};

const mapStateToProps = (storeState: IRootState) => ({
  resetPasswordSuccess: storeState.passwordReset.resetPasswordSuccess,
  loading: storeState.passwordReset.loading,
});

const mapDispatchToProps = {
  handlePasswordResetFinish,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetFinishPage);
