import SignUpLinksBar from 'app/components/SignUpLinksBar/SignUpLinksBar';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal } from 'reactstrap';
import Pricing from 'app/components/Pricing/Pricing';

import '../login/login.scss';

const UnauthorizedPagesContainer = props => {
  const location = useLocation();
  const [isPricingModalOpen, setIsPricingModalOpen] = useState<boolean>(false);

  //TODO: uncomment SignUpLinksBar when billing needs to be launched

  return (
    <div className="unauthorized-page-layout">
      <div className="unauthorized-page-preview-container" style={{ width: location.pathname.includes('register') ? '40%' : '50%' }}>
        {props.content}
      </div>
      <div className="unauthorized-page-form-container" style={{ width: location.pathname.includes('register') ? '60%' : '50%' }}>
        {/* <SignUpLinksBar pricingAction={() => setIsPricingModalOpen(true)} /> */}
        <div
          className={`unauthorized-page-form ${
            !location.pathname.includes('register') ? 'unauthorized-page-form--login' : 'unauthorized-page-form--register'
          }`}
        >
          {props.children}
        </div>
      </div>
      {/* <Modal
        isOpen={isPricingModalOpen}
        centered
        style={{ maxWidth: 1200, minWidth: 1200, width: window.screen.width - 300, borderRadius: 15 }}
        toggle={() => setIsPricingModalOpen(false)}
        backdrop={true}
      >
        <Pricing />
      </Modal> */}
    </div>
  );
};

export default UnauthorizedPagesContainer;
