import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import React from 'react';
import { Switch } from 'react-router-dom';
import RoomleProduct from './roomle-product';
import RoomleProductDetail from './roomle-product-detail';
import RoomleProductUpdate from './roomle-product-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/edit/:user_configuration_id/:mailsha1`} component={RoomleProductUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:mailsha1/:product_id/:roomle_component_id`} component={RoomleProductDetail} />
      <ErrorBoundaryRoute
        exact
        path={`${match.url}/:mailsha1/:product_id/:roomle_component_id/:configuration_id`}
        component={RoomleProductDetail}
      />
      <ErrorBoundaryRoute exact path={`${match.url}/:mailsha1?`} component={RoomleProduct} />
    </Switch>
  </>
);

export default Routes;
