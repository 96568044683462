import { IRootState } from 'app/shared/reducers';
import { AvFeedback, AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react';
import { getUrlParameter } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { handleRegister, reset } from './fill-user-info.reducer';

export interface IFillUserInfoProps extends StateProps, DispatchProps, RouteComponentProps<{ key: string }> {}

export const FillUserInfo = (props: IFillUserInfoProps) => {
    const [key] = useState(getUrlParameter('key', props.location.search));
    const [login] = useState(getUrlParameter('login', props.location.search));
    const [password, setPassword] = useState('');

    useEffect(
      () => () => {
        props.reset();
      },
      []
    );
  
    useEffect(() => {
        if (props.activationSuccess) {
            props.history.push('/');
        }
    }, [props.activationSuccess]);

    const handleValidSubmit = (event, values) => {
      props.handleRegister(values.firstPassword, values.firstname, values.lastname, values.company, key, login);
      event.preventDefault();
    };
  
    const updatePassword = event => setPassword(event.target.value);

    return (
        <div>
            <Row className="justify-content-center align-items-center registration-page">
            <Col md="12">
                <Row className="justify-content-center">
                <Col md="4">
                    <h1 id="register-title">Registration</h1>

                    <AvForm id="register-form" onValidSubmit={handleValidSubmit}>
                    
                    <AvField
                        name="firstname"
                        label="First Name"
                        type="text"
                        validate={{
                        required: { value: true, errorMessage: 'First Name is required.' },
                        minLength: { value: 1, errorMessage: 'First Name is required to be at least 1 characters.' },
                        maxLength: { value: 254, errorMessage: 'First Name cannot be longer than 50 characters.' },
                        }}
                    />

                    <AvField
                        name="lastname"
                        label="Last Name"
                        type="text"
                        validate={{
                        required: { value: true, errorMessage: 'Last Name is required.' },
                        minLength: { value: 1, errorMessage: 'Last Name is required to be at least 5 characters.' },
                        maxLength: { value: 254, errorMessage: 'Last Name cannot be longer than 50 characters.' },
                        }}
                    />
                    
                    <AvField
                        name="company"
                        label="Company Name"
                        type="text"
                        validate={{
                        minLength: { value: 1, errorMessage: 'Company Name is required to be at least 5 characters.' },
                        maxLength: { value: 254, errorMessage: 'Company Name cannot be longer than 50 characters.' },
                        }}
                    />

                    <AvField
                        name="firstPassword"
                        label="New password"
                        type="password"
                        onChange={updatePassword}
                        validate={{
                        required: { value: true, errorMessage: 'Your password is required.' },
                        minLength: { value: 4, errorMessage: 'Your password is required to be at least 4 characters.' },
                        maxLength: { value: 50, errorMessage: 'Your password cannot be longer than 50 characters.' },
                        }}
                    />
                    <AvField
                        name="secondPassword"
                        label="New password confirmation"
                        type="password"
                        validate={{
                        required: { value: true, errorMessage: 'Your confirmation password is required.' },
                        minLength: { value: 4, errorMessage: 'Your confirmation password is required to be at least 4 characters.' },
                        maxLength: { value: 50, errorMessage: 'Your confirmation password cannot be longer than 50 characters.' },
                        match: { value: 'firstPassword', errorMessage: 'The password and its confirmation do not match!' },
                        }}
                    />

                        <Col md="6">
                        <Button id="register-submit" color="primary" type="submit" disabled={props.loading}>
                            Fill
                        </Button>
                        </Col>
                    <AvGroup check className="terms-and-conditions-group">
                        <AvInput
                        name="termsAndConditions"
                        type="checkbox"
                        required
                        />
                        <Label className="form-check-label" for="termsAndConditions">
                        I agree to the <a id='terms-check-label-ref' href='https://www.kitchautomation.com/termsandconditions/'>terms and conditions</a>
                        </Label>
                        <AvFeedback>Terms and conditions is required.</AvFeedback>
                    </AvGroup>
                    </AvForm>
                </Col>
                <Col md="6" className="d-none d-md-block">
                    <div className="register-background float-right">
                    <img src="../../../../content/images/register-draw.png"/>
                    </div>
                </Col>
            </Row>
            </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    activationSuccess: storeState.fillUserInfo.fillUserInfoSuccess,
    loading: storeState.fillUserInfo.loading
});

const mapDispatchToProps = { handleRegister, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FillUserInfo);
