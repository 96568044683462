import React from 'react';
import { Button, CardTitle, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import 'flatpickr/dist/themes/material_blue.css';

export default function ProductFilter({ open, onClose, onAction, selectedFilters, setSelectedFilters, onClear }) {
  const clearFilters = () => {
    onClear();
  };

  return (
    <div>
      <Modal isOpen={open} onClosed={onClose} size={'lg'} toggle={() => onClose()}>
        <ModalHeader>
          <CardTitle>Filter Actions</CardTitle>
        </ModalHeader>
        <ModalBody>
          <Row className="p-2">
            <Col md={6}>
              <Label>Name</Label>
              <FormGroup className="mb-2">
                <Input
                  type="text"
                  className="form-control"
                  onChange={e => {
                    setSelectedFilters('name', e.target.value);
                  }}
                  placeholder={'Name'}
                  value={selectedFilters?.name}
                />
              </FormGroup>
            </Col>
            <Col md={6} className="d-flex flex-column">
              <FormGroup className="mb-2" check inline>
                <Input
                  type="checkbox"
                  onChange={e => {
                    setSelectedFilters('isMOC', e.target.checked);
                  }}
                  checked={selectedFilters?.isMOC}
                  value={selectedFilters?.isMOC}
                />
                <Label className="m-0">isMOC</Label>
              </FormGroup>
              <FormGroup className="mb-2" check inline>
                <Input
                  type="checkbox"
                  onChange={e => {
                    setSelectedFilters('isActive', e.target.checked);
                  }}
                  checked={selectedFilters?.isActive}
                  value={selectedFilters?.isActive}
                />
                <Label className="m-0">isActive</Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="mb-2" check inline>
                <Input
                  type="checkbox"
                  onChange={e => {
                    setSelectedFilters('visibleToAdmin', e.target.checked);
                  }}
                  checked={selectedFilters?.visibleToAdmin}
                  value={selectedFilters?.visibleToAdmin}
                />
                <Label className="m-0">Visible To Admin</Label>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup className="mb-2" check inline>
                <Input
                  type="checkbox"
                  onChange={e => {
                    setSelectedFilters('showInMarketPlace', e.target.checked);
                  }}
                  checked={selectedFilters?.showInMarketPlace}
                  value={selectedFilters?.showInMarketPlace}
                />
                <Label className="m-0">Show In Market Place</Label>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup className="mb-2" check inline>
                <Input
                  type="checkbox"
                  onChange={e => {
                    setSelectedFilters('revitExportEnable', e.target.checked);
                  }}
                  checked={selectedFilters?.revitExportEnable}
                  value={selectedFilters?.revitExportEnable}
                />
                <Label className="m-0">Revit Export Enable</Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="mb-2" check inline>
                <Input
                  type="checkbox"
                  onChange={e => {
                    setSelectedFilters('cleanRevitFamily', e.target.checked);
                  }}
                  checked={selectedFilters?.cleanRevitFamily}
                  value={selectedFilters?.cleanRevitFamily}
                />
                <Label className="m-0">Clean Revit Family</Label>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button className="mt-2 mx-3" onClick={clearFilters} color={'danger'}>
            Clear
          </Button>
          <Button className="mt-2" onClick={onAction} color="primary">
            Apply filters
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
