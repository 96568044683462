import React, { useEffect, useState, useCallback, lazy, Suspense } from 'react';
import BaseLayout from '../BaseLayout';
const Search = lazy(() => import('app/components/Search/Search'));
import EmailSettingsProject from 'app/components/EmailSettingsProject/EmailSettingsProject';
import ConfirmButton from 'app/components/ConfirmButton/ConfirmButton';
import CancelButton from 'app/components/CancelButton/CancelButton';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { createEntity, getEmailSettingBYUser, updateEntity } from 'app/entities/email-setting/email-setting.reducer';
import { Button } from 'reactstrap';
import { getAllProjectSummary } from 'app/entities/project/project.reducer';
import { getEntities } from 'app/entities/roomle-product/roomle-product.reducer';
import AppSpinner from 'app/components/Spinner/Spinner';
import './emailSettings.scss';

interface IccEmails {
  type: string;
  id: string;
  ccEmails: string;
}

const EmailSettings = props => {
  const [ccEmails, setCcEmails] = useState<IccEmails[]>([]);
  const [emailSetting, setEmailSetting] = useState(null);

  useEffect(() => {
    props.getEmailSettingBYUser();
    props.getAllProjectSummary();
    props.getEntities();
  }, []);

  useEffect(() => {
    if (!props.loading && props.emailSetting.id) {
      setCcEmails(props.emailSetting.ccEmails != null && props.emailSetting.ccEmails !== '' ? JSON.parse(props.emailSetting.ccEmails) : []);
      setEmailSetting(props.emailSetting);
    }
  }, [props.emailSetting, props.loading]);

  const updateCcEmails = useCallback(
    (newItem: IccEmails) => {
      const ccEmailsCopy = [...ccEmails];
      const itemForUpdate = ccEmails.findIndex(item => item.id === newItem.id);
      ccEmailsCopy[itemForUpdate] = newItem;
      setCcEmails(ccEmailsCopy);
    },
    [ccEmails]
  );

  const updateProjectData = useCallback(
    (name: string, index: number, value: string) => {
      const ccEmailsCopy = [...ccEmails];
      ccEmailsCopy[index][name] = value;
      setCcEmails(ccEmailsCopy);
    },
    [ccEmails]
  );

  const saveEntity = useCallback(() => {
    const newEmailSetting = emailSetting;
    newEmailSetting.ccEmails = JSON.stringify(ccEmails);
    props.updateEntity(newEmailSetting);
  }, [emailSetting, ccEmails, props.updateEntity]);

  return (
    <BaseLayout title={'Email Settings'}>
      <div className="mt-5"></div>
      {props.loading || props.loadingProjects || props.loadingConfigurators ? (
        <AppSpinner />
      ) : (
        <div className="email-settings-container">
          <p className="email-settings-label">Default Email</p>
          <span className="email-settings-default-email">{props.emailSetting.defaultEmail}</span>
          <div className="email-settings-search-container">
            <p className="email-settings-label">Project Emails</p>
            {props.emailSetting.ccEmails && (
              <Suspense fallback={<span></span>}>
                <Search />
              </Suspense>
            )}
          </div>
          {ccEmails.map((item, index) => (
            <EmailSettingsProject
              index={index}
              updateProjectData={updateProjectData}
              item={item}
              updateCcEmails={updateCcEmails}
              key={item.id}
            />
          ))}

          <Button
            color="primary"
            className="add-email-settings-project-button"
            onClick={() => {
              setCcEmails([...ccEmails, { type: '', id: null, ccEmails: '' }]);
            }}
          >
            Add Project
          </Button>
          <div className="email-settings-buttons-container">
            <ConfirmButton title="Save" confirmAction={saveEntity} />
            <CancelButton title="Cancel" />
          </div>
        </div>
      )}
    </BaseLayout>
  );
};

const mapStateToProps = ({ emailSetting, project, roomleProduct }: IRootState) => ({
  emailSetting: emailSetting.entity,
  loading: emailSetting.loading,
  loadingProjects: project.loading,
  loadingConfigurators: roomleProduct.loading,
});

const mapDispatchToProps = {
  getEmailSettingBYUser,
  createEntity,
  getAllProjectSummary,
  updateEntity,
  getEntities,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailSettings);
