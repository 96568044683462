import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IConsultant, defaultValue } from 'app/shared/model/consultant.model';
import { IDealer } from 'app/shared/model/dealer.model';

export const ACTION_TYPES = {
  FETCH_CONSULTANT_LIST: 'consultant/FETCH_CONSULTANT_LIST',
  FETCH_CONSULTANT: 'consultant/FETCH_CONSULTANT',
  CREATE_CONSULTANT: 'consultant/CREATE_CONSULTANT',
  UPDATE_CONSULTANT: 'consultant/UPDATE_CONSULTANT',
  DELETE_CONSULTANT: 'consultant/DELETE_CONSULTANT',
  RESET: 'consultant/RESET',
};

const initialState = {
  loading: true,
  errorMessage: null,
  entities: [] as ReadonlyArray<IConsultant>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type ConsultantState = Readonly<typeof initialState>;

// Reducer

export default (state: ConsultantState = initialState, action): ConsultantState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CONSULTANT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CONSULTANT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_CONSULTANT):
    case REQUEST(ACTION_TYPES.UPDATE_CONSULTANT):
    case REQUEST(ACTION_TYPES.DELETE_CONSULTANT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_CONSULTANT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CONSULTANT):
    case FAILURE(ACTION_TYPES.CREATE_CONSULTANT):
    case FAILURE(ACTION_TYPES.UPDATE_CONSULTANT):
    case FAILURE(ACTION_TYPES.DELETE_CONSULTANT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_CONSULTANT_LIST):
      return {
        ...state,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_CONSULTANT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_CONSULTANT):
    case SUCCESS(ACTION_TYPES.UPDATE_CONSULTANT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_CONSULTANT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/consultants';

// Actions

export const getEntities: ICrudGetAllAction<IConsultant> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CONSULTANT_LIST,
    payload: axios.get<IConsultant>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IConsultant> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CONSULTANT,
    payload: axios.get<IConsultant>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IConsultant> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CONSULTANT,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IConsultant> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CONSULTANT,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IConsultant> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CONSULTANT,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const getConsultantAdminForCurrentUser = () => {
  const requestUrl = '/api/consultants/get-consultant-by-current-user';
  return {
    type: ACTION_TYPES.FETCH_CONSULTANT,
    payload: axios.get(requestUrl),
  };
};

export const getBusinessApprovals: ICrudGetAllAction<IConsultant> = (page, size, sort) => {
  const requestUrl = `${apiUrl}/business-approvals${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CONSULTANT_LIST,
    payload: axios.get<IConsultant>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};
