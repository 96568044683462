import React, { useState, useEffect } from 'react';
import { Modal, Collapse, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { getUserConfigurationsByFolderId, reset } from 'app/entities/user-configuration/user-configuration.reducer';
import { getEntities } from 'app/entities/folder/folder.reducer';
import { IRootState } from 'app/shared/reducers';
import Search from '../Search/Search';
import InventoryCard from './InventoryCard';
import { DEFAULT_FOLDERS } from 'app/config/constants';
import { useInventoryFolderOpening } from 'app/customHooks/useInventoryFolderOpening';
import { useFolderUserConfigurations } from 'app/customHooks/useFolderUserConfigurations';
import AppSpinner from '../Spinner/Spinner';
import './addInventoryToTheProjectModal.scss.scss';

interface IAddInventoryToTheProjectModal extends StateProps, DispatchProps {
  isOpen: boolean;
  closeModal: Function;
  addInventoryToTheProject: Function;
}

const AddInventoryToTheProjectModal = (props: IAddInventoryToTheProjectModal) => {
  const [searchValue, setSearchValue] = useState('');
  const folderUserConfigurations = useFolderUserConfigurations(props.userConfigurations, searchValue);
  const [isFolderOpen, folderOpenId, toggleFolder] = useInventoryFolderOpening(
    props.folderList,
    DEFAULT_FOLDERS.INBOX,
    props.getUserConfigurationsByFolderId,
    props.reset
  );

  useEffect(() => {
    if (props.isOpen) {
      props.getEntities();
    }
  }, [props.isOpen]);

  return (
    <Modal
      isOpen={props.isOpen}
      centered
      fade={false}
      toggle={() => props.closeModal()}
      backdrop={true}
      style={
        window.screen.width >= 1024
          ? { maxWidth: window.screen.width - 420, width: '100%' }
          : { maxWidth: '95%', width: '95%', margin: 'auto' }
      }
      className="change-configuration-modal"
    >
      <div className="change-configuration-modal-container">
        <button className="change-configuration-modal-close-button" onClick={() => props.closeModal()}>
          X
        </button>
        <div className="search-container">
          <Search searchValue={searchValue} setSearchValue={setSearchValue} />
        </div>

        <div className="inventory-folders-container" style={{ marginTop: 70 }}>
          {props.folderList.map(folder => (
            <div key={folder.id}>
              <div
                className="inventory-folder-bar"
                onClick={e => toggleFolder(folder.id, folderOpenId === folder.id ? !isFolderOpen : true)}
              >
                <div className="inventory-folder-bar-title-container">
                  <span className="inventory-folder-name">{folder.name}</span>
                </div>

                <button className="inventory-folder-open-button">
                  {isFolderOpen && folder.id === folderOpenId ? (
                    <img src="../../../content/images/icons/folder-minus.svg" className="close-folde-image" alt="open folder button" />
                  ) : (
                    <img src="../../../content/images/icons/folder-plus.svg" className="open-folde-image" alt="open folder button" />
                  )}
                </button>
              </div>
              <Collapse isOpen={folder.id === folderOpenId}>
                <div className="inventory-cards-container">
                  {folderUserConfigurations.length !== 0 &&
                    !props.loading &&
                    folderUserConfigurations.map(item => (
                      <InventoryCard userConfiguration={item} key={item.id} addInventoryToTheProject={props.addInventoryToTheProject} />
                    ))}
                  {props.loading && <AppSpinner />}
                </div>
              </Collapse>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: IRootState) => ({
  userConfigurations: state.userConfiguration.entities,
  loading: state.userConfiguration.loading,
  folderList: state.folder.entities,
  loadingFolders: state.folder.loading,
});

const mapDispatchToProps = { getEntities, getUserConfigurationsByFolderId, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AddInventoryToTheProjectModal);
