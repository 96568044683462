import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SocketConnection from './socket-connection';
import SocketConnectionDetail from './socket-connection-detail';
import SocketConnectionUpdate from './socket-connection-update';
import SocketConnectionDeleteDialog from './socket-connection-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={SocketConnectionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={SocketConnectionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={SocketConnectionDetail} />
      <ErrorBoundaryRoute path={match.url} component={SocketConnection} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={SocketConnectionDeleteDialog} />
  </>
);

export default Routes;
