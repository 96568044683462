export const mfrRepHead = {
  'akelly@thermokool.com': {
    name: 'Ashley Kelly',
    associates: ['HC-9733', 'Pu-7284'],
  },
  'aishee@thermokool.com': {
    name: 'Allison Ishee',
    associates: ['hL-9646', 'nJ1zKC', 'ND-4872', 'Vg-9211', 'PF-5674'],
  },
  'dtisdale@thermokool.com': {
    name: 'Debbi Tisdale',
    associates: ['Pb-5348', 'LZ-1683', 'bA-3114'],
  },
  'eflynt@thermokool.com': {
    name: 'Erin Flynt',
    associates: ['su-4604', 'CN-3555'],
  },
};
