import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IManufacturer } from 'app/shared/model/manufacturer.model';
import { getEntities as getManufacturers } from 'app/entities/manufacturer/manufacturer.reducer';
import { IDealer } from 'app/shared/model/dealer.model';
import { getEntities as getDealers } from 'app/entities/dealer/dealer.reducer';
import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { IProduct } from 'app/shared/model/product.model';
import { getEntities as getProducts } from 'app/entities/product/product.reducer';
import { getEntity, updateEntity, createEntity, reset } from './enabled-product-configurators.reducer';
import { IEnabledProductConfigurators } from 'app/shared/model/enabled-product-configurators.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IEnabledProductConfiguratorsUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const EnabledProductConfiguratorsUpdate = (props: IEnabledProductConfiguratorsUpdateProps) => {
  const [manufacturerId, setManufacturerId] = useState('0');
  const [dealerId, setDealerId] = useState('0');
  const [userId, setUserId] = useState('0');
  const [productId, setProductId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { enabledProductConfiguratorsEntity, manufacturers, dealers, users, products, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/enabled-product-configurators' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getManufacturers();
    props.getDealers();
    props.getUsers();
    props.getProducts();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...enabledProductConfiguratorsEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="familyBuilderApiApp.enabledProductConfigurators.home.createOrEditLabel">Create or edit a EnabledProductConfigurators</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : enabledProductConfiguratorsEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="enabled-product-configurators-id">ID</Label>
                  <AvInput id="enabled-product-configurators-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup check>
                <Label id="activatedLabel">
                  <AvInput id="enabled-product-configurators-activated" type="checkbox" className="form-check-input" name="activated" />
                  Activated
                </Label>
              </AvGroup>
              <AvGroup>
                <Label for="enabled-product-configurators-manufacturer">Manufacturer</Label>
                <AvInput id="enabled-product-configurators-manufacturer" type="select" className="form-control" name="manufacturerId">
                  <option value="" key="0" />
                  {manufacturers
                    ? manufacturers.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="enabled-product-configurators-dealer">Dealer</Label>
                <AvInput id="enabled-product-configurators-dealer" type="select" className="form-control" name="dealerId">
                  <option value="" key="0" />
                  {dealers
                    ? dealers.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="enabled-product-configurators-user">User</Label>
                <AvInput id="enabled-product-configurators-user" type="select" className="form-control" name="userId" required>
                  {users
                    ? users.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.login}
                        </option>
                      ))
                    : null}
                </AvInput>
                <AvFeedback>This field is required.</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="enabled-product-configurators-product">Product</Label>
                <AvInput id="enabled-product-configurators-product" type="select" className="form-control" name="productId">
                  <option value="" key="0" />
                  {products
                    ? products.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/enabled-product-configurators" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  manufacturers: storeState.manufacturer.entities,
  dealers: storeState.dealer.entities,
  users: storeState.userManagement.users,
  products: storeState.product.entities,
  enabledProductConfiguratorsEntity: storeState.enabledProductConfigurators.entity,
  loading: storeState.enabledProductConfigurators.loading,
  updating: storeState.enabledProductConfigurators.updating,
  updateSuccess: storeState.enabledProductConfigurators.updateSuccess,
});

const mapDispatchToProps = {
  getManufacturers,
  getDealers,
  getUsers,
  getProducts,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EnabledProductConfiguratorsUpdate);
