import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ManufacturerRefData from './manufacturer-ref-data';
import ManufacturerRefDataDetail from './manufacturer-ref-data-detail';
import ManufacturerRefDataUpdate from './manufacturer-ref-data-update';
import ManufacturerRefDataDeleteDialog from './manufacturer-ref-data-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ManufacturerRefDataUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ManufacturerRefDataUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ManufacturerRefDataDetail} />
      <ErrorBoundaryRoute path={match.url} component={ManufacturerRefData} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={ManufacturerRefDataDeleteDialog} />
  </>
);

export default Routes;
