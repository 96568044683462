import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IManufacturerRefData, defaultValue } from 'app/shared/model/manufacturer-ref-data.model';
import { IManufacturerData } from 'app/shared/model/manufacturer-data.model';
import { IConsultantData } from 'app/shared/model/consultant-data.model';

export const ACTION_TYPES = {
  FETCH_MANUFACTURERREFDATA_LIST: 'manufacturerRefData/FETCH_MANUFACTURERREFDATA_LIST',
  FETCH_MANUFACTURERREFDATA: 'manufacturerRefData/FETCH_MANUFACTURERREFDATA',
  FETCH_REGIONS: 'manufacturerRefData/FETCH_REGIONS',
  FETCH_STATES: 'manufacturerRefData/FETCH_STATES',
  CREATE_MANUFACTURERREFDATA: 'manufacturerRefData/CREATE_MANUFACTURERREFDATA',
  UPDATE_MANUFACTURERREFDATA: 'manufacturerRefData/UPDATE_MANUFACTURERREFDATA',
  DELETE_MANUFACTURERREFDATA: 'manufacturerRefData/DELETE_MANUFACTURERREFDATA',
  RESET: 'manufacturerRefData/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IManufacturerRefData>,
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  regions: [],
  states: [],
  loadingRegions: false,
};

export type ManufacturerRefDataState = Readonly<typeof initialState>;

// Reducer

export default (state: ManufacturerRefDataState = initialState, action): ManufacturerRefDataState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_MANUFACTURERREFDATA_LIST):
    case REQUEST(ACTION_TYPES.FETCH_MANUFACTURERREFDATA):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_REGIONS):
    case REQUEST(ACTION_TYPES.FETCH_STATES):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadingRegions: true,
      };

    case REQUEST(ACTION_TYPES.CREATE_MANUFACTURERREFDATA):
    case REQUEST(ACTION_TYPES.UPDATE_MANUFACTURERREFDATA):
    case REQUEST(ACTION_TYPES.DELETE_MANUFACTURERREFDATA):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_MANUFACTURERREFDATA_LIST):
    case FAILURE(ACTION_TYPES.FETCH_MANUFACTURERREFDATA):
    case FAILURE(ACTION_TYPES.CREATE_MANUFACTURERREFDATA):
    case FAILURE(ACTION_TYPES.UPDATE_MANUFACTURERREFDATA):
    case FAILURE(ACTION_TYPES.DELETE_MANUFACTURERREFDATA):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_MANUFACTURERREFDATA_LIST):
      return {
        ...state,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_MANUFACTURERREFDATA):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_MANUFACTURERREFDATA):
    case SUCCESS(ACTION_TYPES.UPDATE_MANUFACTURERREFDATA):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_MANUFACTURERREFDATA):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case SUCCESS(ACTION_TYPES.FETCH_REGIONS):
      return {
        ...state,
        regions: action.payload.data,
        loadingRegions: false,
      };
    case SUCCESS(ACTION_TYPES.FETCH_STATES):
      return {
        ...state,
        states: action.payload.data,
        loadingRegions: false,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/manufacturer-ref-data';

// Actions

export const getAllManufacturerRefData: ICrudGetAllAction<IManufacturerRefData> = (page, size, sort) => {
  const requestUrl = `${apiUrl}/get-all`;
  return {
    type: ACTION_TYPES.FETCH_MANUFACTURERREFDATA_LIST,
    payload: axios.get<IManufacturerRefData>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntities: ICrudGetAllAction<IManufacturerRefData> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_MANUFACTURERREFDATA_LIST,
    payload: axios.get<IManufacturerRefData>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getManufacturerRefByName: ICrudGetAllAction<IManufacturerRefData> = name => {
  const requestUrl = `${apiUrl}/search/${name}`;
  return {
    type: ACTION_TYPES.FETCH_MANUFACTURERREFDATA_LIST,
    payload: axios.get<IManufacturerRefData>(requestUrl),
  };
};

export const getEntity: ICrudGetAction<IManufacturerRefData> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_MANUFACTURERREFDATA,
    payload: axios.get<IManufacturerRefData>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IManufacturerRefData> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_MANUFACTURERREFDATA,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IManufacturerRefData> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_MANUFACTURERREFDATA,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IManufacturerRefData> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_MANUFACTURERREFDATA,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const getRegions = () => {
  const requestUrl = 'api/get-all-regions';
  return {
    type: ACTION_TYPES.FETCH_REGIONS,
    payload: axios.get(requestUrl),
  };
};
export const getStates = () => {
  const requestUrl = 'api/get-all-states';
  return {
    type: ACTION_TYPES.FETCH_STATES,
    payload: axios.get(requestUrl),
  };
};

export const getMfrRepByMfrAndRegion = (manufacturerName?, region?) => {
  const requestUrl = `api/manufacturer-ref-data/by-region-or-manufacturer`;
  return {
    type: ACTION_TYPES.FETCH_MANUFACTURERREFDATA_LIST,
    payload: axios.get(requestUrl, { params: { manufacturerName, region } }),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
