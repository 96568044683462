import React, { useEffect, useState } from 'react';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';
import './signUpStepProgressBar.scss';

interface ISignUpProgressBarProps {
  step: number;
}

const SignUpProgressBar = (props: ISignUpProgressBarProps) => {
  const [progress, setProgress] = useState(null);

  useEffect(() => {
    if (props.step === 1) {
      setProgress(33);
    } else if (props.step === 2) {
      setProgress(66);
    } else if (props.step === 3) {
      setProgress(100);
    }
  }, [props.step]);
  return (
    <div className="sign-up-progressbar-container">
      <ProgressBar percent={progress} filledBackground="#EB8031" height={12}>
        <Step transition="scale">{({ accomplished }) => <div></div>}</Step>
        <Step transition="scale">{({ accomplished }) => <div></div>}</Step>
        <Step transition="scale">{({ accomplished }) => <div></div>}</Step>
        <Step transition="scale">{({ accomplished }) => <div></div>}</Step>
      </ProgressBar>
    </div>
  );
};

export default SignUpProgressBar;
