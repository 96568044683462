import React, { useState } from 'react';
import ProductParameterItem from './ProductParameterItem';
import './marketplaceCartItem.scss';
import { useEffect } from 'react';

const ProductItem = props => {
  let [count, setCount] = useState(1);

  const incrementCount = () => {
    count = count + 1;
    setCount(count);
  };
  const decrementCount = () => {
    if (count > 1) {
      count = count - 1;
      setCount(count);
    }
  };

  useEffect(() => {
    props.changeProductCount(props.item.id, props.dealerId, count);
  }, [count]);

  const labels = Object.entries(props.item.product.parameterValues);

  return (
    <div className="cart-item-wrapper">
      <div className="cart-product-container">
        <div className="cart-product-image-container">
          <img src={props.item.product.image} alt="product image" width="80" />
        </div>
        <div className="cart-product-name-container">
          <span className="cart-product-name">{props.item.product.name}</span>
          <span className="cart-product-subtitle">{props.item.product.name.description}</span>
        </div>
        <div className="cart-product-data-container">
          {labels.map(item => (
            <ProductParameterItem label={item[0]} value={item[1]} />
          ))}
        </div>
        <div className="cart-product-counter-container">
          <button onClick={decrementCount} className="count-action-button">
            <img src="../../../content/images/icons/cart-counterminus.svg" alt="minus" />
          </button>
          <div className="cart-product-count">{count}</div>
          <button onClick={incrementCount} className="count-action-button">
            <img src="../../../content/images/icons/cart-counterplus.svg" alt="plus" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
