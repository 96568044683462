import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { Field, Form as ReactFinalForm } from 'react-final-form';
import { createEntity, getDealerForCurrentUser, reset, updateEntity } from 'app/entities/dealer/dealer.reducer';
import { getDealerData } from 'app/entities/dealer-data/dealer-data.reducer';
import DealerFileUpload from 'app/entities/dealer/DealerFileUpload';
import BaseLayout from '../BaseLayout';
import AppSpinner from 'app/components/Spinner/Spinner';
import './myDealer.scss';
import {hasAnyAuthority} from "app/shared/auth/private-route";
import {AUTHORITIES} from "app/config/constants";

export interface IVersionUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const DealerUpdate = (props: IVersionUpdateProps) => {
  const { dealerEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/my-dealer' + props.location.search);
  };

  useEffect(() => {
    props.getDealerForCurrentUser();
  }, []);

  useEffect(() => {
    if (props.dealerEntity) {
      props.getDealerData(props.dealerEntity.id);
    }
  }, [props.dealerEntity]);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...dealerEntity,
      ...values,
    };
    props.updateEntity(entity);
  };
  const identity = value => value;
  const required = value => (value ? undefined : <small color="danger">This field is required</small>);

  const handleRedirectDealerProfile=()=> {
    props.history.push('/dealer-account-setup');
  }

  return (
    <BaseLayout title="My Dealer Account Info">
      {loading ? (
        <AppSpinner />
      ) : (
        <div className="my-dealer-data-container">
          <ReactFinalForm
            initialValues={dealerEntity}
            onSubmit={saveEntity}
            validate={values => {
              const errors = {};
              return errors;
            }}
            render={({ handleSubmit, form, values }) => {
              return (
                <Form noValidate onSubmit={handleSubmit} className="my-dealer-form">
                  <FormGroup className="my-dealer-input-container">
                    <Field
                      render={({ input, meta }) => (
                        <div className={'w-100'}>
                          <Label for="exampleEmail" className="my-dealer-form-label">
                            Name
                          </Label>
                          <Input {...input} className="my-dealer-form-input" />
                          {meta.touched && meta.error && <span>{meta.error}</span>}
                        </div>
                      )}
                      parse={identity}
                      placeholder={''}
                      name="name"
                      validate={required}
                    />
                  </FormGroup>
                  <FormGroup className="my-dealer-input-container">
                    <Field
                      render={({ input, meta }) => (
                        <div className={'w-100'}>
                          <Label for="exampleEmail" className="my-dealer-form-label">
                            Website URL
                          </Label>
                          <Input {...input} className="my-dealer-form-input" />
                          {meta.touched && meta.error && <span>{meta.error}</span>}
                        </div>
                      )}
                      parse={identity}
                      placeholder={''}
                      name="websiteURL"
                      validate={required}
                    />
                  </FormGroup>
                  <FormGroup className="my-dealer-input-container">
                    <Field
                      render={({ input, meta }) => (
                        <div className={'w-100'}>
                          <Label for="exampleEmail" className="my-dealer-form-label">
                            City
                          </Label>
                          <Input {...input} className="my-dealer-form-input" />
                          {meta.touched && meta.error && <span>{meta.error}</span>}
                        </div>
                      )}
                      parse={identity}
                      placeholder={''}
                      name="city"
                      validate={required}
                    />
                  </FormGroup>
                  <FormGroup className="my-dealer-input-container">
                    <Field
                      render={({ input, meta }) => (
                        <div className={'w-100'}>
                          <Label for="exampleEmail" className="my-dealer-form-label">
                            State
                          </Label>
                          <Input {...input} className="my-dealer-form-input" />
                          {meta.touched && meta.error && <span>{meta.error}</span>}
                        </div>
                      )}
                      parse={identity}
                      placeholder={''}
                      name="state"
                      validate={required}
                    />
                  </FormGroup>
                  <FormGroup className="my-dealer-input-container">
                    <Field
                      render={({ input, meta }) => (
                        <div className={'w-100'}>
                          <Label for="exampleEmail" className="my-dealer-form-label">
                            Dealer Logo
                          </Label>
                          <DealerFileUpload setOutput={data => input.onChange(data)} />
                          {meta.touched && meta.error && <span>{meta.error}</span>}
                        </div>
                      )}
                      parse={identity}
                      placeholder={''}
                      name="logo"
                      validate={required}
                    />
                  </FormGroup>
                  <div style={{ width: '100%' }}>
                    <FormGroup className="my-dealer-input-container">
                      <div className={'w-100'}>
                        <Label for="exampleEmail" className="my-dealer-form-label">
                          Company Name
                        </Label>
                        <Input disabled value={props.dealerData.company} className="my-dealer-form-input" />
                      </div>
                    </FormGroup>
                    <FormGroup className="my-dealer-input-container">
                      <div className={'w-100'}>
                        <Label for="exampleEmail" className="my-dealer-form-label">
                          Address
                        </Label>
                        <Input disabled value={props.dealerData.mailingAddress} className="my-dealer-form-input" />
                      </div>
                    </FormGroup>
                    <FormGroup className="my-dealer-input-container">
                      <div className={'w-100'}>
                        <Label for="exampleEmail" className="my-dealer-form-label">
                          City
                        </Label>
                        <Input disabled value={props.dealerData.city} className="my-dealer-form-input" />
                      </div>
                    </FormGroup>
                    <FormGroup className="my-dealer-input-container">
                      <div className={'w-100'}>
                        <Label for="exampleEmail" className="my-dealer-form-label">
                          State
                        </Label>
                        <Input disabled value={props.dealerData.state} className="my-dealer-form-input" />
                      </div>
                    </FormGroup>
                    <FormGroup className="my-dealer-input-container">
                      <div className={'w-100'}>
                        <Label for="exampleEmail" className="my-dealer-form-label">
                          Zip Code
                        </Label>
                        <Input disabled value={props.dealerData.zipCode} className="my-dealer-form-input" />
                      </div>
                    </FormGroup>
                    <FormGroup className="my-dealer-input-container">
                      <div className={'w-100'}>
                        <Label for="exampleEmail" className="my-dealer-form-label">
                          Website URL
                        </Label>
                        <a rel="stylesheet" href={props.dealerData.website}>
                          {props.dealerData.website}
                        </a>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="save-dealer-data-button-container">
                    <Button className="save-dealer-data-button" id="save-entity" type="submit" disabled={updating}>
                      Save
                    </Button>

                    &nbsp;{hasAnyAuthority(props.account.authorities, [AUTHORITIES.ROLE_SUPER_ADMIN])? (<Button className="save-dealer-data-button" id="save-entity" type="button" onClick={()=>handleRedirectDealerProfile()}>
                      Edit
                    </Button>):""}
                  </div>
                </Form>
              );
            }}
          />
        </div>
      )}
    </BaseLayout>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  dealerEntity: storeState.dealer.entity,
  dealerData: storeState.dealerData.entity,
  loading: storeState.dealer.loading,
  updating: storeState.dealer.updating,
  updateSuccess: storeState.dealer.updateSuccess,
  account: storeState.authentication.account,

});

const mapDispatchToProps = {
  getDealerForCurrentUser,
  getDealerData,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DealerUpdate);
