const getPartSpecSheet = modelNumber => {
  if (modelNumber.startsWith('14-400') && modelNumber.includes('W')) {
    return specSheetsLink['14-400W'];
  } else if (modelNumber.startsWith('14-400')) {
    return specSheetsLink['14-400L'];
  } else if (modelNumber.startsWith('14-401') && modelNumber.includes('W')) {
    return specSheetsLink['14-401W'];
  } else if (modelNumber.startsWith('14-401')) {
    return specSheetsLink['14-401L'];
  } else if (modelNumber.startsWith('14-402') && modelNumber.includes('W')) {
    return specSheetsLink['14-402W'];
  } else if (modelNumber.startsWith('14-402')) {
    return specSheetsLink['14-402L'];
  } else if (modelNumber.startsWith('14-406') && modelNumber.includes('W')) {
    return specSheetsLink['14-406W'];
  } else if (modelNumber.startsWith('14-406')) {
    return specSheetsLink['14-406L'];
  } else if (modelNumber.startsWith('14-408') && modelNumber.includes('W')) {
    return specSheetsLink['14-408W'];
  } else if (modelNumber.startsWith('14-408')) {
    return specSheetsLink['14-408L'];
  } else if (modelNumber.startsWith('14-410') && modelNumber.includes('W')) {
    return specSheetsLink['14-410W'];
  } else if (modelNumber.startsWith('14-410')) {
    return specSheetsLink['14-410L'];
  } else if (modelNumber.startsWith('14-412') && modelNumber.includes('W')) {
    return specSheetsLink['14-412W'];
  } else if (modelNumber.startsWith('14-412')) {
    return specSheetsLink['14-412L'];
  } else if (modelNumber.startsWith('14-414') && modelNumber.includes('W')) {
    return specSheetsLink['14-414W'];
  } else if (modelNumber.startsWith('14-414')) {
    return specSheetsLink['14-414L'];
  } else if (modelNumber.startsWith('14-416') && modelNumber.includes('W')) {
    return specSheetsLink['14-416W'];
  } else if (modelNumber.startsWith('14-416')) {
    return specSheetsLink['14-416L'];
  } else if (modelNumber.startsWith('14-430') && modelNumber.includes('W')) {
    return specSheetsLink['14-430W'];
  } else if (modelNumber.startsWith('14-430')) {
    return specSheetsLink['14-430L'];
  } else if (modelNumber.startsWith('14-431')) {
    return specSheetsLink['14-431L'];
  } else if (modelNumber.startsWith('14-432') && modelNumber.includes('W')) {
    return specSheetsLink['14-432W'];
  } else if (modelNumber.startsWith('14-432')) {
    return specSheetsLink['14-432L'];
  } else if (modelNumber.startsWith('14-801') && modelNumber.includes('W')) {
    return specSheetsLink['14-801W'];
  } else if (modelNumber.startsWith('14-801')) {
    return specSheetsLink['14-801L'];
  } else if (modelNumber.startsWith('14-802') && modelNumber.includes('W')) {
    return specSheetsLink['14-802W'];
  } else if (modelNumber.startsWith('14-802')) {
    return specSheetsLink['14-802L'];
  } else if (modelNumber.startsWith('14-806') && modelNumber.includes('W')) {
    return specSheetsLink['14-806W'];
  } else if (modelNumber.startsWith('14-806')) {
    return specSheetsLink['14-806L'];
  } else if (modelNumber.startsWith('14-808') && modelNumber.includes('W')) {
    return specSheetsLink['14-808W'];
  } else if (modelNumber.startsWith('14-808')) {
    return specSheetsLink['14-808L'];
  } else if (modelNumber.startsWith('14-810') && modelNumber.includes('W')) {
    return specSheetsLink['14-810W'];
  } else if (modelNumber.startsWith('14-810')) {
    return specSheetsLink['14-810L'];
  } else if (modelNumber.startsWith('14-812') && modelNumber.includes('W')) {
    return specSheetsLink['14-812W'];
  } else if (modelNumber.startsWith('14-812')) {
    return specSheetsLink['14-812L'];
  } else if (modelNumber.startsWith('14-814') && modelNumber.includes('W')) {
    return specSheetsLink['14-814W'];
  } else if (modelNumber.startsWith('14-814')) {
    return specSheetsLink['14-814L'];
  } else if (modelNumber.startsWith('14-816') && modelNumber.includes('W')) {
    return specSheetsLink['14-816W'];
  } else if (modelNumber.startsWith('14-816')) {
    return specSheetsLink['14-816L'];
  } else if (modelNumber.startsWith('14-831') && modelNumber.includes('W')) {
    return specSheetsLink['14-831W'];
  } else if (modelNumber.startsWith('14-831')) {
    return specSheetsLink['14-831L'];
  } else if (modelNumber.startsWith('14-832') && modelNumber.includes('W')) {
    return specSheetsLink['14-832W'];
  } else if (modelNumber.startsWith('14-832')) {
    return specSheetsLink['14-832L'];
  } else if (modelNumber.startsWith('15-301') && modelNumber.includes('W')) {
    return specSheetsLink['15-301W'];
  } else if (modelNumber.startsWith('15-301')) {
    return specSheetsLink['15-301L'];
  } else if (modelNumber.startsWith('15-302') && modelNumber.includes('W')) {
    return specSheetsLink['15-302W'];
  } else if (modelNumber.startsWith('15-302')) {
    return specSheetsLink['15-302L'];
  } else if (modelNumber.startsWith('15-306') && modelNumber.includes('W')) {
    return specSheetsLink['15-306W'];
  } else if (modelNumber.startsWith('15-306')) {
    return specSheetsLink['15-306L'];
  } else if (modelNumber.startsWith('15-308') && modelNumber.includes('W')) {
    return specSheetsLink['15-308W'];
  } else if (modelNumber.startsWith('15-308')) {
    return specSheetsLink['15-308L'];
  } else if (modelNumber.startsWith('15-310') && modelNumber.includes('W')) {
    return specSheetsLink['15-310W'];
  } else if (modelNumber.startsWith('15-310')) {
    return specSheetsLink['15-310L'];
  } else if (modelNumber.startsWith('15-312') && modelNumber.includes('W')) {
    return specSheetsLink['15-312W'];
  } else if (modelNumber.startsWith('15-312')) {
    return specSheetsLink['15-312L'];
  } else if (modelNumber.startsWith('15-314') && modelNumber.includes('W')) {
    return specSheetsLink['15-314W'];
  } else if (modelNumber.startsWith('15-314')) {
    return specSheetsLink['15-314L'];
  } else if (modelNumber.startsWith('15-316') && modelNumber.includes('W')) {
    return specSheetsLink['15-316W'];
  } else if (modelNumber.startsWith('15-316')) {
    return specSheetsLink['15-316L'];
  } else if (modelNumber.startsWith('15-330') && modelNumber.includes('W')) {
    return specSheetsLink['15-330W'];
  } else if (modelNumber.startsWith('15-330')) {
    return specSheetsLink['15-330L'];
  } else if (modelNumber.startsWith('15-331') && modelNumber.includes('W')) {
    return specSheetsLink['15-331W'];
  } else if (modelNumber.startsWith('15-331')) {
    return specSheetsLink['15-331L'];
  } else if (modelNumber.startsWith('15-332') && modelNumber.includes('W')) {
    return specSheetsLink['15-332W'];
  } else if (modelNumber.startsWith('15-332')) {
    return specSheetsLink['15-332L'];
  } else if (modelNumber.startsWith('15-401') && modelNumber.includes('W')) {
    return specSheetsLink['15-401W'];
  } else if (modelNumber.startsWith('15-401')) {
    return specSheetsLink['15-401L'];
  } else if (modelNumber.startsWith('15-402') && modelNumber.includes('W')) {
    return specSheetsLink['15-402W'];
  } else if (modelNumber.startsWith('15-402')) {
    return specSheetsLink['15-402L'];
  } else if (modelNumber.startsWith('15-406') && modelNumber.includes('W')) {
    return specSheetsLink['15-406W'];
  } else if (modelNumber.startsWith('15-406')) {
    return specSheetsLink['15-406L'];
  } else if (modelNumber.startsWith('15-408') && modelNumber.includes('W')) {
    return specSheetsLink['15-408W'];
  } else if (modelNumber.startsWith('15-408')) {
    return specSheetsLink['15-408L'];
  } else if (modelNumber.startsWith('15-410') && modelNumber.includes('W')) {
    return specSheetsLink['15-410W'];
  } else if (modelNumber.startsWith('15-410')) {
    return specSheetsLink['15-410L'];
  } else if (modelNumber.startsWith('15-412') && modelNumber.includes('W')) {
    return specSheetsLink['15-412W'];
  } else if (modelNumber.startsWith('15-412')) {
    return specSheetsLink['15-412L'];
  } else if (modelNumber.startsWith('15-414') && modelNumber.includes('W')) {
    return specSheetsLink['15-414W'];
  } else if (modelNumber.startsWith('15-414')) {
    return specSheetsLink['15-414L'];
  } else if (modelNumber.startsWith('15-416') && modelNumber.includes('W')) {
    return specSheetsLink['15-416W'];
  } else if (modelNumber.startsWith('15-416')) {
    return specSheetsLink['15-416L'];
  } else if (modelNumber.startsWith('15-425') && modelNumber.includes('W')) {
    return specSheetsLink['15-425W'];
  } else if (modelNumber.startsWith('15-425')) {
    return specSheetsLink['15-425L'];
  } else if (modelNumber.startsWith('15-430') && modelNumber.includes('W')) {
    return specSheetsLink['15-430W'];
  } else if (modelNumber.startsWith('15-430')) {
    return specSheetsLink['15-430L'];
  } else if (modelNumber.startsWith('15-431') && modelNumber.includes('W')) {
    return specSheetsLink['15-431W'];
  } else if (modelNumber.startsWith('15-431')) {
    return specSheetsLink['15-431L'];
  } else if (modelNumber.startsWith('15-432') && modelNumber.includes('W')) {
    return specSheetsLink['15-432W'];
  } else if (modelNumber.startsWith('15-432')) {
    return specSheetsLink['15-432L'];
  } else if (modelNumber.startsWith('15-501') && modelNumber.includes('W')) {
    return specSheetsLink['15-501W'];
  } else if (modelNumber.startsWith('15-501')) {
    return specSheetsLink['15-501L'];
  } else if (modelNumber.startsWith('15-502') && modelNumber.includes('W')) {
    return specSheetsLink['15-502W'];
  } else if (modelNumber.startsWith('15-502')) {
    return specSheetsLink['15-502L'];
  } else if (modelNumber.startsWith('15-506') && modelNumber.includes('W')) {
    return specSheetsLink['15-506W'];
  } else if (modelNumber.startsWith('15-506')) {
    return specSheetsLink['15-506L'];
  } else if (modelNumber.startsWith('15-508') && modelNumber.includes('W')) {
    return specSheetsLink['15-508W'];
  } else if (modelNumber.startsWith('15-508')) {
    return specSheetsLink['15-508L'];
  } else if (modelNumber.startsWith('15-510') && modelNumber.includes('W')) {
    return specSheetsLink['15-510W'];
  } else if (modelNumber.startsWith('15-510')) {
    return specSheetsLink['15-510L'];
  } else if (modelNumber.startsWith('15-512') && modelNumber.includes('W')) {
    return specSheetsLink['15-512W'];
  } else if (modelNumber.startsWith('15-512')) {
    return specSheetsLink['15-512L'];
  } else if (modelNumber.startsWith('15-514') && modelNumber.includes('W')) {
    return specSheetsLink['15-514W'];
  } else if (modelNumber.startsWith('15-514')) {
    return specSheetsLink['15-514L'];
  } else if (modelNumber.startsWith('15-516') && modelNumber.includes('W')) {
    return specSheetsLink['15-516W'];
  } else if (modelNumber.startsWith('15-516')) {
    return specSheetsLink['15-516L'];
  } else if (modelNumber.startsWith('15-525') && modelNumber.includes('W')) {
    return specSheetsLink['15-525W'];
  } else if (modelNumber.startsWith('15-525')) {
    return specSheetsLink['15-525L'];
  } else if (modelNumber.startsWith('15-530') && modelNumber.includes('W')) {
    return specSheetsLink['15-530W'];
  } else if (modelNumber.startsWith('15-530')) {
    return specSheetsLink['15-530L'];
  } else if (modelNumber.startsWith('15-531') && modelNumber.includes('W')) {
    return specSheetsLink['15-531W'];
  } else if (modelNumber.startsWith('15-531')) {
    return specSheetsLink['15-531L'];
  } else if (modelNumber.startsWith('15-532') && modelNumber.includes('W')) {
    return specSheetsLink['15-532W'];
  } else if (modelNumber.startsWith('15-532')) {
    return specSheetsLink['15-532L'];
  } else if (modelNumber.startsWith('15-801') && modelNumber.includes('W')) {
    return specSheetsLink['15-801W'];
  } else if (modelNumber.startsWith('15-801')) {
    return specSheetsLink['15-801L'];
  } else if (modelNumber.startsWith('15-802') && modelNumber.includes('W')) {
    return specSheetsLink['15-802W'];
  } else if (modelNumber.startsWith('15-802')) {
    return specSheetsLink['15-802L'];
  } else if (modelNumber.startsWith('15-806') && modelNumber.includes('W')) {
    return specSheetsLink['15-806W'];
  } else if (modelNumber.startsWith('15-806')) {
    return specSheetsLink['15-806L'];
  } else if (modelNumber.startsWith('15-808') && modelNumber.includes('W')) {
    return specSheetsLink['15-808W'];
  } else if (modelNumber.startsWith('15-808')) {
    return specSheetsLink['15-808L'];
  } else if (modelNumber.startsWith('15-810') && modelNumber.includes('W')) {
    return specSheetsLink['15-810W'];
  } else if (modelNumber.startsWith('15-810')) {
    return specSheetsLink['15-810L'];
  } else if (modelNumber.startsWith('15-812') && modelNumber.includes('W')) {
    return specSheetsLink['15-812W'];
  } else if (modelNumber.startsWith('15-812')) {
    return specSheetsLink['15-812L'];
  } else if (modelNumber.startsWith('15-814') && modelNumber.includes('W')) {
    return specSheetsLink['15-814W'];
  } else if (modelNumber.startsWith('15-814')) {
    return specSheetsLink['15-814L'];
  } else if (modelNumber.startsWith('15-816') && modelNumber.includes('W')) {
    return specSheetsLink['15-816W'];
  } else if (modelNumber.startsWith('15-816')) {
    return specSheetsLink['15-816L'];
  } else if (modelNumber.startsWith('15-825') && modelNumber.includes('W')) {
    return specSheetsLink['15-825W'];
  } else if (modelNumber.startsWith('15-825')) {
    return specSheetsLink['15-825L'];
  } else if (modelNumber.startsWith('15-830') && modelNumber.includes('W')) {
    return specSheetsLink['15-830W'];
  } else if (modelNumber.startsWith('15-830')) {
    return specSheetsLink['15-830L'];
  } else if (modelNumber.startsWith('15-831') && modelNumber.includes('W')) {
    return specSheetsLink['15-831W'];
  } else if (modelNumber.startsWith('15-831')) {
    return specSheetsLink['15-831L'];
  } else if (modelNumber.startsWith('15-832') && modelNumber.includes('W')) {
    return specSheetsLink['15-832W'];
  } else if (modelNumber.startsWith('15-832')) {
    return specSheetsLink['15-832L'];
  }
};

const specSheetsLink = {
  '14-400L': 'https://krowne.com/marketing/specs/plumbing/14-400L-L%20Spec%20Sheet.pdf',
  '14-400W': 'https://krowne.com/marketing/specs/plumbing/14-400L-W%20Spec%20Sheet.pdf',
  '14-401L': 'https://krowne.com/marketing/specs/plumbing/14-401L-L%20Spec%20Sheet.pdf',
  '14-401W': 'https://krowne.com/marketing/specs/plumbing/14-401L-W%20Spec%20Sheet.pdf',
  '14-402L': 'https://krowne.com/marketing/specs/plumbing/14-402L-L%20Spec%20Sheet.pdf',
  '14-402W': 'https://krowne.com/marketing/specs/plumbing/14-402L-W%20Spec%20Sheet.pdf',
  '14-406L': 'https://krowne.com/marketing/specs/plumbing/14-406L-L%20Spec%20Sheet.pdf',
  '14-406W': 'https://krowne.com/marketing/specs/plumbing/14-406L-W%20Spec%20Sheet.pdf',
  '14-408L': 'https://krowne.com/marketing/specs/plumbing/14-408L-L%20Spec%20Sheet.pdf',
  '14-408W': 'https://krowne.com/marketing/specs/plumbing/14-408L-W%20Spec%20Sheet.pdf',
  '14-410L': 'https://krowne.com/marketing/specs/plumbing/14-410L-L%20Spec%20Sheet.pdf',
  '14-410W': 'https://krowne.com/marketing/specs/plumbing/14-410L-W%20Spec%20Sheet.pdf',
  '14-412L': 'https://krowne.com/marketing/specs/plumbing/14-412L-L%20Spec%20Sheet.pdf',
  '14-412W': 'https://krowne.com/marketing/specs/plumbing/14-412L-W%20Spec%20Sheet.pdf',
  '14-414L': 'https://krowne.com/marketing/specs/plumbing/14-414L-L%20Spec%20Sheet.pdf',
  '14-414W': 'https://krowne.com/marketing/specs/plumbing/14-414L-W%20Spec%20Sheet.pdf',
  '14-416L': 'https://krowne.com/marketing/specs/plumbing/14-416L-L%20Spec%20Sheet.pdf',
  '14-416W': 'https://krowne.com/marketing/specs/plumbing/14-416L-W%20Spec%20Sheet.pdf',
  '14-430L': 'https://krowne.com/marketing/specs/plumbing/14-430L-L%20Spec%20Sheet.pdf',
  '14-430W': 'https://krowne.com/marketing/specs/plumbing/14-430L-W%20Spec%20Sheet.pdf',
  '14-431L': 'https://krowne.com/marketing/specs/plumbing/14-431L-L%20Spec%20Sheet.pdf',
  '14-432L': 'https://krowne.com/marketing/specs/plumbing/14-432L-L%20Spec%20Sheet.pdf',
  '14-432W': 'https://krowne.com/marketing/specs/plumbing/14-432L-W%20Spec%20Sheet.pdf',
  '14-801L': 'https://krowne.com/marketing/specs/plumbing/14-801L-L%20Spec%20Sheet.pdf',
  '14-801W': 'https://krowne.com/marketing/specs/plumbing/14-801L-W%20Spec%20Sheet.pdf',
  '14-802L': 'https://krowne.com/marketing/specs/plumbing/14-802L-L%20Spec%20Sheet.pdf',
  '14-802W': 'https://krowne.com/marketing/specs/plumbing/14-802L-W%20Spec%20Sheet.pdf',
  '14-806L': 'https://krowne.com/marketing/specs/plumbing/14-806L-L%20Spec%20Sheet.pdf',
  '14-806W': 'https://krowne.com/marketing/specs/plumbing/14-806L-W%20Spec%20Sheet.pdf',
  '14-808L': 'https://krowne.com/marketing/specs/plumbing/14-808L-L%20Spec%20Sheet.pdf',
  '14-808W': 'https://krowne.com/marketing/specs/plumbing/14-808L-W%20Spec%20Sheet.pdf',
  '14-810L': 'https://krowne.com/marketing/specs/plumbing/14-810L-L%20Spec%20Sheet.pdf',
  '14-810W': 'https://krowne.com/marketing/specs/plumbing/14-810L-W%20Spec%20Sheet.pdf',
  '14-812L': 'https://krowne.com/marketing/specs/plumbing/14-812L-L%20Spec%20Sheet.pdf',
  '14-812W': 'https://krowne.com/marketing/specs/plumbing/14-812L-W%20Spec%20Sheet.pdf',
  '14-814L': 'https://krowne.com/marketing/specs/plumbing/14-814L-L%20Spec%20Sheet.pdf',
  '14-814W': 'https://krowne.com/marketing/specs/plumbing/14-814L-W%20Spec%20Sheet.pdf',
  '14-816L': 'https://krowne.com/marketing/specs/plumbing/14-816L-L%20Spec%20Sheet.pdf',
  '14-816W': 'https://krowne.com/marketing/specs/plumbing/14-816L-W%20Spec%20Sheet.pdf',
  '14-831L': 'https://krowne.com/marketing/specs/plumbing/14-831L-L%20Spec%20Sheet.pdf',
  '14-831W': 'https://krowne.com/marketing/specs/plumbing/14-831L-W%20Spec%20Sheet.pdf',
  '14-832L': 'https://krowne.com/marketing/specs/plumbing/14-832L-L%20Spec%20Sheet.pdf',
  '14-832W': 'https://krowne.com/marketing/specs/plumbing/14-832L-W%20Spec%20Sheet.pdf',
  '15-301L': 'https://krowne.com/marketing/specs/plumbing/15-301L-L%20Spec%20Sheet.pdf',
  '15-301W': 'https://krowne.com/marketing/specs/plumbing/15-301L-W%20Spec%20Sheet.pdf',
  '15-302L': 'https://krowne.com/marketing/specs/plumbing/15-302L-L%20Spec%20Sheet.pdf',
  '15-302W': 'https://krowne.com/marketing/specs/plumbing/15-302L-W%20Spec%20Sheet.pdf',
  '15-306L': 'https://krowne.com/marketing/specs/plumbing/15-306L-L%20Spec%20Sheet.pdf',
  '15-306W': 'https://krowne.com/marketing/specs/plumbing/15-306L-W%20Spec%20Sheet.pdf',
  '15-308L': 'https://krowne.com/marketing/specs/plumbing/15-308L-L%20Spec%20Sheet.pdf',
  '15-308W': 'https://krowne.com/marketing/specs/plumbing/15-308L-W%20Spec%20Sheet.pdf',
  '15-310L': 'https://krowne.com/marketing/specs/plumbing/15-310L-L%20Spec%20Sheet.pdf',
  '15-310W': 'https://krowne.com/marketing/specs/plumbing/15-310L-W%20Spec%20Sheet.pdf',
  '15-312L': 'https://krowne.com/marketing/specs/plumbing/15-312L-L%20Spec%20Sheet.pdf',
  '15-312W': 'https://krowne.com/marketing/specs/plumbing/15-312L-W%20Spec%20Sheet.pdf',
  '15-314L': 'https://krowne.com/marketing/specs/plumbing/15-314L-L%20Spec%20Sheet.pdf',
  '15-314W': 'https://krowne.com/marketing/specs/plumbing/15-314L-W%20Spec%20Sheet.pdf',
  '15-316L': 'https://krowne.com/marketing/specs/plumbing/15-316L-L%20Spec%20Sheet.pdf',
  '15-316W': 'https://krowne.com/marketing/specs/plumbing/15-316L-W%20Spec%20Sheet.pdf',
  '15-325L': 'https://krowne.com/marketing/specs/plumbing/15-325L-L%20Spec%20Sheet.pdf',
  '15-325W': 'https://krowne.com/marketing/specs/plumbing/15-325L-W%20Spec%20Sheet.pdf',
  '15-330L': 'https://krowne.com/marketing/specs/plumbing/15-330L-L%20Spec%20Sheet.pdf',
  '15-330W': 'https://krowne.com/marketing/specs/plumbing/15-330L-W%20Spec%20Sheet.pdf',
  '15-331L': 'https://krowne.com/marketing/specs/plumbing/15-331L-L%20Spec%20Sheet.pdf',
  '15-331W': 'https://krowne.com/marketing/specs/plumbing/15-331L-W%20Spec%20Sheet.pdf',
  '15-332L': 'https://krowne.com/marketing/specs/plumbing/15-332L-L%20Spec%20Sheet.pdf',
  '15-332W': 'https://krowne.com/marketing/specs/plumbing/15-332L-W%20Spec%20Sheet.pdf',
  '15-401L': 'https://krowne.com/marketing/specs/plumbing/15-401L-L%20Spec%20Sheet.pdf',
  '15-401W': 'https://krowne.com/marketing/specs/plumbing/15-401L-W%20Spec%20Sheet.pdf',
  '15-402L': 'https://krowne.com/marketing/specs/plumbing/15-402L-L%20Spec%20Sheet.pdf',
  '15-402W': 'https://krowne.com/marketing/specs/plumbing/15-402L-W%20Spec%20Sheet.pdf',
  '15-406L': 'https://krowne.com/marketing/specs/plumbing/15-406L-L%20Spec%20Sheet.pdf',
  '15-406W': 'https://krowne.com/marketing/specs/plumbing/15-406L-W%20Spec%20Sheet.pdf',
  '15-408L': 'https://krowne.com/marketing/specs/plumbing/15-408L-L%20Spec%20Sheet.pdf',
  '15-408W': 'https://krowne.com/marketing/specs/plumbing/15-408L-W%20Spec%20Sheet.pdf',
  '15-410L': 'https://krowne.com/marketing/specs/plumbing/15-410L-L%20Spec%20Sheet.pdf',
  '15-410W': 'https://krowne.com/marketing/specs/plumbing/15-410L-W%20Spec%20Sheet.pdf',
  '15-412L': 'https://krowne.com/marketing/specs/plumbing/15-412L-L%20Spec%20Sheet.pdf',
  '15-412W': 'https://krowne.com/marketing/specs/plumbing/15-412L-W%20Spec%20Sheet.pdf',
  '15-414L': 'https://krowne.com/marketing/specs/plumbing/15-414L-L%20Spec%20Sheet.pdf',
  '15-414W': 'https://krowne.com/marketing/specs/plumbing/15-414L-W%20Spec%20Sheet.pdf',
  '15-416L': 'https://krowne.com/marketing/specs/plumbing/15-416L-L%20Spec%20Sheet.pdf',
  '15-416W': 'https://krowne.com/marketing/specs/plumbing/15-416L-W%20Spec%20Sheet.pdf',
  '15-425L': 'https://krowne.com/marketing/specs/plumbing/15-425L-L%20Spec%20Sheet.pdf',
  '15-425W': 'https://krowne.com/marketing/specs/plumbing/15-425L-W%20Spec%20Sheet.pdf',
  '15-430L': 'https://krowne.com/marketing/specs/plumbing/15-430L-L%20Spec%20Sheet.pdf',
  '15-430W': 'https://krowne.com/marketing/specs/plumbing/15-430L-W%20Spec%20Sheet.pdf',
  '15-431L': 'https://krowne.com/marketing/specs/plumbing/15-431L-L%20Spec%20Sheet.pdf',
  '15-431W': 'https://krowne.com/marketing/specs/plumbing/15-431L-W%20Spec%20Sheet.pdf',
  '15-432L': 'https://krowne.com/marketing/specs/plumbing/15-432L-L%20Spec%20Sheet.pdf',
  '15-432W': 'https://krowne.com/marketing/specs/plumbing/15-432L-W%20Spec%20Sheet.pdf',
  '15-501L': 'https://krowne.com/marketing/specs/plumbing/15-501L-L%20Spec%20Sheet.pdf',
  '15-501W': 'https://krowne.com/marketing/specs/plumbing/15-501L-W%20Spec%20Sheet.pdf',
  '15-502L': 'https://krowne.com/marketing/specs/plumbing/15-502L-L%20Spec%20Sheet.pdf',
  '15-502W': 'https://krowne.com/marketing/specs/plumbing/15-502L-W%20Spec%20Sheet.pdf',
  '15-506L': 'https://krowne.com/marketing/specs/plumbing/15-506L-L%20Spec%20Sheet.pdf',
  '15-506W': 'https://krowne.com/marketing/specs/plumbing/15-506L-W%20Spec%20Sheet.pdf',
  '15-508L': 'https://krowne.com/marketing/specs/plumbing/15-508L-L%20Spec%20Sheet.pdf',
  '15-508W': 'https://krowne.com/marketing/specs/plumbing/15-508L-W%20Spec%20Sheet.pdf',
  '15-510L': 'https://krowne.com/marketing/specs/plumbing/15-510L-L%20Spec%20Sheet.pdf',
  '15-510W': 'https://krowne.com/marketing/specs/plumbing/15-510L-W%20Spec%20Sheet.pdf',
  '15-512L': 'https://krowne.com/marketing/specs/plumbing/15-512L-L%20Spec%20Sheet.pdf',
  '15-512W': 'https://krowne.com/marketing/specs/plumbing/15-512L-W%20Spec%20Sheet.pdf',
  '15-514L': 'https://krowne.com/marketing/specs/plumbing/15-514L-L%20Spec%20Sheet.pdf',
  '15-514W': 'https://krowne.com/marketing/specs/plumbing/15-514L-W%20Spec%20Sheet.pdf',
  '15-516L': 'https://krowne.com/marketing/specs/plumbing/15-516L-L%20Spec%20Sheet.pdf',
  '15-516W': 'https://krowne.com/marketing/specs/plumbing/15-516L-W%20Spec%20Sheet.pdf',
  '15-525L': 'https://krowne.com/marketing/specs/plumbing/15-525L-L%20Spec%20Sheet.pdf',
  '15-525W': 'https://krowne.com/marketing/specs/plumbing/15-525L-W%20Spec%20Sheet.pdf',
  '15-530L': 'https://krowne.com/marketing/specs/plumbing/15-530L-L%20Spec%20Sheet.pdf',
  '15-530W': 'https://krowne.com/marketing/specs/plumbing/15-530L-W%20Spec%20Sheet.pdf',
  '15-531L': 'https://krowne.com/marketing/specs/plumbing/15-531L-L%20Spec%20Sheet.pdf',
  '15-531W': 'https://krowne.com/marketing/specs/plumbing/15-531L-W%20Spec%20Sheet.pdf',
  '15-532L': 'https://krowne.com/marketing/specs/plumbing/15-532L-L%20Spec%20Sheet.pdf',
  '15-532W': 'https://krowne.com/marketing/specs/plumbing/15-532L-W%20Spec%20Sheet.pdf',
  '15-801L': 'https://krowne.com/marketing/specs/plumbing/15-801L-L%20Spec%20Sheet.pdf',
  '15-801W': 'https://krowne.com/marketing/specs/plumbing/15-801L-W%20Spec%20Sheet.pdf',
  '15-802L': 'https://krowne.com/marketing/specs/plumbing/15-802L-L%20Spec%20Sheet.pdf',
  '15-802W': 'https://krowne.com/marketing/specs/plumbing/15-802L-W%20Spec%20Sheet.pdf',
  '15-806L': 'https://krowne.com/marketing/specs/plumbing/15-806L-L%20Spec%20Sheet.pdf',
  '15-806W': 'https://krowne.com/marketing/specs/plumbing/15-806L-W%20Spec%20Sheet.pdf',
  '15-808L': 'https://krowne.com/marketing/specs/plumbing/15-808L-L%20Spec%20Sheet.pdf',
  '15-808W': 'https://krowne.com/marketing/specs/plumbing/15-808L-W%20Spec%20Sheet.pdf',
  '15-810L': 'https://krowne.com/marketing/specs/plumbing/15-810L-L%20Spec%20Sheet.pdf',
  '15-810W': 'https://krowne.com/marketing/specs/plumbing/15-810L-W%20Spec%20Sheet.pdf',
  '15-812L': 'https://krowne.com/marketing/specs/plumbing/15-812L-L%20Spec%20Sheet.pdf',
  '15-812W': 'https://krowne.com/marketing/specs/plumbing/15-812L-W%20Spec%20Sheet.pdf',
  '15-814L': 'https://krowne.com/marketing/specs/plumbing/15-814L-L%20Spec%20Sheet.pdf',
  '15-814W': 'https://krowne.com/marketing/specs/plumbing/15-814L-W%20Spec%20Sheet.pdf',
  '15-816L': 'https://krowne.com/marketing/specs/plumbing/15-816L-L%20Spec%20Sheet.pdf',
  '15-816W': 'https://krowne.com/marketing/specs/plumbing/15-816L-W%20Spec%20Sheet.pdf',
  '15-825L': 'https://krowne.com/marketing/specs/plumbing/15-825L-L%20Spec%20Sheet.pdf',
  '15-825W': 'https://krowne.com/marketing/specs/plumbing/15-825L-W%20Spec%20Sheet.pdf',
  '15-830L': 'https://krowne.com/marketing/specs/plumbing/15-830L-L%20Spec%20Sheet.pdf',
  '15-830W': 'https://krowne.com/marketing/specs/plumbing/15-830L-W%20Spec%20Sheet.pdf',
  '15-831L': 'https://krowne.com/marketing/specs/plumbing/15-831L-L%20Spec%20Sheet.pdf',
  '15-831W': 'https://krowne.com/marketing/specs/plumbing/15-831L-W%20Spec%20Sheet.pdf',
  '15-832L': 'https://krowne.com/marketing/specs/plumbing/15-832L-L%20Spec%20Sheet.pdf',
  '15-832W': 'https://krowne.com/marketing/specs/plumbing/15-832L-W%20Spec%20Sheet.pdf',
};

const getPartSeries = part => {
  const parameter = part.parameters.find(p => p.key === 'series');
  if (parameter) {
    return parameter.valueLabel;
  } else {
    return ' ';
  }
};

export const getSortedPartListForFaucetFlex = partlist => {
  let parts = [];

  const originPart = partlist.perMainComponent[0].originPart;
  originPart.specSheet = getPartSpecSheet(originPart.articleNr);
  originPart.category = getPartSeries(originPart);

  parts.push(originPart);

  for (const part of partlist.fullList) {
    if (part.componentId === originPart.componentId) {
      continue;
    }

    part.specSheet = getPartSpecSheet(part.articleNr);
    part.category = getPartSeries(part);

    parts.push(part);
  }

  return parts;
};
