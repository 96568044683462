import { IRootState } from 'app/shared/reducers';
import { AvFeedback, AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Button, Label } from 'reactstrap';
import { handleRegister, reset } from './register.reducer';
import UnauthorizedPagesContainer from 'app/modules/login/UnauthorizedPagesContainer';
import Select from 'react-select';
import { AUTHORITIES } from 'app/config/constants';
import { getDealerDataByName } from 'app/entities/dealer-data/dealer-data.reducer';
import { getConsultantDataByName } from 'app/entities/consultant-data/consultant-data.reducer';
import { getManufacturerDataByName } from 'app/entities/manufacturer-data/manufacturer-data.reducer';
import { getManufacturerRefByName } from 'app/entities/manufacturer-ref-data/manufacturer-ref-data.reducer';
import useCompanySetUp from 'app/customHooks/useCompanySetUp';
import SignUpProgressBar from 'app/components/SignUpStepProgressBar/SignUpStepProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import SignUpStepsNavigation from './SignUpStepsNavigation';
import './register.scss';

export interface IRegisterProps extends StateProps, DispatchProps, RouteComponentProps {}

export const RegisterPage = (props: IRegisterProps) => {
  const {
    dataForCompaniesSelect,
    selectedIndustry,
    setSelectedIndustry,
    selectedAccountType,
    setSelectedAccountType,
    isCompanyLoading,
    debouncedFindCompanyByName,
    industryOptions,
    options,
    setDataForCompaniesSelect,
  } = useCompanySetUp(props);

  const [isAdminRoleSelected, setIsAdminAccountSelected] = useState<boolean>(false);
  const [userCompanyId, setUserCompanyId] = useState(null);
  const [isOutOfUSA, setIsOutOfUSA] = useState<boolean | string>('not checked');
  const [userSpecialization, setUserSpecialization] = useState(null);
  const [step, setStep] = useState<number>(1);
  const [formValues, setformValues] = useState({
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    passwordConfirmation: null,
    zipCode: null,
    company: null,
  });
  const [countries, setCountries] = useState([]);
  const [userCountry, setUserCountry] = useState(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState<boolean>(false);
  const [myCompanyIsNotInTheList, setMyCompanyIsNotInTheList] = useState<boolean>(false);
  const [isAccountTypeChecked, setIsAccountTypeChecked] = useState<boolean>(false);

  useEffect(() => {
    fetch('https://restcountries.com/v3.1/all')
      .then(response => response.json())
      .then(data => {
        const countryOptions = data
          .map(country => ({ value: country.cca3, label: country.name.common }))
          .sort((a, b) => (a.label > b.label ? 1 : -1));
        setCountries(countryOptions);
      });
  }, []);

  useEffect(
    () => () => {
      props.reset();
    },
    []
  );

  useEffect(() => {
    if (props.registrationSuccess) {
      setNextStep();
      setTimeout(() => {
        redirectToSignIn();
      }, 5000);
    }
  }, [props.registrationSuccess]);

  useEffect(() => {
    setAdminAccount();
  }, [isAdminRoleSelected]);

  const redirectToSignIn = () => {
    props.history.push('/login');
  };

  const handleValidSubmit = (event, values) => {
    props.handleRegister(
      values.email,
      values.password,
      values.firstname,
      values.lastname,
      formValues.company,
      'en',
      selectedAccountType,
      formValues.zipCode,
      userCompanyId,
      selectedIndustry,
      isOutOfUSA,
      userCountry,
      userSpecialization
    );
    event.preventDefault();
  };

  const setAdminAccount = () => {
    if (isAdminRoleSelected) {
      if (selectedAccountType === AUTHORITIES.ROLE_DEALER_USER) {
        setSelectedAccountType(AUTHORITIES.ROLE_DEALER_ADMIN);
      }
      if (selectedAccountType === AUTHORITIES.ROLE_MANUFACTURER_USER) {
        setSelectedAccountType(AUTHORITIES.ROLE_MANUFACTURER_ADMIN);
      }
      if (selectedAccountType === AUTHORITIES.ROLE_CONSULTANT_USER) {
        setSelectedAccountType(AUTHORITIES.ROLE_CONSULTANT_ADMIN);
      }
      if (selectedAccountType === AUTHORITIES.ROLE_MANUFACTURER_REP_USER) {
        setSelectedAccountType(AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN);
      }
    }

    if (!isAdminRoleSelected) {
      if (selectedAccountType === AUTHORITIES.ROLE_DEALER_ADMIN) {
        setSelectedAccountType(AUTHORITIES.ROLE_DEALER_USER);
      }
      if (selectedAccountType === AUTHORITIES.ROLE_MANUFACTURER_ADMIN) {
        setSelectedAccountType(AUTHORITIES.ROLE_MANUFACTURER_USER);
      }
      if (selectedAccountType === AUTHORITIES.ROLE_CONSULTANT_ADMIN) {
        setSelectedAccountType(AUTHORITIES.ROLE_CONSULTANT_USER);
      }
      if (selectedAccountType === AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN) {
        setSelectedAccountType(AUTHORITIES.ROLE_MANUFACTURER_REP_USER);
      }
    }
  };

  const customSelectStyles = {
    placeholder(base, state) {
      return {
        ...base,
        opacity: 0.5,
      };
    },
    menuList: (provided, state) => ({
      ...provided,
      width: 343,
      maxHeight: 210,
      border: '1px solid #CBCBCB',
      background: '#FCFBFC',
      marginTop: -3,
      boxShadow: '0px 15px 30px #a1b0cf14',
      borderRadius: 12,
      marginLeft: -2,

      '::-webkit-scrollbar': {
        width: 8,
      },
      '::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: '#C7C7C7',
        borderRadius: 8,
        height: 70,
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#C4C4C4',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 16,
      color: '#000',
      fontWeight: 500,
      '&:hover': {
        background: 'transparent',
        color: '#E2863C',
      },
      background: 'transparent',
      cursor: 'pointer',
    }),
  };

  const setNextStep = () => {
    setStep(step + 1);
  };

  const setPrevStep = () => {
    setStep(step - 1);
  };

  return (
    <React.Fragment>
      <UnauthorizedPagesContainer
        className="sign-in-backgournd-image"
        image="../../../content/images/sign-up-image.svg"
        content={
          <SignUpStepsNavigation
            step={step}
            isOutOfUSA={isOutOfUSA}
            formValues={formValues}
            selectedIndustry={selectedIndustry}
            userSpecialization={userSpecialization}
            userCompanyId={userCompanyId}
            userCountry={userCountry}
          />
        }
      >
        <div className="sign-up-progress-bar-external-container">
          <img src="../../../content/images/kreator-navbar-logo.svg" alt="kreator logo" className="login-page-kreator-logo--register" />
          <SignUpProgressBar step={step} />
          <div className="sign-up-progress-bar-steps-container">
            <div className={`sign-up-progress-bar-step-item ${step >= 1 ? 'sign-up-progress-bar-step-item--active' : ''}`}>1</div>
            <div className={`sign-up-progress-bar-step-item ${step >= 2 ? 'sign-up-progress-bar-step-item--active' : ''}`}>2</div>
            <div className={`sign-up-progress-bar-step-item ${step >= 3 ? 'sign-up-progress-bar-step-item--active' : ''}`}>3</div>
          </div>
        </div>

        {step !== 4 ? (
          <div>
            <img
              src="../../../content/images/kreator-navbar-logo.svg"
              alt="kreator logo"
              className="login-page-kreator-logo login-page-kreator-logo--register--hide"
            />
            <div>
              <h1 className="sign-in-title">Welcome to Kreator</h1>
              <p className="sign-in-welcome-description">
                Kreator is a commercial foodservice equipment design platform that combines 3D product configurators with a 3D floorplanner,
                AR/VR visualization, and Revit exporting.
              </p>
            </div>
          </div>
        ) : (
          <div className="sign-up-confirmation-block">
            <div style={{ textAlign: 'center', marginBottom: 100 }}>
              <img src="../../../../content/images/kreator-navbar-logo.svg" alt="Kreator logo" width="150" />
            </div>
            <div className="sign-up-confirmation-data-block">
              <div className="sign-up-sent-message-circle">
                <img src="../../../../content/images/send-message.svg" width="70" alt="sent message" />
              </div>
              <h3 className="sign-up-confirmation-data-title">Check your Email</h3>
              <p className="sign-up-confirmation-data-description">
                Thank you for joining Kreator! Please check and confirm your email to finish up Sign Up process
              </p>
            </div>
            <Button type="button" className="sign-in-button">
              You’ll be redirected in 5 seconds
            </Button>
          </div>
        )}

        <AvForm className="sign-up-form" onValidSubmit={handleValidSubmit}>
          {step === 1 && (
            <>
              <div>
                <h3 className="sign-up-step-title">What’s your location?</h3>
                <div className="sign-up-custom-radio-container">
                  <div className="sign-up-custom-radio">
                    <img src="../../../../content/images/icons/sign-up/usa.png" className="sign-up-button-icon" />
                    <input
                      type="radio"
                      id={'US'}
                      value={'false'}
                      checked={typeof isOutOfUSA === 'boolean' && !isOutOfUSA}
                      name="isOutOfUSA"
                      onChange={() => {
                        setIsOutOfUSA(false);
                        setSelectedIndustry(null);
                        setUserCountry(null);
                      }}
                    />
                    <label htmlFor={'US'}>United States</label>
                  </div>
                  <div className="sign-up-custom-radio">
                    <img src="../../../../content/images/icons/sign-up/out-of-usa.png" className="sign-up-button-icon" />
                    <input
                      type="radio"
                      id={'outsideUS'}
                      value={'true'}
                      checked={typeof isOutOfUSA === 'boolean' && isOutOfUSA}
                      name="isOutOfUSA"
                      onChange={() => {
                        setformValues({ ...formValues, zipCode: null });
                        setIsOutOfUSA(true);
                        setSelectedIndustry(null);
                      }}
                    />
                    <label htmlFor={'outsideUS'}>Outside of United States</label>
                  </div>
                </div>
              </div>
              <div className="sign-up-first-step-reserved-space">
                {isOutOfUSA && isOutOfUSA !== 'not checked' && <h3 className="sign-up-step-title">What country you are based?</h3>}
                {!isOutOfUSA && isOutOfUSA !== 'not checked' && <h3 className="sign-up-step-title">What’s your ZIP Code?</h3>}
                {!isOutOfUSA && isOutOfUSA !== 'not checked' && (
                  <div className="field-block position-relative zip-code-field-block">
                    <img
                      src="../../../../content/images/icons/sign-up/zip-code.png"
                      className="sign-up-button-icon sign-up-zip-code-input-icon"
                    />
                    <AvField
                      name="zipCode"
                      type="text"
                      style={{ width: 124, paddingLeft: 35, paddingRight: 0 }}
                      className="sign-in-field"
                      placeholder={'ex. 00001'}
                      onInput={e => setformValues({ ...formValues, zipCode: e.target.value })}
                      value={formValues.zipCode}
                      validate={{
                        required: { value: true, errorMessage: 'ZIP code is required.' },
                        // pattern: { value: '/(^\\d{5}$)|(^\\d{5}-\\d{4}$)/', errorMessage: 'Invalid ZIP code' },
                      }}
                    />
                  </div>
                )}
                {isOutOfUSA === true && (
                  <div className="field-block position-relative">
                    <Select
                      options={countries}
                      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                      className="sign-up-select"
                      placeholder={'Select Country'}
                      styles={customSelectStyles}
                      value={countries.filter(option => option.label === userCountry)}
                      onChange={value => {
                        setUserCountry(value.label);
                      }}
                      noOptionsMessage={() => 'No results found'}
                    />
                    <img src="../../../../content/images/icons/arrow_down.svg" alt="arrow down" className="sign-up-select-arrow-down" />
                  </div>
                )}
                {isOutOfUSA !== 'not checked' && <h3 className="sign-up-step-title">Select Industry</h3>}
                {isOutOfUSA !== 'not checked' && (
                  <div className="sign-up-custom-radio-container">
                    {industryOptions.map(option => (
                      <div className="sign-up-custom-radio" key={option.value}>
                        <img src={`../../../../content/images/icons/sign-up/${option.iconName}.png`} className="sign-up-button-icon" />
                        <img src="" alt="" />
                        <input
                          type="radio"
                          id={option.value}
                          value={option.value}
                          name="industry"
                          onChange={() => {
                            setSelectedIndustry(option.value);
                            setformValues({ ...formValues, company: null });
                            setUserCompanyId(null);
                            setSelectedAccountType(AUTHORITIES.USER);
                            setUserSpecialization(null);
                          }}
                          checked={selectedIndustry === option.value}
                        />
                        <label htmlFor={option.value}>{option.label}</label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
          {step === 2 && (
            <>
              {selectedIndustry === 'Foodservice' && (
                <div>
                  <h3 className="sign-up-step-title">Account Type</h3>
                  <div className="sign-up-custom-radio-container">
                    {options.map(option => (
                      <div className="sign-up-custom-radio" key={option.specialization}>
                        <img src="../../../../content/images/icons/sign-up/man.png" className="sign-up-button-icon" />
                        <input
                          type="radio"
                          id={option.specialization}
                          value={option.specialization}
                          name="accountType"
                          checked={userSpecialization === option.specialization}
                          onChange={() => {
                            setSelectedAccountType(option.value);
                            setUserSpecialization(option.specialization);
                            setIsAdminAccountSelected(false);
                            setDataForCompaniesSelect([]);
                            setMyCompanyIsNotInTheList(false);
                            setIsAccountTypeChecked(true);
                            setUserCompanyId(null);
                            setformValues({ ...formValues, company: '' });
                          }}
                        />
                        <label htmlFor={option.specialization}>{option.label}</label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {selectedAccountType !== AUTHORITIES.USER && selectedIndustry === 'Foodservice' && !isOutOfUSA && (
                <div className="field-block position-relative sign-up-company-input-margin-2">
                  <h3 className="sign-up-step-title">Select your company</h3>
                  {!myCompanyIsNotInTheList && (
                    <Select
                      value={dataForCompaniesSelect.filter(option => option.value === userCompanyId)}
                      options={dataForCompaniesSelect.sort((a, b) => a.label.localeCompare(b.label))}
                      noOptionsMessage={() => 'No Company Found'}
                      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                      className="sign-up-select"
                      placeholder={'Type your company name'}
                      styles={customSelectStyles}
                      isLoading={isCompanyLoading}
                      onInputChange={value => debouncedFindCompanyByName(value)}
                      onChange={value => {
                        setformValues({ ...formValues, company: null });
                        setUserCompanyId(value.value);
                      }}
                    />
                  )}

                  <div className="sign-in-terms-and-conditions-block">
                    <AvGroup check className="terms-and-conditions-group">
                      <AvInput
                        name="myCompanyIsNotInTheList"
                        type="checkbox"
                        className="remember-me-checkbox"
                        onChange={() => {
                          setMyCompanyIsNotInTheList(!myCompanyIsNotInTheList);
                          setUserCompanyId(null);
                          setformValues({ ...formValues, company: '' });
                        }}
                        checked={myCompanyIsNotInTheList}
                        style={{ marginTop: 6.5 }}
                      />
                      <Label className="remember-me-label" for="termsAndConditions" style={{ marginTop: 9 }}>
                        My company is not in the list
                      </Label>
                    </AvGroup>
                  </div>
                </div>
              )}

              {(selectedAccountType === AUTHORITIES.USER || isOutOfUSA || myCompanyIsNotInTheList) && (
                <div
                  className={`field-block position-relative  ${
                    selectedIndustry !== 'Foodservice' ? 'sign-up-company-input-margin-1' : 'sign-up-company-input-margin-2'
                  }`}
                >
                  <h3 className="sign-up-step-title">What’s the name of your company?</h3>
                  <img
                    src="../../../../content/images/icons/sign-up/pencil.png"
                    className="sign-up-button-icon sign-up-zompany-input-icon"
                  />
                  <AvField
                    name="company"
                    type="company"
                    className="sign-in-field"
                    placeholder="ex. Medicover Healthcare"
                    style={{ paddingLeft: 45 }}
                    onInput={e => {
                      setformValues({ ...formValues, company: e.target.value });
                      setUserCompanyId(null);
                    }}
                    value={formValues.company}
                    validate={{
                      required: { value: true, errorMessage: 'My Company is required.' },
                      maxLength: { value: 254, errorMessage: 'My Company cannot be longer than 50 characters.' },
                    }}
                  />
                </div>
              )}
            </>
          )}
          {step === 3 && (
            <>
              <div className="sign-up-name-fields-block">
                <div className="field-block sign-up-name-field sign-up-third-step-input">
                  <span className="sign-up-field-label">First Name</span>
                  <AvField
                    name="firstname"
                    type="text"
                    className="sign-in-field"
                    onInput={e => setformValues({ ...formValues, firstName: e.target.value })}
                    validate={{
                      required: { value: true, errorMessage: 'First Name is required.' },
                      minLength: { value: 1, errorMessage: 'First Name is required to be at least 1 characters.' },
                      maxLength: { value: 254, errorMessage: 'First Name cannot be longer than 50 characters.' },
                    }}
                  />
                </div>

                <div className="field-block sign-up-name-field sign-up-third-step-input">
                  <span className="sign-up-field-label">Last Name</span>
                  <AvField
                    name="lastname"
                    type="text"
                    className="sign-in-field"
                    onInput={e => setformValues({ ...formValues, lastName: e.target.value })}
                    validate={{
                      required: { value: true, errorMessage: 'Last Name is required.' },
                      minLength: { value: 1, errorMessage: 'Last Name is required to be at least 5 characters.' },
                      maxLength: { value: 254, errorMessage: 'Last Name cannot be longer than 50 characters.' },
                    }}
                  />
                </div>
              </div>
              <div className="field-block sign-up-third-step-input">
                <span className="sign-up-field-label">Email</span>
                <AvField
                  name="email"
                  type="email"
                  className="sign-in-field"
                  placeholder="Enter Your Email"
                  onInput={e => setformValues({ ...formValues, email: e.target.value })}
                  validate={{
                    required: { value: true, errorMessage: 'Email cannot be empty!' },
                    pattern: { value: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$', errorMessage: 'Invalid email address' },
                  }}
                />
              </div>

              <div className="field-block sign-up-third-step-input password-input-container">
                <span className="sign-up-field-label">Password</span>
                <AvField
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  className="sign-in-field"
                  placeholder="Enter Your Password"
                  onInput={e => setformValues({ ...formValues, password: e.target.value })}
                  validate={{
                    required: { value: true, errorMessage: 'Your password is required.' },
                    minLength: { value: 4, errorMessage: 'Your password is required to be at least 4 characters.' },
                    maxLength: { value: 50, errorMessage: 'Your password cannot be longer than 50 characters.' },
                  }}
                />
                <button
                  className="sign-up-password-toggle-button password-toggle-button"
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  aria-label={showPassword ? 'Hide password' : 'Show password'}
                >
                  {showPassword ? <FontAwesomeIcon size="lg" icon={faEyeSlash} /> : <FontAwesomeIcon size="lg" icon={faEye} />}
                </button>
              </div>
              <div className="field-block sign-up-third-step-input password-input-container">
                <span className="sign-up-field-label">Confirm Password</span>
                <AvField
                  name="secondPassword"
                  type={showPasswordConfirmation ? 'text' : 'password'}
                  className="sign-in-field"
                  onInput={e => setformValues({ ...formValues, passwordConfirmation: e.target.value })}
                  validate={{
                    required: { value: true, errorMessage: 'Your confirmation password is required.' },
                    minLength: {
                      value: 4,
                      errorMessage: 'Your confirmation password is required to be at least 4 characters.',
                    },
                    maxLength: { value: 50, errorMessage: 'Your confirmation password cannot be longer than 50 characters.' },
                    match: { value: 'password', errorMessage: 'The password and its confirmation do not match!' },
                  }}
                />
                <button
                  className="sign-up-password-toggle-button password-toggle-button"
                  type="button"
                  onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                  aria-label={showPasswordConfirmation ? 'Hide password' : 'Show password'}
                >
                  {showPasswordConfirmation ? <FontAwesomeIcon size="lg" icon={faEyeSlash} /> : <FontAwesomeIcon size="lg" icon={faEye} />}
                </button>
              </div>
              <div className="sign-in-terms-and-conditions-block">
                <AvGroup check className="terms-and-conditions-group">
                  <AvInput name="termsAndConditions" type="checkbox" className="remember-me-checkbox" required />
                  <Label className="remember-me-label" for="termsAndConditions">
                    I agree to the{' '}
                    <a id="terms-check-label-ref" target="_blank" href="https://www.kitchautomation.com/termsandconditions/">
                      terms and conditions
                    </a>
                  </Label>
                  <AvFeedback>Terms and conditions is required.</AvFeedback>
                </AvGroup>
              </div>
            </>
          )}

          {step === 1 && (
            <Button
              onClick={setNextStep}
              type="button"
              className="sign-in-button next-step-button"
              disabled={isOutOfUSA ? !selectedIndustry || !userCountry : !selectedIndustry || !formValues.zipCode}
            >
              Next Step
            </Button>
          )}
          {step === 2 && (
            <Button
              onClick={setNextStep}
              type="button"
              className="sign-in-button next-step-button"
              disabled={(!isAccountTypeChecked && selectedIndustry === 'Foodservice') || (!formValues.company && !userCompanyId)}
            >
              Next Step
            </Button>
          )}

          {step === 3 && (
            <Button type="submit" className="sign-in-button next-step-button" disabled={props.loading}>
              Sign Up
            </Button>
          )}
          {step === 1 ? (
            <div className="do-not-have-an-account-block">
              <span className="do-not-have-an-account-block-text">Already have an account?</span>
              <Link className="do-not-have-an-account" to="/login">
                Sign In
              </Link>
            </div>
          ) : (
            step !== 4 && (
              <div className="do-not-have-an-account-block">
                <span className="do-not-have-an-account-block-text">Go Back to</span>
                <button className="sign-up-prev-step-button" type="button" onClick={setPrevStep}>
                  Previous Step
                </button>
              </div>
            )
          )}
        </AvForm>
      </UnauthorizedPagesContainer>
    </React.Fragment>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  registrationSuccess: storeState.register.registrationSuccess,
  loading: storeState.register.loading,
  dealerData: storeState.dealerData.entities,
  consultantData: storeState.consultantData.entities,
  manufacturerData: storeState.manufacturerData.entities,
  manufacturerRefData: storeState.manufacturerRefData.entities,
});

type StateProps = ReturnType<typeof mapStateToProps>;
const mapDispatchToProps = {
  handleRegister,
  reset,

  getDealerDataByName,
  getConsultantDataByName,
  getManufacturerDataByName,
  getManufacturerRefByName,
};
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
