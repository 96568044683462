import React from 'react';
import './marketplaceCartItem.scss';

interface IProductParameterItem {
  label: string;
  value: any;
}

const ProductParameterItem = (props: IProductParameterItem) => {
  return (
    <div className="product-parameter-container">
      <span className="product-parameter-label">{props.label}</span>
      {props.value}
    </div>
  );
};

export default ProductParameterItem;
