import React, { useState } from 'react';
import './dealerRegistrationProcessPages.scss';

interface IBusinessSkills {
  setSelectedSkills: Function;
  setSelectedBusinessStatus: Function;
  selectedSkills: any;
  selectedBusinessStatus: any;
}

const BusinessSkills = (props: IBusinessSkills) => {
  const skillsList = [
    { skillName: 'Installation', icon: 'installation' },
    { skillName: 'Delivery', icon: 'delivery' },
    { skillName: 'Refrigeration', icon: 'refrigeration' },
    { skillName: 'Fire Suppression', icon: 'fireSuppression' },
    { skillName: 'Welding', icon: 'weldingSkillIcon' },
  ];

  const specialBusinessStatuses = [
    { statusName: 'Women-Owned Small Business' },
    { statusName: 'Minority Business Enterprise ' },
    { statusName: 'Women Business Enterprise' },
    { statusName: 'Veteran owned small business' },
    { statusName: 'Women and Minority owned then' },
  ];

  const selectBusinessSkill = selectedSkill => {
    const exists = props.selectedSkills.includes(selectedSkill);
    const selectedArray = [...props.selectedSkills];

    if (exists) {
      props.setSelectedSkills(
        selectedArray.filter(item => {
          return item !== selectedSkill;
        })
      );
    } else {
      const result = selectedArray;
      result.push(selectedSkill);
      props.setSelectedSkills(result);
    }
  };

  return (
    <>
      <span className="business-field-label">Select Skills</span>
      <div className="business-skills-container">
        {skillsList.map(skill => (
          <button
            className={
              props.selectedSkills.includes(skill.skillName) ? 'business-skill-button selected-skill-button' : 'business-skill-button'
            }
            onClick={() => selectBusinessSkill(skill.skillName)}
          >
            <img src={`../../../content/images/icons/${skill.icon}.svg`} className="business-skill-icon" />
            {skill.skillName}
          </button>
        ))}
      </div>
      <div className="skills-divider"></div>
      <span className="business-field-label">Select if you qualify for the special status</span>
      <div className="business-skills-container">
        {specialBusinessStatuses.map(item => (
          <button
            className={
              props.selectedBusinessStatus.includes(item.statusName)
                ? 'business-skill-button selected-skill-button'
                : 'business-skill-button'
            }
            onClick={() => props.setSelectedBusinessStatus(item.statusName)}
          >
            <img src="../../../content/images/icons/businessStatus.svg" className="business-skill-icon" />
            {item.statusName}
          </button>
        ))}
      </div>
    </>
  );
};

export default BusinessSkills;
