import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import {Field, Form as ReactFinalForm} from 'react-final-form';

import {
  createEntity,
  getManufacturerForCurrentUser,
  reset,
  updateEntity
} from 'app/entities/manufacturer/manufacturer.reducer';
import ManufacturerFileUpload from "app/entities/manufacturer/ManufacturerFileUpload";

export interface IVersionUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const ManufacturerUpdate = (props: IVersionUpdateProps) => {
  const {manufacturerEntity, loading, updating} = props;

  const handleClose = () => {
    props.history.push('/my-manufacturer' + props.location.search);
  };

  useEffect(() => {
    props.getManufacturerForCurrentUser();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (values) => {
    const entity = {
      ...manufacturerEntity,
      ...values,
    };
    props.updateEntity(entity);
  };
  const identity = value => value;
  const required = value => (value ? undefined : <small color="danger">This field is required</small>);

  // @ts-ignore
  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="familyBuilderApiApp.version.home.createOrEditLabel">Create or edit a Version</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (<>
              <ReactFinalForm
                initialValues={manufacturerEntity}
                onSubmit={saveEntity}
                validate={values => {
                  const errors = {};
                  return errors;
                }}
                render={({handleSubmit, form, values}) => {
                  return (
                    <Form noValidate onSubmit={handleSubmit}>

                      <FormGroup className="mb-3">
                        <Field
                          render={({input, meta}) => (
                            <div className={'w-100'}>
                              <Label for="exampleEmail">
                                Name
                              </Label>
                              <Input {...input}/>
                              {meta.touched && meta.error && <span>{meta.error}</span>}
                            </div>
                          )}
                          parse={identity}
                          placeholder={""}
                          name="name"
                          validate={required}
                        />
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <Field
                          render={({input, meta}) => (
                            <div className={'w-100'}>
                              <Label for="exampleEmail">
                                Website
                              </Label>
                              <Input {...input}/>
                              {meta.touched && meta.error && <span>{meta.error}</span>}
                            </div>
                          )}
                          parse={identity}
                          placeholder={""}
                          name="websiteURL"
                          validate={required}

                        />
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <Field
                          render={({input, meta}) => (
                            <div className={'w-100'}>
                              <Label for="exampleEmail">
                                City
                              </Label>
                              <Input {...input}/>
                              {meta.touched && meta.error && <span>{meta.error}</span>}
                            </div>
                          )}
                          parse={identity}
                          placeholder={""}
                          name="city"
                          validate={required}

                        />
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <Field
                          render={({input, meta}) => (
                            <div className={'w-100'}>
                              <Label for="exampleEmail">
                                State
                              </Label>
                              <Input {...input}/>
                              {meta.touched && meta.error && <span>{meta.error}</span>}
                            </div>
                          )}
                          parse={identity}
                          placeholder={""}
                          name="state"
                          validate={required}

                        />
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <Field
                          render={({input, meta}) => (
                            <div className={'w-100'}>
                              <Label for="exampleEmail">
                                Logo
                              </Label>
                              <ManufacturerFileUpload setOutput={(data) => input.onChange(data)}/>
                              {meta.touched && meta.error && <span>{meta.error}</span>}
                            </div>
                          )}
                          parse={identity}
                          placeholder={""}
                          name="companyLogo"
                          validate={required}
                        />
                      </FormGroup>

                      <Button tag={Link} id="cancel-save" to="/my-manufacturer" replace color="info">
                        <FontAwesomeIcon icon="arrow-left"/>
                        &nbsp;
                        <span className="d-none d-md-inline">Back</span>
                      </Button>
                      &nbsp;
                      <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                        <FontAwesomeIcon icon="save"/>
                        &nbsp; Save
                      </Button>

                    </Form>
                  );
                }}
              />

            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  manufacturerEntity: storeState.manufacturer.entity,
  loading: storeState.manufacturer.loading,
  updating: storeState.manufacturer.updating,
  updateSuccess: storeState.manufacturer.updateSuccess,
});

const mapDispatchToProps = {
  getManufacturerForCurrentUser,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManufacturerUpdate);
