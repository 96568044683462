import { defaultValue, IUserConfiguration } from 'app/shared/model/user-configuration.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { getEntities as fetchFolders } from 'app/entities/folder/folder.reducer';

export const ACTION_TYPES = {
  FETCH_USERCONFIGURATION_LIST: 'userConfiguration/FETCH_USERCONFIGURATION_LIST',
  FETCH_USERCONFIGURATION: 'userConfiguration/FETCH_USERCONFIGURATION',
  CREATE_USERCONFIGURATION: 'userConfiguration/CREATE_USERCONFIGURATION',
  UPDATE_USERCONFIGURATION: 'userConfiguration/UPDATE_USERCONFIGURATION',
  DELETE_USERCONFIGURATION: 'userConfiguration/DELETE_USERCONFIGURATION',
  RESET: 'userConfiguration/RESET',
  SET_MARKETPLACE_MODE: 'userConfiguration/SET_MARKETPLACE_MODE',
  GET_USER_CONFIGURATION_BY_DEALERS: 'userConfiguration/GET_USER_CONFIGURATION_BY_DEALERS',
  RESET_USER_CONFIGURATION_BY_DEALERS: 'userConfiguration/RESET_USER_CONFIGURATION_BY_DEALERS',
  SHARE_CONFIGURATION: 'SHARE_CONFIGURATION',
  RESET_ENTITY: 'RESET_ENTITY',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IUserConfiguration>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  marketplaceMode: false,
  userConfigurationsByDealer: [],
};

export type UserConfigurationState = Readonly<typeof initialState>;

// Reducer

export default (state: UserConfigurationState = initialState, action): UserConfigurationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_USERCONFIGURATION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_USERCONFIGURATION):
    case REQUEST(ACTION_TYPES.GET_USER_CONFIGURATION_BY_DEALERS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_USERCONFIGURATION):
    case REQUEST(ACTION_TYPES.UPDATE_USERCONFIGURATION):
    case REQUEST(ACTION_TYPES.DELETE_USERCONFIGURATION):
    case REQUEST(ACTION_TYPES.SHARE_CONFIGURATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_USERCONFIGURATION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_USERCONFIGURATION):
    case FAILURE(ACTION_TYPES.CREATE_USERCONFIGURATION):
    case FAILURE(ACTION_TYPES.UPDATE_USERCONFIGURATION):
    case FAILURE(ACTION_TYPES.DELETE_USERCONFIGURATION):
    case FAILURE(ACTION_TYPES.GET_USER_CONFIGURATION_BY_DEALERS):
    case FAILURE(ACTION_TYPES.SHARE_CONFIGURATION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_USERCONFIGURATION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_USERCONFIGURATION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_USERCONFIGURATION):
    case SUCCESS(ACTION_TYPES.UPDATE_USERCONFIGURATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_USERCONFIGURATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case FAILURE(ACTION_TYPES.SHARE_CONFIGURATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
      };
    case SUCCESS(ACTION_TYPES.SHARE_CONFIGURATION):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
      };
    case SUCCESS(ACTION_TYPES.GET_USER_CONFIGURATION_BY_DEALERS):
      return {
        ...state,
        loading: false,
        userConfigurationsByDealer: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.RESET_USER_CONFIGURATION_BY_DEALERS:
      return {
        ...state,
        userConfigurationsByDealer: [],
      };
    case ACTION_TYPES.SET_MARKETPLACE_MODE:
      return {
        ...state,
        marketplaceMode: action.payload,
      };
    case ACTION_TYPES.RESET_ENTITY:
      return {
        ...state,
        entity: null,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/user-configurations';

// Actions

export const getUserConfigurationsByFolderId: ICrudGetAllAction<IUserConfiguration> = id => {
  const requestUrl = `${apiUrl}/by-folder-id/${id}`;
  return {
    type: ACTION_TYPES.FETCH_USERCONFIGURATION_LIST,
    payload: axios.get<IUserConfiguration>(`${requestUrl}`),
  };
};

export const getEntities: ICrudGetAllAction<IUserConfiguration> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_USERCONFIGURATION_LIST,
    payload: axios.get<IUserConfiguration>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IUserConfiguration> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_USERCONFIGURATION,
    payload: axios.get<IUserConfiguration>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IUserConfiguration> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_USERCONFIGURATION,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const createRevitExport: ICrudPutAction<IUserConfiguration> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_USERCONFIGURATION,
    payload: axios.post(apiUrl + '/export-user-configurations', cleanEntity(entity)),
  });
  return result;
};

export const operateUserConfiguration: ICrudPutAction<IUserConfiguration> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_USERCONFIGURATION,
    payload: axios.post(apiUrl + '/operate', cleanEntity(entity)),
  });
  dispatch(fetchFolders());
  return result;
};

export const updateEntity: ICrudPutAction<IUserConfiguration> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_USERCONFIGURATION,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IUserConfiguration> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_USERCONFIGURATION,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const setMarketPlaceMode = payload => ({
  type: ACTION_TYPES.SET_MARKETPLACE_MODE,
  payload,
});

export const getUserConfigurationsByDearels = dealers => {
  const apiUrl = 'api/marketplace/get-user-configurations-by-dealers';
  return {
    type: ACTION_TYPES.GET_USER_CONFIGURATION_BY_DEALERS,
    payload: axios.post(apiUrl, dealers),
  };
};

export const resetUserConfigurationsByDealers = () => ({
  type: ACTION_TYPES.RESET_USER_CONFIGURATION_BY_DEALERS,
});

export const shareConfiguration = data => {
  const apiUrl = 'api/share/share-configuration';
  return {
    type: ACTION_TYPES.SHARE_CONFIGURATION,
    payload: axios.post(apiUrl, data),
  };
};

export const shareConfigurationwithoutAuthentication = data => {
  const apiUrl = '/api/open/share-revit';
  return {
    type: ACTION_TYPES.SHARE_CONFIGURATION,
    payload: axios.post(apiUrl, data),
  };
};

export const resetEntity = () => ({
  type: ACTION_TYPES.RESET_ENTITY,
});
