import React, { useState } from 'react';
import Switch from 'react-switch';
import './unitsOfMeasureSwitch.scss';

interface IUnitsOfMeasureSwitch {
  setUnitsOfMeasure: Function;
}

const UnitsOfMeasureSwitch = (props: IUnitsOfMeasureSwitch) => {
  const [isUseInches, setIsUseInches] = useState(localStorage.getItem('unitsOfMeasure') === 'CM' ? false : true);

  const setUnitsOfMeasurement = (value: boolean) => {
    if (value) {
      props.setUnitsOfMeasure('inchfeet');
      localStorage.setItem('unitsOfMeasure', 'inchfeet');
    } else {
      props.setUnitsOfMeasure('CM');
      localStorage.setItem('unitsOfMeasure', 'CM');
    }
    setIsUseInches(value);
    location.reload();
  };

  return (
    <div className="roomle-action-bar-switch">
      <span className="roomle-action-bar-switch-side">CM</span>
      <Switch
        offHandleColor="#E2863C"
        onHandleColor="#E2863C"
        activeBoxShadow="0px 0px 1px 2px #FFFFFF"
        uncheckedIcon={false}
        checkedIcon={false}
        handleDiameter={20}
        height={10}
        width={35}
        className="roomle-action-bar-switch-toggle"
        onChange={value => setUnitsOfMeasurement(value)}
        checked={isUseInches}
      />
      <span className="roomle-action-bar-switch-side">INCH</span>
    </div>
  );
};

export default UnitsOfMeasureSwitch;
