import React from 'react';
import { Spinner } from 'reactstrap';
import './spinner.scss';

const AppSpinner = () => {
  return (
    <div className="app-spinner-container">
      <Spinner
        color="secondary"
        style={{
          height: '80px',
          width: '80px',
        }}
      />
    </div>
  );
};

export default AppSpinner;
