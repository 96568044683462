import React, { useEffect, useState } from 'react';
import DealerRegistrationStepProgressBar from 'app/components/StepProgressBar/DealerRegistrationStepProgressBar';
import BaseLayout from '../BaseLayout';
import AdressInformation from 'app/components/DealerRegistrationProcessPages/AdressInformation';
import BusinessSkills from 'app/components/DealerRegistrationProcessPages/BusinessSkills';
import CreditCardInformation from 'app/components/DealerRegistrationProcessPages/CreditCardInformation';
import Approval from 'app/components/DealerRegistrationProcessPages/Approval';
import { IRootState } from 'app/shared/reducers';
import { getConsultantAdminForCurrentUser, updateEntity } from 'app/entities/consultant/consultant.reducer';
import { connect } from 'react-redux';
import { ProfileStatus } from 'app/shared/model/enumerations/profile-status.model';
import { useHistory } from 'react-router-dom';
import { getAllConsultantData } from 'app/entities/consultant-data/consultant-data.reducer';
import './dealerRegistrationProcess.scss';

export interface IDealerProps extends StateProps, DispatchProps {}

const ConsultantAdminRegistrationProcess = (props: IDealerProps) => {
  let history = useHistory();
  const [step, setStep] = useState(25);
  const [business, setBusiness] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedBusinessStatus, setSelectedBusinessStatus] = useState([]);
  const [cardNumber, setCardNumber] = useState(null);
  const [cardHolder, setCardHolder] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [cvc, setCvc] = useState(null);

  useEffect(() => {
    if (props.consultant.skills) {
      setSelectedSkills(props.consultant.skills.split(','));
    }
    if (props.consultant.qualifySpecialStatus) {
      setSelectedBusinessStatus(props.consultant.qualifySpecialStatus.split(','));
    }
    if (props.consultant) {
      setCardNumber(props.consultant.cardNumber);
      setCardHolder(props.consultant.cardHolder);
      setMonth(props.consultant.month);
      setYear(props.consultant.year);
      setCvc(props.consultant.cvc);
      setBusiness({ value: props.consultant.refConsultantId, name: '' });
    }
  }, [props.consultant]);

  useEffect(() => {
    props.getAllConsultantData();
  }, []);

  const processSteps = {
    businessInfo: 25,
    businessSkills: 50,
    creditCard: 75,
    approval: 100,
  };

  const processStepsTitles = {
    25: 'Select Your Company',
    50: 'Business Skills',
    75: 'Credit Card Information',
    100: 'Approval',
  };

  const setNextStep = () => {
    if (step >= 100) return;

    setStep(step + 25);

    const entity = {
      ...props.consultant,
      skills: selectedSkills.join(','),
      qualifySpecialStatus: selectedBusinessStatus.join(','),
      cardNumber,
      cardHolder,
      month,
      year,
      cvc,
      refDealerId: business?.value || null,
    };

    props.updateEntity(entity);
  };

  const handleFinish = () => {
    const entity = {
      ...props.consultant,
      status: ProfileStatus.UPDATE_REQUESTED,
    };
    // @ts-ignore
    props.updateEntity(entity);
    history.push('/business-account-setup-success');
  };

  const setPreviousStep = () => {
    if (step > 25) {
      setStep(step - 25);
    }
  };

  useEffect(() => {
    props.getConsultantAdminForCurrentUser();
  }, []);

  const selectBusinessStatus = (statusName: string) => {
    const newSelectedBusinessStatus = selectedBusinessStatus.includes(statusName) ? [] : [statusName];
    setSelectedBusinessStatus(newSelectedBusinessStatus);
  };

  return (
    <>
      <div className="dealer-registration-step-progress-bar">
        <DealerRegistrationStepProgressBar step={step} />
      </div>
      <BaseLayout title={processStepsTitles[step]}>
        <div className="dealer-registration-information-container">
          {step === processSteps.businessInfo && (
            <AdressInformation
              businessMember={props.consultant}
              stateData={business}
              setBusinessMember={setBusiness}
              dealerDatas={props.consultantDatas}
              selectedBusiness={business}
            />
          )}
          {step === processSteps.businessSkills && (
            <BusinessSkills
              selectedSkills={selectedSkills}
              selectedBusinessStatus={selectedBusinessStatus}
              setSelectedSkills={setSelectedSkills}
              setSelectedBusinessStatus={selectBusinessStatus}
            />
          )}
          {step === processSteps.creditCard && (
            <CreditCardInformation
              updateEntity={props.updateEntity}
              businessMember={props.consultant}
              cardNumber={cardNumber}
              setCardNumber={setCardNumber}
              cardHolder={cardHolder}
              setCardHolder={setCardHolder}
              month={month}
              setMonth={setMonth}
              year={year}
              setYear={setYear}
              cvc={cvc}
              setCvc={setCvc}
            />
          )}
          {step === processSteps.approval && <Approval handleFinish={handleFinish} />}
        </div>

        <div className="dealer-registration-navigation-buttons-container">
          {step > processSteps.businessInfo && (
            <button className="marketplace-button dealer-setup-step-button" onClick={() => setPreviousStep()}>
              <img src="../../../content/images/icons/previous-step-icon.svg" alt="next-step-icon" style={{ marginRight: 10 }} width="20" />
              Previous
            </button>
          )}
          {step === processSteps.creditCard && (
            <div className="billing-conditions-container">
              <p className="billing-conditions-text">
                *Early Signup Advantage: Gain an advantage by signing up before your fellow dealers. If multiple Dealers have set the same
                bid price, the Dealer who signed up earliest will gain the advantage of being presented highest when users are selecting who
                to receive pricing from.
              </p>
              <p className="billing-conditions-text">
                *There is an exception to Marketplace Placement Priority and Superiority; when lead bid price is equal between multiple
                competing Dealers. a Dealer from a Primary Region receives the higher Marketplace placement over a Dealer in their
                non-Primary Region.
              </p>
            </div>
          )}
          {step < processSteps.approval && (
            <button className="marketplace-button step-button dealer-setup-step-button" onClick={() => setNextStep()}>
              Next
              <img src="../../../content/images/icons/next-step-icon.svg" alt="next-step-icon" style={{ marginLeft: 10 }} width="20" />
            </button>
          )}
        </div>
        <div className="dealer-account-setup-feda-logo-container">
          <img src="../../../content/images/feda_logo.svg" alt="FEDA logo" width="300" />
        </div>
      </BaseLayout>
    </>
  );
};

const mapStateToProps = ({ consultant, consultantData }: IRootState) => ({
  consultantDatas: consultantData.entities,
  consultant: consultant.entity,
  loading: consultant.loading,
});

const mapDispatchToProps = {
  getConsultantAdminForCurrentUser,
  updateEntity,
  getAllConsultantData,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantAdminRegistrationProcess);
