import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './consultant-user.reducer';
import { IConsultantUser } from 'app/shared/model/consultant-user.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IConsultantUserDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ConsultantUserDetail extends React.Component<IConsultantUserDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { consultantUserEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            ConsultantUser [<b>{consultantUserEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="name">Name</span>
            </dt>
            <dd>{consultantUserEntity.name}</dd>
            <dt>
              <span id="enabled">Enabled</span>
            </dt>
            <dd>{consultantUserEntity.enabled ? 'true' : 'false'}</dd>
            <dt>
              <span id="logo">Logo</span>
            </dt>
            <dd>{consultantUserEntity.logo}</dd>
            <dt>
              <span id="websiteURL">Website URL</span>
            </dt>
            <dd>{consultantUserEntity.websiteURL}</dd>
            <dt>
              <span id="city">City</span>
            </dt>
            <dd>{consultantUserEntity.city}</dd>
            <dt>
              <span id="state">State</span>
            </dt>
            <dd>{consultantUserEntity.state}</dd>
            <dt>
              <span id="businessName">Business Name</span>
            </dt>
            <dd>{consultantUserEntity.businessName}</dd>
            <dt>
              <span id="skills">Skills</span>
            </dt>
            <dd>{consultantUserEntity.skills}</dd>
            <dt>
              <span id="qualifySpecialStatus">Qualify Special Status</span>
            </dt>
            <dd>{consultantUserEntity.qualifySpecialStatus}</dd>
            <dt>
              <span id="cardNumber">Card Number</span>
            </dt>
            <dd>{consultantUserEntity.cardNumber}</dd>
            <dt>
              <span id="cardHolder">Card Holder</span>
            </dt>
            <dd>{consultantUserEntity.cardHolder}</dd>
            <dt>
              <span id="customerId">Customer Id</span>
            </dt>
            <dd>{consultantUserEntity.customerId}</dd>
            <dt>
              <span id="month">Month</span>
            </dt>
            <dd>{consultantUserEntity.month}</dd>
            <dt>
              <span id="year">Year</span>
            </dt>
            <dd>{consultantUserEntity.year}</dd>
            <dt>
              <span id="cvc">Cvc</span>
            </dt>
            <dd>{consultantUserEntity.cvc}</dd>
            <dt>
              <span id="status">Status</span>
            </dt>
            <dd>{consultantUserEntity.status}</dd>
            <dt>
              <span id="refConsultantId">Ref Consultant Id</span>
            </dt>
            <dd>{consultantUserEntity.refConsultantId}</dd>
            <dt>
              <span id="subscriptionId">Subscription Id</span>
            </dt>
            <dd>{consultantUserEntity.subscriptionId}</dd>
            <dt>
              <span id="payPerUsagesSubscriptionId">Pay Per Usages Subscription Id</span>
            </dt>
            <dd>{consultantUserEntity.payPerUsagesSubscriptionId}</dd>
            <dt>User</dt>
            <dd>{consultantUserEntity.userLogin ? consultantUserEntity.userLogin : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/consultant-user" replace color="info">
            <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/consultant-user/${consultantUserEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ consultantUser }: IRootState) => ({
  consultantUserEntity: consultantUser.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultantUserDetail);
