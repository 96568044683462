import React from 'react';
import { Modal, Table } from 'reactstrap';
import { PaymentsStatusesColors } from 'app/config/constants';
import ProductCardAction from '../ProductCardAction/ProductCardAction';
import moment from 'moment';

// TODO: uncomment LeadStatusSelect after API will be connected

interface IPaymentDetailsModal {
  isOpen: boolean;
  setIsPaymentDetailsModalOpen: Function;
  startDate: any;
  subscription
}

const PaymentDetailsModal = (props: IPaymentDetailsModal) => {
  const paymentsDetails = [1, 2, 3, 4];
  return (
    <Modal
      isOpen={props.isOpen}
      centered
      style={{ maxWidth: '95%', width: '95%' }}
      toggle={props.setIsPaymentDetailsModalOpen}
      backdrop={true}
    >
      <div className="close-payment-detail-modal">
        <ProductCardAction bordered={true} iconName="closeModalIcon" onClick={props.setIsPaymentDetailsModalOpen} />
      </div>
      <h4 className="payment-detail-modal-title">Payment Detail</h4>
      <span className="payment-detail-modal-date">({moment(props.startDate).format('MMM, YYYY')})</span>
      <div className="payment-detail-modal-data">
        <span className="payment-detail-modal-data-item">Lorem Ipsum</span>
        <span className="payment-detail-modal-data-item">Dealer</span>
        <span className="payment-detail-modal-data-item">$100</span>
        <span className="payment-detail-modal-data-item">5</span>
        <span className="payment-detail-modal-data-item">13 June, 2022</span>
        <span className={'payment-detail-modal-data-item' + ' ' + PaymentsStatusesColors['REFUNDED']}>Refunded</span>
        <span className="payment-detail-modal-data-item">customer@gmail.com</span>
      </div>
      <div className="payment-details-table-container">
        <Table responsive borderless className="payments-table">
          <thead>
            <tr className="payments-table-header">
              <th>Date</th>
              <th>Amount</th>
              <th>Manufacturer Name</th>
              <th>Product Name</th>
              <th>Model Number</th>
              <th>Product Price</th>
              <th>Configuration ID</th>
              <th> App User Email Address</th>
              <th>Region</th>
              <th>Lead Recipient Email Address</th>
              <th>Lead Status</th>
            </tr>
          </thead>
          <tbody>
            {/*{props.subscription && props.subscription.data.map(subscription => (*/}
            {/*  <tr className="payment-table-item" key={subscription.id} style={{ cursor: 'auto' }}>*/}
            {/*    <td>{new Date(subscription.created * 1000).toDateString()}</td>*/}
            {/*    <td>{new Date(subscription.current_period_start * 1000).toDateString()} - {new Date(subscription.current_period_end * 1000).toDateString()}</td>*/}
            {/*    <td>{subscription.status.toUpperCase()}</td>*/}
            {/*    <td>{subscription.plan.interval.toUpperCase()}</td>*/}
            {/*    <td>{subscription.plan.usage_type.toUpperCase()}</td>*/}
            {/*    <td>Lorem ipsum</td>*/}
            {/*    <td>MN5625</td>*/}
            {/*    <td> $200</td>*/}
            {/*    <td>13 June, 2022</td>*/}
            {/*    <td>demo@gmail.com</td>*/}
            {/*    <td>Lorem</td>*/}
            {/*    <td>demo@gmail.com</td>*/}
            {/*    <td>/!* <LeadStatusSelect lead={}/> *!/</td>*/}
            {/*  </tr>*/}
            {/*))}*/}
          </tbody>
        </Table>
      </div>
    </Modal>
  );
};

export default PaymentDetailsModal;
