import React from 'react';
import { Modal } from 'reactstrap';
import './createShopDrawingsModal.scss';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';

interface ICreateShopDrawingsModal {
  handleCreateDrawingsButton: Function;
  isOpen: boolean;
  setIsCreateDrawingsModalOpen: Function;
  account: any;
  loadingProject?: boolean;
}

const CreateShopDrawingsModal = (props: ICreateShopDrawingsModal) => {
  return (
    <Modal
      isOpen={props.isOpen}
      centered
      style={{ maxWidth: 700, width: window.screen.width - 1050 }}
      toggle={props.setIsCreateDrawingsModalOpen}
      backdrop={true}
    >
      <div className="create-drawings-modal-container">
        {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ROLE_FOOD_SERVICE_DRAWING]) ? (
          <button
            className="create-drawings-modal-button"
            onClick={() => props.handleCreateDrawingsButton('kreator.rvt', 'FOOD_SERVICE')}
            disabled={props.loadingProject}
          >
            <img src="../../../content/images/icons/marketplace-cart.svg" alt="marketplace cart" width="30" />
            Create Foodservice Drawings
          </button>
        ) : (
          ''
        )}

        {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ROLE_KROWNE_DRAWING]) ? (
          <button
            className="create-drawings-modal-button"
            onClick={() => props.handleCreateDrawingsButton('krowne.rvt', 'KROWNE')}
            disabled={props.loadingProject}
          >
            <img src="../../../content/images/icons/marketplace-cart.svg" alt="marketplace cart" width="30" /> Create Krowne Drawings
          </button>
        ) : (
          ''
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ notification, authentication }: IRootState) => ({
  account: authentication.account,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(CreateShopDrawingsModal);
