import React from 'react';
import { Modal } from 'reactstrap';
import './marketplaceDealerCard.scss';

interface IMarketplaceDealerDetailsModal {
  isOpen: boolean;
  closeModal: Function;
  dealer: any;
}

const MarketplaceDealerDetailsModal = (props: IMarketplaceDealerDetailsModal) => {
  return (
    <Modal
      isOpen={props.isOpen}
      centered
      style={{ maxWidth: 700, width: window.screen.width - 900 }}
      toggle={() => props.closeModal()}
      backdrop={true}
    >
      {props.dealer && (
        <>
          <div className="marketplace-dealer-details-logo-container">
            <img src={props.dealer.logo} alt="dealer logo" className="marketplace-dealer-details-logo" />
          </div>
          <div className="marketplace-dealer-details-info-container">
            <span className="marketplace-dealer-details-name-subtitle">Dealer Name</span>
            <span className="marketplace-dealer-details-name">{props.dealer.name}</span>
            <div className="d-flex align-items-center mt-2">
              <span className="marketplace-dealer-details-field-key">Skills:</span>
              <span className="marketplace-dealer-field-value orange-font">Refrigeration, Delivery, Installation </span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span className="marketplace-dealer-details-field-key">Address:</span>
              <span className="marketplace-dealer-field-value">{props.dealer.city + ',' + props.dealer.state}</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span className="marketplace-dealer-details-field-key">Business:</span>
              <span className="marketplace-dealer-field-value">Minority Business Enterprise (MBE)</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span className="marketplace-dealer-details-field-key">Website:</span>
              <span className="marketplace-dealer-field-value">link to website</span>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default MarketplaceDealerDetailsModal;
