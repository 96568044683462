import React, { useState, useEffect } from 'react';
import ThreeStepProgressBar from 'app/components/StepProgressBar/ThreeStepProgressBar';
import Search from 'app/components/Search/Search';
import ProductsTable from './ProductsTable';
import { useHistory } from 'react-router-dom';
import DeliveryDetailsModal from './DeliveryDetailsModal';
import DealersModal from './DealersModal';
import MarketplaceCartItem from 'app/components/MarketplaceCartItem/MarketplaceCartItem';
import MarketplaceFormPage from 'app/components/MarketplaceFormPage/MarketplaceFormPage';
import { setMarketPlaceMode } from 'app/entities/user-configuration/user-configuration.reducer';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import './projectMarketplace.scss';

interface IProjectMarketplace extends StateProps, DispatchProps {}

const ProjectMarketplace = (props: IProjectMarketplace) => {
  let history = useHistory();
  const [step, setStep] = useState(50);
  const [selectedDealers, setSelectedDealers] = useState([]);
  const [leadsProducts, setLidsProducts] = useState([]);

  // modal windows
  const [isDeliveryDetailsModalOpen, setIsDeliveryDetailsModalOpen] = useState<boolean>(false);
  const [isDealersModalOpen, setIsDealerModalOpen] = useState<boolean>(false);

  useEffect(() => {
    props.setMarketPlaceMode(true);

    return () => {
      props.setMarketPlaceMode(false);
    };
  }, []);

  const marketplaceSteps = {
    manufacturers: 25,
    dealers: 50,
    cart: 75,
    userInfo: 100,
  };

  const productsList = [1, 2, 3];
  const dealersList = [1, 2, 3];

  const setNextStep = () => {
    if (step < 100) setStep(step + 25);
  };
  const setPreviousStep = () => {
    if (step > 25) setStep(step - 25);
  };

  const returnToKreator = () => {
    history.goBack();
  };

  const selectEntity = (e, selectedEntitiesArray, selectedEntity, setSelectFunction) => {
    if (e.target.nodeName === 'I') {
      return;
    }
    const exists = selectedEntitiesArray.includes(selectedEntity);
    const selectedArray = [...selectedEntitiesArray];
    if (exists) {
      setSelectFunction(selectedArray.filter(item => item !== selectedEntity));
    } else if (selectedArray.length < 3) {
      setSelectFunction([...selectedArray, selectedEntity]);
    }
  };

  // uncomment after logic will be added
  // let buttonsClass = 'marketplace-buttons-container';
  // if (props.loadingManufactirers || props.loadingDealers || props.loadingConfiguration) {
  //   buttonsClass += ' d-none';
  // }

  const changeProductCount = (productId, dealerId, quantity) => {
    const updatedLeadsProductsArray = leadsProducts.map(product => {
      if (product.userConfigurationId === productId && product.dealerId === dealerId) {
        return { ...product, quantity };
      } else {
        return product;
      }
    });
    setLidsProducts(updatedLeadsProductsArray);
  };

  return (
    <>
      {step === marketplaceSteps.cart && <div className="marketplace-cart-title">Cart</div>}
      <div className="step-progress-bar-container">
        <ThreeStepProgressBar className="step-progress-bar" step={step} />
      </div>
      <div className="marketplace-list-container mb-5">
        <div className="marketplace-list-container-header">
          {step === marketplaceSteps.dealers && (
            <span className="marketplace-list-container-title">Project Summary - Select Dealer(s)</span>
          )}

          {step < marketplaceSteps.cart && <Search />}
        </div>
      </div>
      {step === marketplaceSteps.dealers && (
        <ProductsTable
          products={productsList}
          openDeliveryDetailsModal={() => setIsDeliveryDetailsModalOpen(true)}
          openDealersModal={() => setIsDealerModalOpen(true)}
        />
      )}
      {
        step === marketplaceSteps.cart && <span>here will be the Cart</span>
        // userConfigurationByDealer.map(item => <MarketplaceCartItem changeProductCount={changeProductCount} item={item} key={item} />)
      }
      {step === marketplaceSteps.userInfo && (
        <MarketplaceFormPage handleSubmit={() => console.log('submit')} isProjectMarketplaceProcced={true} />
      )}
      <div className="marketplace-buttons-container">
        {step > marketplaceSteps.dealers ? (
          <button className="marketplace-button" onClick={() => setPreviousStep()}>
            <img src="../../../content/images/icons/previous-step-icon.svg" alt="next-step-icon" width="20" style={{ marginRight: 10 }} />
            Previous
          </button>
        ) : (
          <button className="marketplace-button" onClick={() => returnToKreator()}>
            Cancel
          </button>
        )}

        <div className="next-buttons-container">
          {step === marketplaceSteps.manufacturers && (
            <button className="marketplace-button" onClick={() => setNextStep()}>
              Skip
            </button>
          )}
          {step < marketplaceSteps.userInfo && (
            <button className="marketplace-button step-button" onClick={() => setNextStep()}>
              Next
              <img src="../../../content/images/icons/next-step-icon.svg" alt="next-step-icon" width="20" style={{ marginLeft: 10 }} />
            </button>
          )}
        </div>
      </div>
      <DeliveryDetailsModal isOpen={isDeliveryDetailsModalOpen} closeModal={() => setIsDeliveryDetailsModalOpen(false)} />
      <DealersModal
        isOpen={isDealersModalOpen}
        closeModal={() => setIsDealerModalOpen(false)}
        dealers={dealersList}
        selectedDealers={selectedDealers}
        setSelectedDealers={setSelectedDealers}
        selectEntity={selectEntity}
      />
    </>
  );
};

const mapStateToProps = ({ project }: IRootState) => ({});

const mapDispatchToProps = {
  setMarketPlaceMode,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMarketplace);
