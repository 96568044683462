import React, { useState } from 'react';
import BaseLayout from '../BaseLayout';
import { Collapse, Input, Label } from 'reactstrap';
import Switch from 'react-switch';
import './instantPricing.scss';

const InstantPricing = () => {
  const [openedManufacturers, setOpenedManufacturers] = useState<number[]>([]);

  const manufacturers = [
    { name: 'Coronet', id: 1 },
    { name: 'Metro', id: 2 },
    { name: 'Hatco', id: 3 },
  ];

  const products = [
    { name: 'Wire Shelving', id: 1 },
    { name: 'Polymer Shelving', id: 2 },
    { name: 'Smartwall', id: 3 },
  ];

  const handleManufacturersOpening = (id: number) => {
    const openedManufacturersCopy = [...openedManufacturers];
    if (openedManufacturersCopy.includes(id)) {
      const updatedArray = openedManufacturersCopy.filter(item => item !== id);
      setOpenedManufacturers(updatedArray);
    } else {
      openedManufacturersCopy.push(id);
      setOpenedManufacturers(openedManufacturersCopy);
    }
  };
  return (
    <BaseLayout title="Setup Instant Pricing">
      {manufacturers.map(item => (
        <div key={item.id}>
          <div className="instant-pricing-manufacturer-item" key={item.id}>
            <span className="instant-pricing-manufacturer-item-name">{item.name}</span>
            <Switch
              offHandleColor="#E2863C"
              onHandleColor="#E2863C"
              activeBoxShadow="0px 0px 1px 2px #FFFFFF"
              uncheckedIcon={false}
              checkedIcon={false}
              handleDiameter={20}
              height={10}
              width={35}
              className="roomle-action-bar-switch-toggle"
              onChange={value => handleManufacturersOpening(item.id)}
              checked={openedManufacturers.includes(item.id)}
            />
          </div>
          <Collapse className="instant-pricing-manufacturer-collapse" isOpen={openedManufacturers.includes(item.id)}>
            {products.map(item => (
              <div key={item.id} className="instant-pricing-product-container">
                <span className="instant-pricing-product-item-name">{item.name}</span>
                <div className="d-flex">
                  <div className="regions-modal-input-container">
                    <Label for="discount">Discount</Label>
                    <Input id="discount" className="regions-modal-input" />
                  </div>
                  <div className="regions-modal-input-container">
                    <Label for="markup">Markup</Label>
                    <Input id="markup" className="regions-modal-input" />
                  </div>
                </div>
              </div>
            ))}
          </Collapse>
        </div>
      ))}
    </BaseLayout>
  );
};

export default InstantPricing;
