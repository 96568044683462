import React from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import './drawingsLanding.scss';

const DrawingsLanding = () => {
  let history = useHistory();
  return (
    <div className="share-page-container drawings-landing-container">
      <header className="share-configuration-landing-header">
        <img src="../../../content/images/kreator-navbar-logo.png" width="50" alt="logo" className="share-page-header-logo" />
        <div className="share-page-buttons-container">
          <Button onClick={() => history.push('/login')} className="share-page-sign-in-button" style={{ marginRight: 10 }}>
            Sign In
          </Button>
        </div>
      </header>
      <div className="drawings-landing-data-container">
        <p>Your Krowne Drawings are in progress, you will receive an email to download the files when they are ready</p>
      </div>
    </div>
  );
};

export default DrawingsLanding;
