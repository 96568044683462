import MenuItem from 'app/shared/layout/menus/menu-item';
import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import '../header/header.scss';
import { regionList } from './region';

export interface IAccountMenu {
  firstName: string;
  lastName: string;
  email: string;
  region: string;
}

export const AccountMenu = (props: IAccountMenu) => {
  const getRegion = regionName => {
    if (regionName) {
      const item = regionList.find(item => regionName.includes('_' + item.code));
      if (item) {
        return item.code + '-' + item.name;
      }
    }
    return '';
  };
  return (
    <UncontrolledDropdown nav inNavbar id="account-menu">
      <DropdownToggle nav>
        <div className="dropdown-block">
          <div className="user-info-block">
            <span className="user-name">
              {props.firstName} {props.lastName}
            </span>
            <span className="user-email">{props.email}</span>
            <span className="user-email">{props.region}</span>
          </div>
          <div className="user-profile-image-container">
            <div className="avatar-image">{props.firstName[0] + props.lastName[0]}</div>
          </div>
          <img src="../../../../content/images/icons/arrow_down.svg" alt="icon-down" className="user-profile-arrow-down" />
        </div>
      </DropdownToggle>
      <DropdownMenu right className="user-profile-dropdown-menu">
        <MenuItem icon="wrench" to="/account/settings">
          User Settings
        </MenuItem>
        <MenuItem icon="lock" to="/account/password">
          Change Password
        </MenuItem>
        <MenuItem icon="sign-out-alt" to="/logout">
          Sign out
        </MenuItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
