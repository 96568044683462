import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './consultant-data.reducer';
import { IConsultantData } from 'app/shared/model/consultant-data.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IConsultantDataDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ConsultantDataDetail extends React.Component<IConsultantDataDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { consultantDataEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            ConsultantData [<b>{consultantDataEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="company">Company</span>
            </dt>
            <dd>{consultantDataEntity.company}</dd>
            <dt>
              <span id="industryType">Industry Type</span>
            </dt>
            <dd>{consultantDataEntity.industryType}</dd>
            <dt>
              <span id="companyType">Company Type</span>
            </dt>
            <dd>{consultantDataEntity.companyType}</dd>
            <dt>
              <span id="specPathId">Spec Path Id</span>
            </dt>
            <dd>{consultantDataEntity.specPathId}</dd>
            <dt>
              <span id="specPathUserId">Spec Path User Id</span>
            </dt>
            <dd>{consultantDataEntity.specPathUserId}</dd>
            <dt>
              <span id="mailingAddress">Mailing Address</span>
            </dt>
            <dd>{consultantDataEntity.mailingAddress}</dd>
            <dt>
              <span id="website">Website</span>
            </dt>
            <dd>{consultantDataEntity.website}</dd>
            <dt>
              <span id="email">Email</span>
            </dt>
            <dd>{consultantDataEntity.email}</dd>
            <dt>
              <span id="phone">Phone</span>
            </dt>
            <dd>{consultantDataEntity.phone}</dd>
            <dt>
              <span id="isDesignDealer">Is Design Dealer</span>
            </dt>
            <dd>{consultantDataEntity.isDesignDealer ? 'true' : 'false'}</dd>
            <dt>
              <span id="zipCode">Zip Code</span>
            </dt>
            <dd>{consultantDataEntity.zipCode}</dd>
            <dt>
              <span id="city">City</span>
            </dt>
            <dd>{consultantDataEntity.city}</dd>
            <dt>
              <span id="state">State</span>
            </dt>
            <dd>{consultantDataEntity.state}</dd>
            <dt>
              <span id="country">Country</span>
            </dt>
            <dd>{consultantDataEntity.country}</dd>
            <dt>
              <span id="regionOne">Region One</span>
            </dt>
            <dd>{consultantDataEntity.regionOne}</dd>
            <dt>
              <span id="regionTwo">Region Two</span>
            </dt>
            <dd>{consultantDataEntity.regionTwo}</dd>
            <dt>
              <span id="regionThree">Region Three</span>
            </dt>
            <dd>{consultantDataEntity.regionThree}</dd>
          </dl>
          <Button tag={Link} to="/entity/consultant-data" replace color="info">
            <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/consultant-data/${consultantDataEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ consultantData }: IRootState) => ({
  consultantDataEntity: consultantData.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultantDataDetail);
