import axios from 'axios';
import { Storage } from 'react-jhipster';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { AUTHORITIES } from 'app/config/constants';

export const ACTION_TYPES = {
  LOGIN: 'authentication/LOGIN',
  GET_SESSION: 'authentication/GET_SESSION',
  GET_REGION: 'authentication/GET_REGION',
  LOGOUT: 'authentication/LOGOUT',
  CLEAR_AUTH: 'authentication/CLEAR_AUTH',
  ERROR_MESSAGE: 'authentication/ERROR_MESSAGE',
  SEND_EMAIL_VERIFICATION: 'authentication/SEND_EMAIL_VERIFICATION',
  SUPERADMIN_LOGIN: 'authentication/SUPERADMIN_LOGIN',
  GET_SUPER_ADMIN_SESSION: 'authentication/GET_SUPER_ADMIN_SESSION',
  SET_SHARED_PAGE_CONFIGURATION: 'authentication/SET_SHARED_PAGE_CONFIGURATION',
  DELETE_ACCOUNT: 'authentication/DELETE_ACCOUNT',
  UPDATE_ACCOUNT: 'account/UPDATE_ACCOUNT',
};

const AUTH_TOKEN_KEY = 'jhi-authenticationToken';

const initialState = {
  loading: false,
  isAuthenticated: false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  showModalLogin: false,
  account: {} as any,
  errorMessage: null, // Errors returned from server side
  redirectMessage: (null as unknown) as string,
  sessionHasBeenFetched: false,
  idToken: (null as unknown) as string,
  logoutUrl: (null as unknown) as string,
  region: (null as unknown) as string,
  sharedPageConfiguration: null,
  updateSuccess: false,
  updateFailure: false,
};

export type AuthenticationState = Readonly<typeof initialState>;

// Reducer

export default (state: AuthenticationState = initialState, action): AuthenticationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.LOGIN):
    case REQUEST(ACTION_TYPES.GET_SESSION):
    case REQUEST(ACTION_TYPES.GET_SUPER_ADMIN_SESSION):
    case REQUEST(ACTION_TYPES.GET_REGION):
    case REQUEST(ACTION_TYPES.SEND_EMAIL_VERIFICATION):
    case REQUEST(ACTION_TYPES.UPDATE_ACCOUNT):
      return {
        ...state,
        loading: true,
        errorMessage: null,
        updateSuccess: false,
      };

    case FAILURE(ACTION_TYPES.LOGIN):
    case FAILURE(ACTION_TYPES.SUPERADMIN_LOGIN):
    case FAILURE(ACTION_TYPES.SEND_EMAIL_VERIFICATION):
      try {
        if (action.type == 'authentication/LOGIN_REJECTED') {
          if (Storage.local.get(AUTH_TOKEN_KEY)) {
            Storage.local.remove(AUTH_TOKEN_KEY);
          }
          if (Storage.session.get(AUTH_TOKEN_KEY)) {
            Storage.session.remove(AUTH_TOKEN_KEY);
          }
        }
      } catch (e) {}
      return {
        ...initialState,
        errorMessage: action?.payload?.response?.data?.detail,
        showModalLogin: true,
        loginError: true,
      };
    case FAILURE(ACTION_TYPES.UPDATE_ACCOUNT):
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        updateFailure: true,
      };
    case FAILURE(ACTION_TYPES.GET_SESSION):
    case FAILURE(ACTION_TYPES.GET_SUPER_ADMIN_SESSION):
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        showModalLogin: true,
        errorMessage: action?.payload?.response?.data?.detail,
      };
    case FAILURE(ACTION_TYPES.GET_REGION):
      return {
        ...state,
        loading: false,
        errorMessage: action?.payload?.response?.data?.detail,
      };
    case SUCCESS(ACTION_TYPES.LOGIN):
    case SUCCESS(ACTION_TYPES.SEND_EMAIL_VERIFICATION):
      return {
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
      };
    case SUCCESS(ACTION_TYPES.SUPERADMIN_LOGIN):
      return {
        ...state,
      };
    case ACTION_TYPES.LOGOUT:
      return {
        ...initialState,
        showModalLogin: true,
      };
    case SUCCESS(ACTION_TYPES.GET_SESSION): {
      const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
      const data = action.payload.data;
      if (sessionStorage.getItem(AUTHORITIES.ROLE_SUPER_ADMIN)) {
        data.authorities = [...data.authorities, AUTHORITIES.ROLE_SUPER_ADMIN];
      }
      return {
        ...state,
        isAuthenticated,
        loading: false,
        sessionHasBeenFetched: true,
        account: data,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_SUPER_ADMIN_SESSION): {
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        sessionHasBeenFetched: true,
        account: action.payload.data,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_REGION): {
      return {
        ...state,
        loading: false,
        region: action.payload.data,
      };
    }
    case SUCCESS(ACTION_TYPES.UPDATE_ACCOUNT):
      return {
        ...state,
        loading: false,
        updateSuccess: true,
        updateFailure: false,
        account: action.payload.data,
      };
    case ACTION_TYPES.ERROR_MESSAGE:
      return {
        ...initialState,
        showModalLogin: true,
        redirectMessage: action.message,
      };
    case ACTION_TYPES.CLEAR_AUTH:
      return {
        ...state,
        loading: false,
        showModalLogin: true,
        isAuthenticated: false,
      };
    case ACTION_TYPES.SET_SHARED_PAGE_CONFIGURATION:
      return {
        ...state,
        sharedPageConfiguration: action.payload,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/account';

export const displayAuthError = message => ({ type: ACTION_TYPES.ERROR_MESSAGE, message });

export const getSession: () => void = () => (dispatch, getState) => {
  dispatch({
    type: ACTION_TYPES.GET_SESSION,
    payload: axios.get('api/account'),
  });
};
export const getSuperAdminSession: () => void = () => (dispatch, getState) => {
  dispatch({
    type: ACTION_TYPES.GET_SUPER_ADMIN_SESSION,
    payload: axios.get('api/account'),
  });
};

export const getRegion: () => void = () => (dispatch, getState) => {
  dispatch({
    type: ACTION_TYPES.GET_REGION,
    payload: axios.get('api/region'),
  });
};

export const login: (username: string, password: string, rememberMe?: boolean) => void = (username, password, rememberMe = false) => async (
  dispatch,
  getState
) => {
  const result = await dispatch({
    type: ACTION_TYPES.LOGIN,
    payload: axios.post('api/authenticate', { username, password, rememberMe }),
  });
  const bearerToken = result.value.headers.authorization;
  if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
    const jwt = bearerToken.slice(7, bearerToken.length);
    if (rememberMe) {
      Storage.local.set(AUTH_TOKEN_KEY, jwt);
    } else {
      Storage.session.set(AUTH_TOKEN_KEY, jwt);
    }
  }
  await dispatch(getSession());
};

export const superAdminLogin: (username: string) => void = username => async (dispatch, getState) => {
  const result = await dispatch({
    type: ACTION_TYPES.SUPERADMIN_LOGIN,
    payload: axios.post('api/authenticate-as-superadmin', { username, password: '0000' }),
  });
  clearAuthToken();
  const bearerToken = result.value.headers.authorization;
  if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
    const jwt = bearerToken.slice(7, bearerToken.length);
    Storage.session.set(AUTH_TOKEN_KEY, jwt);
  }
  await dispatch(getSession());
  window.location.reload();
};

export const clearAuthToken = () => {
  if (Storage.local.get(AUTH_TOKEN_KEY)) {
    Storage.local.remove(AUTH_TOKEN_KEY);
  }
  if (Storage.session.get(AUTH_TOKEN_KEY)) {
    Storage.session.remove(AUTH_TOKEN_KEY);
  }
};

export const logout: () => void = () => dispatch => {
  clearAuthToken();
  dispatch({
    type: ACTION_TYPES.LOGOUT,
  });
};

export const clearAuthentication = () => (dispatch, getState) => {
  clearAuthToken();
  dispatch({
    type: ACTION_TYPES.CLEAR_AUTH,
  });
};

export const deleteAccount = (login: string) => dispatch => {
  dispatch({
    type: ACTION_TYPES.DELETE_ACCOUNT,
    payload: axios.get(`/api/remove-account/?login=${login}`),
  });
};

export const sendVerificationEmail: (email: string) => void = email => async (dispatch, getState) => {
  await dispatch({
    type: ACTION_TYPES.SEND_EMAIL_VERIFICATION,
    payload: axios.post(`api/account/send-account-activation-email/init`, email, { headers: { ['Content-Type']: 'text/plain' } }),
  });
};

export const saveSharePageConfiguration = configurationId => dispatch => {
  dispatch({
    type: ACTION_TYPES.SET_SHARED_PAGE_CONFIGURATION,
    payload: configurationId,
  });
};

export const saveAccountSettings: (account: any) => void = account => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.UPDATE_ACCOUNT,
    payload: axios.post(apiUrl, account),
    meta: {
      successMessage: '<strong>Settings saved!</strong>',
    },
  });
};
