import React from 'react';
import { Input, Label, FormGroup } from 'reactstrap';
import './checkbox.scss';

interface ICheckbox {
  label: string;
  onChange?: Function;
}

const Checkbox = (props: ICheckbox) => {
  return (
    <FormGroup check className="base-layout-checkbox-container">
      <Input type="checkbox" className="base-layout-checkbox" onChange={() => props.onChange()} />
      <Label check className="base-layout-checkbox-label">
        {props.label}
      </Label>
    </FormGroup>
  );
};

export default Checkbox;
