import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CardImg } from 'reactstrap';
import EnabledProductButton from './EnabledProductButton';
import EnableProductButton from './EbableProductButton';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import RegionsSelectModal from './RegionsSelectModal';
import {
  disableConfiguratorForMarketplace,
  enableConfigurator,
} from 'app/entities/enabled-product-configurators/enabled-product-configurators.reducer';
import { IRootState } from 'app/shared/reducers';
import './productConfiguratorCard.scss';
import { toast, ToastContainer } from 'react-toastify';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

interface IProductConfiguratorCard {
  image: string;
  name: string;
  manufacturer: string;
  enableRegions: any;
  id: Number;
  regions: any;
  productId: Number;
  isConfiguratorEnabled: Boolean;
  enableConfigurator: Function;
  disableConfiguratorForMarketplace: Function;
  subscribedProductMetadata: any;
  account: any;
}

const ProductConfiguratorCard = (props: IProductConfiguratorCard) => {
  let history = useHistory();
  const [openRegion, setOpenRegion] = useState<boolean>(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [enableRegions, setEnableRegions] = useState([]);

  useEffect(() => {
    setEnableRegions(sortEnabledRegions(props.enableRegions));
  }, [props.enableRegions]);

  const enableConfigurator = () => {
    const regions = [...enableRegions];
    const newRegions = regions.filter((value, index) => value.commission > 0);
    props.enableConfigurator(props.productId, newRegions);
    setOpenRegion(!openRegion);
  };
  const getEnableRegionsCount = regions => {
    const activeEnabledRegions = regions.filter(item => item.activated === true);
    return activeEnabledRegions.length;
  };

  const handleRegionActivation = (selectedRegion, newCommission) => {
    const newEnabledRegions = [...enableRegions];

    const enableRegionsCount = getEnableRegionsCount(newEnabledRegions);

    if (props.subscribedProductMetadata == null || !props.subscribedProductMetadata.region) {
      toast.error('No activated plan found, to enable regions please upgrade your plan.');
      return;
    }

    if (enableRegionsCount >= parseInt(props.subscribedProductMetadata.region)) {
      toast.error(
        'Plan limit reached,' +
          props.subscribedProductMetadata.region +
          ' regions can enabled maximum, to enable more regions please upgrade your plan.'
      );
      return;
    }

    const existRegion = newEnabledRegions.find(enableRegion => enableRegion.region == selectedRegion);
    if (existRegion) {
      const existingIndex = newEnabledRegions.findIndex(enableRegion => enableRegion.region == selectedRegion);
      const updateEnabledRegion = { ...existRegion };
      updateEnabledRegion.commission = newCommission;
      updateEnabledRegion.activated = newCommission > 0;
      newEnabledRegions.splice(existingIndex, 1, updateEnabledRegion);
      setEnableRegions(sortEnabledRegions(newEnabledRegions));
    } else {
      const newEnabledRegion = {
        activated: newCommission > 0,
        commission: newCommission,
        enabledProductConfiguratorsId: props.productId,
        region: selectedRegion,
      };
      setEnableRegions(sortEnabledRegions([...enableRegions, newEnabledRegion]));
    }
  };

  const disableConfiguratorForMarketplace = () => {
    props.disableConfiguratorForMarketplace(props.id);
    closeConfirmationDialog();
  };

  const closeRegionsModal = () => {
    setOpenRegion(false);
    setEnableRegions([]);
  };

  const closeConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };

  const sortEnabledRegions = regions => {
    return regions.sort((a, b) => Number(a.region.split('_')[1]) - Number(b.region.split('_')[1]));
  };

  let labelClass = 'product-configurator-enable-label';
  if (props.isConfiguratorEnabled) labelClass = labelClass + ' ' + 'enable-label--enabled';
  else labelClass = labelClass = labelClass + ' ' + 'enable-label--disabled';

  return (
    <div className="product-configurator-card">
      <div className="product-configurator-main-info">
        <div className={labelClass}>Enabled</div>
        <CardImg src={props.image} alt="Product Image" className="product-configurator-main-info-image" />
        <div className="product-configurator-main-info-data-block">
          <span className="product-configurator-main-info-data-title">{props.name}</span>
          <span className="product-configurator-main-info-data-manufacturer">{props.manufacturer}</span>
          {props.isConfiguratorEnabled ? (
            <div className="enabled-configurator-cards-container">
              <EnabledProductButton title="Modify" onClick={() => setOpenRegion(!openRegion)} />
              {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ROLE_DEALER_ADMIN]) && (
                <EnabledProductButton title="Pricing" onClick={() => history.push('/instant-pricing')} />
              )}
            </div>
          ) : (
            <EnableProductButton onClick={() => setOpenRegion(!openRegion)} />
          )}
        </div>
      </div>
      <div className="product-configurator-regions">
        <div className="product-configurator-regions-container">
          {props.enableRegions.map(item => (
            <div key={item.id} className="product-configurator-regions-region-item">
              <div className="product-configurator-regions-region-title">{'Region' + ' ' + item.region.split('_')[1]}</div>
              <div className="product-configurator-regions-region-value">${item.commission}</div>
            </div>
          ))}
        </div>
        {props.isConfiguratorEnabled && (
          <button onClick={() => setIsConfirmationDialogOpen(true)} className="product-configurator-regions-disable-button">
            <img
              src="../../../content/images/icons/delete-icon.svg"
              alt="delete-button"
              className="product-configurator-regions-disable-button-icon"
            />
          </button>
        )}
      </div>
      <RegionsSelectModal
        isOpen={openRegion}
        name={props.name}
        regions={props.regions}
        enableRegions={enableRegions}
        closeRegionsModal={closeRegionsModal}
        handleRegionActivation={handleRegionActivation}
        enableConfigurator={enableConfigurator}
      />

      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        closeConfirmationDialog={closeConfirmationDialog}
        confirmAction={disableConfiguratorForMarketplace}
        confirmationInfo={'Are you sure you want to Disable all regions for the Super Erecta Builder Configurator?'}
      />
    </div>
  );
};

const mapStateToProps = ({ enableRegion, stripe, authentication }: IRootState) => ({
  regions: enableRegion.regions,
  subscribedProductMetadata: stripe.subscribedProductMetadata,
  account: authentication.account,
});
const mapDispatchToProps = {
  enableConfigurator,
  disableConfiguratorForMarketplace,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductConfiguratorCard);
