import React from 'react';
import './marketplaceManufacturerCard.scss';

interface IMarketPlaceManufacturerCard {
  manufacturer: any;
  onSelect: Function;
  selectedManufacturers: any;
  setSelectedManufacturers: any;
}

const MarketPlaceManufacturerCard = (props: IMarketPlaceManufacturerCard) => {
  const { manufacturer, onSelect, selectedManufacturers, setSelectedManufacturers } = props;

  let cardClass = 'marketplace-manufacturer-product-card';
  if (selectedManufacturers.includes(manufacturer.id)) {
    cardClass = cardClass + ' ' + 'selected-dealer-layout-card';
  }

  return (
    <React.Fragment>
      <div className={cardClass} onClick={e => onSelect(e, selectedManufacturers, manufacturer.id, setSelectedManufacturers)}>
        <img src={manufacturer.companyLogo} alt="Missing image" className="marketplace-card-image" />
        <span className="marketplace-manufacturer-card-title">{manufacturer.name}</span>
        <span className="marketplace-manufacturer-card-description">{manufacturer.city + ',' + manufacturer.state}</span>
      </div>
    </React.Fragment>
  );
};

export default MarketPlaceManufacturerCard;
