import React, { useState, useMemo, memo, lazy, Suspense } from 'react';
import { Table, Button, Modal } from 'reactstrap';
import { useHistory } from 'react-router';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import useViewConfiguration from 'app/customHooks/useSharePageRedirect';
import { IProjectSubmissionLog } from 'app/shared/model/project-submission-log.model';
import MoreActionsDropdown from './MoreActionsDropdown';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light-border.css';
import moment from 'moment';
import './projectSubmissionTable.scss';

interface IProjectSubmissionTable {
  projectSubmissionLogList: any;
  account: any;
  currentProject: any;
  getProject: Function;
}

const ProjectSubmissionTable = memo((props: IProjectSubmissionTable) => {
  let history = useHistory();

  const { setIsViewConfigurationButtonWasPressed, getCurrentProject } = useViewConfiguration(props);
  const [isDrawingsModalOpen, setIsDrawingsModalOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IProjectSubmissionLog>({});

  // TODO: temporary solution to hide invalid items. Need to find the reason on the backend side
  const filteredList = useMemo(() => props.projectSubmissionLogList.filter(item => item.dateCreated), [props.projectSubmissionLogList]);

  const goToProjectSubmissionDetails = (e, id: number) => {
    if (e.target.nodeName !== 'IMG' && e.target.nodeName !== 'BUTTON' && e.target.nodeName !== 'SPAN') {
      history.push(`/project-submission-details/${id}`);
    }
  };

  return (
    <Table responsive borderless hover className="kreator-page-table-template">
      <thead>
        <tr className="kreator-page-table-template--header">
          <th>Project Name</th>
          <th>Customer</th>
          <th>Date</th>
          {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ROLE_FOOD_SERVICE_DRAWING, AUTHORITIES.ROLE_KROWNE_DRAWING]) && (
            <th>Drawings</th>
          )}
          <th>Configuration</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {filteredList.map((item: IProjectSubmissionLog, index) => (
          <tr key={item.id} onClick={e => goToProjectSubmissionDetails(e, item.id)} className="project-submission-table-item">
            <td>{item.projectProjectName}</td>
            <td>
              <p className="project-submission-log-table-item-value">{item.clientName}</p>
              <p className="project-submission-log-table-item-subvalue">{item.email}</p>
            </td>

            <td>
              <p className="project-submission-log-table-item-value">{moment(item.dateCreated).format('MMMM DD, YYYY')}</p>
              <p className="project-submission-log-table-item-subvalue">{moment(item.dateCreated).format('HH:mm a')}</p>
            </td>
            <td className="project-submission-table-button-container">
              <Button
                className="project-submission-table-button"
                onClick={() => {
                  setIsDrawingsModalOpen(true);
                  setSelectedItem(item);
                }}
                disabled={!item.foodServiceUrl && !item.krowneDrawingUrl}
              >
                <Tippy
                  content="There are no Drawing files"
                  disabled={!!item.foodServiceUrl || !!item.krowneDrawingUrl}
                  placement="bottom"
                  theme="light-border"
                >
                  <div>
                    <img src="../../../content/images/icons/exportPdf.svg" width="25" height="25" />

                    {item.krowneDrawingUrl || item.foodServiceUrl ? (
                      <span className="project-submission-table-filename">
                        {item.krowneDrawingUrl
                          ? `${item.krowneDrawingUrl.split('/').pop().substring(0, 8)}...${item.krowneDrawingUrl
                              .split('/')
                              .pop()
                              .substring(item.krowneDrawingUrl.split('/').pop().length - 6)}`
                          : `${item.foodServiceUrl.split('/').pop().substring(0, 8)}...${item.foodServiceUrl
                              .split('/')
                              .pop()
                              .substring(item.foodServiceUrl.split('/').pop().length - 6)}`}
                      </span>
                    ) : null}
                  </div>
                </Tippy>
              </Button>
            </td>
            <td className="project-submission-table-button-container">
              <Button
                className="project-submission-table-open-configurator-button"
                onClick={() => {
                  getCurrentProject(item.projectId);
                  setIsViewConfigurationButtonWasPressed(true);
                }}
              >
                <span className="project-submission-table-open-configurator-button-label">Open Configurator</span>
              </Button>
            </td>
            <td>
              <MoreActionsDropdown reportUrl={item.reportUrl} externalFileUrl={item.externalFileUrl} />
            </td>
          </tr>
        ))}
      </tbody>
      <Modal
        isOpen={isDrawingsModalOpen}
        centered
        style={{ maxWidth: 700, width: window.screen.width - 1050 }}
        toggle={() => setIsDrawingsModalOpen(false)}
        backdrop={true}
      >
        <div className="create-drawings-modal-container">
          {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ROLE_FOOD_SERVICE_DRAWING]) && selectedItem.foodServiceUrl && (
            <button className="create-drawings-modal-button" onClick={() => window.open(selectedItem.foodServiceUrl, '_blank')}>
              <img src="../../../content/images/icons/marketplace-cart.svg" alt="marketplace cart" width="30" />
              Foodservice Drawings
            </button>
          )}

          {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ROLE_KROWNE_DRAWING]) && selectedItem.krowneDrawingUrl && (
            <button className="create-drawings-modal-button" onClick={() => window.open(selectedItem.krowneDrawingUrl, '_blank')}>
              <img src="../../../content/images/icons/marketplace-cart.svg" alt="marketplace cart" width="30" />
              Krowne Drawings
            </button>
          )}
        </div>
      </Modal>
    </Table>
  );
});

export default ProjectSubmissionTable;
