import { SERVER_API_URL } from 'app/config/constants';
import axios from 'axios';
import { Storage } from 'react-jhipster';


const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

const setupAxiosInterceptors = onUnauthenticated => {
  const onRequestSuccess = config => {
    const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };
  const onResponseSuccess = response => response;
  const onResponseError = err => {

    const ignoreList = [
      "https://www.roomle.com"
    ]

    const whiteListOrigin = ignoreList.some(p => err.request.responseURL.startsWith(p));

    // A roomle configuration can fail so we'll check to only fail the api calls that come from our server
    const status = err.status || (err.response ? err.response.status : 0);
    if ((status === 403 || status === 401) && !whiteListOrigin) {
      onUnauthenticated();
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
