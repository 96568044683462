import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IManufacturerRef, defaultValue } from 'app/shared/model/manufacturer-ref.model';
import { IConsultant } from 'app/shared/model/consultant.model';

export const ACTION_TYPES = {
  FETCH_MANUFACTURERREF_LIST: 'manufacturerRef/FETCH_MANUFACTURERREF_LIST',
  FETCH_MANUFACTURERREF: 'manufacturerRef/FETCH_MANUFACTURERREF',
  CREATE_MANUFACTURERREF: 'manufacturerRef/CREATE_MANUFACTURERREF',
  UPDATE_MANUFACTURERREF: 'manufacturerRef/UPDATE_MANUFACTURERREF',
  DELETE_MANUFACTURERREF: 'manufacturerRef/DELETE_MANUFACTURERREF',
  RESET: 'manufacturerRef/RESET',
};

const initialState = {
  loading: true,
  errorMessage: null,
  entities: [] as ReadonlyArray<IManufacturerRef>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type ManufacturerRefState = Readonly<typeof initialState>;

// Reducer

export default (state: ManufacturerRefState = initialState, action): ManufacturerRefState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_MANUFACTURERREF_LIST):
    case REQUEST(ACTION_TYPES.FETCH_MANUFACTURERREF):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_MANUFACTURERREF):
    case REQUEST(ACTION_TYPES.UPDATE_MANUFACTURERREF):
    case REQUEST(ACTION_TYPES.DELETE_MANUFACTURERREF):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_MANUFACTURERREF_LIST):
    case FAILURE(ACTION_TYPES.FETCH_MANUFACTURERREF):
    case FAILURE(ACTION_TYPES.CREATE_MANUFACTURERREF):
    case FAILURE(ACTION_TYPES.UPDATE_MANUFACTURERREF):
    case FAILURE(ACTION_TYPES.DELETE_MANUFACTURERREF):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_MANUFACTURERREF_LIST):
      return {
        ...state,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_MANUFACTURERREF):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_MANUFACTURERREF):
    case SUCCESS(ACTION_TYPES.UPDATE_MANUFACTURERREF):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_MANUFACTURERREF):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/manufacturer-refs';

// Actions

export const getEntities: ICrudGetAllAction<IManufacturerRef> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_MANUFACTURERREF_LIST,
    payload: axios.get<IManufacturerRef>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IManufacturerRef> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_MANUFACTURERREF,
    payload: axios.get<IManufacturerRef>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IManufacturerRef> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_MANUFACTURERREF,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IManufacturerRef> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_MANUFACTURERREF,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IManufacturerRef> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_MANUFACTURERREF,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const getManufacturerRefForCurrentUser = () => {
  const requestUrl = '/api/manufacturer-refs/get-manufacturer-ref-by-current-user';
  return {
    type: ACTION_TYPES.FETCH_MANUFACTURERREF,
    payload: axios.get(requestUrl),
  };
};

export const getBusinessApprovals: ICrudGetAllAction<IManufacturerRef> = (page, size, sort) => {
  const requestUrl = `${apiUrl}/business-approvals${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_MANUFACTURERREF_LIST,
    payload: axios.get<IManufacturerRef>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};
