import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getDownloadFile, getEntity} from './product.reducer';
import {FOOD_SERVICE_EQUIPMENT, SPECIALITY_EQUIPMENT} from 'app/config/constants';

export interface IProductDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const ProductDetail = (props: IProductDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);
  const fileName = (familyFilePath: string, additionalPath: string, year: string, familyFileName: string) => {
    return `${familyFilePath}\\${additionalPath}\\${year}\\${familyFileName}`;
  };
  const {productEntity} = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          Product [<b>{productEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="name">Name</span>
          </dt>
          <dd>{productEntity.name}</dd>
          <dt>
            <span id="description">Description</span>
          </dt>
          <dd>{productEntity.description}</dd>
          <dt>
            <span id="typeCatalogPath">Type Catalog Path</span>
          </dt>
          <dd>{productEntity.typeCatalogPath}</dd>
          <dt>
            <span id="familyFilePath">Family File Path</span>
          </dt>
          <dd>{productEntity.familyFilePath}</dd>
          <dt>
            <span id="roomleComponentId">Roomle Component Id</span>
          </dt>
          <dd>{productEntity.roomleComponentId}</dd>
          <dt>
            <span id="availableRevitVersions">Available Revit Versions</span>
          </dt>
          <dd>{productEntity.availableRevitVersions}</dd>
          <dt>
            <span id="familyParametersWhiteList">Family Parameters White List</span>
          </dt>
          <dd>{productEntity.familyParametersWhiteList}</dd>
          <dt>
            <span id="configuration">Configuration</span>
          </dt>
          <dd>{productEntity.configuration}</dd>
          <dt>
            <span id="isMOC">Is MOC</span>
          </dt>
          <dd>{productEntity.isMOC ? 'true' : 'false'}</dd>
          <dt>
            <span id="visibleToAdmin">Visible To Admin</span>
          </dt>
          <dd>{productEntity.visibleToAdmin ? 'true' : 'false'}</dd>
          <dt>
            <span id="showInMarketPlace">Show In Market Place</span>
          </dt>
          <dd>{productEntity.showInMarketPlace ? 'true' : 'false'}</dd>
          <dt>Manufacturer</dt>
          <dd>{productEntity.manufacturerName ? productEntity.manufacturerName : ''}</dd>

          <dt>
            <span id="revitExportEnable">Revit Export Enable</span>
          </dt>
          <dd>{productEntity.revitExportEnable ? 'true' : 'false'}</dd>
          <dt>
            <span id="visibleToAdmin">Only Visible for Admin Users</span>
          </dt>
          <dd>{productEntity.visibleToAdmin ? 'Yes' : 'No'}</dd>

          <h5 className={"mb-3 mt-3"}>Specialty Equipment</h5>
          <div className={"ml-5"}>

            <dt>
              <span id="familyFilePath">Family File Path 2019</span>
            </dt>
            <dd>
              {productEntity.availableRevitVersions &&
              productEntity.availableRevitVersions.includes('2019') &&
              productEntity.familyFilePath ? (
                <>
                  <Button color="link" onClick={() => getDownloadFile(productEntity.id, SPECIALITY_EQUIPMENT, '2019')}>
                    <FontAwesomeIcon icon="cloud-download-alt"/>{' '}
                  </Button>
                  <a onClick={() => getDownloadFile(productEntity.id, SPECIALITY_EQUIPMENT, '2019')}>
                    {fileName(productEntity.familyFilePath, SPECIALITY_EQUIPMENT, '2019', productEntity.familyFilename)}
                  </a>
                </>
              ) : (
                <>Not available</>
              )}
            </dd>
            <dt>
              <span id="familyFilePath">Family File Path 2020</span>
            </dt>
            <dd>
              {productEntity.availableRevitVersions &&
              productEntity.availableRevitVersions.includes('2020') &&
              productEntity.familyFilePath ? (
                <>
                  <Button color="link" onClick={() => getDownloadFile(productEntity.id, SPECIALITY_EQUIPMENT, '2020')}>
                    <FontAwesomeIcon icon="cloud-download-alt"/>{' '}
                  </Button>
                  <a onClick={() => getDownloadFile(productEntity.id, SPECIALITY_EQUIPMENT, '2020')}>
                    {fileName(productEntity.familyFilePath, SPECIALITY_EQUIPMENT, '2020', productEntity.familyFilename)}
                  </a>
                </>
              ) : (
                <>Not available</>
              )}
            </dd>
            <dt>
              <span id="familyFilePath">Family File Path 2021</span>
            </dt>
            <dd>
              {productEntity.availableRevitVersions &&
              productEntity.availableRevitVersions.includes('2021') &&
              productEntity.familyFilePath ? (
                <>
                  <Button color="link" onClick={() => getDownloadFile(productEntity.id, SPECIALITY_EQUIPMENT, '2021')}>
                    <FontAwesomeIcon icon="cloud-download-alt"/>{' '}
                  </Button>
                  <a onClick={() => getDownloadFile(productEntity.id, SPECIALITY_EQUIPMENT, '2021')}>
                    {fileName(productEntity.familyFilePath, SPECIALITY_EQUIPMENT, '2021', productEntity.familyFilename)}
                  </a>
                </>
              ) : (
                <>Not available</>
              )}
            </dd>
            <dt>
              <span id="familyFilePath">Family File Path 2022</span>
            </dt>
            <dd>
              {productEntity.availableRevitVersions &&
              productEntity.availableRevitVersions.includes('2022') &&
              productEntity.familyFilePath ? (
                <>
                  <Button color="link" onClick={() => getDownloadFile(productEntity.id, SPECIALITY_EQUIPMENT, '2022')}>
                    <FontAwesomeIcon icon="cloud-download-alt"/>{' '}
                  </Button>
                  <a onClick={() => getDownloadFile(productEntity.id, '2022')}>
                    {fileName(productEntity.familyFilePath, SPECIALITY_EQUIPMENT, '2022', productEntity.familyFilename)}
                  </a>
                </>
              ) : (
                <>Not available</>
              )}
            </dd>
            <dt>
              <span id="familyFilePath">Family File Path 2023</span>
            </dt>
            <dd>
              {productEntity.availableRevitVersions &&
              productEntity.availableRevitVersions.includes('2023') &&
              productEntity.familyFilePath ? (
                <>
                  <Button color="link" onClick={() => getDownloadFile(productEntity.id, SPECIALITY_EQUIPMENT, '2023')}>
                    <FontAwesomeIcon icon="cloud-download-alt"/>{' '}
                  </Button>
                  <a onClick={() => getDownloadFile(productEntity.id, '2023')}>
                    {fileName(productEntity.familyFilePath, SPECIALITY_EQUIPMENT, '2023', productEntity.familyFilename)}
                  </a>
                </>
              ) : (
                <>Not available</>
              )}
            </dd>

            <dt>
              <span id="familyFilePath">Family File Path 2024</span>
            </dt>
            <dd>
              {productEntity.availableRevitVersions &&
              productEntity.availableRevitVersions.includes('2024') &&
              productEntity.familyFilePath ? (
                <>
                  <Button color="link" onClick={() => getDownloadFile(productEntity.id, SPECIALITY_EQUIPMENT, '2024')}>
                    <FontAwesomeIcon icon="cloud-download-alt"/>{' '}
                  </Button>
                  <a onClick={() => getDownloadFile(productEntity.id, '2024')}>
                    {fileName(productEntity.familyFilePath, SPECIALITY_EQUIPMENT, '2024', productEntity.familyFilename)}
                  </a>
                </>
              ) : (
                <>Not available</>
              )}
            </dd>
          </div>

          <h5 className={"mb-3 mt-3"}>Food Service Equipment</h5>
          <div className={"ml-5"}>
            <dt>
              <span id="familyFilePath">Family File Path 2022</span>
            </dt>
            <dd>
              {productEntity.availableRevitVersions &&
              productEntity.availableRevitVersions.includes('2022') &&
              productEntity.familyFilePath ? (
                <>
                  <Button color="link"
                          onClick={() => getDownloadFile(productEntity.id, FOOD_SERVICE_EQUIPMENT, '2022')}>
                    <FontAwesomeIcon icon="cloud-download-alt"/>{' '}
                  </Button>
                  <a onClick={() => getDownloadFile(productEntity.id, FOOD_SERVICE_EQUIPMENT, '2022')}>
                    {fileName(productEntity.familyFilePath, FOOD_SERVICE_EQUIPMENT, '2022', productEntity.familyFilename)}
                  </a>
                </>
              ) : (
                <>Not available</>
              )}
            </dd>
            <dt>
              <span id="familyFilePath">Family File Path 2023</span>
            </dt>
            <dd>
              {productEntity.availableRevitVersions &&
              productEntity.availableRevitVersions.includes('2023') &&
              productEntity.familyFilePath ? (
                <>
                  <Button color="link"
                          onClick={() => getDownloadFile(productEntity.id, FOOD_SERVICE_EQUIPMENT, '2023')}>
                    <FontAwesomeIcon icon="cloud-download-alt"/>{' '}
                  </Button>
                  <a onClick={() => getDownloadFile(productEntity.id, FOOD_SERVICE_EQUIPMENT, '2023')}>
                    {fileName(productEntity.familyFilePath, FOOD_SERVICE_EQUIPMENT, '2023', productEntity.familyFilename)}
                  </a>
                </>
              ) : (
                <>Not available</>
              )}
            </dd>
            <dt>
              <span id="familyFilePath">Family File Path 2024</span>
            </dt>
            <dd>
              {productEntity.availableRevitVersions &&
              productEntity.availableRevitVersions.includes('2024') &&
              productEntity.familyFilePath ? (
                <>
                  <Button color="link"
                          onClick={() => getDownloadFile(productEntity.id, FOOD_SERVICE_EQUIPMENT, '2024')}>
                    <FontAwesomeIcon icon="cloud-download-alt"/>{' '}
                  </Button>
                  <a onClick={() => getDownloadFile(productEntity.id, FOOD_SERVICE_EQUIPMENT, '2024')}>
                    {fileName(productEntity.familyFilePath, FOOD_SERVICE_EQUIPMENT, '2024', productEntity.familyFilename)}
                  </a>
                </>
              ) : (
                <>Not available</>
              )}
            </dd>
          </div>


        </dl>
        <Button tag={Link} to="/product" replace color="info">
          <FontAwesomeIcon icon="arrow-left"/> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/product/${productEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt"/> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({product}: IRootState) => ({
  productEntity: product.entity,
});

const mapDispatchToProps = {getEntity};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
