import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAllAction, getSortState, IPaginationBaseState, JhiPagination } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './dealer.reducer';
import { IDealer } from 'app/shared/model/dealer.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface IDealerProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type IDealerState = IPaginationBaseState;

export class Dealer extends React.Component<IDealerProps, IDealerState> {
  state: IDealerState = {
    ...getSortState(this.props.location, ITEMS_PER_PAGE),
  };

  componentDidMount() {
    this.getEntities();
  }

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    const { activePage, itemsPerPage, sort, order } = this.state;
    this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
  };

  render() {
    const { dealerList, match, totalItems } = this.props;
    return (
      <div>
        <h2 id="dealer-heading">
          Dealers
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp; Create new Dealer
          </Link>
        </h2>
        <div className="table-responsive">
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={this.sort('id')}>
                  ID <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('name')}>
                  Name <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('enabled')}>
                  Enabled <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('logo')}>
                  Logo <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('websiteURL')}>
                  Website URL <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('city')}>
                  City <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('state')}>
                  State <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('businessName')}>
                  Business Name <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('skills')}>
                  Skills <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('qualifySpecialStatus')}>
                  Qualify Special Status <FontAwesomeIcon icon="sort" />
                </th>
                {/*<th className="hand" onClick={this.sort('cardNumber')}>*/}
                {/*  Card Number <FontAwesomeIcon icon="sort" />*/}
                {/*</th>*/}
                {/*<th className="hand" onClick={this.sort('cardHolder')}>*/}
                {/*  Card Holder <FontAwesomeIcon icon="sort" />*/}
                {/*</th>*/}
                {/*<th className="hand" onClick={this.sort('customerId')}>*/}
                {/*  Customer Id <FontAwesomeIcon icon="sort" />*/}
                {/*</th>*/}
                {/*<th className="hand" onClick={this.sort('month')}>*/}
                {/*  Month <FontAwesomeIcon icon="sort" />*/}
                {/*</th>*/}
                {/*<th className="hand" onClick={this.sort('year')}>*/}
                {/*  Year <FontAwesomeIcon icon="sort" />*/}
                {/*</th>*/}
                {/*<th className="hand" onClick={this.sort('cvc')}>*/}
                {/*  Cvc <FontAwesomeIcon icon="sort" />*/}
                {/*</th>*/}
                {/*<th className="hand" onClick={this.sort('status')}>*/}
                {/*  Status <FontAwesomeIcon icon="sort" />*/}
                {/*</th>*/}
                {/*<th className="hand" onClick={this.sort('refDealerId')}>*/}
                {/*  Ref Dealer Id <FontAwesomeIcon icon="sort" />*/}
                {/*</th>*/}
                {/*<th className="hand" onClick={this.sort('subscriptionId')}>*/}
                {/*  Subscription Id <FontAwesomeIcon icon="sort" />*/}
                {/*</th>*/}
                {/*<th className="hand" onClick={this.sort('payPerUsagesSubscriptionId')}>*/}
                {/*  Pay Per Usages Subscription Id <FontAwesomeIcon icon="sort" />*/}
                {/*</th>*/}
                {/*<th>*/}
                {/*  User <FontAwesomeIcon icon="sort" />*/}
                {/*</th>*/}
                <th />
              </tr>
            </thead>
            <tbody>
              {dealerList.map((dealer, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${dealer.id}/edit`} color="primary" size="sm">
                      <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                    </Button>
                    <Button tag={Link} to={`${match.url}/${dealer.id}`} color="link" size="sm">
                      {dealer.id}
                    </Button>
                  </td>
                  <td>{dealer.name}</td>
                  <td>{dealer.enabled ? 'true' : 'false'}</td>
                  <td>
                    <img src={dealer.logo} alt="product image" width="80" />
                  </td>
                  <td>{dealer.websiteURL}</td>
                  <td>{dealer.city}</td>
                  <td>{dealer.state}</td>
                  <td>{dealer.businessName}</td>
                  <td>{dealer.skills}</td>
                  <td>{dealer.qualifySpecialStatus}</td>
                  {/*<td>{dealer.cardNumber}</td>*/}
                  {/*<td>{dealer.cardHolder}</td>*/}
                  {/*<td>{dealer.customerId}</td>*/}
                  {/*<td>{dealer.month}</td>*/}
                  {/*<td>{dealer.year}</td>*/}
                  {/*<td>{dealer.cvc}</td>*/}
                  {/*<td>{dealer.status}</td>*/}
                  {/*<td>{dealer.refDealerId}</td>*/}
                  {/*<td>{dealer.subscriptionId}</td>*/}
                  {/*<td>{dealer.payPerUsagesSubscriptionId}</td>*/}
                  {/*<td>{dealer.userLogin ? dealer.userLogin : ''}</td>*/}
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${dealer.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${dealer.id}/edit`} color="primary" size="sm">
                        <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${dealer.id}/delete`} color="danger" size="sm">
                        <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Delete</span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Row className="justify-content-center">
          <JhiPagination
            activePage={this.state.activePage}
            onSelect={this.handlePagination}
            maxButtons={5}
            itemsPerPage={this.state.itemsPerPage}
            totalItems={totalItems}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ dealer }: IRootState) => ({
  dealerList: dealer.entities,
  totalItems: dealer.totalItems,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Dealer);
