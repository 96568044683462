// @ts-nocheck
import React, { useEffect, useState, useRef } from 'react';
import './dealerRegistrationProcessPages.scss';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FormGroup, Row } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { stripConfig } from '../../../stripe';
import { processSubscription } from 'app/entities/stripe/stripe.reducer';
import DealerPricing from 'app/components/Pricing/DealerPricing';

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      // color: '#348aff',
      // fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      // fontSmoothing: 'antialiased',
      // fontSize: '16px',
      '::placeholder': {
        // color: '#aab7c4'
      },
    },
    invalid: {
      // color: '#fa755a',
      // iconColor: '#fa755a'
    },
  },
};

const CreditCardInformation = ({
  cardNumber,
  setCardNumber,
  cardHolder,
  setCardHolder,
  month,
  setMonth,
  year,
  setYear,
  cvc,
  setCvc,
  account,
  businessMember,
  updateEntity,
  processSubscription,
}) => {
  const cardFomr = useRef(null);
  const stripe = useStripe();
  const elements = useElements();
  const [submitted, setSubmitted] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [message, setMessage] = useState('');
  const [product, setProduct] = useState(null);

  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${stripConfig.stripe_secret_key}`);
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  const getSubscriptionDetails = async () => {
    const sourceRequest = { method: 'GET', headers: myHeaders, redirect: 'follow' };
    const subscriptionDetails = await fetch('https://api.stripe.com/v1/subscriptions/' + businessMember.subscriptionId, sourceRequest)
      .then(response => response.json())
      .catch(reason => reason.response.data);
    setSubscriptionDetails(subscriptionDetails);
  };

  useEffect(() => {
    if (businessMember.subscriptionId) {
      getSubscriptionDetails();
    }
  }, []);

  useEffect(() => {
    if (product) executeScroll();
  }, [product]);

  const handleSubmit = async event => {
    event.preventDefault();
    setSubmitted(true);
    setMessage('');
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardElement);
    const cardValidation = await stripe.createToken(card);
    if (cardValidation.error) {
      setMessage(cardValidation.error.message);
      setSubmitted(false);
      return;
    }

    if (typeof cardValidation.token.id !== 'undefined') {
      const params = new URLSearchParams();
      params.append('source', cardValidation.token.id);
      let customer;
      let stripCustomerId = businessMember.customerId;
      if (stripCustomerId == null) {
        customer = await createCustomerIfNotExist(account.email, account.firstName, cardValidation.token.id);
        if (customer.id) {
          stripCustomerId = customer.id;
        } else {
          setMessage(customer.error.message);
        }
      }

      if (stripCustomerId) {
        const newBusinessMember = { ...businessMember, customerId: stripCustomerId };

        if (newBusinessMember.subscriptionId != null) {
          await cancelSubscription(newBusinessMember.subscriptionId);
        }

        const subscriptionParams = new URLSearchParams();
        subscriptionParams.append('customer', stripCustomerId);
        if (product != null) {
          product.prices.map((price, index) => {
            subscriptionParams.append('items[' + index + '][price]', price.id);
          });
          subscriptionParams.append('metadata[name]', account.firstName);
          subscriptionParams.append('metadata[email]', account.email);
        }
        let firstSubscription = await createSubscription(subscriptionParams);
        if (typeof firstSubscription.id !== 'undefined') {
          if (firstSubscription.status === 'active') {
            newBusinessMember.subscriptionId = firstSubscription.id;
          }
        }

        if (businessMember.payPerUsagesSubscriptionId == null) {
          const peyPerUsagesSubscriptionParams = new URLSearchParams();
          peyPerUsagesSubscriptionParams.append('customer', stripCustomerId);
          peyPerUsagesSubscriptionParams.append('items[0][price]', stripConfig.price_id);
          peyPerUsagesSubscriptionParams.append('metadata[name]', account.firstName);
          peyPerUsagesSubscriptionParams.append('metadata[email]', account.email);
          let payPerSubscription = await createSubscription(peyPerUsagesSubscriptionParams);
          if (typeof payPerSubscription.id !== 'undefined') {
            if (payPerSubscription.status === 'active') {
              newBusinessMember.payPerUsagesSubscriptionId = payPerSubscription.id;
            }
          }
        }
        updateEntity(newBusinessMember);
      }
    }
  };

  const createSubscription = async (subscriptionParams: URLSearchParams) => {
    return await fetch('https://api.stripe.com/v1/subscriptions', {
      method: 'POST',
      headers: myHeaders,
      body: subscriptionParams,
      redirect: 'follow',
    }).then(response => response.json());
  };

  const cancelSubscription = async subscriptionId => {
    return await fetch('https://api.stripe.com/v1/subscriptions/' + subscriptionId, {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    }).then(response => response.json());
  };

  const createCustomerIfNotExist = async (email, name, cardToken) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append('email', email);
    urlencoded.append('name', name);
    urlencoded.append('source', cardToken);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
    };

    return await fetch('https://api.stripe.com/v1/customers', requestOptions)
      .then(response => response.json())
      .catch(reason => reason.response.data);
  };

  const executeScroll = () => {
    cardFomr.current.scrollIntoView({ behavior: 'smooth', inline: 'end' });
  };

  return (
    <>
      <Row style={{ marginTop: -25 }}>
        <DealerPricing setProduct={setProduct} product={product} subscriptionDetails={subscriptionDetails} />
      </Row>
      {/* <Row>

        {products && products.length > 0
          ? products.map((stripeProduct, index) => {
            if (stripeProduct.prices[0].billingScheme == "tiered") {
              return
            }
            return (
              <Col lg={3}>
                <Card>
                  <CardHeader>{stripeProduct.product.name}</CardHeader>
                  <CardBody>
                    {stripeProduct.prices.map((price, index) => {
                      if (price.billingScheme == 'tiered') {
                        return <li>{price.nickname} per point</li>;
                      } else {
                        return (
                          <li>
                            {price.unitAmount / 100}
                            {price.currency.toUpperCase()}/{price.recurring?.interval}
                          </li>
                        );
                      }
                    })}
                  </CardBody>
                  <CardFooter>
                    <Button
                      disabled={subscriptionDetails?.items?.data[0]?.plan?.product === stripeProduct.product.id}
                      color={'primary'}
                      type={'submit'}
                      onClick={() => setProduct(stripeProduct)}
                    >
                      Subscribe
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            );
          })
          : ''}
      </Row> */}

      <div className={'mt-4'} ref={cardFomr}>
        {product != null ? (
          <form onSubmit={handleSubmit}>
            <FormGroup className="mb-3">
              <CardElement options={CARD_ELEMENT_OPTIONS} className="stripe-form" />
            </FormGroup>
            <div className="email-settings-buttons-container">
              <button type={'submit'} className="confirm-button" disabled={!stripe}>
                Subscribe
              </button>
            </div>
          </form>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ authentication, stripe }: IRootState) => ({
  subscription: stripe.subscription,
  products: stripe.products,
  account: authentication.account,
});

const mapDispatchToProps = {
  processSubscription,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardInformation);
