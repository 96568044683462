import React from 'react';
import { Modal } from 'reactstrap';
import './krowneFaucetFlexForm.scss';

interface IRequestSpecPopUp {
  isOpen: boolean;
  closeModal: Function;
  roomleConfiguration: any;
  setIsFaucetFlexFormModalOpen: Function;
}

const RequestSpecPopUp = (props: IRequestSpecPopUp) => {
  return (
    <Modal
      isOpen={props.isOpen}
      centered
      fade={false}
      toggle={() => props.closeModal(false)}
      backdrop={true}
      style={
        window.screen.width >= 1024
          ? { maxWidth: window.screen.width - 420, width: '100%' }
          : { maxWidth: '95%', width: '95%', margin: 'auto' }
      }
    >
      <div className="faucet-flex-spec-pop-up">
        <div className="faucet-flex-spec-pop-up-content">
          <div className="faucet-flex-pop-up-header">
            <img src="../../../content/images/Krowne-FaucetFlex-Logo.png" className="faucet-flex-logo-image" alt="logo" />
            <span className="faucet-flex-close-spec-pup-up-button" onClick={() => props.closeModal()}>
              X
            </span>
          </div>
          <div className="faucet-flex-spec-layout-container">
            <div className="faucet-flex-spec-image-container">
              <img src={props.roomleConfiguration?.mailConfigParam?.configImage} alt="render image" className="render-image" />
            </div>
            <div className="faucet-flex-spec-data-container">
              <p className="faucet-flex-price-title">
                LIST PRICE <span className="faucet-flex-price-value">${props.roomleConfiguration?.price?.price}</span>
              </p>
              <p className="faucet-flex-spec-description"></p>
              <table id="table" className="faucet-flex-table">
                <thead>
                  <tr className="faucet-flex-tr">
                    <th className="faucet-flex-th">DESCRIPTION</th>
                    <th className="faucet-flex-th">MODEL</th>
                    <th className="faucet-flex-th">QUANTITY</th>
                    <th className="faucet-flex-th">PRICE</th>
                  </tr>
                </thead>
                <tbody>
                  {props.roomleConfiguration?.fullList?.map((item, index) => (
                    <tr className="faucet-flex-tr" key={index}>
                      <td className="faucet-flex-td">{item.label}</td>
                      <td className="faucet-flex-td">{item.articleNr}</td>
                      <td className="faucet-flex-td">{item.count}</td>
                      <td className="faucet-flex-td">{item.price ? '$' + item.count * item.price : ' '}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="faucet-flex-request-spec-button-container">
                <button
                  className="faucet-flex-request-spec-button"
                  onClick={() => {
                    props.setIsFaucetFlexFormModalOpen(true);
                    props.closeModal();
                  }}
                >
                  Request Spec
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RequestSpecPopUp;
