import { ILeadsProducts } from 'app/shared/model/leads-products.model';
import { LeadsStatus } from 'app/shared/model/enumerations/leads-status.model';

export const enum InstallationType {
  DROP_SHIP_VIA_COMMON_CARRIER = 'DROP_SHIP_VIA_COMMON_CARRIER',
  STORE_AT_WARE_HOUSE_FOR_SCHEDULE_DELIVERY = 'STORE_AT_WARE_HOUSE_FOR_SCHEDULE_DELIVERY',
  STANDARD_INSTALLATION = 'STANDARD_INSTALLATION',
  TURNKEY_INSTALLATION = 'TURNKEY_INSTALLATION',
}

export interface ILeads {
  id?: number;
  status?: LeadsStatus;
  originFirstName?: string;
  originLastName?: string;
  originEmail?: string;
  originStreetAddress?: string;
  originZipCode?: string;
  originCity?: string;
  originState?: string;
  originCountry?: string;
  originPhone?: string;
  sameAsAbove?: boolean;
  projectFirstName?: string;
  projectLastName?: string;
  projectEmail?: string;
  projectStreetAddress?: string;
  projectZipCode?: string;
  projectCity?: string;
  projectState?: string;
  projectCountry?: string;
  dateTime?: string;
  versionNumber?: string;
  notes?: string;
  projectName?: string;
  deliveryInstallationType?: InstallationType;
  manufacturerName?: string;
  manufacturerId?: number;
  dealerName?: string;
  dealerId?: number;
  userLogin?: string;
  userId?: number;
  leadsProducts?: ILeadsProducts[];
}

export const defaultValue: Readonly<ILeads> = {
  sameAsAbove: false,
};
