import React, { useState } from 'react';
import { Modal } from 'reactstrap';
import axios, { Method } from 'axios';
import './krowneFaucetFlexForm.scss';

interface IFaucetFlexForm {
  isOpen: boolean;
  closeModal: Function;
  roomleConfiguration: any;
}

const FaucetFlexForm = (props: IFaucetFlexForm) => {
  const defaultFormData = {
    name: '',
    company: '',
    email: '',
    phone: '',
    projectName: '',
    projectZip: '',
    occupation: 'Owner',
    followUp: 'no',
    comments: '',
  };
  const [formData, setFormData] = useState(defaultFormData);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (formData.name === '' || formData.company === '' || formData.email === '' || formData.phone === '') {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    sendDataToBackend(formData, props.roomleConfiguration);

    exportRevit(formData, props.roomleConfiguration);

    props.closeModal();
    setFormData(defaultFormData);
    setIsFormValid(false);
  };

  const sendDataToBackend = (formData, configurator) => {
    let data = JSON.stringify({
      formData: formData,
      configurator: configurator,
    });

    const config = {
      method: 'POST' as Method,
      url: 'https://kreator-krowne-email-api.azurewebsites.net/sendmail',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: data,
    };

    axios(config);
  };

  const exportRevit = (formData, configurator) => {
    let data = JSON.stringify({
      email: formData.email,
      configuration: configurator.configurationId,
    });

    const config = {
      method: 'POST' as Method,
      url: 'https://www.kreator.app/api/external/share-revit?api_key=ca59c0b5-ad93-48c5-b52d-6bf3ba7b3492',
      maxBodyLength: Infinity,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      centered
      fade={false}
      toggle={() => props.closeModal(false)}
      backdrop={true}
      style={
        window.screen.width >= 1024
          ? { maxWidth: window.screen.width - 220, width: '100%' }
          : { maxWidth: '95%', width: '95%', margin: 'auto' }
      }
    >
      <div className="faucet-flex-spec-pop-up">
        <div className="pop-up-configuration-data"></div>
        <div className="faucet-flex-client-form-data">
          <div className="faucet-flex-pop-up-header">
            <img src="../../../content/images/Krowne-FaucetFlex-Logo.png" className="faucet-flex-logo-image" alt="logo" />
            <span className="faucet-flex-close-spec-pup-up-button" onClick={() => props.closeModal()}>
              X
            </span>
          </div>
          <form onSubmit={handleSubmit} className="faucet-flex-form">
            <div className="faucet-flex-form-group">
              <label htmlFor="form-client-name">
                Name <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="text"
                className="faucet-flex-form-control"
                id="form-client-name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="faucet-flex-form-group">
              <label htmlFor="form-client-company">
                Company <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="text"
                className="faucet-flex-form-control"
                id="form-client-company"
                name="company"
                value={formData.company}
                onChange={handleInputChange}
              />
            </div>
            <div className="faucet-flex-form-group">
              <label htmlFor="form-client-email">
                Email <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="email"
                className="faucet-flex-form-control"
                id="form-client-email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="faucet-flex-form-group">
              <label htmlFor="form-client-phone">
                Phone Number <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="tel"
                className="faucet-flex-form-control"
                id="form-client-phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
            <div className="faucet-flex-form-group">
              <label htmlFor="form-project">Project Name</label>
              <input
                type="text"
                className="faucet-flex-form-control"
                id="form-project"
                name="projectName"
                value={formData.projectName}
                onChange={handleInputChange}
              />
            </div>
            <div className="faucet-flex-form-group">
              <label htmlFor="form-project-zip">Project Zip</label>
              <input
                type="text"
                className="faucet-flex-form-control"
                id="form-project-zip"
                name="projectZip"
                value={formData.projectZip}
                onChange={handleInputChange}
              />
            </div>
            <div className="faucet-flex-form-group">
              <label htmlFor="form-occupation">
                Occupation <span style={{ color: 'red' }}>*</span>
              </label>
              <select
                className="faucet-flex-form-control"
                id="form-occupation"
                name="occupation"
                value={formData.occupation}
                onChange={handleInputChange}
              >
                <option value="Owner">Owner</option>
                <option value="Dealer">Dealer</option>
                <option value="Consultant">Consultant</option>
                <option value="Mfr Rep">Mfr Rep</option>
                <option value="Architect">Architect</option>
                <option value="Bartender">Bartender</option>
              </select>
            </div>
            <div className="faucet-flex-form-group">
              <label htmlFor="form-followup">Would you like someone from Krowne to follow up with you on this request?:</label>
              <fieldset className="radioButtons-block">
                <div>
                  <input
                    type="radio"
                    id="yes-radio"
                    name="followUp"
                    value="yes"
                    checked={formData.followUp === 'yes'}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="yes-radio" className="radio-button-label">
                    Yes
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="no-radio"
                    name="followUp"
                    value="no"
                    checked={formData.followUp === 'no'}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="no-radio" className="radio-button-label">
                    No
                  </label>
                </div>
              </fieldset>
            </div>
            <div className="faucet-flex-form-group">
              <label htmlFor="form-comments">Comments:</label>
              <textarea
                className="faucet-flex-form-control"
                id="form-comments"
                name="comments"
                value={formData.comments}
                onChange={handleInputChange}
              ></textarea>
            </div>
            <div className="submit faucet-flex-submit-button-container" style={{ textAlign: 'center' }}>
              <button type="submit" className="btn btn-default submit-data faucet-flex-submit-button" disabled={!isFormValid}>
                Submit
              </button>
              <button className="faucet-flex-revit-button btn btn-default" disabled={!isFormValid}>
                Export Revit
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default FaucetFlexForm;
