import React, { useState, useRef } from 'react';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { Nav, NavLink, Button } from 'reactstrap';
import { adminSubmenus, entitiesSubmenus } from './submenus';
import NavDropdown from 'app/shared/layout/menus/SubMenuDropdown';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { Sidebar, useProSidebar } from 'react-pro-sidebar';
import useDidMountEffect from 'app/customHooks/useDidMountEffect';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light-border.css';
import './navbar.scss';

export interface INavBarProps extends StateProps {
  account: any;
  isAuthenticated: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isSwaggerEnabled: boolean;
  closeBurgerMenu?: Function;
  projectOpened: Boolean;
  setIsSidebarCollapsed: Function;
  notificationsCount: any;
}

const Navbar = (props: INavBarProps) => {
  let location = useLocation();
  const [isEntitiesDropdownOpen, setIsEntitiesDropdownOpen] = useState(false);
  const [isAdminDropdownOpen, setIsAdminDropdownOpen] = useState(false);

  const { collapseSidebar, collapsed } = useProSidebar();

  useDidMountEffect(() => {
    collapseSidebar();
  }, [props.isSideBarCollapsed]);

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">Development</a>
      </div>
    ) : null;

  let iconClass = 'navbar-item-icon ';
  let logoContainerClass = 'navbar_logo-container ';
  if (collapsed) {
    iconClass += 'navbar-item-icon-collapsed';
    logoContainerClass += 'navbar_logo-container--collapsed';
  }

  return (
    <div className="collapsed-sidebar">
      <Sidebar backgroundColor="#fffff" width="320" rootStyles={{ height: '100vh', borderRight: '1px solid #eae9e7' }}>
        {!collapsed && renderDevRibbon()}
        <div className={logoContainerClass}>
          <Button className="close-burger-button" onClick={props.closeBurgerMenu}>
            <img src="../../../../content/images/icons/closeCardIcon.svg" alt="" />
          </Button>
          <img src="../../../../content/images/kreator-navbar-logo.png" alt="Logo" width={collapsed ? 60 : 90} />
        </div>
        <Nav vertical onClick={props.closeBurgerMenu}>
          {hasAnyAuthority(props.account.authorities, [
            AUTHORITIES.ADMIN,
            AUTHORITIES.USER,
            AUTHORITIES.ROLE_CONSULTANT_USER,
            AUTHORITIES.ROLE_MANUFACTURER_USER,
            AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
            AUTHORITIES.ROLE_DEALER_USER,
          ]) && (
            <NavLink
              className={
                (props.projectOpened && location.pathname.includes('configurators')) ||
                location.pathname.includes('create-project') ||
                location.pathname.includes('edit-project')
                  ? 'navbar-item--active navbar-item'
                  : 'navbar-item'
              }
              activeClassName="navbar-item--active"
              tag={Link}
              to="/room-designer"
            >
              <Tippy content="Project Designer" placement="right" theme="light-border">
                <img src="../../../../content/images/icons/pen-tool.svg" className={iconClass} alt="Logo" width="25" height="25" />
              </Tippy>
              <span id="room-designer">Project Designer</span>
            </NavLink>
          )}

          {hasAnyAuthority(props.account.authorities, [
            AUTHORITIES.ADMIN,
            AUTHORITIES.USER,
            AUTHORITIES.ROLE_CONSULTANT_USER,
            AUTHORITIES.ROLE_MANUFACTURER_USER,
            AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
            AUTHORITIES.ROLE_DEALER_USER,
          ]) && (
            <NavLink
              className={
                location.pathname.includes('configurators') && !location.pathname.includes('my') && !props.projectOpened
                  ? 'navbar-item--active navbar-item'
                  : 'navbar-item'
              }
              activeClassName="navbar-item--active"
              exact
              tag={Link}
              to="/"
            >
              <Tippy content="Configurators" placement="right" theme="light-border">
                <img
                  src="../../../../content/images/icons/product_configurators.svg"
                  className={iconClass}
                  alt="Logo"
                  width="25"
                  height="25"
                />
              </Tippy>
              <span id="configurators">Configurators</span>
            </NavLink>
          )}

          {hasAnyAuthority(props.account.authorities, [
            AUTHORITIES.ADMIN,
            AUTHORITIES.USER,
            AUTHORITIES.ROLE_CONSULTANT_USER,
            AUTHORITIES.ROLE_MANUFACTURER_USER,
            AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
          ]) && (
            <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/my-inventory">
              {props.notificationsCount?.userConfiguration > 0 && props.notificationsCount?.project > 0 && (
                <div className="unread-inventories-status">
                  {props.notificationsCount?.userConfiguration + props.notificationsCount?.project > 99
                    ? '99+'
                    : props.notificationsCount?.userConfiguration + props.notificationsCount?.project}
                </div>
              )}
              <Tippy content="My Inventory" placement="right" theme="light-border">
                <img src="../../../../content/images/icons/light.svg" className={iconClass} alt="Logo" width="25" height="25" />
              </Tippy>
              <span style={{ width: 160 }}>My Inventory</span>
            </NavLink>
          )}
          {hasAnyAuthority(props.account.authorities, [
            AUTHORITIES.ADMIN,
            AUTHORITIES.USER,
            AUTHORITIES.ROLE_DEALER_ADMIN,
            AUTHORITIES.ROLE_DEALER_USER,
            AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
            AUTHORITIES.ROLE_MANUFACTURER_USER,
            AUTHORITIES.ROLE_CONSULTANT_ADMIN,
            AUTHORITIES.ROLE_CONSULTANT_USER,
            AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN,
            AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
            AUTHORITIES.ROLE_SUPER_ADMIN,
          ]) && (
            <NavLink
              className={'navbar-item'}
              activeClassName="navbar-item--active"
              tag={Link}
              to="/version/all?page=1&sort=releaseDate,desc"
            >
              <Tippy content="Download Revit Add-in" placement="right" theme="light-border">
                <img src="../../../../content/images/icons/download.svg" className={iconClass} alt="Logo" width="25" height="25" />
              </Tippy>
              <span>Download Revit Add-in</span>
            </NavLink>
          )}

          {/* do not delete!!! needs to be uncomment after feature release */}

          {/* {hasAnyAuthority(props.account.authorities, [
            AUTHORITIES.ADMIN,
            AUTHORITIES.ROLE_DEALER_ADMIN,
            AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
            AUTHORITIES.ROLE_CONSULTANT_ADMIN,
            AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN,
          ]) && (
            <>
              <div className="divider"></div>
              <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/dashboard">
                <Tippy content="Dashboard" placement="right" theme="light-border">
                  <img
                    src="../../../../content/images/icons/Dashboard_active.svg"
                    className={iconClass}
                    alt="Logo"
                    width="25"
                    height="25"
                  />
                </Tippy>
                <span id="dashboard">Dashboard</span>
              </NavLink>
            </>
          )} */}

          {/* {hasAnyAuthority(props.account.authorities, [
            AUTHORITIES.ADMIN,
            AUTHORITIES.USER,
            AUTHORITIES.ROLE_DEALER_ADMIN,
            AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
            AUTHORITIES.ROLE_CONSULTANT_ADMIN,
            AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN,
          ]) && (
            <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/my-leads">
              <Tippy
                theme="light-border"
                content={
                  hasAnyAuthority(props.account.authorities, [AUTHORITIES.USER]) ? (
                    <span id="my-leads">My Leads</span>
                  ) : (
                    <span id="my-leads">My Quotes</span>
                  )
                }
                placement="right"
              >
                <img src="../../../../content/images/icons/my_leads.svg" className={iconClass} alt="Logo" width="25" height="25" />
              </Tippy>
              {hasAnyAuthority(props.account.authorities, [AUTHORITIES.USER]) ? (
                <span id="my-leads">My Leads</span>
              ) : (
                <span id="my-leads">My Quotes</span>
              )}
            </NavLink>
          )} */}

          {hasAnyAuthority(props.account.authorities, [
            AUTHORITIES.ADMIN,
            AUTHORITIES.ROLE_DEALER_ADMIN,
            AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
            AUTHORITIES.ROLE_CONSULTANT_ADMIN,
          ]) && (
            <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/my-configurators">
              <Tippy content="Product Configurators" placement="right" theme="light-border">
                <img
                  src="../../../../content/images/icons/product_configurators.svg"
                  className={iconClass}
                  alt="Logo"
                  width="25"
                  height="25"
                />
              </Tippy>
              <span id="my-leads">Product Configurators</span>
            </NavLink>
          )}

          {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN]) && (
            <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/my-dealer">
              <Tippy content="My Dealer" placement="right" theme="light-border">
                <img src="../../../../content/images/icons/email_settings.svg" className={iconClass} alt="Logo" width="25" height="25" />
              </Tippy>
              <span id="email-settings">My Dealer</span>
            </NavLink>
          )}

          {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN]) && (
            <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/my-manufacturer">
              <Tippy content="My Manufacturer" placement="right" theme="light-border">
                <img src="../../../../content/images/icons/email_settings.svg" className={iconClass} alt="Logo" width="25" height="25" />
              </Tippy>
              <span id="email-settings">My Manufacturer</span>
            </NavLink>
          )}

          {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN]) && (
            <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/my-consultant-admin">
              <Tippy content="My Consultant" placement="right" theme="light-border">
                <img src="../../../../content/images/icons/email_settings.svg" className={iconClass} alt="Logo" width="25" height="25" />
              </Tippy>
              <span id="email-settings">My Consultant</span>
            </NavLink>
          )}

          {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN]) && (
            <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/my-manufacturer-ref">
              <Tippy content="My Manufacturer Rep" placement="right" theme="light-border">
                <img src="../../../../content/images/icons/email_settings.svg" className={iconClass} alt="Logo" width="25" height="25" />
              </Tippy>
              <span id="email-settings">My Manufacturer Rep</span>
            </NavLink>
          )}

          {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN]) && (
            <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/email-settings">
              <Tippy content="Email Settings" placement="right" theme="light-border">
                <img src="../../../../content/images/icons/email_settings.svg" className={iconClass} alt="Logo" width="25" height="25" />
              </Tippy>
              <span id="email-settings">Email Settings</span>
            </NavLink>
          )}

          <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/find-manufacturer-rep">
            <Tippy content="Find Manufacturer Reps" placement="right" theme="light-border">
              <img src="../../../../content/images/icons/find-mfr-reps-icon.svg" className={iconClass} alt="Logo" width="25" height="25" />
            </Tippy>
            <span id="email-settings">Find Manufacturer Reps</span>
          </NavLink>

          {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_MANAGER]) && (
            <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/business-approvals">
              <Tippy content="Business Approvals" placement="right" theme="light-border">
                <img
                  src="../../../../content/images/icons/business-approvals-icon.svg"
                  className={iconClass}
                  alt="Logo"
                  width="25"
                  height="25"
                />
              </Tippy>
              <span style={{ width: 160 }}>Business Approvals</span>
            </NavLink>
          )}

          {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_MANAGER]) && (
            <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/payments">
              <Tippy content="Payments" placement="right" theme="light-border">
                <img src="../../../../content/images/icons/payments-icon.svg" className={iconClass} alt="Logo" width="25" height="25" />
              </Tippy>
              <span>Payments</span>
            </NavLink>
          )}

          {hasAnyAuthority(props.account.authorities, [
            AUTHORITIES.ADMIN,
            AUTHORITIES.ROLE_DEALER_USER,
            AUTHORITIES.ROLE_CONSULTANT_USER,
            AUTHORITIES.ROLE_MANUFACTURER_USER,
            AUTHORITIES.ROLE_MANUFACTURER_REP_USER,
            AUTHORITIES.ROLE_PROJECT_DESIGNER,
            AUTHORITIES.USER,
            AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
          ]) && (
            <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/project-submission">
              <Tippy content="Project Submission" placement="right" theme="light-border">
                <img
                  src="../../../../content/images/icons/project-submission-icon.svg"
                  className={iconClass}
                  alt="Logo"
                  width="25"
                  height="25"
                />
              </Tippy>
              <span style={{ width: 160 }}>Project Submission</span>
            </NavLink>
          )}

          {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_DEALER_ADMIN]) && (
            <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/instant-pricing">
              <Tippy content="Instant Pricing" placement="right" theme="light-border">
                <img
                  src="../../../../content/images/icons/instant-pricing-icon.svg"
                  className={iconClass}
                  alt="Logo"
                  width="25"
                  height="25"
                />
              </Tippy>
              <span>Instant Pricing</span>
            </NavLink>
          )}

          {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN]) && (
            <>
              <div className="divider"></div>
              <NavDropdown
                icon={'network'}
                isDropdownOpen={isEntitiesDropdownOpen}
                setIsDropdownOpen={setIsEntitiesDropdownOpen}
                title={'Entities'}
                iconClass={iconClass}
                tooltipTitle="Entities"
              >
                {entitiesSubmenus.map(item => {
                  return (
                    <NavLink className={'navbar-subitem'} activeClassName="navbar-item--active" tag={Link} to={item.route} key={item.title}>
                      <span className="navigation-submenu-item">{item.title}</span>
                    </NavLink>
                  );
                })}
              </NavDropdown>
            </>
          )}

          {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN]) && (
            <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/manufacturer-api">
              <Tippy content="API" placement="right" theme="light-border">
                <img src="../../../../content/images/icons/email_settings.svg" className={iconClass} alt="Logo" width="25" height="25" />
              </Tippy>
              <span id="email-settings">API</span>
            </NavLink>
          )}

          {
            <NavLink className={'navbar-item'} activeClassName="navbar-item--active" tag={Link} to="/feature-request">
              <Tippy content="Feature Request" placement="right" theme="light-border">
                <img src="../../../../content/images/icons/email_settings.svg" className={iconClass} alt="Logo" width="25" height="25" />
              </Tippy>
              <span id="email-settings">Feature Request</span>
            </NavLink>
          }
          {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SUPER_ADMIN]) && (
            <NavDropdown
              icon={'admin'}
              isDropdownOpen={isAdminDropdownOpen}
              setIsDropdownOpen={setIsAdminDropdownOpen}
              title={'Administration'}
              iconClass={iconClass}
              tooltipTitle="Administration"
            >
              {adminSubmenus.map(item => {
                return (
                  <NavLink className={'navbar-subitem'} activeClassName="navbar-item--active" tag={Link} to={item.route} key={item.title}>
                    <span className="navigation-submenu-item">{item.title}</span>
                  </NavLink>
                );
              })}
              {props.isSwaggerEnabled && (
                <NavLink className={'navbar-subitem'} activeClassName="navbar-item--active" tag={Link} to="/admin/docs">
                  API
                </NavLink>
              )}
            </NavDropdown>
          )}
        </Nav>
      </Sidebar>
      <Button
        className="sidebar-position-button"
        onClick={() => {
          props.setIsSidebarCollapsed(!props.isSideBarCollapsed);
        }}
      >
        {collapsed ? (
          <img src="../../../../content/images/icons/sidebar-arrow-right-orange.svg" width="16" />
        ) : (
          <img src="../../../../content/images/icons/sidebar-arrow-left-orange.svg" width="16" />
        )}
      </Button>
    </div>
  );
};

const mapStateToProps = ({ notification, userSettings }: IRootState) => ({
  notificationsList: notification.notifications,
  isSideBarCollapsed: userSettings.isSidebarCollapsed,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Navbar);
