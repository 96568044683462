import React from 'react';
import { CardImg } from 'reactstrap';

interface IProductCard {
  image: string;
  name: string;
  manufacturer: string;
  bordered?: boolean;
}

const ProductCard = (props: IProductCard) => {
  return (
    <React.Fragment>
      <CardImg top src={props.image} alt="Missing image" className="image product-card-image" />
      <span className="product-card-manufacturer">{props.manufacturer}</span>
      <span className="product-card-name">{props.name}</span>
    </React.Fragment>
  );
};

export default ProductCard;
