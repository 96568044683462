import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IUserSettings, defaultValue } from 'app/shared/model/user-settings.model';

export const ACTION_TYPES = {
  FETCH_USERSETTINGS_LIST: 'userSettings/FETCH_USERSETTINGS_LIST',
  FETCH_USERSETTINGS: 'userSettings/FETCH_USERSETTINGS',
  CREATE_USERSETTINGS: 'userSettings/CREATE_USERSETTINGS',
  UPDATE_USERSETTINGS: 'userSettings/UPDATE_USERSETTINGS',
  DELETE_USERSETTINGS: 'userSettings/DELETE_USERSETTINGS',
  GET_CURRENT_USER_SETTINGS: 'GET_CURRENT_USER_SETTINGS',
  UPDATE_CURRENT_USER_SETTINGS: 'UPDATE_CURRENT_USER_SETTINGS',
  SET_NOTIFICATION_TOKEN: 'SET_NOTIFICATION_TOKEN',
  SET_USER_NOTIFICATION_TOKEN: 'SET_USER_NOTIFICATION_TOKEN',
  DELETE_NOTIFICATION_TOKEN: 'DELETE_NOTIFICATION_TOKEN',
  SET_IS_HELP_VIDEOS_MODAL_OPEN: 'SET_IS_HELP_VIDEOS_MODAL_OPEN',
  SET_IS_SIDEBAR_COLLAPSED: 'SET_IS_SIDEBAR_COLLAPSED',
  SET_IS_FULL_SCREEN_MODE: 'SET_IS_FULL_SCREEN_MODE',
  RESET: 'userSettings/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IUserSettings>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  currentUserSettings: null,
  userNotificationToken: null,
  isHelpVideosModalOpen: false,
  isSidebarCollapsed: false,
  isFullScreenMode: false,
};

export type UserSettingsState = Readonly<typeof initialState>;

// Reducer

export default (state: UserSettingsState = initialState, action): UserSettingsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_USERSETTINGS_LIST):
    case REQUEST(ACTION_TYPES.FETCH_USERSETTINGS):
    case REQUEST(ACTION_TYPES.GET_CURRENT_USER_SETTINGS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_USERSETTINGS):
    case REQUEST(ACTION_TYPES.UPDATE_USERSETTINGS):
    case REQUEST(ACTION_TYPES.DELETE_USERSETTINGS):
    case REQUEST(ACTION_TYPES.UPDATE_CURRENT_USER_SETTINGS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_USERSETTINGS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_USERSETTINGS):
    case FAILURE(ACTION_TYPES.CREATE_USERSETTINGS):
    case FAILURE(ACTION_TYPES.UPDATE_USERSETTINGS):
    case FAILURE(ACTION_TYPES.DELETE_USERSETTINGS):
    case FAILURE(ACTION_TYPES.GET_CURRENT_USER_SETTINGS):
    case FAILURE(ACTION_TYPES.UPDATE_CURRENT_USER_SETTINGS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_USERSETTINGS_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_USERSETTINGS):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_USERSETTINGS):
    case SUCCESS(ACTION_TYPES.UPDATE_USERSETTINGS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_USERSETTINGS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case SUCCESS(ACTION_TYPES.GET_CURRENT_USER_SETTINGS):
      return {
        ...state,
        loading: false,
        currentUserSettings: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_CURRENT_USER_SETTINGS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        currentUserSettings: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_NOTIFICATION_TOKEN):
      return {
        ...state,
        userNotificationToken: null,
      };
    case ACTION_TYPES.SET_IS_HELP_VIDEOS_MODAL_OPEN:
      return {
        ...state,
        isHelpVideosModalOpen: action.payload.value,
      };
    case ACTION_TYPES.SET_IS_SIDEBAR_COLLAPSED:
      return {
        ...state,
        isSidebarCollapsed: action.payload.value,
      };
    case ACTION_TYPES.SET_IS_FULL_SCREEN_MODE:
      return {
        ...state,
        isFullScreenMode: action.payload.value,
      };
    case ACTION_TYPES.SET_USER_NOTIFICATION_TOKEN:
      return {
        ...state,
        userNotificationToken: action.payload.token,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/user-settings';

// Actions

export const getEntities: ICrudGetAllAction<IUserSettings> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_USERSETTINGS_LIST,
    payload: axios.get<IUserSettings>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IUserSettings> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_USERSETTINGS,
    payload: axios.get<IUserSettings>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IUserSettings> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_USERSETTINGS,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IUserSettings> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_USERSETTINGS,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IUserSettings> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_USERSETTINGS,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const getCurrentUserSettings = () => {
  const requestUrl = 'api/user-settings/current-user-settings';
  return {
    type: ACTION_TYPES.GET_CURRENT_USER_SETTINGS,
    payload: axios.get(requestUrl),
  };
};

export const updateUserSettings = userSettings => async dispatch => {
  const requestUrl = 'api/user-settings';
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CURRENT_USER_SETTINGS,
    payload: axios.put(requestUrl, cleanEntity(userSettings)),
  });
  dispatch(getCurrentUserSettings());
  return result;
};

export const setNotificationsToken = token => async dispatch => {
  const requestUrl = `api/firebase/add-notification-token?token=${token}`;
  await dispatch({
    type: ACTION_TYPES.SET_NOTIFICATION_TOKEN,
    payload: axios.put(requestUrl),
  });
};

export const deleteNotificationsToken = token => async dispatch => {
  const requestUrl = `api/firebase/delete-token?token=${token}`;
  await dispatch({
    type: ACTION_TYPES.DELETE_NOTIFICATION_TOKEN,
    payload: axios.delete(requestUrl),
  });
};

export const setUserNotificationToken = token => ({
  type: ACTION_TYPES.SET_USER_NOTIFICATION_TOKEN,
  payload: {
    token,
  },
});

export const setIsHelpVideoPopupOpen = value => ({
  type: ACTION_TYPES.SET_IS_HELP_VIDEOS_MODAL_OPEN,
  payload: {
    value,
  },
});

export const setIsSidebarCollapsed = value => ({
  type: ACTION_TYPES.SET_IS_SIDEBAR_COLLAPSED,
  payload: {
    value,
  },
});

export const setIsFullScreenMode = value => ({
  type: ACTION_TYPES.SET_IS_FULL_SCREEN_MODE,
  payload: {
    value,
  },
});
