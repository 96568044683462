export const entitiesSubmenus: { title: string; route: string }[] = [
  { title: 'Configurator Catalog', route: '/configurators' },
  { title: 'Product', route: '/product' },
  { title: 'Manufacturer', route: '/manufacturer' },
  { title: 'User Configuration', route: '/user-configuration' },
  { title: 'Folder', route: '/folder' },
  { title: 'Version', route: '/version' },
  { title: 'Dealer', route: '/dealer' },
  { title: 'Leads', route: '/leads' },
];

export const adminSubmenus: { title: string; route: string }[] = [
  { title: 'User management', route: '/admin/user-management' },
  { title: 'User tracker', route: '/admin/tracker' },
  { title: 'Metrics', route: '/admin/metrics' },
  { title: 'Health', route: '/admin/health' },
  { title: 'Configuration', route: '/admin/configuration' },
  { title: 'Audits', route: '/admin/audits' },
  { title: 'Logs', route: '/admin/logs' },
];
