import React from 'react';
import UnitsOfMeasureSwitch from '../RoomleUnitsOfMeasureSwitch/UnitsOfMeasureSwitch';
import { Button } from 'reactstrap';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import Tippy from '@tippyjs/react';
import './roomleConfigurationBar.scss';

interface IRoomleConfigurationBar {
  setUnitsOfMeasure: Function;
  setIsChangeConfigurationModalOpen: Function;
  setIsForbiddenExportModalOpen: Function;
  // setIsExportRevitModalOpen: Function;
  handleAddToProjectButton: Function;
  handleSaveToInventoryButton: Function;
  setIsCurrentProjectShouldBeClosed: Function;
  productEntity: any;
  loading: boolean;
  loadingProduct: boolean;
  updating: boolean;
  projectUpdating: boolean;
  account: any;
  handleShareButton: Function;
  loadingValuesArray: any;
  triggerShareButton: Function;
  // setIsShareButtonWasPressed: Function;
  roomleLoaded: boolean;
  setIsHelpVideoPopupOpen: Function;
  prevPath: string;
}

const RoomleConfigurationBar = (props: IRoomleConfigurationBar) => {
  // const handleExportRevitModalOpening = () => {
  //   props.setIsShareButtonWasPressed(false);
  //   props.setIsExportRevitModalOpen(true);
  // };

  return (
    <div className="roomle-actions-bar">
      <div className="d-flex">
        <UnitsOfMeasureSwitch setUnitsOfMeasure={props.setUnitsOfMeasure} />
        <Tippy content="Help" placement="bottom" theme="light-border">
          <button className="project-details-button" style={{ marginLeft: 10 }} onClick={() => props.setIsHelpVideoPopupOpen(true)}>
            <img src="../../../content/images/icons/help-video-icon.svg" width="30" />
          </button>
        </Tippy>
      </div>

      <Tippy content="Switch Configurator" placement="bottom" theme="light-border">
        <button
          className="change-user-configuration-button"
          onClick={() => {
            props.setIsChangeConfigurationModalOpen(true);
            props.setIsCurrentProjectShouldBeClosed(false);
          }}
        >
          <div className="user-configuration-action-button-content">
            <span style={{ marginRight: 10 }}>{props.productEntity.manufacturerName + ' ' + props.productEntity.name}</span>
            <img src="../../../content/images/icons/change-configuration-button.svg" alt="pencil" width="20" />
          </div>
        </button>
      </Tippy>

      <div className="user-configuration-action-buttons-conteiner">
        <Tippy content="Share Configuration" placement="bottom" theme="light-border">
          <button
            className="roomle-save-to-inventory-button mr-2"
            onClick={() => props.triggerShareButton()}
            disabled={props.loadingValuesArray.some(item => item) || !props.roomleLoaded}
          >
            <img src="../../../content/images/icons/share-icon.svg" alt="share icon" width="20" className="mr-2" />
            <span>Share</span>
          </button>
        </Tippy>

        {/* <Button
          className="roomle-export-revit-button mr-2"
          onClick={() =>
            props.productEntity.revitExportEnable ? handleExportRevitModalOpening() : props.setIsForbiddenExportModalOpen(true)
          }
          disabled={props.loadingValuesArray.some(item => item) || !props.roomleLoaded}
        >
          <div className="user-configuration-action-button-content">
            <div className="roomle-export-revit-button-icon project-button-icons">
              <img src="../../../content/images/icons/exportRevitBlue.svg" alt="export image" width="27" />
            </div>
            <span>Share Revit</span>
          </div>
        </Button> */}

        {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ROLE_PROJECT_DESIGNER]) &&
          !props.prevPath &&
          !props.prevPath?.includes('room-designer') && (
            <Button
              onClick={() => props.handleAddToProjectButton()}
              className="roomle-save-to-inventory-button mr-2"
              disabled={props.loadingValuesArray.some(item => item) || !props.roomleLoaded}
            >
              <div className="user-configuration-action-button-content">
                <img
                  src="../../../content/images/icons/add-to-project-icon.svg"
                  className="project-button-icons"
                  alt="add icon"
                  width="20"
                />
                <span>Add to Project</span>
              </div>
            </Button>
          )}

        <Button
          onClick={() => props.handleSaveToInventoryButton()}
          className="roomle-save-to-inventory-button"
          disabled={props.loadingValuesArray.some(item => item) || !props.roomleLoaded}
        >
          <div className="user-configuration-action-button-content">
            <img
              src="../../../content/images/icons/save-to-inventory-icon.svg"
              className="project-button-icons"
              alt="add icon"
              width="20"
            />
            <span>Save to Inventory</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default RoomleConfigurationBar;
