import React from 'react';
import { Modal } from 'reactstrap';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import './alertModal.scss';

interface IAlertModal {
  isOpen: boolean;
  closeConfirmationDialog: Function;
  confirmationInfo: String;
  aditionalConfirmationInfo?: any;
}

const AlertModal = (props: IAlertModal) => {
  return (
    <Modal
      isOpen={props.isOpen}
      centered
      style={{ maxWidth: 800, width: window.screen.width - 900 }}
      toggle={() => props.closeConfirmationDialog()}
      backdrop={true}
      className="alert-modal horizontal-shaking"
    >
      <span className="alert-dialog-question">{props.confirmationInfo}</span>
      {props.aditionalConfirmationInfo && (
        <div className="d-flex justify-content-center" style={{ marginBottom: 20 }}>
          {props.aditionalConfirmationInfo}
        </div>
      )}
      <div className="alert-dialog-actions-block">
        <ConfirmButton title="Ok" confirmAction={props.closeConfirmationDialog} />
      </div>
    </Modal>
  );
};

export default AlertModal;
