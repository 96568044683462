import React from 'react';

const TermsAndConditions = () => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 50, paddingBottom: 50, background: '#f8f8f8' }}
    >
      <img src="../../../content/images/Kitchautomation-color-01-300x214.jpg" alt="" />
      <div style={{ width: '80%' }}>
        <h6>Terms and Conditions</h6>
        <h6>Last updated: July 18, 2021</h6>
        <h6>END USER LICENSE AGREEMENT FOR KITCHAUTOMATION SOFTWARE</h6>
        <p>
          IMPORTANT-READ CAREFULLY: This End User License Agreement (“Agreement”) is a binding agreement between the end user (“you”, “your”
          or “End User”) of the software application and website (as defined herein) and Kitchautomation, LLC (“Kitchautomation”). This
          Agreement governs your use of the Kitchautomation Software, including the Hosted Software (both as defined herein) and all related
          documentation. The Software is licensed, not sold, to you.
        </p>
        <p>
          BY DOWNLOADING/INSTALLING OR USING THE APPLICATION, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; (B)
          REPRESENT THAT YOU ARE 18 YEARS OF AGE OR OLDER; AND (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS.
          IF YOU DO NOT AGREE TO THESE TERMS, DO NOT DOWNLOAD, INSTALL OR USE THE APPLICATION AND DELETE IT FROM YOUR DEVICE.
        </p>
        <h5>1. Definitions</h5>
        <p>
          “Deidentified” means data that has been processed to remove, hide, encrypt, anonymize or aggregate identifying information such
          that one could not, using reasonable efforts, link such information back to you.
        </p>
        <p>
          “End User Data” means foodservice equipment data, color and material selection and design layout information and related
          information necessary to use the Software or certain of its features or functionality and other non-personally identifiable data
          entered or uploaded by you to the Hosted Software via the Software.
        </p>
        <p>
          “Hosted Software” means the online software, platform and websites which you can access via the Software and your account that are
          hosted and maintained by Kitchautomation.
        </p>
        <p>“Services” means a web-based software platform with interactive tools for foodservice design.</p>
        <p>
          “Kitchautomation Data” means all information, including, but not limited to, data, analyses, measurements, reports, trends, and
          metrics, generated from, derived from, based on or related to End User Data.
        </p>
        <p>“Website” means the website accessible at Kitchautomation.com and the website accessible at kreator.app.</p>
        <h5>2. License Grant</h5>
        <p>
          Kitchautomation, through the Software, provides the Services to foodservice providers and their agents. Subject to your compliance
          with the terms of this Agreement, Kitchautomation grants you a personal, limited, terminable, non-exclusive, non-transferable
          license to:
        </p>
        <p>(a) download, install, and use the Software for your use strictly in accordance with the Software’s documentation; and</p>
        <p>
          (b) access and use the Services made available in or otherwise accessible through the Software, strictly in accordance with this
          Agreement.
        </p>
        <h5>3. License Restrictions</h5>
        <p>(a) You shall not: ◦(i) copy the Software;</p>
        <p>(ii) modify, translate, adapt or otherwise create derivative works or improvements, of the Software;</p>
        <p>
          iii) reverse engineer, disassemble, decompile, decode or otherwise attempt to derive or gain access to the source code of the
          Software, the Hosted Software or any part thereof;
        </p>
        <p>
          (iv) remove, delete, alter or obscure any trademarks or any copyright, trademark, patent or other intellectual property or
          proprietary rights notices from the Software;
        </p>
        <p>
          (v) rent, lease, lend, sell, sublicense, assign, distribute, publish, publicly perform or display, transfer or otherwise make
          available the Software or any features or functionality of the Software, to any third party for any reason, including by making
          the Software available on a network where it is capable of being accessed by more than one device at any time;
        </p>
        <p>
          (vi) remove, disable, circumvent or otherwise create or implement any workaround to any copy protection, rights management or
          security features in or protecting the Software; or,
        </p>
        <p>(vii) upload or introduce any virus or malware to the Hosted Software.</p>
        <p>
          (b) You are responsible for keeping your account login credentials (user name and password) confidential and not sharing them with
          unauthorized users. If you disclose your login credentials to someone, you are responsible for any use, disclosure, additions,
          deletions and modifications of your End User Data.
        </p>
        <h5>4. Reservation of Rights</h5>
        <p>
          You acknowledge and agree that the Software is provided under license, and not sold, to you. You do not acquire any ownership
          interest in the Software under this Agreement or any other rights thereto other than to use the Software in accordance with the
          license granted, and subject to all terms, conditions and restrictions, under this Agreement. Kitchautomation and its licensors
          and service providers reserve and shall retain each of their entire right, title and interest in and to the Software and Website.
          Kitchautomation is a registered mark of Dollypup Productions LLC. The Software, Hosted Software and certain features or
          functionality are covered by one or more pending patent applications. All rights not specifically granted in this Agreement are
          reserved by Kitchautomation. Kitchautomation shall own all rights in any functionality, features, modification or customization of
          the Software.
        </p>
        <h5>5. Ownership and Use of Data</h5>
        <p>
          All ownership rights in and to the Software shall remain exclusively with Kitchautomation and its licensors, as applicable. Access
          to the Services is provided to you only to allow you to exercise your rights under this Agreement, and no implied license or right
          not expressly set forth in this Agreement is granted to you.
        </p>
        <p>
          (a) End User Data. In connection with the Software, Kitchautomation may collect and maintain End User Data provided by you. You
          shall own all End User Data. Kitchautomation will not collect or maintain any personal identifiable data. All third parties
          authorized by Kitchautomation which may have access to the End User Data shall be under obligations of confidentiality to maintain
          the End User Data as confidential. You agree to and do hereby grant to Kitchautomation the irrevocable, perpetual, transferrable
          right to use, disclose, commercialize, assign, convey, sell, lease, license or transfer any Deidentified End User Data.
        </p>
        <p>
          (b) Kitchautomation Data. All Kitchautomation Data is the sole property of Kitchautomation. Kitchautomation has the right to use,
          collect, store, create, aggregate, mine, analyze, modify, commercialize, assign, convey, sell, lease, license or transfer
          Kitchautomation Data in any form, for any purpose and in any manner. Any Kitchautomation Data provided to you shall be subject to
          limitations and restrictions imposed by any commercial agreements to which Kitchautomation may be a party.
        </p>
        <h5>6. Geographic Restrictions</h5>
        <p>
          The Software and access to Kitchautomation’s Hosted Software are provided for access and use only by persons located in the United
          States. You acknowledge that you may not be able to access all or some of the Hosted Software outside of the United States and
          that access thereto may not be legal by certain persons or in certain countries. If you access the Hosted Software from outside
          the United States, you are responsible for compliance with local laws.
        </p>
        <h5>7. Updates</h5>
        <p>
          Kitchautomation may from time to time in its sole discretion develop and provide Software updates, which may include upgrades, bug
          fixes, patches and other error corrections and/or new features (collectively, including related documentation, “Updates”). Updates
          may also modify or delete in their entirety certain features and functionality. You agree that Kitchautomation has no obligation
          to provide any Updates or to continue to provide or enable any particular features or functionality. Based on your device
          settings, when your device is connected to the Internet either:
        </p>
        <p>(a) the Software will automatically download and install all available Updates; or</p>
        <p>(b) you may receive notice of or be prompted to download and install available Updates.</p>
        <p>
          In order to be able to fully utilize the Software, you must promptly download and install all Updates. You acknowledge that the
          Software or portions thereof may not properly operate should you fail to do so. You further agree that all Updates will be deemed
          part of the Software and be subject to all terms and conditions of this Agreement.
        </p>
        <h5>8. Third Party Materials</h5>
        <p>
          The Software may display, include or make available third-party content (including data, information, applications and other
          products services and/or materials) or provide links to third-party websites or services, including through third-party
          advertising (“Third Party Materials”). You acknowledge and agree that Kitchautomation is not responsible for Third Party
          Materials, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any
          other aspect thereof. Kitchautomation does not assume and will not have any liability or responsibility to you or any other person
          or entity for any Third Party Materials. Third Party Materials and links thereto are provided solely as a convenience to you and
          you access and use them at entirely at your own risk and subject to such third parties’ terms and conditions.
        </p>
        <h5>9. Term and Termination</h5>
        <p>
          (a) The term of Agreement commences when you download/install the Software or acknowledge your acceptance, and will continue in
          effect until terminated by you or Kitchautomation as set forth in herein.
        </p>
        <p>(b) You may terminate this Agreement by deleting the Software and all copies thereof from your device.</p>
        <p>
          (c) Kitchautomation may terminate this Agreement at any time without notice if it ceases to support the Software, which
          Kitchautomation may do in its sole discretion. In addition, Kitchautomation may immediately terminate this Agreement with or
          without any notice if you violate any of the terms and conditions of this Agreement.
        </p>
        <p>(d) Upon termination: ◦(i) all rights granted to you under this Agreement will also terminate;</p>
        <p>(ii) you must cease all use of the Software and delete all copies of the Software from your device; and</p>
        <p>(iii) your access to Kitchautomation’s Hosted Software, your account and your End User Data will be terminated.</p>
        <p>(e) Termination will not limit any of Kitchautomation’s rights or remedies at law or in equity.</p>
        <h5>10. Disclaimer of Warranties</h5>
        <p>
          (a) THE SOFTWARE IS PROVIDED TO YOU “AS IS, AS AVAILABLE” AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE
          MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, KITCHAUTOMATION, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND
          THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR
          OTHERWISE, WITH RESPECT TO THE APPLICATION, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          TITLE AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE
          PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, KITCHAUTOMATION PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF
          ANY KIND THAT THE SOFTWARE WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE OR WORK WITH ANY OTHER
          SOFTWARE, APPLICATIONS, SYSTEMS OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS OR BE
          ERROR FREE OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.
        </p>
        <p>
          (b) WITHOUT LIMITING THE FOREGOING, NEITHER KITCHAUTOMATION NOR ANY KITCHAUTOMATION PROVIDER MAKES ANY REPRESENTATION OR WARRANTY
          OF ANY KIND, EXPRESS OR IMPLIED: (I) AS TO THE OPERATION OR AVAILABILITY OF THE KITCHAUTOMATION SOFTWARE, OR THE INFORMATION,
          CONTENT, AND MATERIALS OR PRODUCTS INCLUDED THEREON; (II) THAT THE KITCHAUTOMATION SOFTWARE WILL BE UNINTERRUPTED OR ERROR-FREE;
          (III) AS TO THE ACCURACY, RELIABILITY, OR CURRENCY OF ANY INFORMATION OR CONTENT PROVIDED BY KITCHAUTOMATION; OR (IV) THAT THE
          KITCHAUTOMATION SOFTWARE, HOSTED SERVERS, THE CONTENT, OR E-MAILS SENT FROM OR ON BEHALF OF KITCHAUTOMATION ARE FREE OF VIRUSES,
          SCRIPTS, TROJAN HORSES, WORMS, MALWARE, TIMEBOMBS OR OTHER HARMFUL COMPONENTS.
        </p>
        <p>
          p(c) SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE
          STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.
        </p>
        <p>(e) Kitchautomation is not liable for the acts or omissions of its contractors, vendors and service providers.</p>
        <h5>11. Limitation of Liability</h5>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL KITCHAUTOMATION OR ITS AFFILIATES, OR ANY OF ITS OR THEIR
          RESPECTIVE LICENSORS OR SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE
          APPLICATION OR THE CONTENT AND SERVICES FOR:
        </p>
        <p>
          (a) PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OR CORRUPTION DATA, LOSS OF
          GOODWILL, BUSINESS INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION OR ANY OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY,
          SPECIAL OR PUNITIVE DAMAGES;
        </p>
        <p>
          (b) DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE GREATER OF (i) THE AMOUNT ACTUALLY PAID BY YOU FOR THE APPLICATION
          AND (ii) ONE HUNDRED DOLLARS (U.S.). THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT,
          TORT (INCLUDING NEGLIGENCE) OR OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR KITCHAUTOMATION WAS ADVISED OF
          THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE
          LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.
        </p>
        <p>
          KITCHAUTOMATION DOES NOT GUARANTEE THE CONTINUOUS, UNINTERRUPTED OR SECURE ACCESS TO THE KITCHAUTOMATION SOFTWARE, KITCHAUTOMATION
          SERVICES, OR ANY RELATED SERVICES. THE OPERATION OF THE KITCHAUTOMATIO SOFTWARE MAY BE INTERFERED WITH BY NUMEROUS FACTORS OUTSIDE
          THE CONTROL OF KITCHAUTOMATION.
        </p>
        <p>
          UNDER NO CIRCUMSTANCES SHALL KITCHAUTOMATION BE LIABLE FOR ANY DAMAGES THAT RESULT FROM THE USE OF OR INABILITY TO USE THE
          KITCHAUTOMATION SOFTWARE, INCLUDING BUT NOT LIMITED TO RELIANCE BY YOU ON ANY INFORMATION OBTAINED FROM KITCHAUTOMATION OR THAT
          RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES OR E-MAIL, ERRORS, DEFECTS, VIRUSES, DELAYS IN OPERATION OR
          TRANSMISSION, OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF GOD, COMMUNICATIONS FAILURE, THEFT,
          DESTRUCTION, OR UNAUTHORIZED ACCESS TO KITCHAUTOMATION RECORDS, PROGRAMS, OR SERVICES. USER HEREBY ACKNOWLEDGES THAT THIS
          PARAGRAPH SHALL APPLY TO ALL CONTENT, DATA, AND INFORMATION SUBMITTED THROUGH THE SOFTWARE AND YOU TO THE KITCHAUTOMATION SERVICES
          AVAILABLE THROUGH THE SOFTWARE.
        </p>
        <h5>12. Indemnification</h5>
        <p>
          You agree to indemnify, defend and hold harmless Kitchautomation and its officers, directors, members, managers, employees,
          agents, affiliates, service providers, successors and assigns from and against any and all losses, damages, liabilities,
          deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind,
          including reasonable attorneys’ fees, arising from or relating to your use or misuse of the Software or your breach of this
          Agreement.
        </p>
        <h5>13. Governing Law</h5>
        <p>
          This Agreement is governed by and construed in accordance with the internal laws of the State of Maryland without giving effect to
          any choice or conflict of law provision or rule. Any legal suit, action or proceeding arising out of or related to this Agreement
          or the Software shall be instituted exclusively in the federal courts of the United States or the courts of the State of Maryland
          in each case located in Prince George’s County. You waive any and all objections to the exercise of jurisdiction over you by such
          courts and to venue in such courts.
        </p>
        <h5>14. Limitation of Time to File Claims</h5>
        <p>
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE APPLICATION MUST BE COMMENCED WITHIN
          ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
        </p>
        <h5>15. Entire Agreement</h5>
        <p>
          This Agreement constitutes the entire agreement between you and Kitchautomation with respect to the Software and the use of the
          Hosted Software and supersede all prior or contemporaneous understandings and agreements, whether written or oral, with respect
          thereto. No failure to exercise, and no delay in exercising, on the part of either party, any right or any power hereunder shall
          operate as a waiver thereof, nor shall any single or partial exercise of any right or power hereunder preclude further exercise of
          that or any other right hereunder. In the event of a conflict between this Agreement and any applicable purchase or other terms,
          the terms of this Agreement shall govern. If any provision of this Agreement is illegal or unenforceable under applicable law, the
          remainder of the provision will be amended to achieve as closely as possible the effect of the original term and all other
          provisions of this Agreement will continue in full force and effect.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
