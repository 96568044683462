import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDpJ1Lw1uDqevEeloG9WN64252HTbAu7ks',
  authDomain: 'kreator-ffd0b.firebaseapp.com',
  projectId: 'kreator-ffd0b',
  storageBucket: 'kreator-ffd0b.appspot.com',
  messagingSenderId: '615265474332',
  appId: '1:615265474332:web:321b168e51047217a30cd9',
  measurementId: 'G-ST8F1EM53D',
};

const vapidKey = 'BLvX0sRvW2DokTHn4-15P-SkmBfvXTAUhUYmxOGZ9W2hotwv0TwKtapSsEyYPA6zMZtyDqa0nNDpw-anGJ2qfE0';

const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const getFirebaseToken = setTokenFound => {
  return getToken(messaging, { vapidKey })
    .then(currentToken => {
      if (currentToken) {
        setTokenFound(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        // shows on the UI that permission is required
      }
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};
