import React from 'react';
import { Collapse, Button, Table } from 'reactstrap';
import AppSpinner from 'app/components/Spinner/Spinner';
import NoDataBanner from 'app/components/NoDataBanner/NoDataBanner';

interface IProductConfigurationItem {
  isOpen: boolean;
  product: any;
  toggleOpen: Function;
  partsListLoading: boolean;
  projectProductsPartsList: any;
}

const ProductConfigurationItem = (props: IProductConfigurationItem) => {
  return (
    <>
      <div className="project-submission-log-details-partlist-table-collapse">
        <Table responsive borderless hover className="kreator-page-table-template">
          <thead>
            <tr className="kreator-page-table-template--header">
              <th>Product Name</th>
              <th>Manufacturer Name</th>
              <th className="project-submission-log-text-right">Parts List</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{props.product.userConfiguration.product.name}</td>
              <td>{props.product.userConfiguration.product.manufacturer.name}</td>
              <td className="project-submission-log-text-right">
                <Button className="project-submission-log-details-open-part-list-button" onClick={() => props.toggleOpen(props.isOpen)}>
                  Products
                  {props.isOpen ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none" style={{ marginLeft: 8 }}>
                      <path
                        d="M4.773 2.20425L8.4855 5.91675L9.546 4.85625L4.773 0.0832481L0 4.85625L1.0605 5.91675L4.773 2.20425Z"
                        fill="currentColor"
                      />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none" style={{ marginLeft: 8 }}>
                      <path
                        d="M4.773 3.79575L8.4855 0.083252L9.546 1.14375L4.773 5.91675L0 1.14375L1.0605 0.083252L4.773 3.79575Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      <Collapse isOpen={props.isOpen}>
        <div className="project-settings-collapse-products-configurator-container">
          {props.partsListLoading ? (
            <AppSpinner />
          ) : props.projectProductsPartsList.length ? (
            <div className="project-submission-log-details-partlist-table-container">
              <Table responsive borderless hover className="kreator-page-table-template project-submission-log-details-partlist-table">
                <thead>
                  <tr className="kreator-page-table-template--header project-submission-log-details-partlist-table">
                    <th>Manufacturer Name</th>
                    <th>Quantity</th>
                    <th>Model Number</th>
                    <th className="project-submission-log-text-right">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {props.projectProductsPartsList[0].partsList.map(item => (
                    <tr key={item.label}>
                      <td>{props.product.userConfiguration.product.manufacturer.name}</td>
                      <td>{item.count}</td>
                      <td>{item.articleNr}</td>
                      <td className="project-submission-log-text-right">{item.label}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <NoDataBanner title="There are no Configurations in the Project!" />
          )}
        </div>
      </Collapse>
    </>
  );
};

export default ProductConfigurationItem;
