import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, Router } from 'react-router-dom';
import { Row } from 'reactstrap';
import { JhiPagination, JhiItemCount } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { getEntities } from './version.reducer';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import VersionsTable from 'app/components/VersionsTable/VersionsTable';
import CreateButton from 'app/components/CreateButton/CreateButton';
import MainThemeButton from 'app/components/MainThemeButton/MainThemeButton';

export interface IVersionProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const Version = (props: IVersionProps) => {
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(
      {
        itemsPerPage: ITEMS_PER_PAGE,
        sort: 'releaseDate',
        order: 'desc',
        activePage: 1,
      },
      props.location.search
    )
  );

  const getAllEntities = () => {
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const goToVersionCreating = () => {
    props.history.push(`${match.url}/new`);
  };

  const goToVersion = versionId => {
    props.history.push(`${match.url}/${versionId}`);
  };

  const editVersion = versionId => {
    props.history.push(
      `${match.url}/${versionId}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`
    );
  };

  const deleteVersion = versionId => {
    props.history.push(
      `${match.url}/${versionId}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`
    );
  };

  const { versionList, match, loading, totalItems } = props;
  return (
    <div>
      <div id="version-heading" className="d-flex justify-content-end" style={{ marginBottom: 25 }}>
        <MainThemeButton title="Create new Version" onClick={goToVersionCreating} iconName="plus" />
      </div>
      <VersionsTable versions={versionList} viewVersion={goToVersion} editVersion={editVersion} deleteVersion={deleteVersion} />
      {props.totalItems ? (
        <div className={versionList && versionList.length > paginationState.itemsPerPage ? 'pagination-container' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ version }: IRootState) => ({
  versionList: version.entities,
  loading: version.loading,
  totalItems: version.totalItems,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Version);
