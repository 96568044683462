import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const useViewConfiguration = props => {
  let history = useHistory();
  const [isViewConfigurationButtonWasPressed, setIsViewConfigurationButtonWasPressed] = useState<boolean>(false);

  useEffect(() => {
    if (props.currentProject && isViewConfigurationButtonWasPressed) {
      if (props.currentProject?.requestOrigin !== 'devKreatorApp' && props.currentProject?.requestOrigin !== 'kreatorApp') {
        window.open(props.currentProject?.configLink, '_blank');
        return;
      }

      props.currentProject?.isRoomDesign
        ? history.push(`/configuration/?floorplanId=${props.currentProject?.roomlePlanId}`)
        : history.push(
            `/configuration/?id=${
              props.currentProject?.projectProducts[0]?.userConfiguration?.configuration
            }&configuratorId=${props.currentProject?.projectProducts[0]?.userConfiguration?.manufacturerName.toLowerCase()}`
          );
    }
  }, [props.currentProject]);

  const getCurrentProject = (id: number) => {
    props.getProject(id);
  };

  return {
    setIsViewConfigurationButtonWasPressed,
    getCurrentProject,
  };
};

export default useViewConfiguration;
