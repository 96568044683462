import React, {useState} from 'react';
import {Table} from 'reactstrap';
import {PaymentsStatusesColors} from 'app/config/constants';
import './paymentsTable.scss';
import PaymentDetailsModal from "app/components/PaymentsTable/PaymentDetailsModal";

const PaymentsTable = props => {
  const businessApprovals = [1, 2, 3];
  const [isPaymentDetailsModalOpen, setIsPaymentDetailsModalOpen] = useState(false);
  const [subscription, setSubscription] = useState(null);

  return (
    <> <Table responsive borderless hover className="payments-table">
      <thead>
      <tr className="payments-table-header">
        <th>Name</th>
        <th>Email</th>
        <th>Created at</th>
        <th>Current Period</th>
        <th>Status</th>
        <th>Interval</th>
        <th>Type</th>
        <th>Items</th>
        <th>Per leads</th>
      </tr>
      </thead>
      <tbody>
      {props.subscriptionList && props.subscriptionList.length > 0 ? props.subscriptionList.map(subscription => (
        <tr className="payment-table-item" key={subscription.id} onClick={() => {
          setIsPaymentDetailsModalOpen(true)
          setSubscription(subscription)
        }}>
          <td>{subscription.metadata.name ? subscription.metadata.name : ""}</td>
          <td>{subscription.metadata.email ? subscription.metadata.email : ""}</td>
          <td>{new Date(subscription.created * 1000).toDateString()}</td>
          <td>{new Date(subscription.current_period_start * 1000).toDateString()} - {new Date(subscription.current_period_end * 1000).toDateString()}</td>
          <td>{subscription.status.toUpperCase()}</td>
          <td>{subscription.plan != null ? subscription.plan.interval.toUpperCase() : ""}</td>
          <td>{subscription.plan != null ? subscription.plan.usage_type.toUpperCase() : ""}</td>
          <td>{subscription.items.total_count}</td>
          <td>{subscription.plan != null && subscription.plan.tiers != null ? subscription?.plan?.tiers[0].unit_amount / 100 : ""}{subscription.plan != null ? subscription.plan.currency : ""}</td>
        </tr>
      )) : ""}
      </tbody>
    </Table>
      <PaymentDetailsModal
        isOpen={isPaymentDetailsModalOpen}
        setIsPaymentDetailsModalOpen={() => setIsPaymentDetailsModalOpen(false)}
        startDate={Date.now()}
        subscription={subscription}
      />
    </>);
};

export default PaymentsTable;
