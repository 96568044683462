export const getInstallHours = configuration => {
  const parameters = JSON.parse(configuration.configuration).parameters;
  return { installHours: parameters.installHours };
};

export const getSortedPartListForDraftBeer = partlist => {
  let parts = [];

  const originPart = partlist.perMainComponent[0].originPart;
  parts.push(originPart);

  // get the rest of parts at the list
  for (const part of partlist.fullList) {
    if (part.componentId === originPart.componentId) {
      continue;
    }

    parts.push(part);
  }

  return parts;
};
