import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { INotification, defaultValue } from 'app/shared/model/notification.model';
import { includes } from 'lodash';

export const ACTION_TYPES = {
  FETCH_NOTIFICATION_LIST: 'notification/FETCH_NOTIFICATION_LIST',
  FETCH_NOTIFICATIONS: 'notification/FETCH_NOTIFICATIONS',
  FETCH_NOTIFICATIONS_COUNT: 'notification/FETCH_NOTIFICATIONS_COUNT',
  FETCH_NOTIFICATION: 'notification/FETCH_NOTIFICATION',
  CREATE_NOTIFICATION: 'notification/CREATE_NOTIFICATION',
  UPDATE_NOTIFICATION: 'notification/UPDATE_NOTIFICATION',
  DELETE_NOTIFICATION: 'notification/DELETE_NOTIFICATION',
  READ_NOTIFICATION: 'READ_NOTIFICATION',
  MARK_NOTIFICATION_AS_READ_UNREAD: 'MARK_NOTIFICATION_AS_READ_UNREAD',
  RESET: 'notification/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<INotification>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  notifications: [],
  totalNotifications: 0,
  notificationsCount: null,
};

export type NotificationState = Readonly<typeof initialState>;

// Reducer

export default (state: NotificationState = initialState, action): NotificationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_NOTIFICATION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_NOTIFICATION):
    case REQUEST(ACTION_TYPES.FETCH_NOTIFICATIONS):
    case REQUEST(ACTION_TYPES.FETCH_NOTIFICATIONS_COUNT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_NOTIFICATION):
    case REQUEST(ACTION_TYPES.UPDATE_NOTIFICATION):
    case REQUEST(ACTION_TYPES.DELETE_NOTIFICATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_NOTIFICATION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_NOTIFICATION):
    case FAILURE(ACTION_TYPES.CREATE_NOTIFICATION):
    case FAILURE(ACTION_TYPES.UPDATE_NOTIFICATION):
    case FAILURE(ACTION_TYPES.DELETE_NOTIFICATION):
    case FAILURE(ACTION_TYPES.FETCH_NOTIFICATIONS):
    case FAILURE(ACTION_TYPES.FETCH_NOTIFICATIONS_COUNT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_NOTIFICATION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_NOTIFICATION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_NOTIFICATIONS):
      return {
        ...state,
        loading: false,
        notifications: action.payload.data,
        totalNotifications: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.CREATE_NOTIFICATION):
    case SUCCESS(ACTION_TYPES.UPDATE_NOTIFICATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_NOTIFICATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case SUCCESS(ACTION_TYPES.FETCH_NOTIFICATIONS_COUNT):
      return {
        ...state,
        loading: false,
        notificationsCount: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.MARK_NOTIFICATION_AS_READ_UNREAD:
      const newState = state.notifications.map(notification => {
        if (action.payload.notificationIds.includes(notification.id)) {
          return { ...notification, status: action.payload.status };
        }
        return notification;
      });

      return {
        ...state,
        notifications: newState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/notifications';

// Actions

export const getEntities: ICrudGetAllAction<INotification> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_NOTIFICATION_LIST,
    payload: axios.get<INotification>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<INotification> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_NOTIFICATION,
    payload: axios.get<INotification>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<INotification> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_NOTIFICATION,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<INotification> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_NOTIFICATION,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<INotification> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_NOTIFICATION,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const getNotifications = (page?, size?, sort?) => {
  const apiUrl = '/api/firebase/notifications';
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_NOTIFICATIONS,
    payload: axios.get(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const readUnreadNotification = (notificationIdsArray, notificationStatus) => async dispatch => {
  const apiUrl = '/api/firebase/notifications/mark-read-unread';
  const result = await dispatch({
    type: ACTION_TYPES.READ_NOTIFICATION,
    payload: axios.put(apiUrl, {
      ids: notificationIdsArray,
      notificationStatus,
    }),
  });

  dispatch(getNotificationsCount());
  return result;
};

export const markAllNotifiationsAsReadUnread = notificationStatus => async dispatch => {
  const apiUrl = `/api/firebase/notifications/mark-all-notification-read-unread?notificationStatus=${notificationStatus}`;
  const result = await dispatch({
    type: ACTION_TYPES.READ_NOTIFICATION,
    payload: axios.put(apiUrl),
  });

  dispatch(getNotificationsCount());
  return result;
};

export const markNotificationAsRead = (notificationIds, status) => ({
  type: ACTION_TYPES.MARK_NOTIFICATION_AS_READ_UNREAD,
  payload: { notificationIds, status },
});

export const deleteNotification = notificationId => async dispatch => {
  const requestUrl = `api/firebase/delete-notifications/${notificationId}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_NOTIFICATION,
    payload: axios.delete(requestUrl),
  });
  dispatch(getNotifications());
  return result;
};

export const getNotificationsCount = () => {
  const apiUrl = '/api/notifications-counter';
  return {
    type: ACTION_TYPES.FETCH_NOTIFICATIONS_COUNT,
    payload: axios.get(apiUrl),
  };
};
