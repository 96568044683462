import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './dealerRegistrationProcessPages.scss';

const DealerRegistrationFinal = () => {
  let history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      redirectToApp();
    }, 10000);
  }, []);

  const redirectToApp = () => {
    history.push('/');
  };

  return (
    <div className="sign-up-account-type-redirect-alert">
      <span className="sign-up-account-type-redirect-alert__text">
        A Kreator staff member will be in touch <br /> shortly to setup your marketplace <br /> account. You will now be re-directed to{' '}
        <br /> your{' '}
        <span onClick={redirectToApp} style={{ fontWeight: 600, cursor: 'pointer' }}>
          Kreator user account
        </span>
      </span>
    </div>
  );
};

export default DealerRegistrationFinal;
