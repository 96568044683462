import React, { forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import { findUnpairedItems } from './findUnpairedItems';
import RoomleConfiguratorApi from '@roomle/embedding-lib/roomle-configurator-api.es.min.js';
import { IProjectProduct } from '../model/project-product.model';

export interface IRoomleProps {
  configurationId: string;
  saveButton: boolean;
  handleFinishLoading: any;
  unitsOfMeasure?: string;
  planId?: string;
  catalogRootTag?: string;
  setRoomleConfiguration?: Function;
  setRoomleConfigurationLoaded?: Function;
  projectConfigurations: IProjectProduct[];
  setFloorPlanObjectsArray: Function;
  floorPlanObjectsArray: any;
  isSaveProjectButtonWasPressed: boolean;
  isFullScreenMode: boolean;
  isSidebarCollapsed: boolean;
}

export const RoomleMOC = forwardRef((props: IRoomleProps, ref) => {
  const [roomleLoaded, setRoomleLoaded] = useState(false);
  const [configurator, setConfigurator] = useState(null);

  useImperativeHandle(ref, () => ({
    requestProductOutsideTheIframe() {
      configurator.ui.triggerRequestPlan();
    },
  }));

  useEffect(() => {
    if (roomleLoaded) {
      return;
    }

    (async () => {
      const options = {
        moc: true,
        catalogRootTag: props.catalogRootTag,
        id: props.planId,
        buttons: { savedraft: false, startconfigure: props.saveButton, requestproduct: true, export3d: false },
        locale: 'en',
        translations: {
          en: {
            params: {
              'request-product': 'Save to Inventory',
            },
          },
        },
      };

      const configurator = await RoomleConfiguratorApi.createPlanner(
        props.configurationId,
        document.getElementById('configurator-container'),
        options
      );

      configurator.ui.callbacks.onRequestPlan = (planId, image, kernelPlanObjects) => {
        if (!props.isSaveProjectButtonWasPressed) {
          const projectConfigurationsIds =
            props.projectConfigurations?.map((item: IProjectProduct) => item.userConfiguration.configuration) || [];
          const roomleMOCItemsIds = kernelPlanObjects.map(item => item.configurationHash);
          const objectsToInsert = findUnpairedItems(projectConfigurationsIds, roomleMOCItemsIds);
          objectsToInsert.forEach(id => configurator.ui.insertObject(id));
        }

        (async () => {
          for (let object of kernelPlanObjects) {
            if (object.configurationRuntimeId) {
              const configString = await configurator.extended.createNewSerializedConfiguration(object.configurationRuntimeId);
              const parsedConfigString = configString ? JSON.parse(configString) : {};
              props.setFloorPlanObjectsArray(prevState => [...prevState, parsedConfigString]);
            }
          }
        })();
        props.setRoomleConfiguration({ planId, image, kernelPlanObjects });
      };

      if (props.handleFinishLoading !== null) {
        props.handleFinishLoading(configurator);
      }

      setConfigurator(configurator);
      setRoomleLoaded(true);
      props.setRoomleConfigurationLoaded(true);
    })();
  }, [props.planId]);

  return (
    <>
      <div
        id="configurator-container"
        className={props.isSidebarCollapsed ? 'roomle-moc-configurator-container' : 'roomle-moc-configurator-container-default'}
      ></div>
    </>
  );
});

export default RoomleMOC;
