import React, { memo } from 'react';
import { CardImg } from 'reactstrap';

interface IWallLayoutCard {
  cardData: any;
  selectedFolder: any;
  setSelectedFolder: Function;
}

const FolderSelectCard = memo((props: IWallLayoutCard) => {
  let cardClass = 'select-folder-modal-folder-card';
  if (props.selectedFolder === props.cardData.id) {
    cardClass = cardClass + ' ' + 'selected-folder-card';
  }

  return (
    <div className={cardClass} onClick={() => props.setSelectedFolder(props.cardData.id)}>
      <CardImg src="../../../content/images/folder.png" alt="folder" className="folder-card-image" />
      <span>{props.cardData.name}</span>
    </div>
  );
});

export default FolderSelectCard;
