import React from 'react';
import './projectsTable.scss';

interface ICardListToggle {
  setIsUseTableView: Function;
  isUseTableView: boolean;
}

const CardListToggle = (props: ICardListToggle) => {
  let cardButtonClass = 'card-list-toggle-button';
  if (!props.isUseTableView) {
    cardButtonClass += ' card-list-toggle-selected';
  }

  let listButtonClass = 'card-list-toggle-button';
  if (props.isUseTableView) {
    listButtonClass += ' card-list-toggle-selected';
  }

  return (
    <div className="card-list-toggle-container">


      <button onClick={() => props.setIsUseTableView(false)} className={cardButtonClass}>
        <img src="../../../content/images/icons/cards-icon.svg" alt="cards"/>
      </button>
      <button onClick={() => props.setIsUseTableView(true)} className={listButtonClass}>
        <img src="../../../content/images/icons/list-icon.svg" alt="cards"/>
      </button>
    </div>
  );
};

export default CardListToggle;
