import React from 'react';
import { Button } from 'reactstrap';
// import UnitsOfMeasureSwitch from 'app/components/RoomleUnitsOfMeasureSwitch/UnitsOfMeasureSwitch';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light-border.css';
import './project.scss';

interface IProjectActionsBar {
  setUnitsOfMeasure: Function;
  projectEntity: any;
  roomleConfigurationLoaded: any;
  roomleChildRef: any;
  setIsProjectDetailsModalOpen: Function;
  setIsCreateDrawingsModalOpen: Function;
  setIsExportRevitModalOpen: Function;
  account: any;
  loadingValuesArray: any;
  setIsSaveProjectButtonWasPressed: Function;
  setIsHelpVideoPopupOpen: Function;
  setIsAddToProjectModalOpen: Function;
}

const ProjectActionsBar = (props: IProjectActionsBar) => {
  return (
    <div className="roomle-actions-bar">
      <div className="d-flex">
        <Tippy content="Project Details" placement="bottom" theme="light-border">
          <button className="project-details-button" onClick={() => props.setIsProjectDetailsModalOpen(true)}>
            <img src="../../../content/images/icons/project-details-orange-icon.svg" width="30" />
          </button>
        </Tippy>
        <Tippy content="Help" placement="bottom" theme="light-border">
          <button className="project-details-button" onClick={() => props.setIsHelpVideoPopupOpen(true)}>
            <img src="../../../content/images/icons/help-video-icon.svg" width="30" />
          </button>
        </Tippy>
        {/* <UnitsOfMeasureSwitch setUnitsOfMeasure={props.setUnitsOfMeasure} /> */}
      </div>

      <span className="user-configuration-action-button-content-title">{props.projectEntity.projectName}</span>

      <div className="d-flex project-bar-action-buttons-container">
        <Button
          className="roomle-project-save-button add-inventory-button"
          disabled={!props.roomleConfigurationLoaded || props.loadingValuesArray.some(item => item)}
          onClick={() => {
            props.setIsAddToProjectModalOpen(true);
          }}
        >
          <div className="user-configuration-action-button-content">
            <span>Add Configuration</span>
          </div>
        </Button>

        <Button
          className="roomle-project-save-button"
          disabled={!props.roomleConfigurationLoaded || props.loadingValuesArray.some(item => item)}
          onClick={() => {
            props.setIsSaveProjectButtonWasPressed(true);
            props.roomleChildRef.current.requestProductOutsideTheIframe();
          }}
        >
          <div className="user-configuration-action-button-content">
            <img src="../../../content/images/icons/save-project-icon.svg" className="project-button-icons" width="22" />
            <span>Save Project</span>
          </div>
        </Button>

        {hasAnyAuthority(props.account.authorities, [
          AUTHORITIES.ROLE_CORONET_DRAWING,
          AUTHORITIES.ROLE_KROWNE_DRAWING,
          AUTHORITIES.ROLE_FOOD_SERVICE_DRAWING,
        ]) && (
          <Tippy
            content="You must add a configuration using Add to Project before you can Create Shop Drawings"
            disabled={
              props.projectEntity.projectProducts?.length > 0 ||
              !props.roomleConfigurationLoaded ||
              props.loadingValuesArray.some(item => item)
            }
            placement="bottom"
            theme="light-border"
          >
            <div className="roomle-save-to-inventory-button-container">
              <button
                className="roomle-save-to-inventory-button create-shop-drawings-button"
                onClick={() => props.setIsCreateDrawingsModalOpen(true)}
                disabled={
                  props.projectEntity.projectProducts?.length === 0 ||
                  !props.roomleConfigurationLoaded ||
                  props.loadingValuesArray.some(item => item)
                }
              >
                <div className="user-configuration-action-button-content">
                  <img src="../../../content/images/icons/project-shop-drawings-icon.svg" className="project-button-icons" width="25" />

                  <span>Create Shop Drawings</span>
                </div>
              </button>
            </div>
          </Tippy>
        )}
        <Button
          className="roomle-save-to-inventory-button"
          onClick={() => props.setIsExportRevitModalOpen(true)}
          disabled={!props.roomleConfigurationLoaded || props.loadingValuesArray.some(item => item)}
        >
          <div className="user-configuration-action-button-content">
            <img src="../../../content/images/icons/share-icon.svg" alt="share icon" width="20" className="mr-2" />
            <span>Share</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default ProjectActionsBar;
