const validateData = arr => {
  if (arr.every(val => val === '')) return false;

  return true;
};

export const getParameter = (configuration, parameterName) => {
  const parameters = JSON.parse(configuration).parameters;
  return parameters[parameterName];
};

export const getCSVContent = configuration => {
  let content = [];

  content.push(['Option', 'Quantity', 'Part Number', 'Description']);

  const parameters = JSON.parse(configuration).parameters;
  const numOfObject = parameters.option.split(',').length;

  const options = parameters.option.split(',');
  const quantities = parameters.quantity.split(',');
  const partNumbers = parameters.partNumber.split(',');
  const descriptions = parameters.description.split(',');

  for (let i = 0; i < numOfObject; i++) {
    if (!validateData([options[i], quantities[i], partNumbers[i], descriptions[i]])) {
      continue;
    }

    content.push([options[i], quantities[i], partNumbers[i], descriptions[i]]);
  }

  return content.join('\n');
};

export const getSortedPartListForThermoKoolWalkIns = partlist => {
  let parts = [];

  const originPart = partlist.perMainComponent[0].originPart;
  parts.push(originPart);

  // get the rest of parts at the list
  for (const part of partlist.fullList) {
    if (part.componentId === originPart.componentId) {
      continue;
    }

    parts.push(part);
  }

  return parts;
};
