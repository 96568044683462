export const regionList=[
  {
    "code": "1",
    "name": "New England"
  },
  {
    "code": "2",
    "name": "Upstate NY - W. Pennsylvania"
  },
  {
    "code": "3",
    "name": "New York Metro"
  },
  {
    "code": "4",
    "name": "Keystone"
  },
  {
    "code": "5",
    "name": "Mid-Atlantic"
  },
  {
    "code": "6",
    "name": "Michigan/Indiana"
  },
  {
    "code": "7",
    "name": "Mid-America"
  },
  {
    "code": "11",
    "name": "Carolinas"
  },
  {
    "code": "12",
    "name": "Southeast Central"
  },
  {
    "code": "13",
    "name": "Florida"
  },
  {
    "code": "14",
    "name": "South Central"
  },
  {
    "code": "15",
    "name": "Texas/Oklahoma"
  },
  {
    "code": "16",
    "name": "Mid-Central"
  },
  {
    "code": "17",
    "name": "Chicago Metro"
  },
  {
    "code": "18",
    "name": "Wisconsin/Upper Michigan"
  },
  {
    "code": "19",
    "name": "Upper Mid-Central"
  },
  {
    "code": "20",
    "name": "Mountain States North"
  },
  {
    "code": "21",
    "name": "Arizona/New Mexico"
  },
  {
    "code": "22",
    "name": "Southern California"
  },
  {
    "code": "24",
    "name": "San Francisco Metro"
  },
  {
    "code": "25",
    "name": "Northwest"
  },
  {
    "code": "26a",
    "name": "British Columbia"
  },
  {
    "code": "26b",
    "name": "Alberta"
  },
  {
    "code": "26c",
    "name": "Manitoba/Saskatchewan/Lakehead"
  },
  {
    "code": "26d",
    "name": "Ontario/Ottawa"
  },
  {
    "code": "26e",
    "name": "Quebec/Ottawa"
  },
  {
    "code": "26f",
    "name": "Atlantic"
  }
]
