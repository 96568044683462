import React from 'react';
import moment from 'moment';

interface IClientDetails {
  details: any;
}

const ClientDetails = (props: IClientDetails) => {
  return (
    <div className="project-submission-log-details-page-project-data-container">
      <div className="project-submission-log-details-page-project-data-container-header">Client details</div>
      <ul style={{ padding: 0, margin: 0 }}>
        {props.details.project?.clietnName && (
          <li className="project-submission-log-details-page-project-data-container-item">
            <span className="project-submission-log-details-page-project-data-container-item-field-name">Client Name :</span>
            <span className="project-submission-log-details-page-project-data-container-item-field-value">
              {props.details.project?.clietnName}
            </span>
          </li>
        )}
        {props.details.project?.dateCreated && (
          <li className="project-submission-log-details-page-project-data-container-item">
            <span className="project-submission-log-details-page-project-data-container-item-field-name">Date Created :</span>
            <span className="project-submission-log-details-page-project-data-container-item-field-value">
              {moment(props.details.project?.dateCreated).format('MMMM DD, YYYY')}
            </span>
          </li>
        )}
        {props.details.project?.lastModified && (
          <li className="project-submission-log-details-page-project-data-container-item">
            <span className="project-submission-log-details-page-project-data-container-item-field-name">Last Modified :</span>
            <span className="project-submission-log-details-page-project-data-container-item-field-value">
              {moment(props.details.project?.lastModified).format('MMMM DD, YYYY, HH:mm a')}
            </span>
          </li>
        )}
        {props.details.project?.projectStreetAddress && (
          <li className="project-submission-log-details-page-project-data-container-item">
            <span className="project-submission-log-details-page-project-data-container-item-field-name">Project Street Address :</span>
            <span className="project-submission-log-details-page-project-data-container-item-field-value">
              {props.details.project?.projectStreetAddress}
            </span>
          </li>
        )}
        {props.details.project?.projectCity && (
          <li className="project-submission-log-details-page-project-data-container-item">
            <span className="project-submission-log-details-page-project-data-container-item-field-name">Project City :</span>
            <span className="project-submission-log-details-page-project-data-container-item-field-value">
              {props.details.project?.projectCity}
            </span>
          </li>
        )}
        {props.details.project?.projectState && (
          <li className="project-submission-log-details-page-project-data-container-item">
            <span className="project-submission-log-details-page-project-data-container-item-field-name">Project State :</span>
            <span className="project-submission-log-details-page-project-data-container-item-field-value">
              {props.details.project?.projectState}
            </span>
          </li>
        )}
        {props.details.project?.projectZipCode && (
          <li className="project-submission-log-details-page-project-data-container-item">
            <span className="project-submission-log-details-page-project-data-container-item-field-name">Project Zip Code :</span>
            <span className="project-submission-log-details-page-project-data-container-item-field-value">
              {props.details.project?.projectZipCode}
            </span>
          </li>
        )}
        {props.details.project?.userCompanyName && (
          <li className="project-submission-log-details-page-project-data-container-item">
            <span className="project-submission-log-details-page-project-data-container-item-field-name">User Company Name :</span>
            <span className="project-submission-log-details-page-project-data-container-item-field-value">
              {props.details.project?.userCompanyName}
            </span>
          </li>
        )}
        {props.details.project?.userStreetAddress && (
          <li className="project-submission-log-details-page-project-data-container-item">
            <span className="project-submission-log-details-page-project-data-container-item-field-name">User Street Address :</span>
            <span className="project-submission-log-details-page-project-data-container-item-field-value">
              {props.details.project?.userStreetAddress}
            </span>
          </li>
        )}
        {props.details.project?.userCity && (
          <li className="project-submission-log-details-page-project-data-container-item">
            <span className="project-submission-log-details-page-project-data-container-item-field-name">User City :</span>
            <span className="project-submission-log-details-page-project-data-container-item-field-value">
              {props.details.project?.userCity}
            </span>
          </li>
        )}
        {props.details.project?.projectState && (
          <li className="project-submission-log-details-page-project-data-container-item">
            <span className="project-submission-log-details-page-project-data-container-item-field-name">User State :</span>
            <span className="project-submission-log-details-page-project-data-container-item-field-value">
              {props.details.project?.projectState}
            </span>
          </li>
        )}
        {props.details.project?.userCompanyUrl && (
          <li className="project-submission-log-details-page-project-data-container-item">
            <span className="project-submission-log-details-page-project-data-container-item-field-name">User Company Website URL :</span>
            <span className="project-submission-log-details-page-project-data-container-item-field-value">
              <a href={props.details.project?.userCompanyUrl}>{props.details.project?.userCompanyUrl}</a>
            </span>
          </li>
        )}
        {props.details.project?.userPhone && (
          <li className="project-submission-log-details-page-project-data-container-item">
            <span className="project-submission-log-details-page-project-data-container-item-field-name">User Phone Number :</span>
            <span className="project-submission-log-details-page-project-data-container-item-field-value">
              {props.details.project?.userPhone}
            </span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default ClientDetails;
