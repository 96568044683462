import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CardImg } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { toast, ToastContainer } from 'react-toastify';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

interface IProductConfiguratorCard {
  manufacturerRep: any;
}

const FindManufacturerRep = (props: IProductConfiguratorCard) => {
  return (
    <div className="mfr-rep-card">
      {/* <div className="mfr-rep-card-image-container">
        <div className="mfr-rep-card-image-container-border">
          <img src="../../../content/images/kreator-navbar-logo.png" alt="mfr rep logo" width="100" className="mfr-rep-card-image" />
        </div>
      </div> */}
      <div className="mfr-rep-card-data-container">
        <span className="mfr-rep-card-name">{props.manufacturerRep.company}</span>
        {/* <span className="mfr-rep-card-description">
          Category leader for making and cooking pasta – a complete range of extruders, sheeters, and combination systems as well as gas and
          electric cookers.
        </span> */}
        <span className="mfr-rep-card-address">
          <img src="../../../content/images/icons/location-marker.svg" alt="location" style={{ marginRight: 5 }} />
          {props.manufacturerRep.mailingAddress +
            ',  ' +
            props.manufacturerRep.city +
            ',  ' +
            props.manufacturerRep.state +
            ' ' +
            props.manufacturerRep.zipCode}
        </span>
        <span className="mfr-rep-card-description">
          {props.manufacturerRep?.manufacturerAToK?.replaceAll(';', ', ') +
            ',' +
            props.manufacturerRep?.manufacturerIToZ?.replaceAll(';', ', ')}
        </span>
        {/* <div className="mfr-rep-card-services-container">
          <img src="../../../content/images/icons/fast-delivery.svg" width="25" className="mfr-rep-card-services-icon" />
          <img src="../../../content/images/icons/fire-orange.svg" width="25" className="mfr-rep-card-services-icon" />
          <img src="../../../content/images/icons/ion_snow.svg" width="25" className="mfr-rep-card-services-icon" />
        </div> */}
      </div>
      {/* <div className="mfr-rep-card-additional-data-container">
        <a href={props.manufacturerRep.website} className="mfr-rep-card-website-link">
          <img src="../../../content/images/icons/website-icon.svg" style={{ marginRight: 5 }} />
          <span>Website</span>
        </a>
      </div> */}
    </div>
  );
};

const mapStateToProps = ({ enableRegion, stripe, authentication }: IRootState) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FindManufacturerRep);
