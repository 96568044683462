import React from 'react';
import { updateProject } from 'app/entities/project/project.reducer';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { PROJECT_STATUSES } from 'app/config/constants';
import { PROJECT_STATUSES_TRANSLATION } from 'app/config/constants';
import { connect } from 'react-redux';

interface IProjectStatusSelect extends DispatchProps {
  project: any;
}

const ProjectStatusSelect = (props: IProjectStatusSelect) => {
  const statusesColors = {
    IN_PROGRESS: 'yellow',
    APPROVED: 'green',
    RESUBMIT: 'yellow',
    CLOSED: 'red',
    SENT: 'yellow',
  };

  const updateProjectStatus = status => {
    const updatedLead = {
      ...props.project,
      status,
    };

    props.updateProject(updatedLead);
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle nav className="lead-status-select-toggle">
        <span className={statusesColors[props.project.status]}>{PROJECT_STATUSES_TRANSLATION[props.project.status].status}</span>
        <img src="../../../content/images/icons/arrow_down.svg" alt="icon-down" className="drop-down-icon" />
      </DropdownToggle>
      <DropdownMenu className="lead-status-dropdown">
        {PROJECT_STATUSES.map(status => (
          <DropdownItem key={status.value} className="lead-status-dropdown-item">
            <span onClick={() => updateProjectStatus(status.value)} className={statusesColors[status.value]}>
              {status.title}
            </span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const mapDispatchToProps = {
  updateProject,
};

type DispatchProps = typeof mapDispatchToProps;

export default connect(null, mapDispatchToProps)(ProjectStatusSelect);
