import React, { useState } from 'react';
import { Modal } from 'reactstrap';
import axios, { Method } from 'axios';
import './krowneDraftBeerSystemForm.scss';

interface IKrowneDraftBeerSystemForm {
  isOpen: boolean;
  closeModal: Function;
  roomleConfiguration: any;
}

const KrowneDraftBeerSystemForm = (props: IKrowneDraftBeerSystemForm) => {
  const defaultFormData = {
    name: '',
    company: '',
    email: '',
    phone: '',
    projectName: '',
    zipCode: '',
    occupation: 'Owner',
    followup: 'Yes',
    comments: '',
  };
  const [formData, setFormData] = useState(defaultFormData);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (formData.name && formData.company && formData.email && formData.phone) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();

    let data = JSON.stringify({
      formData: formData,
      configurator: props.roomleConfiguration,
    });

    const config = {
      method: 'POST' as Method,
      url: 'https://kreator-krowne-email-api.azurewebsites.net/sendmail',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: data,
    };

    axios(config);
    props.closeModal();
    setFormData(defaultFormData);
    setIsFormValid(false);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      centered
      fade={false}
      toggle={() => props.closeModal(false)}
      backdrop={true}
      style={
        window.screen.width >= 1024
          ? { maxWidth: window.screen.width - 820, width: '100%' }
          : { maxWidth: '95%', width: '95%', margin: 'auto' }
      }
    >
      <form onSubmit={handleSubmit} className="krowne-draft-beer-system-modal-container">
        <div className="form-group">
          <label htmlFor="name" className="krowne-draft-beer-system-modal-label">
            Name <strong style={{ color: 'red' }}>*</strong>
          </label>
          <input
            type="text"
            className="form-control"
            id="form-client-name"
            placeholder="Enter Your Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="company" className="krowne-draft-beer-system-modal-label">
            Company <strong style={{ color: 'red' }}>*</strong>
          </label>
          <input
            type="text"
            className="form-control"
            id="form-client-company"
            placeholder="Enter Your Company Name"
            name="company"
            value={formData.company}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email" className="krowne-draft-beer-system-modal-label">
            Email <strong style={{ color: 'red' }}>*</strong>
          </label>
          <input
            type="email"
            className="form-control"
            id="form-client-email"
            placeholder="Enter Your Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone" className="krowne-draft-beer-system-modal-label">
            Phone Number <strong style={{ color: 'red' }}>*</strong>
          </label>
          <input
            type="tel"
            className="form-control"
            id="form-client-phone"
            placeholder="Enter Phone Number"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="project-name" className="krowne-draft-beer-system-modal-label">
            Project Name:
          </label>
          <input
            type="text"
            className="form-control"
            id="form-project"
            placeholder="Enter Your Job Name"
            name="projectName"
            value={formData.projectName}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="project-zip" className="krowne-draft-beer-system-modal-label">
            Project Zip Code:
          </label>
          <input
            type="text"
            className="form-control"
            id="form-project-zip"
            placeholder="Enter Your Project Zip Code"
            name="zipCode"
            value={formData.zipCode}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="occupation" className="krowne-draft-beer-system-modal-label">
            Occupation:
          </label>
          <select className="form-control" id="form-occupation" name="occupation" value={formData.occupation} onChange={handleInputChange}>
            <option value="Owner">Owner</option>
            <option value="Dealer">Dealer</option>
            <option value="Consultant">Consultant</option>
            <option value="Mfr Rep">Mfr Rep</option>
            <option value="Architect">Architect</option>
            <option value="Bartender">Bartender</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="followup" className="krowne-draft-beer-system-modal-label">
            Would you like someone from Krowne to follow up with you on this request?:
          </label>
          <select className="form-control" id="form-followup" name="followup" value={formData.followup} onChange={handleInputChange}>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="comments" className="krowne-draft-beer-system-modal-label">
            Comments:
          </label>
          <input
            type="text"
            className="form-control"
            id="form-comments"
            placeholder="Enter Your Comments"
            name="comments"
            value={formData.comments}
            onChange={handleInputChange}
          />
        </div>
        <div className="submit" style={{ textAlign: 'center' }}>
          <button type="submit" disabled={!isFormValid} className="btn btn-default submit-data">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default KrowneDraftBeerSystemForm;
