import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IEnableRegion, defaultValue } from 'app/shared/model/enable-region.model';
import { Region } from 'app/shared/model/enumerations/region.model';

export const ACTION_TYPES = {
  FETCH_ENABLEREGION_LIST: 'enableRegion/FETCH_ENABLEREGION_LIST',
  FETCH_ENABLEREGION: 'enableRegion/FETCH_ENABLEREGION',
  CREATE_ENABLEREGION: 'enableRegion/CREATE_ENABLEREGION',
  UPDATE_ENABLEREGION: 'enableRegion/UPDATE_ENABLEREGION',
  DELETE_ENABLEREGION: 'enableRegion/DELETE_ENABLEREGION',
  FETCH_REGION_LIST: 'enableRegion/FETCH_REGION_LIST',
  RESET: 'enableRegion/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IEnableRegion>,
  regions: [] as ReadonlyArray<Region>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type EnableRegionState = Readonly<typeof initialState>;

// Reducer

export default (state: EnableRegionState = initialState, action): EnableRegionState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_REGION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ENABLEREGION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ENABLEREGION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_ENABLEREGION):
    case REQUEST(ACTION_TYPES.UPDATE_ENABLEREGION):
    case REQUEST(ACTION_TYPES.DELETE_ENABLEREGION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_ENABLEREGION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_REGION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ENABLEREGION):
    case FAILURE(ACTION_TYPES.CREATE_ENABLEREGION):
    case FAILURE(ACTION_TYPES.UPDATE_ENABLEREGION):
    case FAILURE(ACTION_TYPES.DELETE_ENABLEREGION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ENABLEREGION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_REGION_LIST):
      return {
        ...state,
        loading: false,
        regions: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ENABLEREGION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_ENABLEREGION):
    case SUCCESS(ACTION_TYPES.UPDATE_ENABLEREGION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_ENABLEREGION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/enable-regions';

// Actions

export const getAllRegions = () => {
  const requestUrl = `api/get-all-regions`;
  return {
    type: ACTION_TYPES.FETCH_REGION_LIST,
    payload: axios.get<IEnableRegion>(`${requestUrl}`),
  };
};

export const getEntities: ICrudGetAllAction<IEnableRegion> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_ENABLEREGION_LIST,
    payload: axios.get<IEnableRegion>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IEnableRegion> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ENABLEREGION,
    payload: axios.get<IEnableRegion>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IEnableRegion> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ENABLEREGION,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IEnableRegion> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ENABLEREGION,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IEnableRegion> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ENABLEREGION,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
