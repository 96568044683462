import { AvField, AvForm } from 'availity-reactstrap-validation';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { handlePasswordResetInit, reset } from '../password-reset.reducer';
import UnauthorizedPagesContainer from 'app/modules/login/UnauthorizedPagesContainer';
import SignInCarousel from '../../register/SignInCarousel';

export type IPasswordResetInitProps = DispatchProps & RouteComponentProps;

export class PasswordResetInit extends React.Component<IPasswordResetInitProps> {
  state = {
    imagesLoading: true,
  };

  handleImagesLoaded = () => {
    this.setState({ imagesLoading: false });
  };

  componentWillUnmount() {
    this.props.reset();
  }

  handleValidSubmit = (event, values) => {
    this.props.handlePasswordResetInit(values.email);
    event.preventDefault();

    this.props.history.push('/');
  };

  render() {
    return (
      <UnauthorizedPagesContainer content={<SignInCarousel />}>
        <img
          src="../../../content/images/kreator-navbar-logo.svg"
          alt="kreator logo"
          className="login-page-kreator-logo"
          style={{ marginBottom: 50 }}
        />
        <div style={{ height: '100%', width: '100%' }} className="d-flex flex-column justify-content-center">
          <p className="reset-password-title">Reset your password</p>
          <p className="reset-password-description">Enter the email address you used to register</p>
          <AvForm onValidSubmit={this.handleValidSubmit} className="sign-in-form">
            <div className="field-block">
              <span className="sign-in-field-label">Email</span>
              <AvField
                className="sign-in-field"
                name="email"
                placeholder={'Your email'}
                type="email"
                validate={{
                  required: { value: true, errorMessage: 'Email cannot be empty!' },
                  pattern: { value: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$', errorMessage: 'Invalid email address' },
                }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 70 }}>
              <Button className="sign-in-button reset-password-button" type="submit">
                Reset Password
              </Button>
            </div>
          </AvForm>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16, marginBottom: 16 }}>
            <Link className="reset-password-go-back-button" to="/login">
              Go Back
            </Link>
          </div>
          <div className="do-not-have-an-account-block">
            <span className="do-not-have-an-account-block-text">Don’t Have an Account?</span>
            <button type="button" className="do-not-have-an-account-button">
              <Link className="do-not-have-an-account" to="/account/register">
                Sign Up
              </Link>
            </button>
          </div>
        </div>
      </UnauthorizedPagesContainer>
    );
  }
}

const mapDispatchToProps = { handlePasswordResetInit, reset };

type DispatchProps = typeof mapDispatchToProps;

export default connect(null, mapDispatchToProps)(PasswordResetInit);
