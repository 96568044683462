import React from 'react';
import { DropdownItem } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import '../header/header.scss';

export interface IMenuItem {
  icon?: IconProp;
  to?: string;
  id?: string;
}

export default class MenuItem extends React.Component<IMenuItem> {
  render() {
    const { to, id, children } = this.props;

    return (
      <DropdownItem tag={Link} to={to} id={id} className="user-profile-dropdown-menu-item">
        {children}
      </DropdownItem>
    );
  }
}
