import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ProjectSubmissionLog from './project-submission-log';
import ProjectSubmissionLogDetail from './project-submission-log-detail';
import ProjectSubmissionLogUpdate from './project-submission-log-update';
import ProjectSubmissionLogDeleteDialog from './project-submission-log-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ProjectSubmissionLogUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ProjectSubmissionLogUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ProjectSubmissionLogDetail} />
      <ErrorBoundaryRoute path={match.url} component={ProjectSubmissionLog} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={ProjectSubmissionLogDeleteDialog} />
  </>
);

export default Routes;
