import React from 'react';
import { Button } from 'reactstrap';

interface IUserInfo {
  details: any;
}

const UserInfo = (props: IUserInfo) => {
  return (
    <div className="project-submission-log-details-page-project-data-container">
      <div className="project-submission-log-details-page-project-data-container-header">User info</div>
      <ul style={{ padding: 0, margin: 0 }}>
        <li className="project-submission-log-details-page-project-data-container-item">
          <span className="project-submission-log-details-page-project-data-container-item-field-name">Name:</span>
          <span className="project-submission-log-details-page-project-data-container-item-field-value">
            {props.details.user.firstName + ' ' + props.details.user.lastName}
          </span>
        </li>
        <li className="project-submission-log-details-page-project-data-container-item">
          <span className="project-submission-log-details-page-project-data-container-item-field-name">Email:</span>
          <span className="project-submission-log-details-page-project-data-container-item-field-value">{props.details.user.email}</span>
        </li>
        {props.details.user.project?.projectCode && (
          <li className="project-submission-log-details-page-project-data-container-item">
            <span className="project-submission-log-details-page-project-data-container-item-field-name">Project Code:</span>
            <span className="project-submission-log-details-page-project-data-container-item-field-value">
              {props.details.user.project?.projectCode}
            </span>
          </li>
        )}
        {props.details.user.project?.projectName && (
          <li className="project-submission-log-details-page-project-data-container-item">
            <span className="project-submission-log-details-page-project-data-container-item-field-name">Project Name:</span>
            <span className="project-submission-log-details-page-project-data-container-item-field-value">
              {props.details.user.project?.projectName}
            </span>
          </li>
        )}
        {props.details?.project?.externalFileUrl && (
          <li className="project-submission-log-details-page-project-data-container-item">
            <span className="project-submission-log-details-page-project-data-container-item-field-name">File Uploads:</span>
            <span className="project-submission-log-details-page-project-data-container-item-field-value">
              <Button
                className="project-submission-table-button"
                onClick={() => window.open(props.details?.project?.externalFileUrl, '_blank')}
              >
                <img src="../../../content/images/icons/download-blue-icon.svg" width="25" height="25" />
              </Button>
            </span>
          </li>
        )}
        {props.details.user.project?.projectZipCode && (
          <li className="project-submission-log-details-page-project-data-container-item">
            <span className="project-submission-log-details-page-project-data-container-item-field-name">Project Zip Code:</span>
            <span className="project-submission-log-details-page-project-data-container-item-field-value">
              {props.details.user.project?.projectZipCode}
            </span>
          </li>
        )}
        {props.details.user.company && (
          <li className="project-submission-log-details-page-project-data-container-item">
            <span className="project-submission-log-details-page-project-data-container-item-field-name">Company:</span>
            <span className="project-submission-log-details-page-project-data-container-item-field-value">
              {props.details.user.company}
            </span>
          </li>
        )}
        {props.details.project.marketSegment && (
          <li className="project-submission-log-details-page-project-data-container-item">
            <span className="project-submission-log-details-page-project-data-container-item-field-name">Market Segment:</span>
            <span className="project-submission-log-details-page-project-data-container-item-field-value">
              {props.details.project.marketSegment}
            </span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default UserInfo;
