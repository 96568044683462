import React, { memo } from 'react';
import './productCardAction.scss';

interface IProductCardAction {
  iconName: string;
  onClick?: Function;
  bordered: Boolean;
}

const ProductCardAction = memo((props: IProductCardAction) => {
  return (
    <button onClick={e => props.onClick(e)} className={props.bordered ? 'bordere-action-button copy-button' : 'copy-button'}>
      <img
        src={`../../../content/images/icons/${props.iconName}.svg`}
        alt="copy icon"
        className="action-image delete-project-button-icon"
      />
    </button>
  );
});

export default ProductCardAction;
