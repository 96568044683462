import React from 'react';
import { Col, Button } from 'reactstrap';
import './pricing.scss';

interface IPricingCard {
  stripeProduct: any;
  isDealerPricingMode: boolean;
  setProduct: any;
  subscriptionDetails: any;
  selectedProduct?: any;
}

const PricingCard = (props: IPricingCard) => {
  const { subscriptionDetails, stripeProduct, setProduct, selectedProduct } = props;

  return (
    <Col
      className={
        props.isDealerPricingMode
          ? selectedProduct?.product?.id === stripeProduct.product.id
            ? 'pricing-card dealer-pricing-card selected-plan'
            : 'pricing-card dealer-pricing-card'
          : 'pricing-card'
      }
    >
      <span className="pricing-card-title">{stripeProduct.title}</span>
      {!props.isDealerPricingMode && <span className="pricing-card-level">{stripeProduct.planLevel}</span>}
      {props.isDealerPricingMode ? (
        <span className="pricing-card-price dealer-pricing-price">{stripeProduct.prices[0].unitAmount / 100}</span>
      ) : (
        <span className={stripeProduct.id === 4 ? 'pricing-card-price custom-price' : 'pricing-card-price'}>
          {stripeProduct.prices[0].unitAmount / 100}
        </span>
      )}
      <span className="pricing-card-price-label">*License billed annually</span>
      <div className="pricing-card-plan-points">
        {!props.isDealerPricingMode && <span className="pricing-card-plan-points-title">The plan includes</span>}
        {stripeProduct.product.metadata.features
          ? stripeProduct.product.metadata.features.split(',').map((feature, index) => {
              return (
                <span className="pricing-plan-points" key={index}>
                  {feature}
                </span>
              );
            })
          : ''}
      </div>
      {props.isDealerPricingMode ? (
        <Button
          disabled={subscriptionDetails?.items?.data[0]?.plan?.product === stripeProduct.product.id}
          className="pricing-card-button dealer-pricing-card-button"
          onClick={() => setProduct(stripeProduct)}
        >
          {subscriptionDetails?.items?.data[0]?.plan?.product === stripeProduct.product.id ? 'Activated' : 'Select'}
        </Button>
      ) : (
        <Button className="pricing-card-button">Contact sales</Button>
      )}
    </Col>
  );
};

export default PricingCard;
