import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getEntities as getFolders} from 'app/entities/folder/folder.reducer';
import {getEntities as getProducts} from 'app/entities/product/product.reducer';
import {getUsers} from 'app/modules/administration/user-management/user-management.reducer';
import {IRootState} from 'app/shared/reducers';
import {AvFeedback, AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import MD5 from 'crypto-js/md5';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {createEntity, getEntity, reset, updateEntity} from './user-configuration.reducer';

export interface IUserConfigurationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const UserConfigurationUpdate = (props: IUserConfigurationUpdateProps) => {
    const [userId, setUserId] = useState('0');
    const [productId, setProductId] = useState('0');
    const [folderId, setFolderId] = useState('0');
    const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

    const {userConfigurationEntity, users, products, folders, loading, updating} = props;

    const handleClose = () => {
        props.history.push('/user-configuration' + props.location.search);
    };

    useEffect(() => {
        if (isNew) {
            props.reset();
        } else {
            props.getEntity(props.match.params.id);
        }

        // props.getUsers();
        // props.getProducts();
        props.getFolders();
    }, []);

    useEffect(() => {
        if (props.updateSuccess) {
            handleClose();
        }
    }, [props.updateSuccess]);

    const saveEntity = (event, errors, values) => {
        if (errors.length === 0) {
            const entity = {
                ...userConfigurationEntity,
                ...values,
                userHash: MD5(props.account.email).toString(),
            };

            if (isNew) {
                props.createEntity(entity);
            } else {
                props.updateEntity(entity);
            }
        }
    };

    return (
        <div>
            <Row className="justify-content-center">
                <Col md="8">
                    <h2 id="familyBuilderApiApp.userConfiguration.home.createOrEditLabel">Create or edit a User
                        Configuration</h2>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md="8">
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <AvForm model={isNew ? {} : userConfigurationEntity} onSubmit={saveEntity}>
                            {!isNew ? (
                                <AvGroup>
                                    <Label for="user-configuration-id">ID</Label>
                                    <AvInput id="user-configuration-id" type="text" className="form-control" name="id"
                                             required readOnly/>
                                </AvGroup>
                            ) : null}
                            <AvGroup check>
                                <Label id="visibleLabel">
                                    <AvInput id="user-configuration-visible" type="checkbox"
                                             className="checkbox-form-control" name="visible"/>
                                    Visible
                                </Label>
                            </AvGroup>
                            <AvGroup>
                                <Label id="modelNumberLabel" for="user-configuration-modelNumber">
                                    Model Number
                                </Label>
                                <AvField
                                    id="user-configuration-modelNumber"
                                    type="text"
                                    name="modelNumber"
                                    validate={{
                                        required: {value: true, errorMessage: 'This field is required.'},
                                    }}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label id="descriptionLabel" for="user-configuration-description">
                                    Description
                                </Label>
                                <AvField
                                    id="user-configuration-description"
                                    type="text"
                                    name="description"
                                    validate={{
                                        required: {value: true, errorMessage: 'This field is required.'},
                                    }}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label id="thumbnailImageLabel" for="user-configuration-thumbnailImage">
                                    Thumbnail Image
                                </Label>
                                <AvField id="user-configuration-thumbnailImage" type="text" name="thumbnailImage"/>
                            </AvGroup>
                            <AvGroup>
                                <Label id="configSizeLabel" for="user-configuration-configSize">
                                    Config Size
                                </Label>
                                <AvField id="user-configuration-configSize" type="text" name="configSize"/>
                            </AvGroup>
                            <AvGroup>
                                <Label id="configSizeInchLabel" for="user-configuration-configSizeInch">
                                    Config Size Inch
                                </Label>
                                <AvField id="user-configuration-configSizeInch" type="text" name="configSizeInch"/>
                            </AvGroup>
                            <AvGroup>
                                <Label id="cutsheetUrlLabel" for="user-configuration-cutsheetUrl">
                                    Cutsheet Url
                                </Label>
                                <AvField id="user-configuration-cutsheetUrl" type="text" name="cutsheetUrl"/>
                            </AvGroup>
                            <AvGroup>
                                <Label id="typenameLabel" for="user-configuration-typename">
                                    Typename
                                </Label>
                                <AvField id="user-configuration-typename" type="text" name="typename"/>
                            </AvGroup>
                            <AvGroup>
                                <Label id="typecatalogheaderLabel" for="user-configuration-typecatalogheader">
                                    Typecatalogheader
                                </Label>
                                <AvField id="user-configuration-typecatalogheader" type="text"
                                         name="typecatalogheader"/>
                            </AvGroup>
                            <AvGroup>
                                <Label id="typecatalogLabel" for="user-configuration-typecatalog">
                                    Typecatalog
                                </Label>
                                <AvField id="user-configuration-typecatalog" type="text" name="typecatalog"/>
                            </AvGroup>

                            <AvGroup>
                                <Label id="baseFamilyLabel" for="user-configuration-baseFamily">
                                    Base Family
                                </Label>
                                <AvField readOnly id="user-configuration-baseFamily" type="text" name="baseFamily"/>
                            </AvGroup>
                            {/*<AvGroup>
                <Label for="user-configuration-user">User</Label>
                <AvInput id="user-configuration-user" type="select" className="form-control" name="userId" required>
                  {users
                    ? users.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.login}
                        </option>
                      ))
                    : null}
                </AvInput>
                <AvFeedback>This field is required.</AvFeedback>
              </AvGroup>*/}
                            <AvGroup>
                                <Label for="user-configuration-folder">Folder</Label>
                                <AvInput id="user-configuration-folder" type="select" className="form-control"
                                         name="folderId" required>
                                    <option value={''} disabled key={0}>
                                        Select Folder
                                    </option>
                                    {folders
                                        ? folders.map(otherEntity => (
                                            <option value={otherEntity.id} key={otherEntity.id + 1}>
                                                {otherEntity.name}
                                            </option>
                                        ))
                                        : null}
                                </AvInput>
                                <AvFeedback>This field is required.</AvFeedback>
                            </AvGroup>
                            <Button tag={Link} id="cancel-save" to="/user-configuration" replace color="info">
                                <FontAwesomeIcon icon="arrow-left"/>
                                &nbsp;
                                <span className="d-none d-md-inline">Back</span>
                            </Button>
                            &nbsp;
                            <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                                <FontAwesomeIcon icon="save"/>
                                &nbsp; Save
                            </Button>
                        </AvForm>
                    )}
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    account: storeState.authentication.account,
    users: storeState.userManagement.users,
    products: storeState.product.entities,
    folders: storeState.folder.entities,
    userConfigurationEntity: storeState.userConfiguration.entity,
    loading: storeState.userConfiguration.loading,
    updating: storeState.userConfiguration.updating,
    updateSuccess: storeState.userConfiguration.updateSuccess,
});

const mapDispatchToProps = {
    getUsers,
    getProducts,
    getFolders,
    getEntity,
    updateEntity,
    createEntity,
    reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserConfigurationUpdate);
