import React, { memo } from 'react';
import ReactChipInput from 'react-chip-input';
import './emailSettingsProject.scss';
import { Input } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';

interface IEmailSettingProject extends StateProps, DispatchProps {
  item: any;
  updateCcEmails: Function;
  index: Number;
  updateProjectData: Function;
}

const EmailSettingsProject = memo((props: IEmailSettingProject) => {
  const emailValidation = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

  const addEmail = (newEmail: string) => {
    if (newEmail.match(emailValidation)) {
      props.updateCcEmails({
        ...props.item,
        ccEmails: props.item.ccEmails ? (props.item.ccEmails += `,${newEmail}`) : (props.item.ccEmails += newEmail),
      });
    }
  };

  const deleteEmail = index => {
    const ccEmails = [...props.item.ccEmails.split(',')];
    ccEmails.splice(index, 1);
    props.updateCcEmails({ ...props.item, ccEmails: ccEmails.join(',') });
  };

  return (
    <div className="email-settings-project-container">
      <Input
        value={props.item.type}
        type="select"
        className="email-settings-project-select type"
        onChange={event => props.updateProjectData('type', props.index, event.target.value)}
      >
        <option value={'PROJECT'}>Project</option>
        <option value={'CONFIGURATION'}>Configuration</option>
      </Input>

      <Input
        value={props.item.id}
        type="select"
        className="email-settings-project-select name"
        onChange={event => props.updateProjectData('id', props.index, event.target.value)}
      >
        {props.item.type === 'PROJECT' || props.item.type === '' ? (
          <>
            <option value={null}></option>
            {props.projects.map(item => (
              <option value={item.id}>{item.projectName}</option>
            ))}
          </>
        ) : (
          <>
            <option value={null}></option>
            {props.productList.map(item => (
              <option value={item.id}>{item.name}</option>
            ))}
          </>
        )}
      </Input>

      <ReactChipInput
        classes="email-settings-email-input"
        chips={props.item.ccEmails ? props.item.ccEmails.split(',') : []}
        onSubmit={value => addEmail(value)}
        onRemove={index => deleteEmail(index)}
      />
    </div>
  );
});

const mapStateToProps = ({ project, userConfiguration, authentication, roomleProduct }: IRootState) => ({
  projects: project.entities,
  productList: roomleProduct.entities,
  updating: userConfiguration.updating,
  updateSuccess: project.updateSuccess,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmailSettingsProject);
