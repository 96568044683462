export const saveConfiguration = (roomleConfiguration, productEntity, mailsha1, isSavingToInventory?) => {
  if (roomleConfiguration === null) return;

  const parsedJson = JSON.parse(roomleConfiguration.configuration);
  const partsJson = JSON.parse(roomleConfiguration.parts);

  const childrenElements = parsedJson.children;
  const components = [];
  if (childrenElements != undefined) {
    childrenElements.map(element => {
      const positionString: string = element.dockPosition;
      let position =
        positionString &&
        positionString
          .replace('{', '')
          .replace('}', '')
          .split(',')
          .map(p => {
            return parseFloat(p);
          });

      if (position.length !== 3) {
        position = [0.0, 0.0, 0.0];
      }

      components.push({
        offsetX: position[0],
        offsetY: position[1],
        offsetZ: position[2],
        rotationAngle: 0,
        baseFamily: element?.parameters?.baseFamily || '',
        typecatalog: element?.parameters?.typecatalog || '',
        typecatalogheader: element?.parameters?.typecatalogheader || '',
      });
    });
  }

  const composite = {
    components,
  };

  const configSize =
    parsedJson.parameters && parsedJson.parameters.width && parsedJson.parameters.length
      ? `${parsedJson.parameters.width}mm x ${parsedJson.parameters.length}mm`
      : '';
  const configSizeInch =
    parsedJson.parameters && parsedJson.parameters.width && parsedJson.parameters.length
      ? `${(parsedJson.parameters.width / 25.4).toFixed(2)}" x ${(parsedJson.parameters.height / 25.4).toFixed(2)}"`
      : '';
  const typecatalog = parsedJson.parameters ? parsedJson.parameters.typecatalog : '';
  const typecatalogheader = parsedJson.parameters ? parsedJson.parameters.typecatalogheader : '';
  const baseFamily = parsedJson.parameters ? parsedJson.parameters.baseFamily : '';
  const parameters = parsedJson.parameters ? `${parsedJson.parameters.parameterlabel}\n${parsedJson.parameters.parameterlabelvalue}` : '';

  const newEntity = {
    visible: true,
    description: productEntity?.description || '',
    modelNumber: isSavingToInventory
      ? partsJson?.originPart?.articleNr
      : partsJson?.originPart?.articleNr || partsJson.perMainComponent[0].originPart.articleNr,
    thumbnailImage: roomleConfiguration?.perspectiveImage || roomleConfiguration?.data?.perspectiveImage,
    configSize,
    configSizeInch,
    typename: '',
    userHash: mailsha1,
    productId: productEntity?.id || null,
    typecatalog,
    typecatalogheader,
    baseFamily,
    configuration: roomleConfiguration?.configurationHash,
    parameters,
    parameterValues: null,
    composite,
    roomleComponentId: parsedJson?.componentId,
    sectionPrefix: parsedJson?.parameters?.sectionPrefix,
  };

  return newEntity;
};
