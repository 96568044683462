import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IProject } from 'app/shared/model/project.model';

export const ACTION_TYPES = {
  EXPORT_PROJECT: 'project/EXPORT_PROJECT',
  FETCH_PROJECT_LIST: 'project/FETCH_PROJECT_LIST',
  FETCH_PROJECT: 'project/FETCH_PROJECT',
  CREATE_PROJECT: 'project/CREATE_PROJECT',
  UPDATE_PROJECT: 'project/UPDATE_PROJECT',
  DELETE_PROJECT: 'project/DELETE_PROJECT',
  RESET: 'project/RESET',
  RESET_CURRENT_PROJECT: 'RESET_CURRENT_PROJECT',
  SHARE_PROJECT: 'SHARE_PROJECT',
  CREATE_SHOP_DRAWINGS: 'CREATE_SHOP_DRAWINGS',
  SET_NEW_PROJECT_NAME: 'SET_NEW_PROJECT_NAME',
  SET_IS_PROJECT_SHOULD_BE_CLOSED: 'SET_IS_PROJECT_SHOULD_BE_CLOSED',
  SET_FLOORPLAN_ID_FOR_DIRECT_ADDING: 'SET_FLOORPLAN_ID_FOR_DIRECT_ADDING',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IProject>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  newProjectName: '',
  isProjectShouldBeClosed: true,
  loadingProjectsList: true,
  floorplanIdForDirectAdding: null,
};

export type ProjectState = Readonly<typeof initialState>;

// Reducer

export default (state: ProjectState = initialState, action): ProjectState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PROJECT_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadingProjectsList: true,
      };
    case REQUEST(ACTION_TYPES.EXPORT_PROJECT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_PROJECT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PROJECT):
    case REQUEST(ACTION_TYPES.UPDATE_PROJECT):
    case REQUEST(ACTION_TYPES.DELETE_PROJECT):
    case REQUEST(ACTION_TYPES.CREATE_SHOP_DRAWINGS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PROJECT_LIST):
      return {
        ...state,
        loadingProjectsList: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.FETCH_PROJECT):
    case FAILURE(ACTION_TYPES.EXPORT_PROJECT):
    case FAILURE(ACTION_TYPES.CREATE_PROJECT):
    case FAILURE(ACTION_TYPES.UPDATE_PROJECT):
    case FAILURE(ACTION_TYPES.DELETE_PROJECT):
    case FAILURE(ACTION_TYPES.CREATE_SHOP_DRAWINGS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PROJECT_LIST):
      return {
        ...state,
        loadingProjectsList: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_PROJECT):
    case SUCCESS(ACTION_TYPES.EXPORT_PROJECT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PROJECT):
    case SUCCESS(ACTION_TYPES.UPDATE_PROJECT):
      sessionStorage.setItem('currentProject', action.payload.data.id);
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SHOP_DRAWINGS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PROJECT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
        newProjectName: state.newProjectName,
      };
    case ACTION_TYPES.RESET_CURRENT_PROJECT:
      sessionStorage.removeItem('currentProject');
      return {
        ...state,
        entity: {},
      };
    case ACTION_TYPES.SET_IS_PROJECT_SHOULD_BE_CLOSED:
      return {
        ...state,
        isProjectShouldBeClosed: action.payload,
      };
    case ACTION_TYPES.SET_NEW_PROJECT_NAME:
      return {
        ...state,
        newProjectName: action.payload,
      };
    case ACTION_TYPES.SET_FLOORPLAN_ID_FOR_DIRECT_ADDING:
      return {
        ...state,
        floorplanIdForDirectAdding: action.payload,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/projects';

// Actions

export const getEntities: ICrudGetAllAction<IProject> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_PROJECT_LIST,
    payload: axios.get<IProject>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

// @ts-ignore
export const getAllProjectByLoginUser: ICrudGetAllAction<IProject> = (page, size, sort, search) => {
  const requestUrl = `${apiUrl}/by-login-user${sort ? `?page=${page}&size=${size}&sort=${sort}&search=${search}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_PROJECT_LIST,
    payload: axios.get<IProject>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getAllProjectSummary: ICrudGetAllAction<IProject> = () => {
  const requestUrl = `${apiUrl}/project-summary`;
  return {
    type: ACTION_TYPES.FETCH_PROJECT_LIST,
    payload: axios.get<IProject>(`${requestUrl}`),
  };
};

export const getProject: ICrudGetAction<IProject> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PROJECT,
    payload: axios.get<IProject>(requestUrl),
  };
};

export const exportProjects = () => {
  const requestUrl = `${apiUrl}/export-project-data`;
  axios
    .get(requestUrl)
    .then(response => {
      // Create a URL for the blob object
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create an anchor element and trigger a download
      const link = document.createElement('a');
      link.href = url;

      // Set the downloaded file's name (optional)
      link.setAttribute('download', 'Projects-Export.csv');

      // Append the anchor to the document and trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the anchor element
      link.parentNode.removeChild(link);
    })
    .catch(error => {
      console.error('Error downloading the CSV file', error);
    });
};

export const forgeConnect = (id, drawingType) => {
  const requestUrl = `${apiUrl}/forge-connect/${id}/${drawingType}/kreatorWeb`;
  return {
    type: ACTION_TYPES.CREATE_SHOP_DRAWINGS,
    payload: axios.post(requestUrl),
  };
};

export const createProject: ICrudPutAction<IProject> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_PROJECT,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getAllProjectByLoginUser());
  return result;
};

export const updateProject: ICrudPutAction<IProject> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PROJECT,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  dispatch(getAllProjectByLoginUser());
  return result;
};

export const updateProjectDetails = data => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PROJECT,
    payload: axios.put('api/projects-update', data, { params: { requestOrigin: 'kreatorWeb' } }),
  });
  dispatch(getAllProjectByLoginUser());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IProject> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PROJECT,
    payload: axios.delete(requestUrl),
  });
  dispatch(getAllProjectByLoginUser());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const resetCurrentProject = () => ({
  type: ACTION_TYPES.RESET_CURRENT_PROJECT,
});

export const setIsCurrentProjectShouldBeClosed = value => ({
  type: ACTION_TYPES.SET_IS_PROJECT_SHOULD_BE_CLOSED,
  payload: value,
});

export const setNewProjectName = newProjectName => ({
  type: ACTION_TYPES.SET_NEW_PROJECT_NAME,
  payload: newProjectName,
});

export const shareProject = data => {
  const apiUrl = 'api/share/share-project';
  return {
    type: ACTION_TYPES.SHARE_PROJECT,
    payload: axios.post(apiUrl, data),
  };
};

export const setFloorPlanIdForDirectAdding = floorplanId => ({
  type: ACTION_TYPES.SET_FLOORPLAN_ID_FOR_DIRECT_ADDING,
  payload: floorplanId,
});
