import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './consultant.reducer';
import { IConsultant } from 'app/shared/model/consultant.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IConsultantDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ConsultantDetail extends React.Component<IConsultantDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { consultantEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            Consultant [<b>{consultantEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="name">Name</span>
            </dt>
            <dd>{consultantEntity.name}</dd>
            <dt>
              <span id="enabled">Enabled</span>
            </dt>
            <dd>{consultantEntity.enabled ? 'true' : 'false'}</dd>
            <dt>
              <span id="logo">Logo</span>
            </dt>
            <dd>{consultantEntity.logo}</dd>
            <dt>
              <span id="websiteURL">Website URL</span>
            </dt>
            <dd>{consultantEntity.websiteURL}</dd>
            <dt>
              <span id="city">City</span>
            </dt>
            <dd>{consultantEntity.city}</dd>
            <dt>
              <span id="state">State</span>
            </dt>
            <dd>{consultantEntity.state}</dd>
            <dt>
              <span id="businessName">Business Name</span>
            </dt>
            <dd>{consultantEntity.businessName}</dd>
            <dt>
              <span id="skills">Skills</span>
            </dt>
            <dd>{consultantEntity.skills}</dd>
            <dt>
              <span id="qualifySpecialStatus">Qualify Special Status</span>
            </dt>
            <dd>{consultantEntity.qualifySpecialStatus}</dd>
            <dt>
              <span id="cardNumber">Card Number</span>
            </dt>
            <dd>{consultantEntity.cardNumber}</dd>
            <dt>
              <span id="cardHolder">Card Holder</span>
            </dt>
            <dd>{consultantEntity.cardHolder}</dd>
            <dt>
              <span id="customerId">Customer Id</span>
            </dt>
            <dd>{consultantEntity.customerId}</dd>
            <dt>
              <span id="month">Month</span>
            </dt>
            <dd>{consultantEntity.month}</dd>
            <dt>
              <span id="year">Year</span>
            </dt>
            <dd>{consultantEntity.year}</dd>
            <dt>
              <span id="cvc">Cvc</span>
            </dt>
            <dd>{consultantEntity.cvc}</dd>
            <dt>
              <span id="status">Status</span>
            </dt>
            <dd>{consultantEntity.status}</dd>
            <dt>
              <span id="refConsultantId">Ref Consultant Id</span>
            </dt>
            <dd>{consultantEntity.refConsultantId}</dd>
            <dt>
              <span id="subscriptionId">Subscription Id</span>
            </dt>
            <dd>{consultantEntity.subscriptionId}</dd>
            <dt>
              <span id="payPerUsagesSubscriptionId">Pay Per Usages Subscription Id</span>
            </dt>
            <dd>{consultantEntity.payPerUsagesSubscriptionId}</dd>
            <dt>User</dt>
            <dd>{consultantEntity.userLogin ? consultantEntity.userLogin : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/consultant" replace color="info">
            <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/consultant/${consultantEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ consultant }: IRootState) => ({
  consultantEntity: consultant.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultantDetail);
