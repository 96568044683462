import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Leads from './leads';
import LeadsDetail from './leads-detail';
import LeadsUpdate from './leads-update';
import LeadsDeleteDialog from './leads-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={LeadsUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={LeadsUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={LeadsDetail} />
      <ErrorBoundaryRoute path={match.url} component={Leads} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={LeadsDeleteDialog} />
  </>
);

export default Routes;
