import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './user-settings.reducer';
import { IUserSettings } from 'app/shared/model/user-settings.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IUserSettingsDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class UserSettingsDetail extends React.Component<IUserSettingsDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { userSettingsEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            UserSettings [<b>{userSettingsEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="generalNotification">General Notification</span>
            </dt>
            <dd>{userSettingsEntity.generalNotification ? 'true' : 'false'}</dd>
            <dt>
              <span id="marketPlaceNotification">Market Place Notification</span>
            </dt>
            <dd>{userSettingsEntity.marketPlaceNotification ? 'true' : 'false'}</dd>
            <dt>
              <span id="hideManufacturers">Hide Manufacturers</span>
            </dt>
            <dd>{userSettingsEntity.hideManufacturers}</dd>
            <dt>
              <span id="projectStartWith">Project Start With</span>
            </dt>
            <dd>{userSettingsEntity.projectStartWith ? 'true' : 'false'}</dd>
            <dt>
              <span id="drawnBy">Drawn By</span>
            </dt>
            <dd>{userSettingsEntity.drawnBy}</dd>
            <dt>
              <span id="onBoardingSeen">On Boarding Seen</span>
            </dt>
            <dd>{userSettingsEntity.onBoardingSeen ? 'true' : 'false'}</dd>
            <dt>User</dt>
            <dd>{userSettingsEntity.user ? userSettingsEntity.user.login : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/user-settings" replace color="info">
            <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/user-settings/${userSettingsEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ userSettings }: IRootState) => ({
  userSettingsEntity: userSettings.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSettingsDetail);
