import { useState, useEffect } from 'react';
import { IProjectProduct } from 'app/shared/model/project-product.model';

export const usePartsList = (projectProducts: IProjectProduct[]) => {
  const [isDetailsOpen, setisDetailsOpen] = useState(false);
  const [projectProductsPartsList, setProjectProductsPartsList] = useState([]);
  const [partsListLoading, setPartlistLoading] = useState(false);

  useEffect(() => {
    if (isDetailsOpen && !projectProductsPartsList.length && projectProducts) {
      setPartlistLoading(true);
      Promise.all(projectProducts.map(item => getPartsList(item.userConfiguration?.configuration)))
        .then(resolvedValues => {
          resolvedValues.forEach(value => {
            const data = JSON.parse(value.configuration.parts);
            const fullList = data.fullList.sort(function (x, y) {
              return x.label == data.originPart.label ? -1 : y.label == data.originPart.label ? 1 : 0;
            });
            setProjectProductsPartsList(oldValue => [...oldValue, { configurationTitle: data.originPart.label, partsList: fullList }]);
          });
          setPartlistLoading(false);
        })
        .catch(error => {
          setPartlistLoading(false);
        });
    }
  }, [isDetailsOpen]);

  const getPartsList = async configurationId => {
    return fetch(`https://api.roomle.com/v2/configurations/${configurationId}`).then(response => response.json());
  };

  return { isDetailsOpen, setisDetailsOpen, projectProductsPartsList, partsListLoading };
};
