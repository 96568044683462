import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, FormGroup, Form, Label, Input } from 'reactstrap';
import { setFileData } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { Field, Form as ReactFinalForm } from 'react-final-form';

import { createEntity, getEntity, reset, setBlob, updateEntity } from './version.reducer';
import VersionFileUpload from 'app/entities/version/VersionFileUpload';

export interface IVersionUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const VersionUpdate = (props: IVersionUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const [fileName, setFileName] = useState(null);
  const [fileHasChanged, setFileHasChanged] = useState(false);

  const { versionEntity, loading, updating } = props;

  const { file, fileContentType } = versionEntity;

  const handleClose = () => {
    props.history.push('/version' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  const onBlobChange = (isAnImage, name) => event => {
    setFileData(event, (contentType, data) => props.setBlob(name, data, contentType), isAnImage);
    setFileName(event.target.files[0].name);
    setFileHasChanged(true);
  };

  const clearBlob = name => () => {
    props.setBlob(name, undefined, undefined);
  };

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...versionEntity,
      ...values,
    };

    if (isNew) {
      props.createEntity(entity);
    } else {
      props.updateEntity(entity);
    }
  };
  const identity = value => value;
  const required = value => (value ? undefined : <small color="danger">This field is required</small>);

  // @ts-ignore
  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="familyBuilderApiApp.version.home.createOrEditLabel">Create or edit a Version</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              <ReactFinalForm
                initialValues={versionEntity}
                onSubmit={saveEntity}
                validate={values => {
                  const errors = {};

                  /*if (values.releaseNotes >= 100) {
                    errors.releaseNotes = 'This field cannot be longer than 1000 characters.';
                  }
                  if (values.name.match("'^(\\\\d+\\\\.)?(\\\\d+\\\\.)?(\\\\d+\\\\.)?(\\\\*|\\\\d+)$'")) {
                    errors.name = "Invalid format. You must follow pattern (example: 2.4.1.11)";
                  }*/

                  return errors;
                }}
                render={({ handleSubmit, form, values }) => {
                  return (
                    <Form noValidate onSubmit={handleSubmit}>
                      <FormGroup className="mb-3">
                        <Field
                          render={({ input, meta }) => (
                            <div className={'w-100'}>
                              <Label for="exampleEmail">Type</Label>
                              <Input {...input} type="select">
                                <option value="">Select Type</option>
                                <option value="KREATOR">KREATOR</option>
                              </Input>
                              {meta.touched && meta.error && <span>{meta.error}</span>}
                            </div>
                          )}
                          parse={identity}
                          placeholder={''}
                          name="type"
                          validate={required}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <Field
                          render={({ input, meta }) => (
                            <div className={'w-100'}>
                              <Label for="exampleEmail">Name</Label>
                              <Input {...input} />
                              {meta.touched && meta.error && <span>{meta.error}</span>}
                            </div>
                          )}
                          parse={identity}
                          placeholder={''}
                          name="name"
                          validate={required}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <Field
                          render={({ input, meta }) => (
                            <div className={'w-100'}>
                              <Label for="exampleEmail">Version Number</Label>
                              <Input {...input} />
                              {meta.touched && meta.error && <span>{meta.error}</span>}
                            </div>
                          )}
                          parse={identity}
                          placeholder={''}
                          name="versionNumber"
                          validate={required}
                        />
                      </FormGroup>
                      <span>Release Notes</span>
                      <FormGroup className="mb-3 ">
                        <Field
                          // render={({ input, meta }) => (
                          //   <div className={'w-100'}>
                          //     <Label for="exampleEmail">Release Notes</Label>
                          //     <Input {...input} />
                          //     {meta.touched && meta.error && <span>{meta.error}</span>}
                          //   </div>
                          // )}
                          parse={identity}
                          placeholder={''}
                          name="releaseNotes"
                          validate={required}
                          component="textarea"
                          style={{ width: '100%' }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <Field
                          render={({ input, meta }) => (
                            <div className={'w-100'}>
                              <Label for="exampleEmail">Release Date</Label>
                              <Input type={'date'} {...input} />
                              {meta.touched && meta.error && <span>{meta.error}</span>}
                            </div>
                          )}
                          parse={identity}
                          placeholder={''}
                          name="releaseDate"
                          validate={required}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <Field
                          render={({ input, meta }) => (
                            <div className={'w-100'}>
                              <Label for="exampleEmail">Release Type</Label>
                              <Input {...input} type="select">
                                <option value="">Select Release Type</option>
                                <option value="CURRENT_VERSION">CURRENT_VERSION</option>
                                <option value="PAST_VERSION">PAST_VERSION</option>
                              </Input>
                              {meta.touched && meta.error && <span>{meta.error}</span>}
                            </div>
                          )}
                          parse={identity}
                          placeholder={''}
                          name="releaseType"
                          validate={required}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <Field
                          render={({ input, meta }) => (
                            <div className={'w-100'}>
                              <Label for="exampleEmail">File</Label>
                              <VersionFileUpload version={values} setOutput={data => input.onChange(data)} />
                              {meta.touched && meta.error && <span>{meta.error}</span>}
                            </div>
                          )}
                          parse={identity}
                          placeholder={''}
                          name="filePath"
                          validate={required}
                        />
                      </FormGroup>
                      <Button tag={Link} id="cancel-save" to="/version" replace color="info">
                        <FontAwesomeIcon icon="arrow-left" />
                        &nbsp;
                        <span className="d-none d-md-inline">Back</span>
                      </Button>
                      &nbsp;
                      <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                        <FontAwesomeIcon icon="save" />
                        &nbsp; Save
                      </Button>
                    </Form>
                  );
                }}
              />
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  versionEntity: storeState.version.entity,
  loading: storeState.version.loading,
  updating: storeState.version.updating,
  updateSuccess: storeState.version.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VersionUpdate);
