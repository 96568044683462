import React, { useEffect, useState } from 'react';
import BusinessApprovalTable from '../../components/BusinessApprovalTable/BusinessApproval';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { JhiPagination } from 'react-jhipster';
import { getBusinessApprovals } from 'app/entities/consultant/consultant.reducer';
import AppSpinner from 'app/components/Spinner/Spinner';
import NoDataBanner from 'app/components/NoDataBanner/NoDataBanner';

export const ConsultantBusinessApprovals = props => {
  const [activePage, setActivePage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const getAllEntities = () => {
    props.getBusinessApprovals(activePage - 1, itemsPerPage);
  };

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    sortEntities();
  }, [activePage]);

  const handlePagination = currentPage => setActivePage(currentPage);
  const { consultantList, totalItems } = props;

  return (
    <>
      <div className="mt-5"></div>
      {props.loading && <AppSpinner />}
      {!props.loading && consultantList && consultantList.length > 0 && (
        <BusinessApprovalTable members={consultantList} type={'consultant'} />
      )}
      {!props.loading && consultantList && consultantList.length > 0 && (
        <>
          {totalItems ? (
            <div className={consultantList && consultantList.length > itemsPerPage ? 'pagination-container' : 'd-none'}>
              <Row className="justify-content-center">
                <JhiPagination
                  activePage={activePage}
                  onSelect={handlePagination}
                  maxButtons={5}
                  itemsPerPage={itemsPerPage}
                  totalItems={props.totalItems}
                />
              </Row>
            </div>
          ) : (
            ''
          )}
        </>
      )}
      {!props.loading && consultantList && consultantList.length === 0 && (
        <NoDataBanner title="You have no Business approvals to display yet!" />
      )}
    </>
  );
};

const mapStateToProps = ({ consultant, authentication }: IRootState) => ({
  consultantList: consultant.entities,
  loading: consultant.loading,
  totalItems: consultant.totalItems,
  account: authentication.account,
});

const mapDispatchToProps = {
  getBusinessApprovals,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantBusinessApprovals);
