import React from 'react';

interface IEnableProductButton {
  onClick: any;
}

const EnableProductButton = (props: IEnableProductButton) => {
  return (
    <button onClick={props.onClick} className="enable-product-button">
      Enable
    </button>
  );
};

export default EnableProductButton;
