import React from 'react';
import './divider.scss';

interface DividerProps {
  length: string;
}

const Divider: React.FC<DividerProps> = ({ length }) => {
  return <div style={{ width: length, height: '1px', backgroundColor: '#E1E4EA' }} />;
};

export default Divider;
