import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';

export const ACTION_TYPES = {
  CREATE_ACCOUNT: 'register/CREATE_ACCOUNT',
  RESET: 'register/RESET',
  SET_IS_DEALER_ACCOUNT_SETUP_BANNER_SHOWN: 'SET_IS_DEALER_ACCOUNT_SETUP_BANNER_SHOWN',
  SET_IS_MANUFACTURER_ACCOUNT_SETUP_BANNER_SHOWN: 'SET_IS_MANUFACTURER_ACCOUNT_SETUP_BANNER_SHOWN',
  SET_IS_LOGIN_BUTTON_WAS_PRESSED: 'SET_IS_LOGIN_BUTTON_WAS_PRESSED',
};

const initialState = {
  loading: false,
  registrationSuccess: false,
  registrationFailure: false,
  errorMessage: null,
  isDealerAccountSetupBannerShown: true,
  isManufacturerAccountSetupBannerShown: true,
  isLoginButtonWasPressed: false,
};

export type RegisterState = Readonly<typeof initialState>;

// Reducer
export default (state: RegisterState = initialState, action): RegisterState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_ACCOUNT):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.CREATE_ACCOUNT):
      return {
        ...initialState,
        registrationFailure: true,
        errorMessage: action.payload.response.data.errorKey,
      };
    case SUCCESS(ACTION_TYPES.CREATE_ACCOUNT):
      return {
        ...initialState,
        registrationSuccess: true,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.SET_IS_DEALER_ACCOUNT_SETUP_BANNER_SHOWN:
      return {
        ...initialState,
        isDealerAccountSetupBannerShown: action.payload.data,
      };
    case ACTION_TYPES.SET_IS_MANUFACTURER_ACCOUNT_SETUP_BANNER_SHOWN:
      return {
        ...initialState,
        isManufacturerAccountSetupBannerShown: action.payload.data,
      };
    case ACTION_TYPES.SET_IS_LOGIN_BUTTON_WAS_PRESSED:
      return {
        ...state,
        isLoginButtonWasPressed: action.payload,
      };
    default:
      return state;
  }
};

// Actions
export const handleRegister = (
  email,
  password,
  firstName,
  lastName,
  company,
  langKey = 'en',
  type,
  zipCode,
  userCompanyId,
  industry,
  outsideUsa,
  country,
  accountType
) => ({
  type: ACTION_TYPES.CREATE_ACCOUNT,
  payload: axios.post('api/register', {
    login: email,
    email,
    password,
    langKey,
    firstName,
    lastName,
    company,
    type,
    zipCode,
    specPathUserId: userCompanyId,
    industry,
    outsideUsa,
    country,
    accountType,
  }),
  meta: {
    successMessage: 'Registration saved! Please check your email for confirmation.',
  },
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const setIsDealerAccountBannerShown = isBannerShown => ({
  type: ACTION_TYPES.SET_IS_DEALER_ACCOUNT_SETUP_BANNER_SHOWN,
  payload: isBannerShown,
});

export const setIsManufacturerAccountBannerShown = isBannerShown => ({
  type: ACTION_TYPES.SET_IS_MANUFACTURER_ACCOUNT_SETUP_BANNER_SHOWN,
  payload: isBannerShown,
});

export const setIsLoginButtonWasPressed = isLoginButtonWasPressed => ({
  type: ACTION_TYPES.SET_IS_LOGIN_BUTTON_WAS_PRESSED,
  payload: isLoginButtonWasPressed,
});
