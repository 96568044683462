import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';

interface IMoreActionsDropdown {
  reportUrl: string;
  externalFileUrl: string;
}

const MoreActionsDropdown = (props: IMoreActionsDropdown) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <Dropdown isOpen={isOpen} onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      <DropdownToggle className="project-submission-table-more-actions-button">
        <img src="../../../content/images/icons/more_icon.svg" alt="more actions icon" />
      </DropdownToggle>
      <DropdownMenu className="project-submission-table-more-actions-dropdown">
        <DropdownItem className="project-submission-table-more-actions-item" onClick={() => window.open(props.externalFileUrl, '_blank')}>
          <div className="project-submission-table-more-actions-item-dot"></div> File Uploads
        </DropdownItem>
        <DropdownItem className="project-submission-table-more-actions-item" onClick={() => window.open(props.reportUrl, '_blank')}>
          <div className="project-submission-table-more-actions-item-dot"></div> Report
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default MoreActionsDropdown;
