import { height } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import React from 'react';

const Maintenance = () => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 50, paddingBottom: 50, background: '#f8f8f8' }}
    >
      <img src="../../../content/images/Kitchautomation-color-01-300x214.jpg" alt="" />
      <div style={{ width: '80%' }}>
        <h6>Maintenance Terms and Conditions:</h6>

        <p>
          1. Term and Termination – This Agreement shall be effective as of the time frame Customer signs up for Maintenance Services. This
          Agreement may be terminated by either party upon written notice to the other, if the other party breaches any material obligation
          provided hereunder and the breaching party fails to cure such breach within thirty (30) days of receipt of the notice. This
          Agreement may be terminated by Kitchautomation, LLC (i) immediately if Customer fails to pay any fees hereunder; or (ii) if
          Customer fails to cooperate with Kitchautomation, LLC or hinders Kitchautomation, LLC’s ability to perform the Maintenance
          Services hereunder.
        </p>
        <p>
          2. Fees; Cancellation; Limitations on Refunds and Cancellation Fees – Customer agrees to pay Kitchautomation, LLC any and all
          fee(s) as billed in accordance with this Agreement. The fee(s) must be received prior to the start of any Maintenance Services.
          Customer has the right to cancel this agreement with thirty (30) days written notice. THE CUSTOMER FURTHER AGREES THAT, IN THE
          EVENT OF ANY TERMINATION OF THIS AGREEMENT BY CUSTOMER, NO REFUNDS SHALL BE GIVEN UNDER ANY CIRCUMSTANCES WHATSOEVER. THE CUSTOMER
          FURTHER AGREES TO PAY UPON CANCELLATION THE AMOUNT OF ANY CANCELLATION FEES OR OTHER AMOUNTS DUE TO KITCHAUTOMATION, LLC AS
          PROVIDED IN THE AGREEMENT.
        </p>
        <p>
          3. Customer Responsibilities – For the purposes of providing these services, Customer agrees: o To provide Kitchautomation, LLC
          with access to its web sites for creating new pages, and making changes for the purpose of providing Maintenance Services. o To
          properly convey to Kitchautomation, LLC the information that needs to be changed or added.
        </p>
        <p>
          4. Customer Acknowledgements – Customer understands, acknowledges, and agrees that: o Unused time is not accumulative. Unused time
          does not transfer from month to month. Maintenance Services time is strictly month to month. o Kitchautomation, LLC is not
          responsible for changes made to Customer’s web site(s) by other parties. o Kitchautomation, LLC is not responsible for changes
          made inside of the Customer’s Roomle admin account interface by other parties.
        </p>
        <p>
          5. Additional Services – Services not considered 'standard configurator maintenance' shall be considered "additional" and will
          require separate agreement and payment.{' '}
        </p>
        <p>
          6. Indemnification – Customer shall indemnify and hold harmless Kitchautomation, LLC (and its subsidiaries, affiliates, officers,
          agents, co-branders or other partners, and employees) from any and all claims, damages, liabilities, costs, and expenses
          (including, but not limited to, reasonable attorneys’ fees and all related costs and expenses) incurred by Kitchautomation, LLC as
          a result of any claim, judgment, or adjudication against Kitchautomation, LLC related to or arising from (a) any photographs,
          illustrations, graphics, audio clips, video clips, text, data or any other information, content, display, or material (whether
          written, graphic, sound, or otherwise) provided by Customer to Kitchautomation, LLC (the “Customer Content”), or (b) a claim that
          Kitchautomation, LLC’s use of the Customer Content infringes the intellectual property rights of a third party. To qualify for
          such defense and payment, Kitchautomation, LLC must: (i) give Customer prompt written notice of a claim; and (ii) allow Customer
          to control, and fully cooperate with Customer in, the defense and all related negotiations.
        </p>
        <p>
          7. Disclaimer of All Other Warranties – KITCHAUTOMATION, LLC DOES NOT WARRANT THAT THE MAINTENANCE SERVICES WILL MEET THE
          CUSTOMER’S EXPECTATIONS OR REQUIREMENTS. THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE IS WITH CUSTOMER. EXCEPT AS OTHERWISE
          SPECIFIED IN THIS AGREEMENT, KITCHAUTOMATION, LLC PROVIDES ITS SERVICES “AS IS” AND WITHOUT WARRANTY OF ANY KIND. THE PARTIES
          AGREE THAT (A) THE LIMITED WARRANTIES SET FORTH IN THIS SECTION ARE THE SOLE AND EXCLUSIVE WARRANTIES PROVIDED BY EACH PARTY, AND
          (B) EACH PARTY DISCLAIMS ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, RELATING TO THIS AGREEMENT, PERFORMANCE OR INABILITY TO PERFORM UNDER THIS
          AGREEMENT, THE CONTENT, AND EACH PARTY’S COMPUTING AND DISTRIBUTION SYSTEM. IF ANY PROVISION OF THIS AGREEMENT SHALL BE UNLAWFUL,
          VOID, OR FOR ANY REASON UNENFORCEABLE, THEN THAT PROVISION SHALL BE DEEMED SEVERABLE FROM THIS AGREEMENT AND SHALL NOT AFFECT THE
          VALIDITY AND ENFORCEABILITY OF ANY REMAINING PROVISIONS.
        </p>
        <p>
          8. Limited Liability – IN NO EVENT SHALL KITCHAUTOMATION, LLC BE LIABLE TO CUSTOMER FOR ANY INDIRECT, SPECIAL, EXEMPLARY OR
          CONSEQUENTIAL DAMAGES, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR IMPLIED WARRANTIES
          ARISING FROM COURSE OF DEALING OR COURSE OF PERFORMANCE, LOST PROFITS, WHETHER OR NOT FORESEEABLE OR ALLEGED TO BE BASED ON BREACH
          OF WARRANTY, CONTRACT, NEGLIGENCE OR STRICT LIABILITY, ARISING UNDER THIS AGREEMENT, LOSS OF DATA, OR ANY PERFORMANCE UNDER THIS
          AGREEMENT, EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND NOTWITHSTANDING THE FAILURE OF ESSENTIAL
          PURPOSE OF ANY LIMITED REMEDY PROVIDED HEREIN. THERE SHALL BE NO REFUNDS. KITCHAUTOMATION, LLC MAKES NO WARRANTY OF ANY KIND,
          WHETHER EXPRESS OR IMPLIED, WITH REGARD TO ANY THIRD PARTY PRODUCTS, THIRD PARTY CONTENT OR ANY SOFTWARE, EQUIPMENT, OR HARDWARE
          OBTAINED FROM THIRD PARTIES.
        </p>
        <p>
          9. Customer Representations – Customer makes the following representations and warranties for the benefit of Kitchautomation, LLC:
          o Customer represents to Kitchautomation, LLC and unconditionally guarantees that any elements of drawings, text, graphics,
          photos, designs, trademarks, or other artwork furnished to Kitchautomation, LLC are owned by Customer, or that Customer has
          permission from the rightful owner to use each of these elements, and will hold harmless, protect, and defend Kitchautomation, LLC
          and its subcontractors from any claim or suit arising from the use of such elements furnished by Customer. o Customer guarantees
          to Kitchautomation, LLC and unconditionally guarantees that Customer’s website has not been compromised, hacked, or otherwise
          defaced or infected prior to ordering Maintenance Services. o Customer guarantees any elements of drawings, text, graphics,
          photos, designs, trademarks, or other artwork provided to Kitchautomation, LLC for inclusion in the web-based configurators are
          owned by Customer, or that Customer has received permission from the rightful owner(s) to use each of the elements, and will hold
          harmless, protect, and permission from the rightful owner(s) to use each of the elements, and will hold harmless, protect, and
          defend Kitchautomation, LLC and its subcontractors from any liability or suit arising from the use of such elements. o From time
          to time governments enact laws and levy taxes and tariffs affecting Internet electronic commerce. Customer agrees that the client
          is solely responsible for complying with such laws, taxes, and tariffs, and will hold harmless, protect, and defend
          Kitchautomation, LLC and its subcontractors from any claim, suit, penalty, tax, or tariff arising from Customer’s exercise of
          Internet electronic commerce.
        </p>
        <p>
          10. Confidentiality – The parties agree to hold each other’s Proprietary or Confidential Information in strict confidence.
          “Proprietary or Confidential Information” shall include, but is not limited to, written or oral contracts, trade secrets,
          know-how, business methods, business policies, memoranda, reports, records, computer retained information, notes, or financial
          information. Proprietary or Confidential Information shall not include any information which: (i) is or becomes generally known to
          the public by any means other than a breach of the obligations of the receiving party; (ii) was previously known to the receiving
          party or rightly received by the receiving party from a third party; (iii) is independently developed by the receiving party; or
          (iv) is subject to disclosure under court order or other lawful process. The parties agree not to make each other’s Proprietary or
          Confidential Information available in any form to any third party or to use each other’s Proprietary or Confidential Information
          for any purpose other than as specified in this Agreement. Each party’s proprietary or confidential information shall remain the
          sole and exclusive property of that party. The parties agree that in the event of use or disclosure by the other party other than
          as specifically provided for in this Agreement, the non-disclosing party may be entitled to equitable relief. Notwithstanding
          termination or expiration of this Agreement, Kitchautomation, LLC and Customer acknowledge and agree that their obligations of
          confidentiality with respect to Proprietary or Confidential Information shall continue in effect for a total period of three (3)
          years from the effective date.
        </p>
        <p>
          11. Force Majeure – Neither party will be liable for, or will be considered to be in breach of or default under this Agreement on
          account of, any delay or failure to perform as required by this Agreement as a result of any causes or conditions that are beyond
          such Party’s reasonable control and that such Party is unable to overcome through the exercise of commercially reasonable
          diligence. If any force majeure event occurs, the affected Party will give prompt written notice to the other Party and will use
          commercially reasonable efforts to minimize the impact of the event.
        </p>
        <p>
          12. Relationship of Parties – Kitchautomation, LLC, in rendering performance under this Agreement, shall be deemed an independent
          contractor and nothing contained herein shall constitute this arrangement to be employment, a joint venture, or a partnership.
          Customer does not undertake by this Agreement, or otherwise, to perform any obligation of Kitchautomation, LLC, whether by
          regulation or contract. In no way is Kitchautomation, LLC to be construed as the agent or to be acting as the agent of Customer in
          any respect, any other provisions of this Agreement notwithstanding.
        </p>
        <p>
          13. Notice and Payment – Any notice required to be given under this Agreement shall be in writing and delivered personally to the
          other designated party, mailed by certified, registered or Express mail, return receipt requested or by Federal Express. Either
          party may change its address to which notice or payment is to be sent by written notice to the other under any provision of this
          paragraph.
        </p>
        <p>
          14. Jurisdiction/Disputes – This Agreement shall be governed in accordance with the laws of the State of Maryland and Prince
          Georges County. All disputes under this Agreement shall be resolved by litigation in the courts of the State of Maryland and
          Prince Georges County including the federal courts therein and the Parties all consent to the jurisdiction of such courts, agree
          to accept service of process by mail, and hereby waive any jurisdictional or venue defenses otherwise available to it.
        </p>
        <p>
          15. Agreement Binding on Successors – The provisions of the Agreement shall be binding upon and shall inure to the benefit of the
          Parties hereto, their heirs, administrators, successors, and assigns.
        </p>
        <p>
          16. Assignability – Customer may not assign this Agreement or the rights and obligations thereunder to any third party without the
          prior express written approval of Kitchautomation, LLC. Kitchautomation, LLC reserves the right to assign subcontractors as needed
          to this project to ensure on-time completion. 17. Waiver – No waiver by either party of any default shall be deemed as a waiver of
          prior or subsequent default of the same of other provisions of this Agreement.
        </p>
        <p>
          17. Waiver – No waiver by either party of any default shall be deemed as a waiver of prior or subsequent default of the same of
          other provisions of this Agreement.
        </p>
        <p>
          18. Severability – If any term, clause or provision hereof is held invalid or unenforceable by a court of competent jurisdiction,
          such invalidity shall not affect the validity or operation of any other term, clause or provision and such invalid term, clause or
          provision shall be deemed to be severed from the Agreement.
        </p>
        <p>
          19. No Inference Against Author – No provision of this Agreement shall be interpreted against any Party because such Party or its
          legal representative drafted such provision.
        </p>
        <p>
          20. Disputes – Customer and Kitchautomation, LLC agree to make a good-faith effort to resolve any disagreement arising out of, or
          in connection with, this Agreement through negotiation. Should the parties fail to resolve any such disagreement within ten (10)
          days, any controversy or claim arising out of or relating to this Agreement, including, without limitation, the interpretation or
          breach thereof, shall be submitted by either party to arbitration in Prince George’s County, Maryland and in accordance with the
          Commercial Arbitration Rules of the American Arbitration Association. The arbitration shall be conducted by one arbitrator, who
          shall be (a) selected in the sole discretion of the American Arbitration Association administrator and (b) a licensed attorney
          with at least ten (10) years experience in the practice of law and at least five (5) years experience in the negotiation of
          technology contracts or litigation of technology disputes. The arbitrator shall have the power to enter any award that could be
          entered by a judge of the state courts of Maryland sitting without a jury, and only such power, except that the arbitrator shall
          not have the power to award punitive damages, treble damages, or any other damages which are not compensatory, even if permitted
          under the laws of the State of Maryland or any other applicable law. The arbitrator must issue his or her resolution of any
          dispute within thirty (30) days of the date the dispute is submitted for arbitration. The written decision of the arbitrator shall
          be final and binding and enforceable in any court having jurisdiction over the parties and the subject matter of the arbitration.
          Notwithstanding the foregoing, this Section shall not preclude either party from seeking temporary, provisional, or injunctive
          relief from any court.
        </p>
        <p>
          21. Read and Understood – Each Party acknowledges that it has read and understands this Agreement and agrees to be bound by its
          terms and conditions.
        </p>
      </div>
    </div>
  );
};

export default Maintenance;
