import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './project-submission-log.reducer';
import { IProjectSubmissionLog } from 'app/shared/model/project-submission-log.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IProjectSubmissionLogDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ProjectSubmissionLogDetail extends React.Component<IProjectSubmissionLogDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { projectSubmissionLogEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            ProjectSubmissionLog [<b>{projectSubmissionLogEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="foodServiceUrl">Food Service Url</span>
            </dt>
            <dd>{projectSubmissionLogEntity.foodServiceUrl}</dd>
            <dt>
              <span id="krowneDrawingUrl">Krowne Drawing Url</span>
            </dt>
            <dd>{projectSubmissionLogEntity.krowneDrawingUrl}</dd>
            <dt>
              <span id="coronetUrl">Coronet Url</span>
            </dt>
            <dd>{projectSubmissionLogEntity.coronetUrl}</dd>
            <dt>
              <span id="reportUrl">Report Url</span>
            </dt>
            <dd>{projectSubmissionLogEntity.reportUrl}</dd>
            <dt>
              <span id="guid">Guid</span>
            </dt>
            <dd>{projectSubmissionLogEntity.guid}</dd>
            <dt>
              <span id="autoDeskId">Auto Desk Id</span>
            </dt>
            <dd>{projectSubmissionLogEntity.autoDeskId}</dd>
            <dt>
              <span id="dateCreated">Date Created</span>
            </dt>
            <dd>
              <TextFormat value={projectSubmissionLogEntity.dateCreated} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="jobId">Job Id</span>
            </dt>
            <dd>{projectSubmissionLogEntity.jobId}</dd>
            <dt>
              <span id="progressPercentage">Progress Percentage</span>
            </dt>
            <dd>{projectSubmissionLogEntity.progressPercentage}</dd>
            <dt>User</dt>
            <dd>{projectSubmissionLogEntity.userLogin ? projectSubmissionLogEntity.userLogin : ''}</dd>
            <dt>Project</dt>
            <dd>{projectSubmissionLogEntity.projectProjectName ? projectSubmissionLogEntity.projectProjectName : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/project-submission-log" replace color="info">
            <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/project-submission-log/${projectSubmissionLogEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ projectSubmissionLog }: IRootState) => ({
  projectSubmissionLogEntity: projectSubmissionLog.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectSubmissionLogDetail);
