import React, { useEffect, useState } from 'react';
import RoomleConfiguratorApi from '@roomle/embedding-lib/roomle-configurator-api.es.min.js';
import { saveSharePageConfiguration } from 'app/shared/reducers/authentication';
import { shareConfigurationwithoutAuthentication, createEntity } from 'app/entities/user-configuration/user-configuration.reducer';
import { Button } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { saveConfiguration } from 'app/shared/util/save-configuration';
import ShareConfigurationModal from 'app/components/ShareConfigurationModal/ShareConfigurationModal';
import ShareConfigurationByEmailModal from 'app/components/ShareConfigurationByeEmailModal/ShareConfigurationByEmailFolder';
import LoadingBar from 'react-redux-loading-bar';
import './sharedConfiguration.scss';

export interface ISharedConfiguration extends StateProps, DispatchProps {}

const SharedConfiguration = (props: ISharedConfiguration) => {
  const urlParams = new URLSearchParams(window.location.search);
  const roomleId = urlParams.get('id');
  const configurationId = urlParams.get('configuratorId');
  const floorplanId = urlParams.get('floorplanId');
  const [isShareConfigurationModalOpen, setIsShareConfigurationModalOpen] = useState<boolean>(false);
  const [isSaveToInventoryButtonWasPressed, setIsSaveToInventoryButtonWasPressed] = useState<boolean>(false);
  const [roomleConfiguration, setRoomleConfiguration] = useState(null);
  const [configuratorInstanse, setConfiguratorInstanse] = useState(null);
  const [isShareByEmailModalOpen, setIsShareByEmailModalOpen] = useState<boolean>(false);
  const [shareEmail, setShareEmail] = useState('');
  let history = useHistory();

  useEffect(() => {
    if (isSaveToInventoryButtonWasPressed && roomleConfiguration) {
      const newEntity = saveConfiguration(roomleConfiguration, null, null, true);
      props.createEntity(newEntity);
      setIsSaveToInventoryButtonWasPressed(false);
    }
  }, [roomleConfiguration]);

  const shareButtonHandler = () => {
    configuratorInstanse.ui.triggerRequestProduct();
    setIsShareConfigurationModalOpen(true);
  };

  const shareConfigurationByEmail = () => {
    props.shareConfigurationwithoutAuthentication({ configuration: roomleConfiguration.configurationHash, email: shareEmail });
    setIsShareByEmailModalOpen(false);
    setIsShareConfigurationModalOpen(false);
  };

  const handleSaveToInventoryButton = () => {
    configuratorInstanse.ui.triggerRequestProduct();
    setIsSaveToInventoryButtonWasPressed(true);
  };

  useEffect(() => {
    if (roomleId && !floorplanId) {
      (async () => {
        const options = {
          buttons: {
            savedraft: false,
          },
          translations: {
            en: {
              params: {
                'request-product': 'Request Product',
              },
            },
          },
        };

        const configurator = await RoomleConfiguratorApi.createConfigurator(
          configurationId,
          document.getElementById('share-page-configurator-container'),
          {
            ...options,
            usePriceService: true,
          }
        );
        setConfiguratorInstanse(configurator);

        props.saveSharePageConfiguration(roomleId);
        if (!roomleId) {
          return;
        }
        configurator.ui.loadObject(roomleId);

        configurator.ui.callbacks.onRequestProduct = (confId, image, partlist, price, labels, configuration) => {
          setRoomleConfiguration(configuration);
        };
      })();
    } else if (floorplanId && !roomleId) {
      (async () => {
        const options = {
          moc: true,
          id: floorplanId,
          buttons: { savedraft: false, requestproduct: true, export3d: false },
          locale: 'en',
          translations: {
            en: {
              params: {
                'request-product': 'Save to Inventory',
              },
            },
          },
        };

        const configurator = await RoomleConfiguratorApi.createPlanner(
          'kreator',
          document.getElementById('share-page-configurator-container'),
          options
        );

        setConfiguratorInstanse(configurator);

        configurator.ui.callbacks.onRequestPlan = (planId, image, kernelPlanObjects) => {};
      })();
    }
  }, []);

  return (
    <div className="share-page-container">
      <LoadingBar className="loading-bar" style={{ backgroundColor: '#d9954c' }} />
      <header className="share-configuration-landing-header">
        {!props.account.login && (
          <img src="../../../content/images/kreator-navbar-logo.png" width="50" alt="logo" className="share-page-header-logo" />
        )}
        <div className="share-page-buttons-container">
          {roomleId && (
            <Button onClick={shareButtonHandler} className="share-page-share-button" style={{ marginRight: 10 }}>
              <img src="../../../content/images/icons/share-icon.svg" alt="share-icon" width="20" />
              <span className="share-page-share-button-text">Share</span>
            </Button>
          )}
          {props.account.login && roomleId && (
            <Button onClick={handleSaveToInventoryButton} className="share-page-sign-up-button">
              <span className="share-page-share-button-text">Save To Inventory</span>
            </Button>
          )}
          {!props.account.login && (
            <Button onClick={() => history.push('/login')} className="share-page-sign-in-button" style={{ marginRight: 10 }}>
              Sign In to Save to your Inventory
            </Button>
          )}
          {!props.account.login && (
            <Button onClick={() => history.push('/account/register')} className="share-page-sign-up-button">
              Sign Up
            </Button>
          )}
        </div>
      </header>
      <div
        className={
          props.account.login ? 'share-page-configurator-external-container--authorized' : 'share-page-configurator-external-container'
        }
      >
        <div
          id="share-page-configurator-container"
          className={props.account.login ? 'share-page-configurator-container--authorized' : 'share-page-configurator-container'}
        ></div>
      </div>
      <ShareConfigurationModal
        isOpen={isShareConfigurationModalOpen}
        closeModal={() => setIsShareConfigurationModalOpen(false)}
        roomleConfiguration={roomleConfiguration}
        handleShareByEmailButton={() => setIsShareByEmailModalOpen(true)}
        isInProduction={props.isInProduction}
      />
      <ShareConfigurationByEmailModal
        isOpen={isShareByEmailModalOpen}
        setShareEmail={setShareEmail}
        shareConfiguration={shareConfigurationByEmail}
        setIsShareConfigurationByEmailModalOpen={() => setIsShareByEmailModalOpen(false)}
        isShareButtonWasPressed={true}
        saveTo="Save to Inventory"
      />
    </div>
  );
};

const mapStateToProps = ({ applicationProfile, authentication }: IRootState) => ({
  isInProduction: applicationProfile.inProduction,
  account: authentication.account,
});

const mapDispatchToProps = { saveSharePageConfiguration, shareConfigurationwithoutAuthentication, createEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SharedConfiguration);
