import React, { useState, useEffect } from 'react';
import { CardImg, Modal, Input, Table } from 'reactstrap';
import { Tooltip } from 'reactstrap';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { useHistory } from 'react-router-dom';
import { updateEntity } from 'app/entities/user-configuration/user-configuration.reducer';
import { downloadPartList } from 'app/shared/util/downloadPartList';
import { IUserConfiguration } from 'app/shared/model/user-configuration.model';
import AppSpinner from '../Spinner/Spinner';
import MD5 from 'crypto-js/md5';
import './details.scss';

interface IInventoryDetailsModal extends StateProps, DispatchProps {
  isOpen: Boolean;
  product: IUserConfiguration;
  closeModal: Function;
  redirectToDetails?: Function;
  partsListLoading?: any;
  projectProductsPartsList?: any;
  userManufacturer: any;
}

const InventoryDetailsModal = (props: IInventoryDetailsModal) => {
  const [mailsha1, setMailsha1] = useState(MD5(props.account.email).toString());
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [isLargeImageOpen, setIsLargeImageOpen] = useState<boolean>(false);
  const [productDescriptionValue, setProductDescriptionValue] = useState('');
  let history = useHistory();

  useEffect(() => {
    if (props.product) setProductDescriptionValue(props.product.description);
  }, [props.product]);

  const updateProductDescription = () => {
    if (props.product.description !== productDescriptionValue) {
      props.updateEntity({ ...props.product, description: productDescriptionValue });
    }
  };

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const args = {
    autohide: true,
    flip: true,
  };

  const redirectToDetails = (product: IUserConfiguration, e) => {
    const configuration = product.roomleComponentId;
    history.push(`/configurators/${mailsha1}/${product.id}/${configuration}/${product.configuration}`);
    props.closeModal();
  };

  const handleAQButton = (product: IUserConfiguration) => {
    const configuration = product.roomleComponentId;
    const configurationLink = `https://kreator.app/configurators/${mailsha1}/${product.id}/${configuration}/${product.configuration}`;
    downloadPartList(props.projectProductsPartsList[0].partsList, configurationLink, product.manufacturerName);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      centered
      toggle={() => props.closeModal()}
      backdrop={true}
      style={
        window.screen.width >= 1024
          ? { maxWidth: window.screen.width - 500, width: '100%' }
          : { maxWidth: '90%', width: '100%', margin: 'auto' }
      }
      className="product-details-modal"
    >
      {props.product && (
        <div className="configurator-details-modal-container">
          <div className="product-details-modal-image-block">
            <CardImg
              onDoubleClick={() => setIsLargeImageOpen(true)}
              top
              src={props.product.thumbnailImage}
              alt="Missing image"
              className="image product-details-image"
              id="productImage"
            />

            <Tooltip {...args} isOpen={tooltipOpen} target="productImage" toggle={toggle}>
              Double-Click to Enlarge
            </Tooltip>
            <div className="product-action-buttons-container">
              {/* <img
                src="../../../content/images/icons/pdf-file.svg"
                alt="pdf-icon"
                width="30"
                height="30"
                className="product-details-action-button"
              /> */}
              <button onClick={() => redirectToDetails(props.product, e)} className="product-details-action-button">
                <img
                  src="../../../content/images/icons/reconfigure-icon.svg"
                  alt="reconfigure"
                  style={{ marginBottom: 5 }}
                  width="40"
                  height="40"
                />
                <span>Reconfigure</span>
              </button>
              <button
                disabled={!props.projectProductsPartsList.length}
                onClick={() => handleAQButton(props.product)}
                className="product-details-action-button aq-button"
              >
                <span className="product-details-action-button-text">AQ</span>
              </button>
            </div>
          </div>
          <div className="product-details-modal-data-block">
            <div className="product-details-modal-header">
              <img
                src="../../../content/images/icons/closeModalIcon.svg"
                alt="close modal icon"
                className="close-modal-image"
                width="25"
                height="25"
                onClick={() => props.closeModal()}
              />
              {props.product?.shareBy && (
                <span className="product-details-modal-data-shared-by">{props.product?.shareBy + ' ' + 'shared this with you.'}</span>
              )}
            </div>
            <div className="product-details-modal-data-container">
              <div className="product-details-modal-product-name">
                <span className="product-details-modal-name-description">Product Name</span>
                <span className="product-details-modal-name-value">{props.product.productName}</span>
              </div>
              <div className="product-details-modal-manufacturer">
                <span className="product-details-modal-key">Manufacturer Name:</span>
                <span className="product-details-modal-manufacturer-value">{props.product.manufacturerName}</span>
              </div>
              {props.userManufacturer?.showMrfInventoryDetails && (
                <div className="product-details-modal-manufacturer">
                  <span className="product-details-modal-key">Manufacturer Rep Name:</span>
                  <span className="product-details-modal-manufacturer-value">{props.product?.manufacturerRefData?.company}</span>
                </div>
              )}
              <div className="product-details-modal-description">
                <span className="product-details-modal-key">Product Description:</span>
                <Input
                  value={productDescriptionValue}
                  onChange={e => setProductDescriptionValue(e.target.value)}
                  onBlur={updateProductDescription}
                  className="product-details-modal-description-input"
                  placeholder="Click to add a Description"
                />
              </div>
              <div className="product-details-modal-manufacturer-link-container">
                <div className="product-details-modal-manufacturer-link"></div>
                <div className="product-details-modal-manufacturer-link-value">
                  <div className="product-details-modal-manufacturer-link-button">
                    {props?.product?.manufacturerLogo && (
                      <img src={props?.product?.manufacturerLogo} alt="manufacturer-logo" style={{ marginBottom: 10 }} width="100" />
                    )}
                    {/* TODO: add manufacturerWebsiteURL when it will be in projectProducts */}
                    {/* <a
                      href={`https://${props?.product?.manufacturerName?.toLowerCase()}.com`}
                      target="blank"
                      className="product-details-modal-manufacturer-link-text"
                    >
                      Click to view website
                    </a> */}
                  </div>
                </div>
              </div>
              <span className="configurator-details-modal-options-title">Selected Options</span>
              {props.product.parameterValues
                ? Object.entries(props.product.parameterValues).map(([key, value]) => (
                    <div className="product-details-modal-option" key={key}>
                      <span className="product-options-modal-key">{key}:</span>
                      <span className="product-options-modal-option-value">{value}</span>
                    </div>
                  ))
                : ''}
              <span className="configurator-details-modal-options-title">Parts List</span>
              {props.projectProductsPartsList ? (
                props.projectProductsPartsList.map(item => (
                  <div key={item} className="project-settings-collapse-products-configurator-container-item" style={{ width: '100%' }}>
                    <Table>
                      <thead>
                        <tr>
                          <th className="project-submission-details-partslist-table-title">DESCRIPTION</th>
                          <th className="project-submission-details-partslist-table-title">MODEL</th>
                          <th className="project-submission-details-partslist-table-title">QUANTITY</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.partsList.map(item => (
                          <tr key={item.articleNr + item.subpartId}>
                            <td>{item.label}</td>
                            <td>{item.articleNr}</td>
                            <td>{item.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ))
              ) : (
                <AppSpinner />
              )}

              {/* <button onClick={e => props.redirectToDetails(props.product, e)} className="product-details-modal-configure-button">
                Configure
              </button> */}
              <Modal
                isOpen={isLargeImageOpen}
                centered
                toggle={() => setIsLargeImageOpen(false)}
                backdrop={true}
                style={{ maxWidth: window.screen.width - 480, width: '100%' }}
                className="product-details-modal"
              >
                <div className="product-details-modal-header">
                  <img
                    src="../../../content/images/icons/closeModalIcon.svg"
                    alt="close modal icon"
                    className="close-modal-image"
                    width="25"
                    height="25"
                    onClick={() => setIsLargeImageOpen(false)}
                  />
                </div>
                <CardImg
                  onDoubleClick={() => setIsLargeImageOpen(true)}
                  top
                  src={props.product.thumbnailImage}
                  alt="Missing image"
                  className="image product-details-image"
                  id="productImage"
                />
              </Modal>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  account: authentication.account,
});

const mapDispatchToProps = {
  updateEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(InventoryDetailsModal);

function e(product: any, e: any): void {
  throw new Error('Function not implemented.');
}
