import React, { useState, useEffect } from 'react';
import { CardImg, Modal, Button } from 'reactstrap';
import './shareConfigurationModal.scss';

interface IShareConfigurationModal {
  isOpen: boolean;
  closeModal: Function;
  isInProduction: any;
  handleShareByEmailButton: Function;
  roomleConfiguration: any;
}

const ShareConfigurationModal = (props: IShareConfigurationModal) => {
  const [configurationLink, setConfigurationLink] = useState('');

  useEffect(() => {
    setConfigurationLink(
      `https://kreator.app/configuration/?id=${props.roomleConfiguration?.configurationHash}&configuratorId=${props.roomleConfiguration?.catalog}`
    );
  }, [props.roomleConfiguration]);

  return (
    <Modal
      isOpen={props.isOpen}
      centered
      fade={false}
      toggle={() => props.closeModal()}
      backdrop={true}
      style={
        window.screen.width >= 1024
          ? { maxWidth: window.screen.width - 550, width: '100%' }
          : { maxWidth: '90%', width: '100%', margin: 'auto' }
      }
      className="share-comfiguration-modal"
    >
      <div className="share-configurator-modal-container">
        <div className="share-configuration-modal-image-block">
          <CardImg top src={props.roomleConfiguration?.perspectiveImage} alt="Missing image" className="image share-modal-product-image" />
        </div>
        <div className="share-configuration-modal-data-block">
          <div className="share-configuration-modal-header">
            <img
              src="../../../content/images/icons/closeModalIcon.svg"
              alt="close modal icon"
              className="close-share-modal-image"
              width="15"
              height="15"
              onClick={() => props.closeModal()}
            />
          </div>
          <div className="share-configuration-modal-data-container">
            <span className="share-configuration-modal-title">Share your Configuration</span>
            <span className="share-configuration-modal-description">
              Share your configuration with anybody, even if they are not Kreator users
            </span>
            <span className="share-configuration-link">{configurationLink}</span>
            <span id="share-configuration-page-copied-to-clipboard">copied to clipboard</span>
            <div className="share-configuration-modal-buttons-container">
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(configurationLink);
                  document.getElementById('share-configuration-page-copied-to-clipboard').style['visibility'] = 'visible';
                  setTimeout(function () {
                    document.getElementById('share-configuration-page-copied-to-clipboard').style['visibility'] = 'hidden';
                  }, 1500);
                }}
                className="share-configuration-modal-button"
              >
                Copy URL
              </Button>
              <Button
                onClick={props.handleShareByEmailButton}
                className="share-configuration-modal-button share-configuration-modal-button-blue"
              >
                Share by Email
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareConfigurationModal;
