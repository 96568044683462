import React, { useEffect } from 'react';
import BaseLayout from '../BaseLayout';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import './ManufacturerApi.scss';
import { InputGroup, Input, InputGroupText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getManufacturerForCurrentUser } from 'app/entities/manufacturer/manufacturer.reducer';

const ManufacturerApi = props => {
  useEffect(() => {
    props.getManufacturerForCurrentUser();
  }, []);

  return (
    <BaseLayout title="Share Revit Api">
      <div className="toggles-container">
        <InputGroup>
          <Input placeholder="API KEY " value={props.manufacturer.apiKey} />
          <InputGroupText>
            <FontAwesomeIcon icon="copy" />
          </InputGroupText>
        </InputGroup>
      </div>
    </BaseLayout>
  );
};

const mapStateToProps = ({ manufacturer }: IRootState) => ({
  manufacturer: manufacturer.entity,
});

const mapDispatchToProps = {
  getManufacturerForCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManufacturerApi);
