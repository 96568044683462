import React, { useEffect, useState } from 'react';
import { Modal, CardImg, Input } from 'reactstrap';
import ProjectStatusSelect from '../RoomDesignerProjectsTable/ProjectStatusSelect';
import ProductCardAction from '../ProductCardAction/ProductCardAction';
import AppSpinner from '../Spinner/Spinner';
import { Collapse } from 'reactstrap';
import moment from 'moment';
import './projectDetailsModal.scss';
import { Button } from 'reactstrap';

interface IProjectDetailsModal {
  isOpen: Boolean;
  project: any;
  closeModal: Function;
  updateProjectDetails: Function;
  projectLoading: boolean;
  projectUpdating: boolean;
  projectUpdateSuccess: boolean;
}

const ProjectDetailsModal = (props: IProjectDetailsModal) => {
  const { project, updateProjectDetails, projectUpdateSuccess } = props;

  const [projectData, setProjectData] = useState(project);
  const [isUserInfoOpen, setIsUserInfoOpen] = useState<boolean>(false);
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false);

  const updateProjectData = (fieldName: string, fieldValue: string | number) => {
    if (props.project[fieldName] !== fieldValue) {
      setProjectData({ ...projectData, [fieldName]: fieldValue });
    }
  };

  useEffect(() => {
    if (projectData != project) {
      setIsDataChanged(true);
    }
  }, [projectData]);

  const handleUpdateProject = () => {
    updateProjectDetails(projectData);
  };

  useEffect(() => {
    props.closeModal();
  }, [projectUpdateSuccess]);

  useEffect(() => {
    setProjectData(project);
  }, [project]);

  return (
    <>
      {project && (
        <Modal
          isOpen={props.isOpen}
          centered
          toggle={() => props.closeModal()}
          backdrop={true}
          style={{ maxWidth: window.screen.width - 500, width: '100%' }}
          className="project-details-modal"
        >
          {props.projectLoading || props.projectUpdating ? (
            <div style={{ paddingBottom: 150 }}>
              <AppSpinner />
            </div>
          ) : (
            <div className="project-details-modal-container">
              <div className="project-details-modal-image-block">
                {props.project.isRoomDesign && props.project.roomlePlanId && (
                  <CardImg top src={props.project.thumbnailImage} alt="Missing image" className="image project-details-image" />
                )}
              </div>
              <div className="project-details-modal-data-block">
                <div className={isDataChanged ? 'project-details-modal-header-active' : 'project-details-modal-header'}>
                  <ProjectStatusSelect project={project} />
                  <div className="mb-2 ml-4">
                    {isDataChanged ? (
                      <Button
                        onClick={() => props.closeModal()}
                        className="roomle-save-to-inventory-button save-project-button cancel-project-button mr-2"
                        disabled={props.projectLoading || props.projectUpdating}
                      >
                        Cancel
                      </Button>
                    ) : (
                      <ProductCardAction iconName="closeModalIcon" bordered={true} onClick={() => props.closeModal()} />
                    )}
                    {isDataChanged && (
                      <Button
                        onClick={() => handleUpdateProject()}
                        className="roomle-save-to-inventory-button save-project-button mr-2"
                        disabled={props.projectLoading || props.projectUpdating}
                      >
                        Save
                      </Button>
                    )}
                  </div>
                </div>
                <div className={isDataChanged ? 'project-details-modal-data-container--active' : 'project-details-modal-data-container'}>
                  <div className="project-details-modal-input-container">
                    <span className="project-details-modal-key">Project Code</span>
                    <Input
                      value={projectData.projectCode || ''}
                      onChange={e => updateProjectData('projectCode', e.target.value)}
                      className="project-details-modal-input"
                    />
                  </div>
                  <div className="project-details-modal-input-container">
                    <span className="project-details-modal-key">Project Name</span>
                    <Input
                      value={projectData.projectName || ''}
                      onChange={e => updateProjectData('projectName', e.target.value)}
                      className="project-details-modal-input"
                    />
                  </div>
                  <div className="project-details-modal-input-container">
                    <span className="project-details-modal-key">Client Name</span>
                    <Input
                      value={projectData.clientName || ''}
                      onChange={e => updateProjectData('clientName', e.target.value)}
                      className="project-details-modal-input"
                    />
                  </div>
                  <div className="project-details-read-only-item">
                    <span className="project-details-modal-key">Date Created :</span>
                    <span className="project-details-modal-value">{moment(project.dateCreated).format('MMMM DD, YYYY')}</span>
                  </div>
                  <div className="project-details-read-only-item">
                    <span className="project-details-modal-key">Last Modified :</span>
                    <span className="project-details-modal-value">{moment(project.lastModified).format('MMMM DD, YYYY, HH:mm a')}</span>
                  </div>
                  <div className="project-details-modal-input-container">
                    <span className="project-details-modal-key">Drawn By :</span>
                    <Input
                      value={projectData.drawnBy || ''}
                      onChange={e => updateProjectData('drawnBy', e.target.value)}
                      className="project-details-modal-input"
                    />
                  </div>
                  <div className="project-details-modal-input-container">
                    <span className="project-details-modal-key">Project Street Address :</span>
                    <Input
                      value={projectData.projectStreetAddress || ''}
                      onChange={e => updateProjectData('projectStreetAddress', e.target.value)}
                      className="project-details-modal-input"
                    />
                  </div>
                  <div className="project-details-modal-input-container">
                    <span className="project-details-modal-key">Project City :</span>
                    <Input
                      value={projectData.projectCity || ''}
                      onChange={e => updateProjectData('projectCity', e.target.value)}
                      className="project-details-modal-input"
                    />
                  </div>
                  <div className="project-details-modal-input-container">
                    <span className="project-details-modal-key">Project State :</span>
                    <Input
                      value={projectData.projectState || ''}
                      onChange={e => updateProjectData('projectState', e.target.value)}
                      className="project-details-modal-input"
                    />
                  </div>
                  <div className="project-details-modal-input-container">
                    <span className="project-details-modal-key">Project Zip Code :</span>
                    <Input
                      value={projectData.projectZipCode || ''}
                      onChange={e => updateProjectData('projectZipCode', e.target.value)}
                      className="project-details-modal-input"
                    />
                  </div>

                  <div onClick={() => setIsUserInfoOpen(!isUserInfoOpen)} className="user-info-collapse-bar">
                    <h6 className="project-modal-section-title">User Info</h6>
                    {isUserInfoOpen ? (
                      <img src="../../../content/images/icons/folder-minus.svg" />
                    ) : (
                      <img src="../../../content/images/icons/folder-plus.svg" />
                    )}
                  </div>
                  <Collapse isOpen={isUserInfoOpen}>
                    <div className="project-details-modal-input-container">
                      <span className="project-details-modal-key">User Company Name :</span>
                      <Input
                        value={projectData.userCompanyName || ''}
                        onChange={e => updateProjectData('userCompanyName', e.target.value)}
                        className="project-details-modal-input"
                      />
                    </div>

                    <div className="project-details-modal-input-container">
                      <span className="project-details-modal-key">User Street Address :</span>
                      <Input
                        value={projectData.userStreetAddress || ''}
                        onChange={e => updateProjectData('userStreetAddress', e.target.value)}
                        className="project-details-modal-input"
                      />
                    </div>
                    <div className="project-details-modal-input-container">
                      <span className="project-details-modal-key">User City :</span>
                      <Input
                        value={projectData.userCity || ''}
                        onChange={e => updateProjectData('userCity', e.target.value)}
                        className="project-details-modal-input"
                      />
                    </div>
                    <div className="project-details-modal-input-container">
                      <span className="project-details-modal-key">User State :</span>
                      <Input
                        value={projectData.userState || ''}
                        onChange={e => updateProjectData('userState', e.target.value)}
                        className="project-details-modal-input"
                      />
                    </div>
                    <div className="project-details-modal-input-container">
                      <span className="project-details-modal-key">User Zip Code :</span>
                      <Input
                        value={projectData.userZipCode || ''}
                        onChange={e => updateProjectData('userZipCode', e.target.value)}
                        className="project-details-modal-input"
                      />
                    </div>
                    <div className="project-details-modal-input-container">
                      <span className="project-details-modal-key">User Company Website URL :</span>
                      <Input
                        value={projectData.userCompanyUrl || ''}
                        onChange={e => updateProjectData('userCompanyUrl', e.target.value)}
                        className="project-details-modal-input"
                      />
                    </div>
                    <div className="project-details-modal-input-container">
                      <span className="project-details-modal-key" style={{ marginBottom: 30 }}>
                        User Phone Number :
                      </span>
                      <Input
                        value={projectData.userPhone || ''}
                        onChange={e => updateProjectData('userPhone', e.target.value)}
                        className="project-details-modal-input"
                      />
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default ProjectDetailsModal;
