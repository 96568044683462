import { useState, useEffect } from 'react';

export const useInventoryFolderOpening = (folderList, defaultFolderName, getUserConfigurationsByFolderId, reset) => {
  const [isFolderOpen, setIsFolderOpen] = useState(false);
  const [folderOpenId, setFolderOpenId] = useState(null);

  useEffect(() => {
    const folder = folderList.find(item => item.name === defaultFolderName);

    if (folder) {
      setIsFolderOpen(true);
      getUserConfigurationsByFolderId(folder.id);
      setFolderOpenId(folder.id);
    }
  }, [folderList]);

  const toggleFolder = (folderId, folderState) => {
    reset();
    if (folderState) {
      getUserConfigurationsByFolderId(folderId);
      setFolderOpenId(folderId);
    }
    setIsFolderOpen(folderState);
  };

  return [isFolderOpen, folderOpenId, toggleFolder];
};
