import React, { useState, useEffect } from 'react';
import BaseLayout from '../BaseLayout';
import ManufacturerRepCard from './ManufacturerRepCard';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import Search from 'app/components/Search/Search';
import { getRegions } from 'app/entities/manufacturer-ref-data/manufacturer-ref-data.reducer';
import AppSpinner from 'app/components/Spinner/Spinner';
import { getMfrRepByMfrAndRegion, reset } from 'app/entities/manufacturer-ref-data/manufacturer-ref-data.reducer';
import NoDataBanner from 'app/components/NoDataBanner/NoDataBanner';
import * as _ from 'lodash';
import './findManufacturerRep.scss';

interface IFindManufacturerRep extends StateProps, DispatchProps, RouteComponentProps<{ url: string; mailsha1: string }> {
  regions: any;
}

const FindManufacturerRep = (props: IFindManufacturerRep) => {
  const [currentRegion, setCurrentRegion] = useState(null);
  const [regionsForSelect, setRegionsForSelect] = useState<{ label: string; value: string }[]>([]);
  const [manufacturerSearchValue, setManufacturerSearchValue] = useState('');

  useEffect(() => {
    props.reset();
    props.getRegions();
    props.getMfrRepByMfrAndRegion();
  }, []);

  useEffect(() => {
    if (props.regions && props.userRegion) {
      const regions = props.regions.map(item => ({ label: item, value: item }));
      setRegionsForSelect(regions);

      setCurrentRegion(getItemWithMatchingNumber(props.regions, extractNumberFromString(props.userRegion)));
    }
  }, [props.regions, props.userRegion]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      props.getMfrRepByMfrAndRegion(manufacturerSearchValue, currentRegion);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [manufacturerSearchValue, currentRegion]);

  function extractNumberFromString(inputString: string): number | null {
    const match = inputString.match(/REGION_(\d)/);
    return match ? parseInt(match[1]) : null;
  }

  function getItemWithMatchingNumber(arr: string[], targetNumber: number | null): string | null {
    for (const item of arr) {
      const match = item.match(/^0*(\d+) - /);
      if (match && parseInt(match[1]) === targetNumber) {
        return item;
      }
    }
    return null;
  }

  const customSelectStyles = {
    placeholder(base, state) {
      return {
        ...base,
        opacity: 0.5,
        fontSize: 15,
        color: '#5E7086',
      };
    },
    menuList: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      marginTop: -8,
      boxShadow: '0px 15px 30px #a1b0cf14',
      padding: 0,
      borderRadius: 10,
    }),
    option: (provided, state) => ({
      ...provided,
      paddingTop: 13,
      paddingBottom: 13,
      borderBottom: '1px solid #7070700d',
      color: '#9FAAB5',
      fontWeight: 300,
      '&:hover': {
        background: 'transparent',
        color: '#E2863C',
      },
      background: 'transparent',
      cursor: 'pointer',
    }),
  };

  return (
    <BaseLayout title={`Find Manufacturer's Reps`}>
      <div className="mt-5"></div>

      {props.loadingRegions ? (
        <AppSpinner />
      ) : (
        <div className="find-mfr-rep-page-content-container">
          <img src="../../../content/images/regionsMap.svg" alt="regions map" className="find-mfr-rep-page-regions-map" width="40%" />
          <span className="find-mfr-rep-page-regions-map-subtitle">{`Showing results for region ${currentRegion}`}</span>
          <div className="find-mfr-rep-page-search-container">
            <Search
              placeholder="Lookup Manufacturer Rep by Manufacturers"
              searchValue={manufacturerSearchValue}
              setSearchValue={setManufacturerSearchValue}
            />

            {/* <Search placeholder="Lookup Manufacturers by Manufacturers Rep" /> */}

            <Select
              options={regionsForSelect}
              className="regions-select"
              onChange={item => setCurrentRegion(item.label)}
              styles={customSelectStyles}
              placeholder="Change Region"
            />
          </div>
          {props.loading && <AppSpinner />}
          {!props.loading &&
            props.manufacturerRep &&
            props.manufacturerRep.map(item => <ManufacturerRepCard manufacturerRep={item} key={item.id} />)}
          {!props.loading && !props.manufacturerRep && <NoDataBanner title="There are no Manufacturer Reps for your request!" />}
        </div>
      )}
    </BaseLayout>
  );
};

const mapStateToProps = ({ manufacturerRefData, authentication }: IRootState) => ({
  regions: manufacturerRefData.regions,
  loadingRegions: manufacturerRefData.loadingRegions,
  loading: manufacturerRefData.loading,
  userRegion: authentication.region,
  manufacturerRep: manufacturerRefData.entities,
});

const mapDispatchToProps = {
  getRegions,
  getMfrRepByMfrAndRegion,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FindManufacturerRep);
