export const findUnpairedItems = (arr1, arr2) => {
  const countMap1 = new Map();
  const countMap2 = new Map();
  const unpairedItems = [];

  for (const item of arr1) {
    countMap1.set(item, (countMap1.get(item) || 0) + 1);
  }

  for (const item of arr2) {
    countMap2.set(item, (countMap2.get(item) || 0) + 1);
  }

  for (const [item, count] of countMap1.entries()) {
    const countInArr2 = countMap2.get(item) || 0;
    if (count !== countInArr2) {
      for (let i = 0; i < Math.abs(count - countInArr2); i++) {
        unpairedItems.push(item);
      }
    }
  }

  return unpairedItems;
};
