import React, { useEffect, useRef, useState } from 'react';
import {
  createProject,
  deleteEntity,
  getProject,
  reset,
  updateProject,
  updateProjectDetails,
  shareProject,
  forgeConnect,
  resetCurrentProject,
} from 'app/entities/project/project.reducer';
import RoomleMOC from 'app/shared/util/roomle-moc';
import ConfigurationCartTable from 'app/components/ConfigurationCart/ConfigurationCartTable';
import AppSpinner from 'app/components/Spinner/Spinner';
import ShareConfigurationByEmailModal from 'app/components/ShareConfigurationByeEmailModal/ShareConfigurationByEmailFolder';
import CreateShopDrawingsModal from 'app/components/CreateShopDrawingsModal/CreateShopDrawingsModal';
import ProjectDetailsModal from 'app/components/ProjectDetailsModal/ProjectDetailsModal';
import ConfirmationDialog from 'app/components/ConfirmationDialog/ConfirmationDialog';
import DeleteAlert from 'app/components/DeleteAlert/DeleteAlert';
import InventoryDetailsModal from 'app/components/InventoryDetailsModal/InventoryDetailsModal';
import ProjectActionsBar from './ProjectActionsBar';
import AddInventoryToTheProjectModal from 'app/components/AddInventoryToTheProjectModal/AddInventoryToTheProjectModal';
import ProjectCodeInputModal from 'app/components/ProjectCodeInputModal/ProjectCodeInputModal';
import { deleteProjectProduct } from 'app/entities/project-product/project-product.reducer';
import { connect } from 'react-redux';
import { createEntity, getEntity, resetEntity } from 'app/entities/user-configuration/user-configuration.reducer';
import { useHistory } from 'react-router';
import { usePartsList } from 'app/customHooks/usePartsList';
import { useCreateDrawings } from 'app/customHooks/useProjectDrawings';
import { saveConfiguration } from 'app/shared/util/save-configuration';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { getManufacturerForCurrentUser } from 'app/entities/manufacturer/manufacturer.reducer';
import { setIsHelpVideoPopupOpen, setIsSidebarCollapsed, setIsFullScreenMode } from 'app/entities/user-settings/user-settings.reducer';
import { IUserConfiguration } from 'app/shared/model/user-configuration.model';
import ChangeConfigurationModal from 'app/components/ChangeConfigurationModal/ChangeConfigurationModal';
import { IProjectProduct } from 'app/shared/model/project-product.model';
import { Modal } from 'reactstrap';
import MD5 from 'crypto-js/md5';
import './project.scss';

export const Project = props => {
  const [mailsha1, setMailsha1] = useState(props.match.params.mailsha1 ? props.match.params.mailsha1 : MD5(props.account.email).toString());
  const history = useHistory();
  const { projectEntity } = props;
  const savedUnitsOfMeasure = localStorage.getItem('unitsOfMeasure');
  const roomleChildRef = useRef(null);
  const [unitsOfMeasure, setUnitsOfMeasure] = useState<string>(savedUnitsOfMeasure || 'inchfeet');
  const [roomleConfiguration, setRoomleConfiguration] = useState(null);
  const [roomleConfigurationLoaded, setRoomleConfigurationLoaded] = useState<boolean>(false);
  const [shareEmail, setShareEmail] = useState('');
  const [selectedConfigurationObject, setSelectedConfigurationObject] = useState(null);
  const [productForDetailsModal, setProductForDetailsModal] = useState(null);
  const [isSaveProjectButtonWasPressed, setIsSaveProjectButtonWasPressed] = useState<boolean>(false);
  const [floorPlanObjectsArray, setFloorPlanObjectsArray] = useState([]);
  const [isAddInventoryButtonWasPressed, setIsAddInventoryButtonWasPressed] = useState<boolean>(false);
  const [isAddToProjectModalOpen, setIsAddToProjectModalOpen] = useState<boolean>(false);
  const [isAddConfigurationModalOpen, setIsAddConfigurationModalOpen] = useState<boolean>(false);
  const { isDetailsOpen, setisDetailsOpen, projectProductsPartsList, partsListLoading } = usePartsList([
    { userConfiguration: productForDetailsModal },
  ]);
  const {
    projectCodeValue,
    setProjectCodeValue,
    isProjectCodeInputModalOpen,
    setIsProjectCodeInputModalOpen,
    isCreateDrawingsModalOpen,
    setIsCreateDrawingsModalOpen,
    createDrawings,
    updateProjectCode,
    // isEmptyFieldsData,
    // setIsEmptyFieldsData,
    // isNeedToFillTheDataForDrawings,
    setIsNeedToFillTheDataForDrawings,
  } = useCreateDrawings(props.projectEntity, props.account, props.forgeConnect, props.updateProject, props.updateProjectDetails);

  // modal windows
  const [isShareConfigurationModalOpen, setIsShareConfigurationModalOpen] = useState<boolean>(false);
  const [isProjectDetailsModalOpen, setIsProjectDetailsModalOpen] = useState<boolean>(false);
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState<boolean>(false);
  const [isInventoriesModalOpen, setIsInventoriesModalOpen] = useState<boolean>(false);

  useEffect(() => {
    props.setIsSidebarCollapsed(true);
    props.setIsFullScreenMode(true);
    props.getProject(props.match.params.id);

    return () => {
      props.setIsSidebarCollapsed(false);
      props.setIsFullScreenMode(false);
      props.resetCurrentProject();
    };
  }, []);

  useEffect(() => {
    if (roomleConfiguration && isSaveProjectButtonWasPressed) {
      saveEntity();
    }
  }, [floorPlanObjectsArray]);

  useEffect(() => {
    if (roomleConfigurationLoaded) {
      setTimeout(() => {
        roomleChildRef.current.requestProductOutsideTheIframe();
      }, 1000);
    }
  }, [roomleConfigurationLoaded]);

  useEffect(() => {
    if (isDetailsOpen) {
      props.getManufacturerForCurrentUser();
    }
  }, [isDetailsOpen]);

  useEffect(() => {
    if (props.updateSuccess && isAddInventoryButtonWasPressed) {
      history.go(0);
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    if (props.projectProductsUpdateSuccess) {
      props.getProject(props.projectEntity.id);
    }
  }, [props.projectProductsUpdateSuccess]);

  const prepareProjectProducts = async () => {
    let projectProducts = [];
    let kernelPlanObjects = [...roomleConfiguration.kernelPlanObjects];

    kernelPlanObjects.map(object => {
      object.configuration =
        JSON.stringify(floorPlanObjectsArray.find(item => item.componentId === JSON.parse(object.data.configuration).componentId)) ||
        object.configuration;
      object.parts = JSON.stringify(object.parts);
    });

    kernelPlanObjects.forEach(item => {
      let newEntity = saveConfiguration(item, null, MD5(props.account.email).toString());

      newEntity = {
        ...newEntity,
        // @ts-ignore
        id: findUserConfigurationId(item.configurationHash),
        positionX: item.center.x || 0,
        positionY: item.center.y || 0,
        positionZ: item.center.z || 0,
        rotation: item.rotation || 0,
      };

      const projectProduct = prepareProjectProduct(newEntity);
      projectProducts.push(projectProduct);
    });

    const result = replaceDuplicateIdsWithNull(projectProducts);

    return result;
  };

  function replaceDuplicateIdsWithNull(objectsArray) {
    const idsSet = new Set();

    for (let i = 0; i < objectsArray.length; i++) {
      const obj = objectsArray[i];
      if (obj.hasOwnProperty('id')) {
        if (idsSet.has(obj.id)) {
          obj.id = null;
        } else {
          idsSet.add(obj.id);
        }
      }
    }

    return objectsArray;
  }

  const prepareProjectProduct = (userConfiguration: IUserConfiguration) => {
    const { sectionPrefix, ...restUserConfig } = userConfiguration;
    const projectProduct = findProjectProduct(restUserConfig.id);

    return {
      id: projectProduct ? projectProduct.id : null,
      quantity: projectProduct ? projectProduct.quantity : 1,
      itemNumber: projectProduct ? projectProduct.itemNumber : 1,
      userConfiguration: restUserConfig,
      sectionPrefix: sectionPrefix,
    };
  };

  const saveEntity = async () => {
    const thumbnailImage = `https://uploads.roomle.com/planSnapshots/${roomleConfiguration.planId}/perspectiveImage.png`;

    let entity = {
      ...projectEntity,
      roomlePlanId: roomleConfiguration.planId,
      thumbnailImage,
    };

    entity.projectProducts = await prepareProjectProducts();

    props.updateProject(entity);
    setIsSaveProjectButtonWasPressed(false);
  };

  const findUserConfigurationId = (configurationHash: string): number | null => {
    const projectProduct = projectEntity?.projectProducts?.find(item => item.userConfiguration?.configuration === configurationHash);
    return projectProduct?.userConfiguration?.id || null;
  };

  const findProjectProduct = (configurationId: number): IProjectProduct => {
    const projectProduct = projectEntity?.projectProducts?.find(
      item => item.userConfiguration?.id === configurationId && item.id !== null && item.userConfiguration
    );
    return projectProduct || null;
  };

  const deleteConfigurationFromCart = () => {
    props.deleteProjectProduct(selectedConfigurationObject.id);

    setIsDeleteConfirmationDialogOpen(false);
    setSelectedConfigurationObject(null);
  };

  const updateUserConfigurationInsideProject = (fieldValue: any, userConfigurationId: number, fieldName: string) => {
    const projectCopy = Object.assign({}, props.projectEntity);
    const objIndex = props.projectEntity.projectProducts.findIndex(item => item.userConfiguration.id === userConfigurationId);
    projectCopy.projectProducts[objIndex][fieldName] = fieldValue;

    props.updateProject(projectCopy);
    setIsNeedToFillTheDataForDrawings(false);
  };

  const shareConfiguration = () => {
    props.shareProject({ projectId: props.projectEntity.id, shareEmail: shareEmail });
    setIsShareConfigurationModalOpen(false);
  };

  const addInventoryToTheProject = (userConfigurationEntity: IUserConfiguration) => {
    props.updateProject({
      ...props.projectEntity,
      projectProducts: [
        ...props.projectEntity.projectProducts,
        { quantity: 1, itemNumber: 1, sectionPrefix: userConfigurationEntity.sectionPrefix, userConfiguration: userConfigurationEntity },
      ],
    });

    setIsInventoriesModalOpen(false);
  };

  const handleReconfigureButton = (product: IProjectProduct, e) => {
    const configuration = product.userConfiguration.roomleComponentId;
    history.push(`/configurators/${mailsha1}/${product.id}/${configuration}/${product.userConfiguration.configuration}`);
  };

  return (
    <>
      {props.loading ? (
        <AppSpinner />
      ) : (
        <>
          <ProjectActionsBar
            setUnitsOfMeasure={setUnitsOfMeasure}
            projectEntity={props.projectEntity}
            roomleConfigurationLoaded={roomleConfigurationLoaded}
            roomleChildRef={roomleChildRef}
            setIsProjectDetailsModalOpen={setIsProjectDetailsModalOpen}
            setIsCreateDrawingsModalOpen={setIsCreateDrawingsModalOpen}
            setIsExportRevitModalOpen={setIsShareConfigurationModalOpen}
            account={props.account}
            setIsSaveProjectButtonWasPressed={setIsSaveProjectButtonWasPressed}
            setIsAddToProjectModalOpen={setIsAddToProjectModalOpen}
            setIsHelpVideoPopupOpen={props.setIsHelpVideoPopupOpen}
            loadingValuesArray={[
              props.loading,
              props.updating,
              props.loadingProjectProduct,
              props.updatingProjectProduct,
              props.loadingUserConfiguration,
              props.updatingUserConfiguration,
            ]}
          />

          {projectEntity && !props.loading && (
            <>
              <RoomleMOC
                handleFinishLoading={null}
                configurationId={'kreator'}
                planId={props?.projectEntity?.roomlePlanId || 'uvofbl6hsqgarfztc11if4u94o9zigv'}
                catalogRootTag={'kreator_root'}
                saveButton={true}
                unitsOfMeasure={unitsOfMeasure}
                ref={roomleChildRef}
                setRoomleConfiguration={setRoomleConfiguration}
                setRoomleConfigurationLoaded={setRoomleConfigurationLoaded}
                projectConfigurations={props.projectEntity?.projectProducts}
                setFloorPlanObjectsArray={setFloorPlanObjectsArray}
                floorPlanObjectsArray={floorPlanObjectsArray}
                isSaveProjectButtonWasPressed={isSaveProjectButtonWasPressed}
                isFullScreenMode={props.userSettings.isFullScreenMode}
                isSidebarCollapsed={props.userSettings.isSidebarCollapsed}
              />
              {/* DO NOT DELETE */}
              {/* <div style={{ height: 20, marginBottom: 30 }}></div> */}
              {/* {props.updatingProjectProduct || props.loading || props.updating || props.loadingProjectProduct ? (
                <div>
                  <AppSpinner />
                  <div style={{ marginBottom: 50 }}></div>
                </div>
              ) : (
                props.projectEntity.totalProductCount > 0 && (
                  <ConfigurationCartTable
                    project={props.projectEntity}
                    deleteConfigurationFromCart={deleteConfigurationFromCart}
                    projectUpdating={props.projectUpdating}
                    updateUserConfigurationInsideProject={updateUserConfigurationInsideProject}
                    setIsDeleteConfirmationDialogOpen={setIsDeleteConfirmationDialogOpen}
                    setSelectedConfigurationObject={setSelectedConfigurationObject}
                    setProductForDetailsModal={setProductForDetailsModal}
                    setisProductDetailsModalOpen={setisDetailsOpen}
                    account={props.account}
                    isNeedToFillTheDataForDrawings={isNeedToFillTheDataForDrawings}
                    getUserConfiguration={props.getEntity}
                    handleReconfigureButton={handleReconfigureButton}
                  />
                )
              )} */}
              <Modal
                isOpen={isAddToProjectModalOpen}
                centered
                style={{ maxWidth: 700, width: window.screen.width - 1050 }}
                toggle={() => setIsAddToProjectModalOpen(false)}
                backdrop={true}
              >
                <div className="create-drawings-modal-container">
                  <button
                    className="create-drawings-modal-button"
                    onClick={() => {
                      setIsAddInventoryButtonWasPressed(true);
                      setIsInventoriesModalOpen(true);
                      setIsAddToProjectModalOpen(false);
                    }}
                  >
                    Add from Inventory
                  </button>

                  <button
                    className="create-drawings-modal-button"
                    onClick={() => {
                      setIsAddConfigurationModalOpen(true);
                      setIsAddToProjectModalOpen(false);
                    }}
                  >
                    Add Configuration
                  </button>
                </div>
              </Modal>

              <ShareConfigurationByEmailModal
                isOpen={isShareConfigurationModalOpen}
                setShareEmail={setShareEmail}
                shareConfiguration={shareConfiguration}
                setIsShareConfigurationByEmailModalOpen={() => setIsShareConfigurationModalOpen(false)}
                saveTo="Save Project"
                setIsHelpVideoPopupOpen={props.setIsHelpVideoPopupOpen}
              />
              <CreateShopDrawingsModal
                isOpen={isCreateDrawingsModalOpen}
                setIsCreateDrawingsModalOpen={() => setIsCreateDrawingsModalOpen(false)}
                handleCreateDrawingsButton={createDrawings}
              />
              <ProjectDetailsModal
                isOpen={isProjectDetailsModalOpen}
                closeModal={() => setIsProjectDetailsModalOpen(false)}
                project={props.projectEntity}
                updateProjectDetails={props.updateProjectDetails}
                projectLoading={props.loading}
                projectUpdating={props.updating}
                projectUpdateSuccess={props.updateSuccess}
              />
              <ConfirmationDialog
                isOpen={isDeleteConfirmationDialogOpen}
                closeConfirmationDialog={() => setIsDeleteConfirmationDialogOpen(false)}
                confirmationInfo={`Are you sure you want to delete the
            ${selectedConfigurationObject?.userConfiguration?.productName}?`}
                aditionalConfirmationInfo={<DeleteAlert />}
                confirmAction={deleteConfigurationFromCart}
              />
              <InventoryDetailsModal
                isOpen={isDetailsOpen}
                product={productForDetailsModal}
                closeModal={() => {
                  setisDetailsOpen(false);
                  setProductForDetailsModal(null);
                }}
                projectProductsPartsList={projectProductsPartsList}
                partsListLoading={partsListLoading}
                userManufacturer={props.userManufacturer}
              />
              <AddInventoryToTheProjectModal
                isOpen={isInventoriesModalOpen}
                closeModal={() => setIsInventoriesModalOpen(false)}
                addInventoryToTheProject={addInventoryToTheProject}
              />
              <ProjectCodeInputModal
                isOpen={isProjectCodeInputModalOpen}
                closeModal={setIsProjectCodeInputModalOpen}
                projectCodeValue={projectCodeValue}
                setProjectCodeValue={setProjectCodeValue}
                updateProjectCode={updateProjectCode}
              />
              <ChangeConfigurationModal
                isOpen={isAddConfigurationModalOpen}
                closeModal={() => setIsAddConfigurationModalOpen(false)}
                currentProjectId={props.projectEntity.id}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = storeState => ({
  users: storeState.userManagement.users,
  projectEntity: storeState.project.entity,
  loading: storeState.project.loading,
  updating: storeState.project.updating,
  updateSuccess: storeState.project.updateSuccess,
  newProjectName: storeState.project.newProjectName,
  account: storeState.authentication.account,
  loadingProjectProduct: storeState.projectProduct.loading,
  updatingProjectProduct: storeState.projectProduct.updating,
  projectProductsUpdateSuccess: storeState.projectProduct.updateSuccess,
  loadingUserConfiguration: storeState.userConfiguration.loading,
  updatingUserConfiguration: storeState.userConfiguration.updating,
  userManufacturer: storeState.manufacturer.entity,
  userSettings: storeState.userSettings,
});

const mapDispatchToProps = {
  createProject,
  deleteEntity,
  getProject,
  updateProject,
  updateProjectDetails,
  createEntity,
  reset,
  shareProject,
  forgeConnect,
  resetCurrentProject,
  deleteProjectProduct,
  getEntity,
  resetEntity,
  getManufacturerForCurrentUser,
  setIsHelpVideoPopupOpen,
  setIsSidebarCollapsed,
  setIsFullScreenMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);
