import { IRoomleProduct } from 'app/shared/model/roomle-product.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction } from 'react-jhipster';

// import { dispatch } from 'rxjs/internal/observable/pairs';

export const ACTION_TYPES = {
  FETCH_ROOMLE_PRODUCT_LIST: 'product/FETCH_ROOMLE_PRODUCT_LIST',
  FETCH_ROOMLE_PRODUCT: 'product/FETCH_ROOMLE_PRODUCT',
  RESET: 'product/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IRoomleProduct>,
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type RoomleProductState = Readonly<typeof initialState>;

// Reducer

export default (state: RoomleProductState = initialState, action): RoomleProductState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ROOMLE_PRODUCT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ROOMLE_PRODUCT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_ROOMLE_PRODUCT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ROOMLE_PRODUCT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ROOMLE_PRODUCT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_ROOMLE_PRODUCT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/products/configurators';
const roomleApiUrl = 'https://www.roomle.com/api/v2/items';
// Actions

const getRoomleData: any = async (request: any) => {
  const entities = await request;
  const allRequests = entities.data.map(p => axios.get(`${roomleApiUrl}/${p.roomleComponentId}`));

  const reflect = p =>
    p.then(
      response => ({ response, status: 'fulfilled' }),
      error => ({ error, status: 'rejected' })
    );

  await Promise.all(allRequests.map(reflect)).then((results: any) => {
    results.map((resp, index) => {
      if (results[index].status === 'fulfilled') {
        entities.data[index].roomleData = resp.response.data;
      } else {
        entities.data[index].roomleData = null;
      }
    });
  });

  Promise.resolve();
  return entities;
};

// @ts-ignore
export const getEntities: ICrudGetAllAction<IRoomleProduct> = (page, size, sort, search) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&search=${search}` : ''}`;

  return {
    type: ACTION_TYPES.FETCH_ROOMLE_PRODUCT_LIST,
    payload: axios.get<IRoomleProduct>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IRoomleProduct> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ROOMLE_PRODUCT,
    payload: axios.get<IRoomleProduct>(requestUrl),
  };
};

export const getEntityByComponentId: ICrudGetAction<IRoomleProduct> = componentId => {
  const requestUrl = `${apiUrl}/by-componentId/${componentId}`;
  return {
    type: ACTION_TYPES.FETCH_ROOMLE_PRODUCT,
    payload: axios.get<IRoomleProduct>(requestUrl),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
