import { IUserConfiguration } from './user-configuration.model';

export interface IProjectProduct {
  id?: number;
  quantity?: number;
  itemNumber?: string;
  description?: any;
  position?: number;
  sectionPrefix?: string;
  deleted?: boolean;
  userLogin?: string;
  userId?: number;
  userConfigurationId?: number;
  userConfiguration?: IUserConfiguration;
  projectId?: number;
}

export const defaultValue: Readonly<IProjectProduct> = {
  deleted: false,
};
