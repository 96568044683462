import React, { useState, useEffect } from 'react';
import LoadingBar from 'react-redux-loading-bar';
import { useHistory } from 'react-router-dom';
import { NavLink as Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { AccountMenu } from 'app/shared/layout/menus';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { Button } from 'reactstrap';
import Tippy from '@tippyjs/react';
import './header.scss';

export interface IHeaderProps extends StateProps {
  firstName: string;
  lastName: string;
  email: string;
  marketplaceMode: string;
  account: any;
  region: any;
  openBurgerMenu: Function;
  isSideBarCollapsed: boolean;
  notificationsCount: number;
}

const Header = (props: IHeaderProps) => {
  let history = useHistory();

  let headerClass = 'portal-header ';
  if (props.isSideBarCollapsed) {
    headerClass += 'portal-header--collapsed-sidebar';
  }

  return (
    <React.Fragment>
      <div className={headerClass + props.marketplaceMode}>
        <LoadingBar className="loading-bar" style={{ backgroundColor: '#d9954c' }} />
        <Button className="open-burger-menu-button" onClick={props.openBurgerMenu}>
          ☰
        </Button>
        <img
          src="../../../../content/images/kreator-navbar-logo.png"
          alt="logo"
          width="70"
          className={props.marketplaceMode ? 'marketplace-logo' : 'd-none'}
          onClick={() => history.push('/')}
        />

        {hasAnyAuthority(props.account.authorities, [
          AUTHORITIES.USER,
          AUTHORITIES.ROLE_DEALER_ADMIN,
          AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
        ]) && (
          <NavLink tag={Link} className="notifications-link" to="/notifications">
            {props.notificationsCount > 0 && (
              <div className="notifications-status">{props.notificationsCount > 99 ? '99+' : props.notificationsCount}</div>
            )}
            <Tippy content={'Notifications'} placement="bottom" theme="light-border">
              <img src="../../../../content/images/notificationButton.svg" />
            </Tippy>
          </NavLink>
        )}

        <AccountMenu firstName={props.firstName} lastName={props.lastName} email={props.email} region={props.region} />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ notification }: IRootState) => ({
  notificationsList: notification.notifications,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Header);
