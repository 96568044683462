import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IManufacturer } from 'app/shared/model/manufacturer.model';
import { getEntities as getManufacturers } from 'app/entities/manufacturer/manufacturer.reducer';
import { IDealer } from 'app/shared/model/dealer.model';
import { getEntities as getDealers } from 'app/entities/dealer/dealer.reducer';
import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntity, updateEntity, createEntity, reset } from './leads.reducer';
import { ILeads } from 'app/shared/model/leads.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ILeadsUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const LeadsUpdate = (props: ILeadsUpdateProps) => {
  const [manufacturerId, setManufacturerId] = useState('0');
  const [dealerId, setDealerId] = useState('0');
  const [userId, setUserId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { leadsEntity, manufacturers, dealers, users, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/leads' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getManufacturers();
    props.getDealers();
    props.getUsers();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...leadsEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="familyBuilderApiApp.leads.home.createOrEditLabel">Create or edit a Leads</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : leadsEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="leads-id">ID</Label>
                  <AvInput id="leads-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="statusLabel" for="leads-status">
                  Status
                </Label>
                <AvInput
                  id="leads-status"
                  type="select"
                  className="form-control"
                  name="status"
                  value={(!isNew && leadsEntity.status) || 'IN_PROGRESS'}
                >
                  <option value="IN_PROGRESS">IN_PROGRESS</option>
                  <option value="COMPLETED">COMPLETED</option>
                  <option value="REJECTED">REJECTED</option>
                  <option value="CLOSED">CLOSED</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="originFirstNameLabel" for="leads-originFirstName">
                  Origin First Name
                </Label>
                <AvField id="leads-originFirstName" type="text" name="originFirstName" />
              </AvGroup>
              <AvGroup>
                <Label id="originLastNameLabel" for="leads-originLastName">
                  Origin Last Name
                </Label>
                <AvField id="leads-originLastName" type="text" name="originLastName" />
              </AvGroup>
              <AvGroup>
                <Label id="originEmailLabel" for="leads-originEmail">
                  Origin Email
                </Label>
                <AvField id="leads-originEmail" type="text" name="originEmail" />
              </AvGroup>
              <AvGroup>
                <Label id="originStreetAddressLabel" for="leads-originStreetAddress">
                  Origin Street Address
                </Label>
                <AvField id="leads-originStreetAddress" type="text" name="originStreetAddress" />
              </AvGroup>
              <AvGroup>
                <Label id="originZipCodeLabel" for="leads-originZipCode">
                  Origin Zip Code
                </Label>
                <AvField id="leads-originZipCode" type="text" name="originZipCode" />
              </AvGroup>
              <AvGroup>
                <Label id="originCityLabel" for="leads-originCity">
                  Origin City
                </Label>
                <AvField id="leads-originCity" type="text" name="originCity" />
              </AvGroup>
              <AvGroup>
                <Label id="originStateLabel" for="leads-originState">
                  Origin State
                </Label>
                <AvField id="leads-originState" type="text" name="originState" />
              </AvGroup>
              <AvGroup>
                <Label id="originCountryLabel" for="leads-originCountry">
                  Origin Country
                </Label>
                <AvField id="leads-originCountry" type="text" name="originCountry" />
              </AvGroup>
              <AvGroup>
                <Label id="originPhoneLabel" for="leads-originPhone">
                  Origin Phone
                </Label>
                <AvField id="leads-originPhone" type="text" name="originPhone" />
              </AvGroup>
              <AvGroup check>
                <Label id="sameAsAboveLabel">
                  <AvInput id="leads-sameAsAbove" type="checkbox" className="form-check-input" name="sameAsAbove" />
                  Same As Above
                </Label>
              </AvGroup>
              <AvGroup>
                <Label id="projectFirstNameLabel" for="leads-projectFirstName">
                  Project First Name
                </Label>
                <AvField id="leads-projectFirstName" type="text" name="projectFirstName" />
              </AvGroup>
              <AvGroup>
                <Label id="projectLastNameLabel" for="leads-projectLastName">
                  Project Last Name
                </Label>
                <AvField id="leads-projectLastName" type="text" name="projectLastName" />
              </AvGroup>
              <AvGroup>
                <Label id="projectEmailLabel" for="leads-projectEmail">
                  Project Email
                </Label>
                <AvField id="leads-projectEmail" type="text" name="projectEmail" />
              </AvGroup>
              <AvGroup>
                <Label id="projectStreetAddressLabel" for="leads-projectStreetAddress">
                  Project Street Address
                </Label>
                <AvField id="leads-projectStreetAddress" type="text" name="projectStreetAddress" />
              </AvGroup>
              <AvGroup>
                <Label id="projectZipCodeLabel" for="leads-projectZipCode">
                  Project Zip Code
                </Label>
                <AvField id="leads-projectZipCode" type="text" name="projectZipCode" />
              </AvGroup>
              <AvGroup>
                <Label id="projectCityLabel" for="leads-projectCity">
                  Project City
                </Label>
                <AvField id="leads-projectCity" type="text" name="projectCity" />
              </AvGroup>
              <AvGroup>
                <Label id="projectStateLabel" for="leads-projectState">
                  Project State
                </Label>
                <AvField id="leads-projectState" type="text" name="projectState" />
              </AvGroup>
              <AvGroup>
                <Label id="projectCountryLabel" for="leads-projectCountry">
                  Project Country
                </Label>
                <AvField id="leads-projectCountry" type="text" name="projectCountry" />
              </AvGroup>
              <AvGroup>
                <Label id="dateTimeLabel" for="leads-dateTime">
                  Date Time
                </Label>
                <AvField id="leads-dateTime" type="text" name="dateTime" />
              </AvGroup>
              <AvGroup>
                <Label id="versionNumberLabel" for="leads-versionNumber">
                  Version Number
                </Label>
                <AvField id="leads-versionNumber" type="text" name="versionNumber" />
              </AvGroup>
              <AvGroup>
                <Label id="notesLabel" for="leads-notes">
                  Notes
                </Label>
                <AvField id="leads-notes" type="text" name="notes" />
              </AvGroup>
              <AvGroup>
                <Label id="projectNameLabel" for="leads-projectName">
                  Project Name
                </Label>
                <AvField id="leads-projectName" type="text" name="projectName" />
              </AvGroup>
              <AvGroup>
                <Label for="leads-manufacturer">Manufacturer</Label>
                <AvInput id="leads-manufacturer" type="select" className="form-control" name="manufacturerId">
                  <option value="" key="0" />
                  {manufacturers
                    ? manufacturers.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="leads-dealer">Dealer</Label>
                <AvInput id="leads-dealer" type="select" className="form-control" name="dealerId">
                  <option value="" key="0" />
                  {dealers
                    ? dealers.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="leads-user">User</Label>
                <AvInput id="leads-user" type="select" className="form-control" name="userId">
                  <option value="" key="0" />
                  {users
                    ? users.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.login}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/leads" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  manufacturers: storeState.manufacturer.entities,
  dealers: storeState.dealer.entities,
  users: storeState.userManagement.users,
  leadsEntity: storeState.leads.entity,
  loading: storeState.leads.loading,
  updating: storeState.leads.updating,
  updateSuccess: storeState.leads.updateSuccess,
});

const mapDispatchToProps = {
  getManufacturers,
  getDealers,
  getUsers,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LeadsUpdate);
