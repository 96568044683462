import React, { useState, useEffect, useRef } from 'react';
import ReactSwipe from 'react-swipe';
import './register.scss';
import AppSpinner from 'app/components/Spinner/Spinner';

interface ISignInCarousel {}

const content: { title: string; description: string; backgroundColor: string; image: string }[] = [
  {
    title: 'Configure Equipment',
    description:
      'Create the perfect equipment using a full set of options and accessories using our interactive 3D photorealistic configurators.',
    backgroundColor: '#EB8031',
    image: '../../../../content/images/product_feature_screenshot_01.svg',
  },
  {
    title: 'Add to Inventory',
    description:
      'Save all of your configured creations in your inventory, organize with folders, and share with others to collaborate on the same design.',
    backgroundColor: '#44A3E6',
    image: '../../../../content/images/product_feature_screenshot_02.svg',
  },
  {
    title: 'Design Floorplans',
    description:
      'The Project Designer is where it all comes together. You can place any of your creations into a room, add walls and floors, and take visualization to a new level with AR/VR/Spatial.',
    backgroundColor: '#3FB575',
    image: '../../../../content/images/product_feature_screenshot_03.svg',
  },
  {
    title: 'Export Anywhere',
    description:
      'Take your designs with you! Whether you are looking for Revit families, a PNG snapshot image, or data for an ERP system, Kreator offers a large variety of export options.',
    backgroundColor: '#E8AC38',
    image: '../../../../content/images/product_feature_screenshot_04.svg',
  },
];

const SignInCarousel = (props: ISignInCarousel) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [imagesLoading, setImagesLoading] = useState<boolean>(true);

  const reactSwipeEl = useRef(null);

  useEffect(() => {
    const imagePromises = content.map(item => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = item.image;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all(imagePromises)
      .then(() => setImagesLoading(false))
      .catch(err => setImagesLoading(false));
  }, []);

  const goToSlide = index => {
    if (reactSwipeEl.current) {
      reactSwipeEl.current.slide(index);
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    if (location.pathname.includes('login')) {
      const interval = setInterval(() => {
        const nextIndex = (activeIndex + 1) % content.length;
        goToSlide(nextIndex);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [activeIndex, content.length]);

  const activeItem = content[activeIndex];

  return imagesLoading ? (
    <div style={{ marginTop: -230 }}>
      <AppSpinner />
    </div>
  ) : (
    <div className="sign-in-page-content-block" style={{ backgroundColor: activeItem.backgroundColor }}>
      <ReactSwipe className="carousel" swipeOptions={{ continuous: false }} ref={reactSwipeEl} childCount={content.length}>
        {content.map((item, index) => (
          <div className={`sign-in-page-content-block-item ${index === activeIndex ? 'active' : ''}`} key={item.title}>
            <img src={item.image} className={`sign-in-image ${item.image.includes('04') ? 'sign-in-image-scaled' : ''}`} />

            <h3 className="sign-in-page-content-block-item-title">{item.title}</h3>
            <p className="sign-in-page-content-block-item-description">{item.description}</p>
          </div>
        ))}
      </ReactSwipe>
      <div className="sign-in-page-content-block-buttons-container">
        <div className="sign-in-page-content-block-item-markers-container">
          {content.map((item, index) => (
            <div
              className={`sign-in-page-content-block-item-marker ${
                index === activeIndex ? 'sign-in-page-content-block-item-marker-active' : ''
              }`}
              key={item.title}
              onClick={() => goToSlide(index)}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SignInCarousel;
