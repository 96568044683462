import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IEnabledProductConfigurators, defaultValue } from 'app/shared/model/enabled-product-configurators.model';

export const ACTION_TYPES = {
  FETCH_ENABLEDPRODUCTCONFIGURATORS_LIST: 'enabledProductConfigurators/FETCH_ENABLEDPRODUCTCONFIGURATORS_LIST',
  FETCH_ENABLEDPRODUCTCONFIGURATORS: 'enabledProductConfigurators/FETCH_ENABLEDPRODUCTCONFIGURATORS',
  CREATE_ENABLEDPRODUCTCONFIGURATORS: 'enabledProductConfigurators/CREATE_ENABLEDPRODUCTCONFIGURATORS',
  UPDATE_ENABLEDPRODUCTCONFIGURATORS: 'enabledProductConfigurators/UPDATE_ENABLEDPRODUCTCONFIGURATORS',
  DELETE_ENABLEDPRODUCTCONFIGURATORS: 'enabledProductConfigurators/DELETE_ENABLEDPRODUCTCONFIGURATORS',
  RESET: 'enabledProductConfigurators/RESET',
  FETCH_ENABLEDPRODUCTCONFIGURATORS_FOR_MARKETPLACE: 'enabledProductConfigurators/FETCH_ENABLEDPRODUCTCONFIGURATORS_FOR_MARKETPLACE',
  ENABLE_CONFIGURATOR_FOR_MARKETPLACE: 'enabledProductConfigurators/ENABLE_CONFIGURATOR_FOR_MARKETPLACE',
  DISABLE_PRODUCT_CONFIGURATOR: 'DISABLE_PRODUCT_CONFIGURATOR',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IEnabledProductConfigurators>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  enabledConfiguratorsForMarketplace: [],
};

export type EnabledProductConfiguratorsState = Readonly<typeof initialState>;

// Reducer

export default (state: EnabledProductConfiguratorsState = initialState, action): EnabledProductConfiguratorsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ENABLEDPRODUCTCONFIGURATORS_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ENABLEDPRODUCTCONFIGURATORS):
    case REQUEST(ACTION_TYPES.FETCH_ENABLEDPRODUCTCONFIGURATORS_FOR_MARKETPLACE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_ENABLEDPRODUCTCONFIGURATORS):
    case REQUEST(ACTION_TYPES.UPDATE_ENABLEDPRODUCTCONFIGURATORS):
    case REQUEST(ACTION_TYPES.DELETE_ENABLEDPRODUCTCONFIGURATORS):
    case REQUEST(ACTION_TYPES.ENABLE_CONFIGURATOR_FOR_MARKETPLACE):
    case REQUEST(ACTION_TYPES.DISABLE_PRODUCT_CONFIGURATOR):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_ENABLEDPRODUCTCONFIGURATORS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ENABLEDPRODUCTCONFIGURATORS):
    case FAILURE(ACTION_TYPES.FETCH_ENABLEDPRODUCTCONFIGURATORS_FOR_MARKETPLACE):
    case FAILURE(ACTION_TYPES.CREATE_ENABLEDPRODUCTCONFIGURATORS):
    case FAILURE(ACTION_TYPES.UPDATE_ENABLEDPRODUCTCONFIGURATORS):
    case FAILURE(ACTION_TYPES.DELETE_ENABLEDPRODUCTCONFIGURATORS):
    case FAILURE(ACTION_TYPES.ENABLE_CONFIGURATOR_FOR_MARKETPLACE):
    case FAILURE(ACTION_TYPES.DISABLE_PRODUCT_CONFIGURATOR):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ENABLEDPRODUCTCONFIGURATORS_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_ENABLEDPRODUCTCONFIGURATORS):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ENABLEDPRODUCTCONFIGURATORS_FOR_MARKETPLACE):
      return {
        ...state,
        loading: false,
        enabledConfiguratorsForMarketplace: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_ENABLEDPRODUCTCONFIGURATORS):
    case SUCCESS(ACTION_TYPES.UPDATE_ENABLEDPRODUCTCONFIGURATORS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_ENABLEDPRODUCTCONFIGURATORS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/enabled-product-configurators';

// Actions

export const getEntities: ICrudGetAllAction<IEnabledProductConfigurators> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_ENABLEDPRODUCTCONFIGURATORS_LIST,
    payload: axios.get<IEnabledProductConfigurators>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IEnabledProductConfigurators> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ENABLEDPRODUCTCONFIGURATORS,
    payload: axios.get<IEnabledProductConfigurators>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IEnabledProductConfigurators> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ENABLEDPRODUCTCONFIGURATORS,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IEnabledProductConfigurators> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ENABLEDPRODUCTCONFIGURATORS,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IEnabledProductConfigurators> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ENABLEDPRODUCTCONFIGURATORS,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const enabledConfiguratorsForMarketplace = () => {
  const requestUrl = 'api/enabled-product-configurators-by-dealers';
  return {
    type: ACTION_TYPES.FETCH_ENABLEDPRODUCTCONFIGURATORS_FOR_MARKETPLACE,
    payload: axios.get(requestUrl),
  };
};

export const enableConfigurator = (productId, enableRegions) => async dispatch => {
  const apiUrl = `/api/enabled-product-configurators-for-product/${productId}`;
  const result = await dispatch({
    type: ACTION_TYPES.ENABLE_CONFIGURATOR_FOR_MARKETPLACE,
    payload: axios.post(apiUrl, enableRegions),
  });
  dispatch(enabledConfiguratorsForMarketplace());
  return result;
};

export const disableConfiguratorForMarketplace = productId => async dispatch => {
  const requestUrl = `/api/disable-product-configurators-for-product/${productId}`;
  const result = await dispatch({
    type: ACTION_TYPES.DISABLE_PRODUCT_CONFIGURATOR,
    payload: axios.post(requestUrl),
  });
  dispatch(enabledConfiguratorsForMarketplace());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
