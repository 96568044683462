import React, { useState, useEffect } from 'react';
import { Modal } from 'reactstrap';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { getEntities } from 'app/entities/roomle-product/roomle-product.reducer';
import AppSpinner from '../Spinner/Spinner';
import { IRootState } from 'app/shared/reducers';
import ProductCard from 'app/entities/roomle-product/ProductCard';
import { IRoomleProduct } from 'app/shared/model/roomle-product.model';
import { setFloorPlanIdForDirectAdding } from 'app/entities/project/project.reducer';
import MD5 from 'crypto-js/md5';
import { groupBy } from 'lodash';
import Search from '../Search/Search';
import './changeConfigurationModal.scss';

interface IChangeConfigurationModal extends StateProps, DispatchProps {
  isOpen: boolean;
  closeModal: Function;
  setisProductDetailsModalOpen?: Function;
  setProductForDetailsModal?: Function;
  currentProjectId?: number;
}

const ChangeConfigurationModal = (props: IChangeConfigurationModal) => {
  let history = useHistory();
  const { productList, loading } = props;
  const [mailsha1, setMailsha1] = useState(MD5(props.account.email).toString());

  useEffect(() => {
    if (props.isOpen && props.productList.length === 0) {
      props.getEntities();
    }
  }, [props.isOpen]);

  const groupByManufacturerName = (elements: readonly IRoomleProduct[]) => {
    const groupedArr = groupBy(elements, elements => elements.manufacturerName);
    return groupedArr;
  };

  const redirectToDetails = (e, product: IRoomleProduct) => {
    if (e.target.nodeName !== 'I') {
      if (location.pathname.includes('project')) {
        props.setFloorPlanIdForDirectAdding(props.currentProjectId);
      }
      history.push('/');
      setTimeout(() => {
        history.push(`${mailsha1}/${product.id}/${product.roomleComponentId}`);
      }, 0);
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      centered
      toggle={() => props.closeModal()}
      backdrop={true}
      style={
        window.screen.width >= 1024
          ? { maxWidth: window.screen.width - 420, width: '100%' }
          : { maxWidth: '95%', width: '95%', margin: 'auto' }
      }
      className="change-configuration-modal"
    >
      <div className="change-configuration-modal-container">
        <button className="change-configuration-modal-close-button" onClick={() => props.closeModal()}>
          X
        </button>
        {/* {productList && productList.length > 0 && (
          <div className="search-container">
            <Search />
          </div>
        )} */}
        {props.loading && <AppSpinner />}
        {productList && productList.length > 0 && !props.loading
          ? Object.keys(groupByManufacturerName(productList)).map(group => (
              <div className="product-group-by-man-mane" key={group}>
                <span className="product-group-title">{group}</span>
                <div className="product-group-items-container change-configuration-items-container">
                  {groupByManufacturerName(productList)[group].map(product => (
                    <div
                      className="configurators-product-card bordered-configuration-card change-configuration-card"
                      key={product.id}
                      onClick={e => redirectToDetails(e, product)}
                    >
                      <i
                        style={{ left: '87%' }}
                        className="configurator-details-button"
                        onClick={e => {
                          props.setisProductDetailsModalOpen(true);
                          props.setProductForDetailsModal(product);
                        }}
                      >
                        i
                      </i>
                      <ProductCard image={product.image} manufacturer={product.manufacturerName} name={product.name} bordered={true} />
                    </div>
                  ))}
                </div>
              </div>
            ))
          : !loading && <div className="alert alert-warning">No Products found</div>}
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ roomleProduct, authentication }: IRootState) => ({
  productList: roomleProduct.entities,
  loading: roomleProduct.loading,
  account: authentication.account,
});

const mapDispatchToProps = {
  getEntities,
  setFloorPlanIdForDirectAdding,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ChangeConfigurationModal);
