import React from 'react';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IEmailSettingProject extends StateProps, DispatchProps {
  item: any;
  index: Number;
  handleRemove: any;
}

const AdminEmailSettings = (props: IEmailSettingProject) => {
  return (
    <tr>
      <td>{props.item.regionOrState}</td>
      <td>{props.item.email}</td>
      <td style={{ display: 'flex', justifyContent: 'end', paddingRight: '10px', marginTop: '-3px' }}>
        <Button color="danger" id="save-entity" type="button" onClick={() => props.handleRemove(props.index)} className="btn-sm">
          <FontAwesomeIcon icon="trash" />
        </Button>
      </td>
    </tr>
  );
};

const mapStateToProps = ({ project, userConfiguration, authentication, roomleProduct, manufacturerRefData }: IRootState) => ({
  projects: project.entities,
  productList: roomleProduct.entities,
  updating: userConfiguration.updating,
  updateSuccess: project.updateSuccess,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdminEmailSettings);
