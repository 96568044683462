import React from 'react';
import { Table } from 'reactstrap';
import './versionsTable.scss';
import { IVersion } from 'app/shared/model/version.model';

interface IVersionsTable {
  versions: any;
  viewVersion: Function;
  editVersion: Function;
  deleteVersion: Function;
}

const VersionsTable = (props: IVersionsTable) => {
  return (
    <Table responsive borderless className="versions-table">
      <thead>
        <tr className="versions-table-header">
          <th>ID</th>
          <th>Type</th>
          <th>Name</th>
          <th>Version Number</th>
          <th>File</th>
          <th>Release Notes</th>
          <th>Release Date</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.versions.map((version: IVersion) => (
          <tr className="versions-table-item" key={version.id}>
            <th>{version.id}</th>
            <td>{version.type}</td>
            <td>{version.name}</td>
            <td>{version.versionNumber}</td>
            <td>{version.file}</td>
            <td>
              <div dangerouslySetInnerHTML={{ __html: version.releaseNotes.replace(/\n/g, '<br />') }} />
            </td>
            <td>{version.releaseDate}</td>
            <td>
              <div className="versions-actions-container">
                <button onClick={() => props.viewVersion(version.id)} className="version-action-button show-version-button">
                  <img src="../../../content/images/icons/Show.svg" alt="download icon" />
                </button>
                <button onClick={() => props.editVersion(version.id)} className="version-action-button edit-version-button">
                  <img src="../../../content/images/icons/Edit.svg" alt="download icon" />
                </button>
                <button onClick={() => props.deleteVersion(version.id)} className="version-action-button delete-version-button">
                  <img src="../../../content/images/icons/Delete.svg" alt="download icon" />
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default VersionsTable;
