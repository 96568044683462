import React from 'react';
import { updateUserSettings } from 'app/entities/user-settings/user-settings.reducer';
import BaseLayout from '../BaseLayout';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import './ConfiguratorSettings.scss';

const ConfiguratorSettings = props => {
  const updateConfiguratorSettings = (manufacturer: string) => {
    const { currentUserSettings, updateUserSettings } = props;

    let hideManufacturers = [];
    if (currentUserSettings?.hideManufacturers?.includes(manufacturer)) {
      const newHideManufacturers = currentUserSettings.hideManufacturers ? [...JSON.parse(currentUserSettings.hideManufacturers)] : [];
      const index = newHideManufacturers.findIndex(value1 => value1 == manufacturer);
      if (index > -1) {
        newHideManufacturers.splice(index, 1);
        hideManufacturers = newHideManufacturers;
      }
    } else {
      const newHideManufacturers = currentUserSettings.hideManufacturers ? [...JSON.parse(currentUserSettings.hideManufacturers)] : [];
      newHideManufacturers.push(manufacturer);
      hideManufacturers = newHideManufacturers;
    }
    updateUserSettings({
      ...currentUserSettings,
      hideManufacturers: JSON.stringify(hideManufacturers),
    });
  };

  const updateProjectStartWithSetting = () => {
    props.updateUserSettings({
      ...props.currentUserSettings,
      projectStartWith: !props.currentUserSettings.projectStartWith,
    });
  };

  function renderChecked(value: string) {
    return props.currentUserSettings?.hideManufacturers?.includes(value) || false;
  }

  return (
    <BaseLayout title="Options">
      <span className="options-block-title">Project Designer</span>
      <div className="option-toggle-container">
        <span style={{ marginRight: 50 }}>Show option to create new Projects using Product Configurators</span>
        <div className="user-settings-toggle-switch-container">
          <span className="user-settings-toggle-position">Hide</span>
          <Switch
            offHandleColor="#E2863C"
            onHandleColor="#E2863C"
            activeBoxShadow="0px 0px 1px 2px #FFFFFF"
            uncheckedIcon={false}
            checkedIcon={false}
            handleDiameter={20}
            height={10}
            width={35}
            className="roomle-action-bar-switch-toggle"
            onChange={value => updateProjectStartWithSetting()}
            checked={props.currentUserSettings.projectStartWith}
          />
          <span className="user-settings-toggle-position">Show</span>
        </div>
      </div>
      <span className="options-block-title">Show/Hide Configurators</span>
      <div className="show-hide-toggles-container">
        {['Kreator', 'Krowne', 'Hatco', 'Thermo-Kool', 'Metro', 'Coronet', 'Eagle MHC','Cambro', 'Low Temp (LTI)'].map(manufacturer => {
          return (
            <div className="user-settings-toggle-container" key={manufacturer}>
              <span style={{ marginRight: 10 }}>{manufacturer}</span>
              <div className="user-settings-toggle-switch-container">
                <span className="user-settings-toggle-position">Hide</span>
                <Switch
                  offHandleColor="#E2863C"
                  onHandleColor="#E2863C"
                  activeBoxShadow="0px 0px 1px 2px #FFFFFF"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  handleDiameter={20}
                  height={10}
                  width={35}
                  className="roomle-action-bar-switch-toggle"
                  onChange={value => updateConfiguratorSettings(manufacturer)}
                  checked={!renderChecked(manufacturer)}
                />
                <span className="user-settings-toggle-position">Show</span>
              </div>
            </div>
          );
        })}
      </div>
    </BaseLayout>
  );
};

const mapStateToProps = ({ userSettings, authentication }: IRootState) => ({
  currentUserSettings: userSettings.currentUserSettings,
  account: authentication.account,
});

const mapDispatchToProps = {
  updateUserSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfiguratorSettings);
