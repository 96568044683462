import axios from 'axios';
import React, {useEffect, useState} from 'react';

const BOARD_TOKEN = '48b23f93-ebdb-8095-d672-c2d84d2a48b4';
const App_ID = '640484df508fa4451241fc5e';

function FeatureRequest() {
  const [ssoToken, setSsoToken] = useState(null);

  useEffect(() => {
    axios.get('api/canny-sso-token').then(response => {
      const {data} = response;
      setSsoToken(data);

      // @ts-ignore
      Canny('render', {
        boardToken: BOARD_TOKEN,
        basePath: null, // See step 2
        ssoToken: data,
      });
    });
  }, [])

  return (<>
    {ssoToken ? (
      <div data-canny />
    ) : ""}
  </>);

}

export default FeatureRequest;
