import React, { memo } from 'react';
import './addFloorPlanCard.scss';

interface IAddFloorPlanCard {
  onClick: any;
}

const AddFloorPlanCard = memo((props: IAddFloorPlanCard) => {
  return (
    <div className="add-floor-plan-card" onClick={props.onClick}>
      <img src="../../../content/images/icons/floor-plan-add.svg" alt="add icon" className="add-new-floorpla-card-image" />
      <span className="add-floor-plan-card-text">Create new Project</span>
    </div>
  );
});

export default AddFloorPlanCard;
