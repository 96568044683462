import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { ICrudGetAllAction, getSortState, IPaginationBaseState, JhiPagination } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './manufacturer.reducer';
import { IManufacturer } from 'app/shared/model/manufacturer.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface IManufacturerProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type IManufacturerState = IPaginationBaseState;

export class Manufacturer extends React.Component<IManufacturerProps, IManufacturerState> {
  state: IManufacturerState = {
    ...getSortState(this.props.location, ITEMS_PER_PAGE),
  };

  componentDidMount() {
    this.getEntities();
  }

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    const { activePage, itemsPerPage, sort, order } = this.state;
    this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
  };

  render() {
    const { manufacturerList, match, totalItems } = this.props;
    return (
      <div>
        <h2 id="manufacturer-heading">
          Manufacturers
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp; Create new Manufacturer
          </Link>
        </h2>
        <div className="table-responsive">
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={this.sort('id')}>
                  ID <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('name')}>
                  Name <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('enabled')}>
                  Enabled <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('websiteURL')}>
                  Website URL <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('companyLogo')}>
                  Company Logo <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('city')}>
                  City <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('state')}>
                  State <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('apiKey')}>
                  Api <FontAwesomeIcon icon="sort" />
                </th>
                {/*<th className="hand" onClick={this.sort('businessName')}>
                  Business Name <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('skills')}>
                  Skills <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('qualifySpecialStatus')}>
                  Qualify Special Status <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('cardNumber')}>
                  Card Number <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('cardHolder')}>
                  Card Holder <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('customerId')}>
                  Customer Id <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('month')}>
                  Month <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('year')}>
                  Year <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('cvc')}>
                  Cvc <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('status')}>
                  Status <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('refManufacturerId')}>
                  Ref Manufacturer Id <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('subscriptionId')}>
                  Subscription Id <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  User <FontAwesomeIcon icon="sort" />
                </th>*/}
                <th />
              </tr>
            </thead>
            <tbody>
              {manufacturerList.map((manufacturer, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${manufacturer.id}/edit`} color="primary" size="sm">
                      <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                    </Button>
                    <Button tag={Link} to={`${match.url}/${manufacturer.id}`} color="link" size="sm">
                      {manufacturer.id}
                    </Button>
                  </td>
                  <td>{manufacturer.name}</td>
                  <td>{manufacturer.enabled ? 'true' : 'false'}</td>
                  <td>{manufacturer.websiteURL}</td>
                  <td>
                    <img src={manufacturer.companyLogo} alt="product image" width="80" />
                  </td>
                  <td>{manufacturer.city}</td>
                  <td>{manufacturer.state}</td>
                  <td>{manufacturer.apiKey}</td>
                  {/*<td>{manufacturer.businessName}</td>
                  <td>{manufacturer.skills}</td>
                  <td>{manufacturer.qualifySpecialStatus}</td>
                  <td>{manufacturer.cardNumber}</td>
                  <td>{manufacturer.cardHolder}</td>
                  <td>{manufacturer.customerId}</td>
                  <td>{manufacturer.month}</td>
                  <td>{manufacturer.year}</td>
                  <td>{manufacturer.cvc}</td>
                  <td>{manufacturer.status}</td>
                  <td>{manufacturer.refManufacturerId}</td>
                  <td>{manufacturer.subscriptionId}</td>
                  <td>{manufacturer.userLogin ? manufacturer.userLogin : ''}</td>*/}
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${manufacturer.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                      </Button>

                      <Button tag={Link} to={`${match.url}/${manufacturer.id}/delete`} color="danger" size="sm">
                        <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Delete</span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Row className="justify-content-center">
          <JhiPagination
            activePage={this.state.activePage}
            onSelect={this.handlePagination}
            maxButtons={5}
            itemsPerPage={this.state.itemsPerPage}
            totalItems={totalItems}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ manufacturer }: IRootState) => ({
  manufacturerList: manufacturer.entities,
  totalItems: manufacturer.totalItems,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Manufacturer);
