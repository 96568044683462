import React from 'react';
import { Modal, Input } from 'reactstrap';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import CancelButton from '../CancelButton/CancelButton';
import './projectCodeInputModal.scss';

interface IProjectCodeInputModal {
  isOpen: boolean;
  closeModal: Function;
  projectCodeValue: any;
  setProjectCodeValue: Function;
  updateProjectCode: Function;
}

const ProjectCodeInputModal = (props: IProjectCodeInputModal) => {
  return (
    <Modal
      isOpen={props.isOpen}
      centered
      style={{ maxWidth: 700, width: window.screen.width - 1000 }}
      toggle={() => props.closeModal()}
      backdrop={true}
      autoFocus={false}
    >
      <div className="create-project-name-modal-container">
        <span className="create-project-name-modal-title">You must provide Project Code to continue</span>
        <span className="create-folder-modal-input-label">Project Code</span>
        <Input onChange={e => props.setProjectCodeValue(e.target.value)} autoFocus={true} className="create-project-name-modal-input" />
        <div className="create-project-name-modal-actions-container">
          <ConfirmButton title="Ok" confirmAction={() => props.updateProjectCode()} disabled={false} />
          <CancelButton title="Cancel" confirmAction={props.closeModal} />
        </div>
      </div>
    </Modal>
  );
};

export default ProjectCodeInputModal;
